import { Button, FormControl, Grid, IconButton, TextField, Typography } from "@mui/material";
import { IconTrashX } from "@tabler/icons-react";
import { Controller, useFieldArray } from "react-hook-form";
import { v4 as uuid } from "uuid";
import MapValuesComponent from "../../FormBuilder/components/ComponentEditor/EditorComponents/MapValuesComponent";

const StatusMessages = (props: any) => {
  const { control, namePrefix, views, view } = props;

  const {
    fields: statusMessages,
    append: appendStatusMessage,
    remove: removeStatusMessage,
  } = useFieldArray({
    control,
    name: `${namePrefix}.statusMessages`,
  });

  return (
    <Grid item xs={12}>
      <Typography mb={2}>Status Messages:</Typography>
      {statusMessages?.map((item: any, index: number) => {
        return (
          <Grid container spacing={2} mb={2} key={item.id}>
            <Grid item xs>
              <Controller
                control={control}
                name={`${namePrefix}.statusMessages[${index}]`}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth>
                    <TextField
                      aria-errormessage={"error message"}
                      onChange={e => {
                        onChange({ ...value, key: e.target.value });
                      }}
                      size={"small"}
                      value={value?.key}
                      fullWidth
                      label={"Status"}
                    />
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs>
              <Controller
                control={control}
                name={`${namePrefix}.statusMessages[${index}]`}
                render={({ field }) => {
                  return (
                    <MapValuesComponent
                      views={views}
                      view={view}
                      isStatusMessage={true}
                      label={"Message"}
                      enableMapValues={true}
                      value={field?.value?.value}
                      mapValuesObject={item.mapValuesObjectsMessage}
                      handleChangeProp={type => {
                        if (type === "mapValuesObject") {
                          return currentValue => {
                            field.onChange({ ...field.value, mapValuesObjectsMessage: currentValue.target.value });
                          };
                        } else {
                          return currentValue => {
                            field.onChange({ ...field.value, value: currentValue.target.value });
                          };
                        }
                      }}
                      valueString={"value"}
                      inputLabelProps={{ style: { fontSize: "12px" } }}
                      inputProps={{ style: { fontSize: "14px" } }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton
                onClick={() => {
                  removeStatusMessage(index);
                }}
              >
                <IconTrashX color='red' />
              </IconButton>
            </Grid>
          </Grid>
        );
      })}
      <Button
        variant='outlined'
        onClick={() => {
          appendStatusMessage({ id: uuid(), key: "", value: "", mapValuesObjectsMessage: {} });
        }}
      >
        Add row
      </Button>
    </Grid>
  );
};

export default StatusMessages;
