import { Box } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import DialogActions from "@mui/material/DialogActions";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { IconCopy } from "@tabler/icons-react";
import _ from "lodash";
import React, { FC, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ItemActions } from "src/components/BXUI/DataTable/ItemActions";
import { BXDataTableColumn } from "src/components/BXUI/DataTable/index";
import { PermissibleRender } from "src/components/PermissionValidation/PermissibleRender";
import { useAppState } from "src/features/appState/hooks";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import { formatDate } from "src/utils/generalUtils";
import { ActionButton, useReplaceDataPlaceholders } from "./ActionButton";
import CellValue from "./CellValue";

const useStyles = makeStyles((theme: Theme) => ({
  videoMedia: {
    height: "120px",
    border: `0.5px solid ${theme.palette.primary.main}`,
    backgroundColor: `${theme.palette.common.black}`,
    [theme.breakpoints.down("lg")]: {
      height: "80px",
    },
  },
  root: {
    "&:hover": {
      "& $copyContainer": { display: "block" },
    },
  },
  copyContainer: {
    display: "none",
  },
}));

export const TableItem: FC<{
  item: any;
  auth: any;
  columns: BXDataTableColumn[];
  actions?: any[];
  isSelected?: boolean;
  userInputsValues?: { [inputName: string]: any };
  isUserInput?: boolean;
  views: any;
  tableId: any;
  onSelectRow?: (object: any) => void;
  handleDragHover?: any;
  __data?: any;
  closeModal?: any;
  parentIds?: any;
  index?: any;
  order?: any;
  pageId?: any;
  handleUpdateElement?: any;
  queryKeys?: any[];
  viewName?: string;
  selectedRows?: any[];
  isSelectAllChecked: boolean;
  path?: string;
  dataEntry?: string;
  info?: {
    name?: string;
    showApiMode?: string;
    paginationMode?: string;
    enableMultipleSelection?: string;
    viewName?: string;
    actionRowConfig?: any;
    enableActionOnRowClick?: boolean;
  };
}> = ({
  item,
  columns,
  actions,
  auth,
  onSelectRow,
  userInputsValues,
  isUserInput,
  views,
  tableId,
  __data,
  closeModal,
  parentIds,
  queryKeys,
  pageId,
  viewName,
  info,
  selectedRows,
  path,
  index,
  dataEntry,
}) => {
  const classes = useStyles();
  const currentApp = useBuildxProviderValue("currentApp");
  const viewsState = useBuildxProviderValue("viewsState");
  const fqdnApp = useBuildxProviderValue("fqdnApp");
  const { replaceDataPlaceholders } = useReplaceDataPlaceholders({ viewName: info?.viewName });
  const [state, setState] = useState("normal");
  const isDisabled = () => state === "deleted";
  const isLoading = () => state === "loading";
  const { setValue, getValue } = useAppState();
  const navigate = useNavigate();
  const { watch } = useAppState();

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const getCellValue = useCallback(
    (item: any, col: any) => {
      const { source, type, hyperLinkPlaceholder } = col || {};
      const value = source;

      if (!value) return "";
      let result = replaceDataPlaceholders({
        queryString: value,
        item,
        viewsState,
        pageId,
        __data,
        env: currentApp?.env,
        fallback: "",
        index,
        selector: dataEntry ? `this.data.${dataEntry}[*]` : `this.data._body[*]`,
      });

      const typeIsDate = type === "Date Time";

      if (_.isObject(result)) {
        result = JSON.stringify(result);
      } else if (typeIsDate) result = formatDate(result, col?.dateFormat, "", col?.isUserLocalTime);

      return result;
    },
    [isUserInput, userInputsValues]
  );
  const CheckTooltip = useCallback(
    (props: any) => {
      const { children, col } = props;
      if (
        !(
          col?.type != "Boolean" &&
          !col?.allowWrap &&
          col?.type != "Image" &&
          col?.type != "Video" &&
          col?.type != "Audio" &&
          col?.type != "View Builder" &&
          getCellValue(item, col)
        )
      )
        return <>{children}</>;
      return <Tooltip title={getCellValue(item, col)}>{children}</Tooltip>;
    },
    [getCellValue, item]
  );

  const handleCheckboxChange = (item: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentSelectedRows = getValue(`${pageId}.${viewName}.selectedItems`, { pageId, viewName: info?.viewName }) || [];
    const isChecked = e.target.checked;
    if (isChecked) {
      if (!currentSelectedRows?.some(row => row?.__id === item?.__id)) {
        setValue(
          `${pageId}.${viewName}.selectedItems`,
          [...currentSelectedRows, item],

          { pageId, viewName: info?.viewName }
        );
      }
    } else {
      setValue(
        `${pageId}.${viewName}.selectedItems`,
        currentSelectedRows.filter((rowItem: any) => rowItem?.__id !== item?.__id),

        { pageId, viewName: info?.viewName }
      );
    }
  };

  const handleLinkClick = index => {
    const linkUrlValue =
      replaceDataPlaceholders({
        queryString: columns[index]?.linkUrl,
        item,
        viewsState,
        pageId,
        __data,
        env: currentApp?.env,
        selectedRows,
      }) || "";

    if (linkUrlValue) {
      if (linkUrlValue.startsWith("/")) {
        const pathPrefix = fqdnApp ? "" : currentApp?.slug;
        navigate(`${pathPrefix}${linkUrlValue}`);
      } else {
        window.open(linkUrlValue, columns[index]?.openLinkAs || "_blank");
      }
    } else {
      enqueueSnackbarRef("Invalid URL!", { variant: "error" });
    }
  };

  return (
    <React.Fragment>
      {info?.enableMultipleSelection && (
        <Checkbox
          sx={{
            margin: "13px 5px",
          }}
          color='primary'
          checked={selectedRows?.some(row => row?.__id === item?.__id)}
          onChange={handleCheckboxChange(item)}
          onClick={e => {
            e.stopPropagation();
          }}
        />
      )}

      <ActionButton
        tableId={tableId}
        path={path}
        item={item}
        viewName={viewName}
        queryKeys={queryKeys}
        pageId={pageId}
        _key={item.__id}
        isUserInput={isUserInput}
        views={views}
        tableAction={{ action: "this is not empty" }} // dummy action
        onSelectRow={onSelectRow}
        __data={__data}
        closeModal={closeModal}
        parentIds={parentIds}
        isLoadingForEntireChain={info?.actionRowConfig?.actionMappingConfig?.isLoadingForEntireChain}
        actionsMap={info?.actionRowConfig?.actionsMap}
        actionsKey={info?.actionRowConfig?.actionsKey}
        isChainMapped={info?.actionRowConfig?.isChainMapped}
        conditionKeyFromTable={info?.actionRowConfig?.conditionActionMapKey}
        dataEntry={dataEntry}
        index={index}
        actions={actions}
      >
        {columns.map((col, indexItem) => (
          <PermissibleRender isAllowed action={["VIEW"]} path={[path, col.name].join(".")}>
            {({ permitted }) =>
              permitted && (
                <React.Fragment key={col?.name + indexItem}>
                  {col?.type != "Actions" ? (
                    // <CheckTooltip col={col}>
                    <TableCell
                      align={(col?.alignColumn as any) ?? "left"}
                      sx={{
                        position: "relative",
                        ...(!col?.allowWrap && { textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }),
                        cursor: info?.enableActionOnRowClick ? "pointer" : "default",
                        minWidth: col?.type == "Video" || col?.type == "Audio" ? 300 : undefined,
                        color: col?.type == "Link" ? "#6060ff" : "inherit",
                        verticalAlign: col?.verticalAlign ?? "inherit",
                      }}
                      className={classes.root}
                      onClick={() => {
                        if (col?.type == "Link") {
                          handleLinkClick(indexItem);
                        }
                      }}
                    >
                      {col?.allowCopy &&
                        col?.type != "Boolean" &&
                        col?.type != "Image" &&
                        col?.type != "Video" &&
                        col?.type != "Audio" &&
                        col?.type != "HyperLink" &&
                        !!getCellValue(item, col) && (
                          <Box
                            sx={{
                              position: "absolute",
                              top: "35%",
                              left: 0,
                            }}
                            onClick={e => {
                              e.stopPropagation();
                              enqueueSnackbarRef?.("Copied!", {
                                variant: "success",
                              });
                              navigator.clipboard.writeText(getCellValue(item, col));
                            }}
                            className={classes.copyContainer}
                          >
                            <IconCopy />
                          </Box>
                        )}

                      <CellValue
                        __data={__data}
                        path={path}
                        item={item}
                        index={index}
                        pageId={pageId}
                        views={views}
                        col={col}
                        value={getCellValue(item, col)}
                        dataEntry={dataEntry}
                        viewName={info?.viewName}
                      />
                    </TableCell>
                  ) : (
                    // </CheckTooltip>
                    col?.type == "Actions" &&
                    !_.isEmpty(actions?.filter(action => !action.isGlobal)) && (
                      <TableCell
                        sx={{
                          verticalAlign: col?.verticalAlign ?? "inherit",
                        }}
                      >
                        <DialogActions
                          style={{ width: "100%", padding: 0, display: "flex", justifyContent: "flex-start", alignItems: "center" }}
                        >
                          <ItemActions
                            item={item}
                            path={path}
                            queryKeys={queryKeys}
                            viewName={viewName}
                            onSelectRow={onSelectRow}
                            disabled={isDisabled() || isLoading()}
                            onStateChange={newState => setState(newState)}
                            actions={actions?.filter?.(action => !action.isGlobal)}
                            isUserInput={isUserInput}
                            showEllipsisForActions={col?.showEllipsisForActions}
                            views={views}
                            tableId={tableId}
                            __data={__data}
                            closeModal={closeModal}
                            pageId={pageId}
                            parentIds={parentIds}
                            dataEntry={dataEntry}
                            index={index}
                          />
                        </DialogActions>
                      </TableCell>
                    )
                  )}
                </React.Fragment>
              )
            }
          </PermissibleRender>
        ))}
      </ActionButton>
    </React.Fragment>
  );
};
