import { IconButton } from "@mui/material";
import { joinObjects } from "hd-utils";
import { FC } from "react";
import { BXIcon } from "src/components/BXUI/Icon";
import { CustomIconButtonProps } from "./types";

export const CustomIconButton: FC<CustomIconButtonProps> = props => {
  // TODO: remove this after supporting action config upload remove the onclick
  return (
    <IconButton
      onClick={props?.onClick}
      size={props?.size}
      sx={props?.sx}
      disabled={props?.loading || props?.disabled}
      style={joinObjects(
        {
          background: props?.backgroundColor,
          width: "100%",
          height: "100%",
          border: `${props?.borderWidth} solid transparent`,
          borderColor: props?.borderColor,
          padding: 0,
        },
        props?.style
      )}
    >
      <BXIcon
        width={props?.iconSize}
        height={props?.iconSize}
        icon={props?.iconConfig?.icon || props?.iconName || "User"}
        url={props?.iconConfig?.url}
        visibility={props?.iconConfig?.visibility}
        color={props?.iconColor || "#fff"}
        style={{ maxWidth: "100%", maxHeight: "100%" }}
      />
    </IconButton>
  );
};
