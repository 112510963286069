const commentHints: { [key: string]: string } = {
  "{this}": `**This refers to the current context**`,
  "{$global}": "Global variables across the app",
  "{$page}": "Page-specific data or actions",
  "{$app}": "App-level configuration and settings",
  "{$comp}": "Component-specific properties and methods",
  "{$}": "equivalent to current item: this.component.data.items[*]",
  "{this.data}": "This refers to the data within the current context",
  "{this.state}": "This refers to the current view state",
  "{this.errors}": "This refers to any validation errors",
  "{this.parent}": "This refers to the direct parent component reference",
  "{this.parents}": "This refers to an array of references",
  "{this.parentView}": "This refers to the parent view",
  "{this.props}": "This refers to the props passed to the view",
  "{this.compRef}": "",
  "{this.vars}": "This refers to local variables defined in the current context",
  "{this.actions}": "This refers to an array of objects actions",
  "{this.columns}": "This refers to an array of objects column",
  "{this.dirty}": "This indicates whether the view fields state has been modified",
  "{this.children}": "This refers to an array of references",
  "{this.data.<jsonPath>}": "This refers to the JSON path within your data",
  "{this.data._status}": "This indicates the current status of the request",
  "{this.data._headers}": "This refers to the headers returned from the request",
  "{this.data._messages}": "This refers to any messages associated with the data",
  "{this.data._pagination}": "This refers to pagination details in the data",
  "{this.data._request}": "This refers to the original request made to fetch the data",
  "{this.data._body}": "This refers to the body of the data response",
  "{this.data._request.headers}": "This refers to the headers of the original request",
  "{this.data._request.payload}": "This refers to the payload of the original request",
  "{this.data._request.payload.queryParams}": "This refers to the query parameters in the request payload",
  "{this.data._request.payload.body}": "This refers to the body of the request payload",
  "{this.data.errors.list}": "This refers to a list of validation errors",
  "{this.data.errors.hasError}": "This indicates whether there are any validation errors",
};
export const getVariableSuggestions = (currentApp: any) => {
  const configVars = currentApp?.env?.config?.variables ? Object.keys(currentApp.env.config.variables) : [];
  return configVars.map(key => `{{${key}}}`);
};

export const getViewSuggestions = (views: any) => {
  return views.map((view: any) => `{${view.info?.viewName}}`).filter((name: string) => name !== undefined);
};

export const getVariableSuggestionsPage = (currentApp: any) => {
  const configVars = currentApp?.env?.config?.variables ? Object.keys(currentApp.env.config.variables) : [];
  return configVars.map(key => `{$app.env.${key}}`);
};

export const getComponentKeys = (view: any) => {
  return view?.dataSource?.formBuilder?.map((component: any) => component?.props?.key) || [];
};

export const getComponentProps = (view: any) => {
  const extractChildren = (components: any[]): any[] => {
    return components.map(component => {
      const current = {
        id: component?.id,
        type: component?.type,
        props: component?.props,
        config: component?.config,
        children: component?.children ? extractChildren(component.children) : [],
      };

      return current;
    });
  };

  return view?.dataSource?.formBuilder ? extractChildren(view.dataSource.formBuilder) : [];
};

export const processDirectChildren = (component, key, options, parentKey = "") => {
  if (component.children && component.children.length > 0) {
    component.children.forEach(child => {
      const childKey = parentKey ? `${parentKey}.${child.props.key}` : child.props.key;
      options.push(`{this.${key}.${childKey}}`);
    });
  }
};

export const getResponseOptionByActionSourceType = (view, key, prefix) => {
  const formBuilder = view.dataSource?.formBuilder || [];
  const responseOptions: any = [];

  formBuilder.forEach(component => {
    const actionsMap = component?.actionsMap || {};

    if (actionsMap.default) {
      actionsMap.default.forEach(action => {
        const actionSourceType = action?.actionConfig?.actionSourceType?.type;

        if (actionSourceType) {
          if (actionSourceType === "form-builder") {
            responseOptions.push(`{${prefix}.${key}.response.form-builder}`);
          } else if (actionSourceType === "API") {
            responseOptions.push(`{${prefix}.${key}.response.api}`);
          }
        }
      });
    }
  });

  return responseOptions;
};

export const getSelectedViewName = selectedView => {
  return selectedView?.info?.viewName ? `{${selectedView.info.viewName}` : "";
};

export const getComponentType = (view: any, key: string) => {
  const component = view?.dataSource?.formBuilder?.find((comp: any) => comp?.props?.key === key);
  return component?.type || "";
};

export const getViewVariableSuggestions = view => {
  const vars = view?.dataSource?.varsConfig?.vars || [];
  return vars.map(varItem => `{${view.info?.viewName}.vars.${varItem.varKey}}`);
};

export const getThisVariableSuggestions = view => {
  const vars = view?.dataSource?.varsConfig?.vars || [];
  return vars.map(varItem => `{this.vars.${varItem.varKey}}`);
};

export const getOptionByComponentType = (prefix: string, key: string, componentType: string) => {
  switch (componentType) {
    case "ColorPicker":
      return [
        `{${prefix}.${key}.state.r}`,
        `{${prefix}.${key}.state.g}`,
        `{${prefix}.${key}.state.b}`,
        `{${prefix}.${key}.state.rHex}`,
        `{${prefix}.${key}.state.gHex}`,
        `{${prefix}.${key}.state.bHex}`,
        `{${prefix}.${key}.state.rgb}`,
        `{${prefix}.${key}.state.hex}`,
        `{${prefix}.${key}.state._actualHexColor}`,
      ];
    case "DatePicker":
    case "DateTimePicker":
      return [`{${prefix}.${key}.state.utcISO}`, `{${prefix}.${key}.state.userLocalISO}`, `{${prefix}.${key}.state.epoch}`];
    case "FileUploadInput":
      return [
        `{${prefix}.${key}.state.key}`,
        `{${prefix}.${key}.state.url}`,
        `{${prefix}.${key}.state.file}`,
        `{${prefix}.${key}.state.ext}`,
      ];
    case "CustomAutoCompleteBX": {
      const options = [`{${prefix}.${key}.state.userInput}`, `{${prefix}.${key}.state.userOptions}`];
      return options;
    }
    case "paginationBar": {
      const options = [
        `{${prefix}.${key}.state.currentPage}`,
        `{${prefix}.${key}.state.currentCursor}`,
        `{${prefix}.${key}.state.fwdCursor}`,
        `{${prefix}.${key}.state.prevCursor}`,
        `{${prefix}.${key}.state.firstCursor}`,
        `{${prefix}.${key}.state.hasMore}`,
      ];
      return options;
    }
    default:
      return [];
  }
};

export const getSecondLevelOptions = (prefix: string, key: string, optionKey: string) => {
  if (optionKey === `{${prefix}.${key}.state.userOptions}`) {
    return [
      `{${prefix}.${key}.state.userOptions.label}`,
      `{${prefix}.${key}.state.userOptions.value}`,
      `{${prefix}.${key}.state.userOptions.selectedData}`,
    ];
  }
  return [];
};

export const getHintForOption = (option: string, views: any, currentApp: any, view: any): any => {
  const variableSuggestions = getVariableSuggestions(currentApp);
  const viewSuggestions = getViewSuggestions(views);
  const componentKeys = getComponentKeys(view);

  for (const key of componentKeys) {
    if (option.startsWith(`{this.${key}.}`)) {
      return "This indicates a specific component in the view.";
    }
  }

  if (variableSuggestions.includes(option)) {
    return "Environment variable Key";
  } else if (viewSuggestions.includes(option)) {
    return "This refers to the Specific View";
  } else {
    return commentHints[option] || "No hint available for this option.";
  }
};
