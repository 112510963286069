import { LoadingButton } from "@mui/lab";
import { useTheme } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch } from "react-redux";
import AnimateButton from "src/components/extended/AnimateButton";
import { setProviderState } from "src/features/buildxProvider/buildxProviderSlice";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import { getAuth } from "src/utils/buildxProviderOperations";

export const CurrentAppUserMenu: any = () => {
  const currentApp = useBuildxProviderValue("currentApp");
  const isSwitchingMode = useBuildxProviderValue("isSwitchingMode");
  const isAdministrationMode = useBuildxProviderValue("isAdministrationMode");
  const appProfiles = useBuildxProviderValue("appProfiles");
  const currentProfileId = useBuildxProviderValue("currentProfileId");
  const appTokens = useBuildxProviderValue("appTokens");
  const dispatch = useDispatch();
  const { palette } = useTheme();

  if (!currentApp || isAdministrationMode) {
    return <></>;
  }

  const { token } = getAuth(currentApp!.id, currentProfileId, appProfiles, appTokens, currentApp) || {};
  if (!token) return <></>;

  return (
    !isAdministrationMode && (
      <div style={{ paddingInlineEnd: 16, paddingInlineStart: 16, paddingBottom: 0, paddingTop: 8 }}>
        {!isSwitchingMode ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                {appProfiles?.[currentApp?.id]?.map((profile: any) => {
                  const { user } = getAuth(
                    currentApp?.id,
                    currentProfileId,
                    appProfiles,
                    appTokens,
                    currentApp,
                    profile?.id,
                    profile?.signInWithGoogle
                  );

                  return (
                    <Tooltip key={profile?.id} title={profile?.profileName || user?.name}>
                      <Grid
                        item
                        onClick={() => {
                          localStorage.setItem(currentApp?.id + "-profileId", profile?.id);
                          dispatch(setProviderState({ loadingApps: true, currentProfileId: profile?.id }));
                          setTimeout(() => {
                            dispatch(setProviderState({ loadingApps: false }));
                          }, 0);
                        }}
                      >
                        <AnimateButton>
                          <Avatar
                            style={{
                              cursor: "pointer",
                              border: currentProfileId == profile?.id ? `2px solid ${palette.primary.main}` : "2px solid",
                              boxSizing: "content-box",
                            }}
                            src={user?.profilePhoto}
                          />
                        </AnimateButton>
                      </Grid>
                    </Tooltip>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant='contained'
                onClick={() => {
                  dispatch(setProviderState({ isSwitchingMode: true }));
                }}
              >
                Manage Profiles
              </Button>
            </Grid>
          </Grid>
        ) : (
          <LoadingButton
            fullWidth
            onClick={() => {
              dispatch(setProviderState({ isSwitchingMode: false }));
            }}
            color='error'
          >
            Return to {currentApp?.name || "App"}
          </LoadingButton>
        )}
      </div>
    )
  );
};
