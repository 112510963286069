import { Autocomplete, Box, ListItem, TextField, Tooltip, Typography } from "@mui/material";
import {
  IconAppWindow,
  IconBox,
  IconComponents,
  IconEqual,
  IconEye,
  IconJson,
  IconListTree,
  IconLogin,
  IconSeeding,
  IconVersions,
  IconWorld,
} from "@tabler/icons-react";

import React, { FC, useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import {
  getComponentKeys,
  getComponentProps,
  getComponentType,
  getHintForOption,
  getOptionByComponentType,
  getResponseOptionByActionSourceType,
  getSelectedViewName,
  getThisVariableSuggestions,
  getVariableSuggestions,
  getVariableSuggestionsPage,
  getViewVariableSuggestions,
  processDirectChildren,
} from "../syntaxHintUtils";

interface AutoCompleteSyntaxProps {
  label?: string;
  view: any;
  views: any;
  onChange: any;
  fullWidth?: any;
  value?: string;
  size?: any;
  variant?: any;
  onFocus?: any;
  onBlur?: any;
  InputLabelProps?: any;
  inputProps?: any;
  disabled?: any;
  select?: any;
  placeholder?: any;
  type?: any;
  required?: any;
  name?: any;
  sx?: any;
  id?: any;
  error?: any;
  ariaErrormessage?: any;
}

export const AutoCompleteSyntax: FC<AutoCompleteSyntaxProps> = ({
  label,
  fullWidth = true,
  value = "",
  onChange,
  view,
  views,
  size = "medium",
  variant = "outlined",
  InputLabelProps,
  inputProps,
  disabled,
  select,
  type,
  placeholder,
  required,
  name,
  sx,
  id,
  error,
  ariaErrormessage,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<string[]>([]);
  const [selectedView, setSelectedView] = useState<any>(null);
  const [inputValueState, setInputValueState] = useState<string>("");
  const currentApp = useBuildxProviderValue("currentApp");
  const inputRef = useRef<HTMLInputElement>(null);
  const viewSuggestions = views?.map((view: any) => `{${view?.info?.viewName}}`).filter((name: string) => name !== undefined);

  const stripBraces = (str: string) => str.replace(/[{}]/g, "");
  const escapeRegex = (string: string) => {
    return string.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&");
  };

  const getAllSuggestions = () => {
    return [
      "{this}",
      "{$global}",
      "{$page}",
      "{$app}",
      "{$comp}",
      "{$buildx}",
      "{buildxLogin}",
      "{$}",
      "{=}",
      ...views?.map((view: any) => `{${view.info?.viewName}}`).filter((name: string) => name !== undefined),
    ];
  };

  const updateOptions = (inputValue: string) => {
    const strippedValue = stripBraces(inputValue);
    const viewName = getSelectedViewName(selectedView);
    const componentKeys = getComponentKeys(selectedView);
    const thisComponentKeys = getComponentKeys(view);
    const componentProps = getComponentProps(selectedView);
    const thisComponentProps = getComponentProps(view);
    let matched = false;

    switch (true) {
      case views?.some(view =>
        componentKeys.some(key => {
          if (strippedValue.startsWith(`${view.info.viewName}.${key}.response`)) {
            const options = getResponseOptionByActionSourceType(view, key, view.info.viewName);
            setOptions(options);
            matched = true;
            return true;
          }
          return false;
        })
      ):
        break;
      case views?.some(view =>
        componentKeys.some(key => {
          if (strippedValue.startsWith(`${view.info.viewName}.${key}.props`)) {
            setOptions([`{${view.info.viewName}.${key}.props.key}`]);
            matched = true;
            return true;
          }
          return false;
        })
      ):
        break;
      case views?.some(view =>
        componentKeys.some(key => {
          if (strippedValue.startsWith(`${view.info.viewName}.${key}.state`)) {
            let componentType = getComponentType(view, key);
            const options = getOptionByComponentType(view.info.viewName, key, componentType);
            setOptions(options);
            matched = true;
            return true;
          }
          return false;
        })
      ):
        break;
      case views?.some(view =>
        componentKeys.some(key => {
          if (strippedValue.startsWith(`${view.info.viewName}.${key}`)) {
            setOptions([
              `{${view.info.viewName}.${key}.data}`,
              `{${view.info.viewName}.${key}.state}`,
              `{${view.info.viewName}.${key}.errors}`,
              `{${view.info.viewName}.${key}.parent}`,
              `{${view.info.viewName}.${key}.parents}`,
              `{${view.info.viewName}.${key}.parentView}`,
              `{${view.info.viewName}.${key}.props}`,
              `{${view.info.viewName}.${key}.response}`,
              `{${view.info.viewName}.${key}.dirty}`,
              `{${view.info.viewName}.${key}.children}`,
              `{${view.info.viewName}.${key}.isLoading}`,
            ]);
            matched = true;
            return true;
          }
          return false;
        })
      ):
        break;
      case views?.some(view => strippedValue.startsWith(`${view.info?.viewName}.errors`)):
        setOptions([`${viewName}.errors.list}`, `${viewName}.errors.hasError}`, `${viewName}.errors.error}`]);
        matched = true;
        break;
      case views?.some(view => strippedValue.startsWith(`${view.info?.viewName}.vars`)):
        const viewVarsuggestions = getViewVariableSuggestions(selectedView);
        if (viewVarsuggestions.length > 0) {
          setOptions(viewVarsuggestions);
          matched = true;
        }
        break;
      case views?.some(view => strippedValue.startsWith(`${view.info?.viewName}.dirty`)):
        setOptions([`${viewName}.dirty.fields}`, `${viewName}.dirty.isDirty}`]);
        matched = true;
        break;
      case views?.some(view => strippedValue.startsWith(`${view.info?.viewName}.data._request.payload.`)):
        setOptions([`${viewName}.data._request.payload.queryParams}`, `${viewName}.data._request.payload.body}`]);
        matched = true;
        break;
      case views?.some(view => strippedValue.startsWith(`${view.info?.viewName}.data._request.`)):
        setOptions([`${viewName}.data._request.headers}`, `${viewName}.data._request.payload}`]);
        matched = true;
        break;
      case views?.some(view => strippedValue.startsWith(`${view.info?.viewName}.data._body.`)):
        setOptions([`${viewName}.data._body.<jsonPath>`]);
        matched = true;
        break;
      case views?.some(view => strippedValue.startsWith(`${view.info?.viewName}.data._headers.`)):
        setOptions([
          `${viewName}.data._headers.Date}`,
          `${viewName}.data._headers.Content-Type}`,
          `${viewName}.data._headers.Transfer-Encoding}`,
          `${viewName}.data._headers.Connection}`,
        ]);
        matched = true;
        break;
      case views?.some(view => strippedValue.startsWith(`${view.info?.viewName}.data`)):
        setOptions([
          `${viewName}.data._body}`,
          `${viewName}.data._status}`,
          `${viewName}.data._headers}`,
          `${viewName}.data._messages}`,
          `${viewName}.data._pagination}`,
          `${viewName}.data._request}`,
          `${viewName}.data.<jsonPath>}`,
        ]);
        matched = true;
        break;
      case views?.some(view => {
        const viewName = view.info?.viewName;
        const regex = new RegExp(`^${viewName}\\.actions\\[(\\d+)\\](\\.)?$`);
        if (regex.test(strippedValue)) {
          const match = strippedValue.match(/\[(\d+)\]/);
          if (match && match[1]) {
            const columnIndex = match[1];
            setOptions([`${viewName}.actions[${columnIndex}].response}`, `${viewName}.actions[${columnIndex}].response}`]);
            matched = true;
          }
          return true;
        }
        return false;
      }):
        break;
      case views?.some(view => {
        const viewName = view.info?.viewName;
        const regex = new RegExp(`^${viewName}\\.columns\\[(\\d+)\\](\\.)?$`);
        if (regex.test(strippedValue)) {
          const match = strippedValue.match(/\[(\d+)\]/);
          if (match && match[1]) {
            const columnIndex = match[1];
            setOptions([`${viewName}.columns[${columnIndex}].props}`]);
            matched = true;
          }
          return true;
        }
        return false;
      }):
        break;
      case views?.some(view => strippedValue.startsWith(`${view.info?.viewName}.`)):
        setOptions([
          `${viewName}.data}`,
          `${viewName}.state}`,
          `${viewName}.errors}`,
          `${viewName}.parent}`,
          `${viewName}.parents}`,
          `${viewName}.parentView}`,
          `${viewName}.props}`,
          `${viewName}.vars}`,
          `${viewName}.dirty}`,
          `${viewName}.children}`,
          `${viewName}.isLoading}`,
          ...(selectedView?.type === "data-table" ? [`${viewName}.actions}`, `${viewName}.columns}`] : []),
          ...componentKeys.map(key => `${viewName}.${key}}`),
        ]);
        matched = true;
        break;
      case views?.some(view =>
        thisComponentKeys.some(key => {
          if (strippedValue.startsWith(`this.${key}.props`)) {
            setOptions([`{this.${key}.props.key}`]);
            matched = true;
            return true;
          }
          return false;
        })
      ):
        break;
      case views?.some(view =>
        thisComponentKeys.some(key => {
          if (strippedValue.startsWith(`this.${key}.state`)) {
            let componentType = getComponentType(view, key);
            const options = getOptionByComponentType("this", key, componentType);
            setOptions(options);
            matched = true;
            return true;
          }
          return false;
        })
      ):
        break;
      case views?.some(view =>
        thisComponentKeys.some(key => {
          if (strippedValue.startsWith(`this.${key}.response`)) {
            const options = getResponseOptionByActionSourceType(view, key, "this");
            setOptions(options);
            matched = true;
            return true;
          }
          return false;
        })
      ):
        break;
      case views?.some(view =>
        thisComponentKeys.some(key => {
          if (strippedValue.startsWith(`this.${key}`)) {
            let options = [
              `{this.${key}.data}`,
              `{this.${key}.state}`,
              `{this.${key}.errors}`,
              `{this.${key}.parent}`,
              `{this.${key}.parents}`,
              `{this.${key}.parentView}`,
              `{this.${key}.props}`,
              `{this.${key}.response}`,
              `{this.${key}.responses}`,
              `{this.${key}.dirty}`,
              `{this.${key}.children}`,
              `{this.${key}.isLoading}`,
            ];
            const component = thisComponentProps.find(comp => comp.props.key === key);
            if (component) {
              processDirectChildren(component, key, options);
              component.children?.forEach(child => {
                if (child.props.key && strippedValue.startsWith(`this.${key}.${child.props.key}`)) {
                  processDirectChildren(child, key, options, child.props.key);
                }
              });
            }
            setOptions(options);
            matched = true;
            return true;
          }
          return false;
        })
      ):
        break;
      case strippedValue.startsWith("this.data._request.payload"):
        setOptions(["{this.data._request.payload.queryParams}", "{this.data._request.payload.body}"]);
        matched = true;
        break;
      case strippedValue.startsWith("this.data._request"):
        setOptions(["{this.data._request.headers}", "{this.data._request.payload}"]);
        matched = true;
        break;
      case strippedValue.startsWith("this.data._body"):
        setOptions(["{this.data._body.<jsonPath>}"]);
        matched = true;
        break;
      case strippedValue.startsWith("this.data._headers"):
        setOptions([
          "{this.data._headers.Date}",
          "{this.data._headers.Content-Type}",
          "{this.data._headers.Transfer-Encoding}",
          "{this.data._headers.Connection}",
        ]);
        matched = true;
        break;
      case strippedValue.startsWith("this.data"):
        setOptions([
          "{this.data._body}",
          "{this.data._status}",
          "{this.data._headers}",
          "{this.data._messages}",
          "{this.data._pagination}",
          "{this.data._request}",
          "{this.data.<jsonPath>}",
        ]);
        matched = true;
        break;
      case strippedValue.startsWith("this.errors"):
        setOptions(["{this.errors.list}", "{this.errors.hasError}", "{this.errors.error}"]);
        matched = true;
        break;
      case strippedValue.startsWith("this.vars"):
        const suggestions = getThisVariableSuggestions(view);
        if (suggestions.length > 0) {
          setOptions(suggestions);
          matched = true;
        }
        break;
      case strippedValue.startsWith("this.dirty"):
        setOptions(["{this.dirty.fields}", "{this.dirty.isDirty}"]);
        matched = true;
        break;
      case strippedValue.startsWith("this.columns"):
        const columnRegex = /\[(\d+)\]/;
        const match = strippedValue.match(columnRegex);
        if (match && match[1]) {
          const columnIndex = match[1];
          setOptions([`this.columns[${columnIndex}].props`]);
          matched = true;
        }
        break;
      case strippedValue.startsWith("this"):
        setOptions([
          "{this.data}",
          "{this.state}",
          "{this.errors}",
          "{this.parents}",
          "{this.parent}",
          "{this.parentView}",
          "{this.props}",
          "{this.vars}",
          "{this.dirty}",
          "{this.children}",
          "{this.isLoading}",
          ...(view?.type === "data-table" ? ["{this.actions}", "{this.columns}"] : []),
          ...thisComponentKeys.map(key => `{this.${key}}`),
        ]);
        matched = true;
        break;
      case strippedValue.startsWith("$global.cookies"):
        setOptions(["{$global.cookies.<cookie_name>}"]);
        matched = true;
        break;
      case strippedValue.startsWith("$global.browser"):
        setOptions(["{$global.browser.deviceType}", "{$global.browser.osVersion}", "{$global.browser.timeZone}"]);
        matched = true;
        break;
      case strippedValue.startsWith("$global.localStorage"):
        setOptions(["{$global.localStorage.<local_storage_key>}"]);
        matched = true;
        break;
      case strippedValue.startsWith("$global.location.pathVar"):
        setOptions(["{$global.location.pathVar.<pathVar>}"]);
        matched = true;
        break;
      case strippedValue.startsWith("$global.location.queryParams"):
        setOptions(["{$global.location.queryParams.<queryParams>}"]);
        matched = true;
        break;
      case strippedValue.startsWith("$global.location"):
        setOptions([
          "{$global.location.queryParams}",
          "{$global.location.host}",
          "{$global.location.path}",
          "{$global.location.url}",
          "{$global.location.pathVar}",
          "{$global.location.state}",
          "{$global.location.params}",
        ]);
        matched = true;
        break;
      case strippedValue.startsWith("$comp.props"):
        setOptions([`{$comp.props.key}`]);
        matched = true;
        break;
      case strippedValue.startsWith("buildxLogin"):
        setOptions([`{buildxLogin.email}`, `{buildxLogin.password}`, `{buildxLogin.recaptcha}`]);
        matched = true;
        break;
      case strippedValue.startsWith("$buildx"):
        setOptions([`{$buildx.appVersion}`]);
        matched = true;
        break;
      case strippedValue.startsWith("$comp"):
        setOptions([
          `{$comp.data}`,
          `{$comp.state}`,
          `{$comp.errors}`,
          `{$comp.parent}`,
          `{$comp.parents}`,
          `{$comp.parentView}`,
          `{$comp.props}`,
          `{$comp.response}`,
          `{$comp.dirty}`,
          `{$comp.children}`,
          `{$comp.isLoading}`,
        ]);
        matched = true;
        break;
      case strippedValue.startsWith("$global"):
        setOptions(["{$global.location}", "{$global.localStorage}", "{$global.cookies}", "{$global.browser}, {$global.breakpoint}"]);
        matched = true;
        break;
      case strippedValue.startsWith("$page.errors"):
        setOptions(["{$page.errors.list}", "{$page.errors.error}", "{$page.errors.hasError}"]);
        matched = true;
        break;
      case strippedValue.startsWith("$page"):
        setOptions(["{$page.errors}", "{$page.parent}", "{$page.props}"]);
        matched = true;
        break;
      case strippedValue.startsWith("$app.env"):
        setOptions(getVariableSuggestionsPage(currentApp));
        matched = true;
        break;
      case strippedValue.startsWith("="):
        setOptions(["{=.sum}", "{=.concate}", "{=.array}", "{=.buildLinks}", "{=.eval}", "{=.join}", "{=.urlEncode}", "{=.contrast}"]);
        matched = true;
        break;
      case strippedValue.startsWith("$app"):
        setOptions(["{$app.env}", "{$app.props}"]);
        matched = true;
        break;
      default:
        setOptions(getAllSuggestions());
        matched = true;
        break;
    }

    if (matched) {
      setOpen(true);
    }
  };

  const moveCursorToEnd = (inputElement: HTMLInputElement, position: number) => {
    requestAnimationFrame(() => {
      if (inputElement.setSelectionRange) {
        inputElement.setSelectionRange(position, position);
      }
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const firstTwoChars = inputValue.slice(0, 2);
    const lastTwoChars = inputValue.slice(-2);

    if (inputValue.charAt(0) === "=") {
      setInputValueState(inputValue);
    }

    if (firstTwoChars === "{{") {
      if (lastTwoChars !== "}}") {
        const newValue = `${inputValue}}`;
        onChange({ target: { value: newValue } });
        setInputValueState(newValue);
        moveCursorToEnd(e.target, newValue.length - 2);
      }
      return;
    }

    if (inputValue.slice(-1) === "{") {
      if (lastTwoChars !== "}}") {
        const newValue = `${inputValue}}`;
        onChange({ target: { value: newValue } });
        setInputValueState(newValue);
        moveCursorToEnd(e.target, newValue.length - 1);
      }
      return;
    }

    if (inputValue.charAt(0) === "{" && inputValue.charAt(inputValue.length - 1) === "}") {
      updateOptions(inputValue);
    }

    if (inputValue.includes("{") || inputValue.includes("}")) {
      const strippedInput = inputValue.replace(/[{}]/g, "");
      if (strippedInput.trim()) {
        updateOptions(strippedInput);
        setOpen(true);
      } else {
        setOpen(false);
      }
    } else if (inputValue.trim() === "") {
      setOptions([]);
      setOpen(false);
      return;
    }

    const selectedView = views?.find((view: any) => `{${view.info?.viewName}` === inputValue);
    if (selectedView && inputValue.charAt(inputValue.length - 1) !== ".") {
      setSelectedView(selectedView);
    }

    onChange(e);
  };

  const handleAutoCompleteInputChange = (newInputValue: string) => {
    const selectedView = views?.find((view: any) => `{${view?.info?.viewName}}` === newInputValue);

    if (inputValueState.charAt(0) === "{" && inputValueState.charAt(inputValueState.length - 1) === "}") {
      const newInput = newInputValue.replace(/\}$/, "");
      onChange({ target: { value: newInput } });
      if (inputRef.current) {
        let cursorPosition = newInput.length;
        if (newInputValue.startsWith("{{") && newInputValue.endsWith("}}")) {
          cursorPosition = newInput.length - 1;
        }
        moveCursorToEnd(inputRef.current, cursorPosition);
      }
      if (selectedView) {
        setSelectedView(selectedView);
      }
    }

    onChange({ target: { value: newInputValue } });
  };

  const renderOptionIcon = (option: string, referencesArray: any[], isThisReference: any, isViewReference: any, isParentReference: any) => {
    const componentKeyMatch = option.match(/{this\.(\w+)}/);
    const viewName = getSelectedViewName(selectedView).trim();
    const escapedViewName = escapeRegex(viewName.replace(/{/g, "").trim());
    const regex = new RegExp(`{${escapedViewName}\\.(\\w+)}`);
    const componentKeyMatch2 = option.match(regex);

    let icon = <IconEye width={14} height={14} />;
    if (option.includes("<jsonPath>")) {
      icon = <IconJson width={14} height={14} />;
    } else if (componentKeyMatch) {
      const componentKey = componentKeyMatch[1];
      const componentKeys = getComponentKeys(view);
      if (componentKeys.includes(componentKey)) {
        icon = <IconComponents width={14} height={14} />;
      }
    } else if (componentKeyMatch2) {
      const componentKey = componentKeyMatch2[1];
      const componentKeys = getComponentKeys(selectedView);
      if (componentKeys.includes(componentKey)) {
        icon = <IconComponents width={14} height={14} />;
      }
    } else if (option.includes("this")) {
      icon = <IconListTree width={14} height={14} />;
    } else if (option.includes("$global")) {
      icon = <IconWorld width={14} height={14} />;
    } else if (option.includes("$app")) {
      icon = <IconBox width={14} height={14} />;
    } else if (option.includes("$page")) {
      icon = <IconAppWindow width={14} height={14} />;
    } else if (option.includes("$comp")) {
      icon = <IconComponents width={14} height={14} />;
    } else if (option.includes("$buildx")) {
      icon = <IconVersions width={14} height={14} />;
    } else if (option.includes("buildxLogin")) {
      icon = <IconLogin width={14} height={14} />;
    } else if (option.includes("{{")) {
      icon = <IconSeeding width={14} height={14} />;
    } else if (option.includes("=")) {
      icon = <IconEqual width={14} height={14} />;
    }

    return (
      <Box position='relative' display='inline-block'>
        {icon}
        {(isThisReference ||
          isViewReference ||
          isParentReference ||
          referencesArray.includes("children") ||
          referencesArray.includes("parents") ||
          referencesArray.includes("parentView") ||
          referencesArray.includes("response")) && (
          <Box position='absolute' top='-4px' left='-4px'>
            <Typography fontSize={6} color='#1877F2'>
              Ref
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  const filterOptions = (options: string[], { inputValue }: { inputValue: string }) => {
    const strippedInput = stripBraces(inputValue);
    return options.filter(option => stripBraces(option).toLowerCase().includes(strippedInput.toLowerCase()));
  };

  useEffect(() => {
    if (value === inputValueState) {
      if (inputValueState.trim() === "") {
        setOptions([]);
        setOpen(false);
        return;
      }

      if (inputValueState.startsWith("=")) {
        setOptions(["=contrast", "=concate", "=array", "=sum", "=buildLinks", "=eval", "=join", "=urlEncode", "=concate"]);
        setOpen(true);
        return;
      }

      if (inputValueState.startsWith("{{") && inputValueState.endsWith("}}")) {
        setOptions(getVariableSuggestions(currentApp));
        setOpen(true);
        return;
      }
      if (
        inputValueState.charAt(0) === "{" &&
        inputValueState.charAt(inputValueState.length - 1) === "}" &&
        !inputValueState.startsWith("{{") &&
        !inputValueState.endsWith("}}")
      ) {
        updateOptions(inputValueState);
        setOpen(true);
      }
    }
  }, [inputValueState, value]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.code === "Space") {
        setOpen(true);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Autocomplete
      freeSolo
      options={options}
      open={open && options.length > 0}
      filterOptions={filterOptions}
      onClose={() => setOpen(false)}
      disableClearable
      inputValue={value}
      getOptionDisabled={option =>
        option.includes("<jsonPath>") ||
        option.includes("<local_storage_key>") ||
        option.includes("<cookie_name>") ||
        option.includes("<pathVar>") ||
        option.includes("<queryParams>")
      }
      componentsProps={{ popper: { style: { width: "300px" } } }}
      onInputChange={(_, newInputValue) => handleAutoCompleteInputChange(newInputValue)}
      renderOption={(props, option) => {
        const isThisReference = option.startsWith("{this}");
        const isEqualOperation = option.startsWith("=");
        const isViewReference = viewSuggestions.includes(option);
        const isParentReference = /\bparent\b/.test(option);
        const referencesArray: any = [];
        const hint = getHintForOption(option, views, currentApp, view);
        const lastKey = option.replace(/[{}]/g, "").split(".").pop();

        if (/\bchildren\b/.test(option)) referencesArray.push("children");
        if (/\bcolumns\b/.test(option)) referencesArray.push("columns");
        if (/\bactions\b/.test(option)) referencesArray.push("actions");
        if (/\bparents\b/.test(option)) referencesArray.push("parents");
        if (/\parentView\b/.test(option)) referencesArray.push("parentView");
        if (/\response\b/.test(option)) referencesArray.push("response");

        let referenceLabel = "";
        if (isThisReference) {
          referenceLabel = "Reference";
        } else if (isViewReference) {
          referenceLabel = "Reference";
        } else if (isEqualOperation) {
          referenceLabel = "Function: ( , )";
        } else if (isParentReference) {
          referenceLabel = "Reference";
        } else if (referencesArray.includes("columns") || referencesArray.includes("actions")) {
          referenceLabel = "Object: [ ]";
        }

        return (
          <ListItem {...props} disablePadding>
            <Tooltip title={<ReactMarkdown>{hint}</ReactMarkdown>} followCursor placement='left'>
              <Box display='flex' width='100%' justifyContent={"space-between"} alignItems={"center"}>
                <Box component='li' display='flex' alignItems='center' width='80%'>
                  <Box position='relative'>
                    {renderOptionIcon(option, referencesArray, isThisReference, isViewReference, isParentReference)}
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      marginLeft: "8px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    variant='body2'
                  >
                    {lastKey}
                  </Typography>
                </Box>

                {(isThisReference || isViewReference || isParentReference || isEqualOperation || referencesArray.length > 0) && (
                  <Typography
                    sx={{
                      fontSize: "10px",
                      color: "gray",
                      whiteSpace: "nowrap",
                    }}
                    variant='body2'
                  >
                    {referenceLabel}
                  </Typography>
                )}
              </Box>
            </Tooltip>
          </ListItem>
        );
      }}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth={fullWidth}
          inputRef={inputRef}
          size={size}
          label={label}
          placeholder={placeholder}
          select={select}
          disabled={disabled}
          variant={variant}
          type={type}
          value={value}
          required={required}
          name={name}
          sx={{ width: "100%", ...sx }}
          id={id}
          aria-errormessage={ariaErrormessage}
          error={error}
          InputProps={{
            ...params.InputProps,
            ...inputProps,
          }}
          onChange={handleInputChange}
          InputLabelProps={InputLabelProps}
          {...rest}
        />
      )}
    />
  );
};
