import {
  Box,
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import { IconChecks, IconExclamationCircle } from "@tabler/icons-react";
import _ from "lodash";
import { useState } from "react";
import BXModal from "src/components/BXUI/Modal";
import { queryClient } from "src/features/buildxProvider/buildxProviderUtils";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import axiosServices from "src/utils/axios";

const logColumns: any[] = [
  { id: "id", label: "App ID", minWidth: 270 },
  { id: "name", label: "Name", minWidth: 270 },
  { id: "status", label: "Status", minWidth: 270 },
];
const BackUpAll = () => {
  const { palette } = useTheme();

  const [backupLogs, setBackupLogs] = useState<any>([]);

  const handleBackupClick = () => {
    const getApps = async (pageParam?: string) => {
      axiosServices
        .get(process.env.REACT_APP_HOST_API_KEY + "/api/admin/app-history/apps", {
          params: {
            cursor: pageParam,
            limit: 10,
          },
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        })
        .then(({ data }) => {
          const appsNeedToBackup: any = data?.items;
          const inProgressApps = appsNeedToBackup?.map((app: any) => ({ id: app?.id, name: app?.name, status: "In Progress" }));
          setBackupLogs((prev: any) => [...inProgressApps, ...prev]);

          const handleNextCall = () => {
            if (data?.hasMore) {
              getApps(data?.cursor);
            } else {
              queryClient.refetchQueries(["apps"]);
              enqueueSnackbarRef?.("Backup Success", {
                variant: "success",
              });
            }
          };
          if (!appsNeedToBackup.length) {
            handleNextCall();
          }
          appsNeedToBackup.map(async (app: any, index: number) => {
            try {
              const res = await axiosServices.post(process.env.REACT_APP_HOST_API_KEY + `/api/admin/app-history/${app.id}/backup`, {
                templateConfig: app?.templateConfig,
              });
              setBackupLogs((prev: any) => prev?.map((log: any) => (log?.id != app?.id ? log : { ...log, status: "Backup" })));
              if (appsNeedToBackup.length === index + 1) {
                handleNextCall();
              }
            } catch (e) {
              setBackupLogs((prev: any) => prev?.map((log: any) => (log?.id != app?.id ? log : { ...log, status: "Failed" })));
              if (appsNeedToBackup.length === index + 1) {
                handleNextCall();
              }
            }
          });
        });
    };
    getApps();
  };

  return (
    <BXModal
      maxWidth={"lg"}
      label={"Backup All"}
      buttonProps={{ variant: "contained", onClick: handleBackupClick }}
      onClose={() => {
        setBackupLogs([]);
      }}
      title={"Backup All"}
    >
      <TableContainer style={{ backgroundColor: palette.background.paper }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {logColumns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, backgroundColor: palette.background.paper }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {backupLogs?.length === 0 && (
              <TableRow>
                <TableCell colSpan={logColumns.length + 1} align={"center"}>
                  No backups yet
                </TableCell>
              </TableRow>
            )}
            {backupLogs?.map((row: any) => {
              return (
                <TableRow hover role='checkbox' tabIndex={-1} key={row?.id}>
                  {logColumns.map(column => {
                    const value = _.get(row, column.id);

                    return (
                      <TableCell key={column.id}>
                        <Grid container alignItems={"center"}>
                          {value === "In Progress" ? (
                            <CircularProgress size={20} />
                          ) : value === "Failed" ? (
                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                              <IconExclamationCircle color='red' />

                              <Typography marginInlineStart={1} color='error'>
                                Failed
                              </Typography>
                            </Box>
                          ) : value === "Backup" ? (
                            <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                              <IconChecks color='green' />
                              <Typography marginInlineStart={1} color='green'>
                                {value}
                              </Typography>
                            </Box>
                          ) : (
                            value
                          )}
                        </Grid>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </BXModal>
  );
};

export default BackUpAll;
