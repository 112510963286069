import { ReactNode, createContext, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { BXConfirmationDialog } from "src/components/BXUI/AlertDialog/ConfirmationDialog";
import { useAppState } from "src/features/appState/hooks";
import { removeAppField } from "src/features/endUser/endUserSlice";
import { useCallbackPrompt } from "src/hooks/useCallbackPrompt";

interface IBXPageContext {
  isDirtyPage: boolean;
}

const BXPageContext = createContext<IBXPageContext | null>(null);

export const BXPageProvider = ({
  children,
  pageId,
  page,
  sharedCollection,
}: {
  children: ReactNode;
  pageId: string;
  page: any;
  sharedCollection?: any;
}) => {
  const viewId = page?.info?.modalView;
  const preventdefaultModalBrowser = page?.info?.preventdefaultModalBrowser;
  const customModalType = page?.info?.customModalType;
  const customMessage = page?.info?.customMessage;
  const sharedViews = sharedCollection?.pages?.[0]?.views || [];
  const { getValue } = useAppState();
  const dispatch = useDispatch();
  const isDirtyPage = getValue(`${pageId}.isDirty`, { pageId }) || false;
  const [showPrompt, confirmNavigation, cancelNavigation, lastLocation] = useCallbackPrompt(
    isDirtyPage,
    preventdefaultModalBrowser,
    customModalType,
    customMessage
  );

  useEffect(() => {
    return () => {
      dispatch(removeAppField({ name: pageId, pathVariables: { pageId } }));
    };
  }, [dispatch, pageId]);

  // useEffect(() => {
  //   if (showPrompt) {
  //     setActionConfirmControl({
  //       confirm: async () => {
  //         await confirmNavigation(lastLocation);
  //       },
  //       cancel: () => {
  //         cancelNavigation();
  //       },
  //     });
  //   }
  // }, [showPrompt]);

  return (
    <>
      {showPrompt && preventdefaultModalBrowser && customModalType && (
        <BXConfirmationDialog
          open={showPrompt}
          title='WARNING'
          customDialogView={viewId}
          isCustom={true}
          pageId={pageId}
          views={sharedViews}
          onConfirm={confirmNavigation}
          onCancel={cancelNavigation}
        />
      )}
      <BXPageContext.Provider value={{ isDirtyPage }}>{children}</BXPageContext.Provider>
    </>
  );
};

export const useBXPageContext = () => {
  const context = useContext(BXPageContext);
  if (context === null) {
    throw new Error("useBXPageContext must be used within a BXPageProvider");
  }
  return context;
};
