import { configureStore } from "@reduxjs/toolkit";
import builderReducer from "src/features/builder/builderSlice";
import endUserSlice from "src/features/endUser/endUserSlice";
import buildxProviderSlice from "../features/buildxProvider/buildxProviderSlice";

const store = configureStore({
  reducer: {
    builder: builderReducer,
    endUser: endUserSlice,
    buildxProvider: buildxProviderSlice,
  },
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware({
      serializableCheck: false, // Disables the check for serializable values
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
