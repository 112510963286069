import { useMemo } from "react";

export const DOTS = "...";

const letterRange = (start: any, end: any) => {
  const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => alphabet[(idx + start - 1) % 26]);
};
const numberRange = (start: any, end: any) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

// const range = (start: any, end: any,pageType?: string) => {
//   let length = end - start + 1;
//   return Array.from({ length }, (_, idx) => idx + start);
// };

export const usePagination = ({ totalPageCount, siblingCount = 1, currentPage, hasNextPage,pageType = 'numbers' ,maxPrev,isPaginationBar}: any): any => {
  const paginationRange = useMemo(() => {
    // Pages count is determined as siblingCount + firstPage + lastPage + currentPage + 2*DOTS
    const totalPageNumbers = siblingCount + (isPaginationBar && maxPrev ? Number(maxPrev)  : 5)
    // const totalPageNumbers = siblingCount + (isPaginationBar ? Math.max(Number(maxPrev), 2) : 5);
    const range = pageType === 'letters' ? letterRange : numberRange;



    /*
      If the number of pages is less than the page numbers we want to show in our
      paginationComponent, we return the range [1..totalPageCount]
    */
    if (totalPageNumbers >= totalPageCount) {
      const array = range(1, totalPageCount);
      if (hasNextPage) {
        array.push(totalPageCount + 1);
        array.push(DOTS);
      }
      return array;
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 3);
    const rightSiblingIndex = Math.min(currentPage + siblingCount, totalPageCount);


    /*
      We do not want to show dots if there is only one position left 
      after/before the left/right page count as that would lead to a change if our Pagination
      component size which we do not want
    */
    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

    const firstPageIndex = 1;
    const lastPageIndex = totalPageCount;

    if (!shouldShowLeftDots && shouldShowRightDots) {

      let leftItemCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemCount);

      const array = [...leftRange, DOTS, totalPageCount];

      return array;
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      // let rightItemCount = (isPaginationBar ? (Number(maxPrev) - 2) : 3) + 2 * siblingCount;
      let rightItemCount = Math.max((isPaginationBar && maxPrev ? (Number(maxPrev) - 2) : 3) + 2 * siblingCount, 1);
      let rightRange = range(totalPageCount - rightItemCount + 1, totalPageCount);
      const array = [firstPageIndex, DOTS, ...rightRange];
      if (hasNextPage) {
        array.push(lastPageIndex + 1);
        array.push(DOTS);
      }
      return array;
    }

    if (shouldShowLeftDots && shouldShowRightDots) {

      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      const array = [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];

      if (hasNextPage) {
        array.push(lastPageIndex + 1);
        array.push(DOTS);
      }

      return array;
    }
  }, [hasNextPage, totalPageCount, siblingCount, currentPage,pageType]);

  return paginationRange;
};
