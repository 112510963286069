import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank } from "@mui/icons-material";
import { Autocomplete, Checkbox, Chip, TextField } from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { debounce } from "lodash";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useBuildxContext } from "src/BXEngine/BuildxContext";
import { useReplaceDataPlaceholders } from "src/components/BXUI/DataTable/ActionButton";
import { BXIcon } from "src/components/BXUI/Icon";
import { replaceBaseUrl } from "src/features/buildxProvider/buildxProviderUtils";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import extAxiosServices from "src/utils/axiosExt";
import { getAuth } from "src/utils/buildxProviderOperations";
import { getAuthorizationHeader } from "src/utils/generalUtils";
import { CustomAutoCompleteProps } from "./types";
export const CustomAutoCompleteBX: FC<CustomAutoCompleteProps> = props => {
  const {
    label,
    value,
    onChange,
    multiple,
    freeSolo,
    showCheckbox,
    showMore,
    numShowMore,
    dataAutoComplete,
    readOnly,
    keyLabelData,
    keyValueData,
    placeholder,
    addIcon,
    iconRight,
    config,
    suggestionList,
    id,
    metaData,
    arrowIcon,
    filterSelectedOptions,
    autoSelectValue,
    addWord,
    listFreeSolo,
    changeCheckbox,
    colorIconChecked,
    colorIconNotChecked,
    keyLabelSuggestData,
    keyValueSuggestData,
    minWidthAutoComplete,
    maxWidthAutoComplete,
    InputProps,
  } = props;
  interface OptionType {
    label: string;
    value: string;
  }

  const [options, setOptions] = useState<any[]>([]);
  const [isFocused, setIsFocused] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [refreshKey, setRefreshKey] = useState(0);

  const additionalData = useRef<any[]>([]);
  const [filteredAdditionalDataApi, setFilteredAdditionalDataApi] = useState<any[]>([]);
  const timeDebounce = Number(config?.suggestionEndpoint?.debounce);
  const currentApp = useBuildxProviderValue("currentApp");
  const viewsState = useBuildxProviderValue("viewsState");
  const appTokens = useBuildxProviderValue("appTokens");
  const appProfiles = useBuildxProviderValue("appProfiles");
  const currentProfileId = useBuildxProviderValue("currentProfileId");
  const { unprotectedPages } = useBuildxContext();

  const { replaceDataPlaceholdersRecursively, replaceDataPlaceholders } = useReplaceDataPlaceholders({ viewName: props?.info?.viewName });
  const deviceToken = localStorage.getItem(`${currentApp?.id}-accessToken-device`);
  const { token: accessToken } = getAuth(currentApp?.id!, currentProfileId, appProfiles, appTokens, currentApp) || {};

  const checkUnprotected = path => {
    const pathSlugs = path?.split(".").slice(0, 3) || [];

    if (pathSlugs?.length < 3) {
      return true;
    }
    const pagePath = pathSlugs?.join(".");
    return unprotectedPages.current.has(pagePath as string);
  };
  const isUnprotectedPage = checkUnprotected(props?.metaData?.path);

  const token = isUnprotectedPage ? deviceToken : accessToken || localStorage.getItem("accessToken") || deviceToken;

  const requestHeaders = {
    ...getAuthorizationHeader(currentApp?.appConfig?.auth, token),
    ...replaceDataPlaceholdersRecursively({
      obj: config?.suggestionEndpoint?.source?.headers,
      viewsState,
      pageId: props?.metaData?.pageId,
      __data: props?.metaData?.__data,
      env: currentApp?.env,
    }),
  };
  const debouncedFetchRef = useRef(
    debounce(async inputValue => {
      try {
        let uriSuggestList = replaceDataPlaceholders({
          queryString: config?.suggestionEndpoint?.source?.apiUrl || "",
          viewsState,
          item: props?.metaData?.item,
          pageId: props?.metaData?.pageId,
          __data: props?.metaData?.__data,
          env: currentApp?.env,
          fallback: "",
        });
        uriSuggestList = replaceBaseUrl(uriSuggestList, currentApp);
        const response = await extAxiosServices.request({
          url: uriSuggestList,
          headers: requestHeaders,
        });
        const dataEntry = config?.suggestionEndpoint?.suggestionDataEntry;
        const newFilteredData = response.data?.[dataEntry]?.map(option => ({
          label: option[keyLabelSuggestData],
          value: option[keyValueSuggestData],
          selectedData: option,
        }));
        const filteredAdditionalData = newFilteredData?.filter(
          option =>
            option?.label?.toLowerCase().startsWith(inputValue.toLowerCase()) &&
            !options?.some(existingOption => existingOption?.value === option?.value)
        );
        setFilteredAdditionalDataApi(filteredAdditionalData);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    }, timeDebounce || 200)
  );

  if (config?.suggestionEndpoint?.suggestionSourceType == "SIMPLE" && suggestionList) {
    let dataEntry = config?.suggestionEndpoint?.suggestionDataEntryList;
    additionalData.current = config?.suggestionEndpoint?.suggestedValues[dataEntry];
  }

  useEffect(() => {
    if (Array.isArray(dataAutoComplete) && dataAutoComplete?.length > 0) {
      const options: any = [];
      const seenLabels = new Set();
      dataAutoComplete.forEach(item => {
        const label = item[keyLabelData];
        const value = item[keyValueData];
        if (!seenLabels.has(label)) {
          options.push({
            label: label,
            value: value,
            selectedData: item,
          });
          seenLabels.add(label);
        }
      });
      if (value) {
        let defaultValue = options.find(val => val.value == value.userOptions?.value);
        if (defaultValue) {
          multiple
            ? onChange?.(
                { userOptions: [defaultValue], userInput: "", noChange: true },
                {
                  disableDebounce: true,
                }
              )
            : onChange?.(
                { userOptions: defaultValue, userInput: "", noChange: true },
                {
                  disableDebounce: true,
                }
              );
        }
      }
      if (autoSelectValue && options.length == 1) {
        const valueSelect = options?.[0];
        multiple
          ? onChange?.(
              { userOptions: [valueSelect], userInput: "", noChange: true },
              {
                disableDebounce: true,
              }
            )
          : onChange?.(
              { userOptions: valueSelect, userInput: "", noChange: true },
              {
                disableDebounce: true,
              }
            );
      }

      setOptions(options);
    }
  }, [dataAutoComplete]);

  const handleOnChangeSingle = (event, newValue) => {
    if (newValue == null) {
      setUserInput("");
      onChange?.(null, {
        disableDebounce: true,
      });
    } else if (typeof newValue === "string") {
      const newOption = {
        label: newValue,
        value: newValue,
        selectedData: { label: newValue, value: newValue },
      };
      onChange?.(newOption, {
        disableDebounce: true,
      });

      if (!options.some(option => option.value === newValue)) {
        setOptions(prevOptions => [...prevOptions, newOption]);
      }
    } else {
      const modifiedLabel = newValue.label.replace(/^Add\s+/i, "");
      const newOption = {
        ...newValue,
        label: modifiedLabel,
        selectedData: newValue.selectedData || { label: modifiedLabel, value: newValue.value },
      };

      if (!options.some(option => option.value === newOption.value)) {
        setOptions(prevOptions => [...prevOptions, newOption]);
      }

      onChange?.(
        { userOptions: newOption, userInput: "" },
        {
          disableDebounce: true,
        }
      );
    }
  };

  const handleOnChangeMultiple = (event, newValues) => {
    const newOptions = newValues
      .filter(val => !options.some(option => option.value === (typeof val === "string" ? val : val.value)))
      .map(val => {
        if (typeof val === "string") {
          return { label: val, value: val, selectedData: { label: val, value: val } };
        } else {
          const modifiedLabel = val.label.replace(/^Add\s+/i, "");
          return { ...val, label: modifiedLabel };
        }
      });

    if (newOptions.length > 0) {
      setOptions(prevOptions => [...prevOptions, ...newOptions]);
    }

    const selectedValues = newValues.map(val => {
      if (typeof val === "string") {
        return { label: val, value: val, selectedData: { label: val, value: val } };
      } else {
        const modifiedLabel = val.label.replace(/^Add\s+/i, "");
        return { ...val, label: modifiedLabel, selectedData: val.selectedData || { label: modifiedLabel, value: val.value } };
      }
    });
    setUserInput("");
    onChange?.(
      { userOptions: selectedValues, userInput: "" },
      {
        disableDebounce: true,
      }
    );
  };

  const handleChange = (event, value) => {
    if (multiple && Array.isArray(value)) {
      handleOnChangeMultiple(event, value);
    } else if (value && typeof value === "object") {
      handleOnChangeSingle(event, value);
    }
    setUserInput("");
  };

  const iconChecked = props?.iconConfigChecked ? (
    <BXIcon
      width={"25px"}
      height={"25px"}
      icon={props?.iconConfigChecked?.icon}
      url={props?.iconConfigChecked?.url}
      visibility={props?.iconConfigChecked?.visibility}
      color={colorIconChecked || "#fff"}
      style={{ maxWidth: "100%", maxHeight: "100%" }}
    />
  ) : (
    <CheckBoxIcon fontSize='small' />
  );

  const iconNotChecked = props?.iconConfigNotChecked ? (
    <BXIcon
      width={"25px"}
      height={"25px"}
      icon={props?.iconConfigNotChecked?.icon}
      url={props?.iconConfigNotChecked?.url}
      visibility={props?.iconConfigNotChecked?.visibility}
      color={colorIconNotChecked || "#fff"}
      style={{ maxWidth: "100%", maxHeight: "100%" }}
    />
  ) : (
    <CheckBoxOutlineBlank fontSize='small' />
  );

  const icon = changeCheckbox ? iconNotChecked : <CheckBoxOutlineBlank fontSize='small' />;
  const checkedIcon = changeCheckbox ? iconChecked : <CheckBoxIcon fontSize='small' />;
  // const checkedIcon = <CheckBoxAutoCompleteIcon width={21} height={21} />;

  const iconElement = addIcon && (
    <BXIcon
      width={"25px"}
      height={"25px"}
      icon={props?.iconConfig?.icon}
      url={props?.iconConfig?.url}
      visibility={props?.iconConfig?.visibility}
      color={"#fff"}
      style={{ maxWidth: "100%", maxHeight: "100%", marginLeft: "10px" }}
    />
  );

  const selectedValues = useMemo(() => {
    if (multiple) {
      const array = (Array.isArray(value?.userOptions) as any)
        ? value?.userOptions.map(val => options.find(option => option.value === val.value))
        : [];
      return array;
    } else {
      return (
        options?.find(option => option.value === value?.userOptions?.value) ||
        (value?.userOptions?.value ? { label: value?.userOptions?.value, value: value?.userOptions?.value } : null)
      );
    }
  }, [value, options, multiple]);

  return (
    <Autocomplete
      freeSolo={freeSolo}
      clearOnEscape
      filterSelectedOptions={filterSelectedOptions}
      multiple={multiple}
      readOnly={readOnly}
      options={options}
      inputValue={userInput}
      onInputChange={(event, newInputValue, reason) => {
        if (reason === "clear" && !multiple) {
          setUserInput("");
          onChange?.(
            { userOptions: { label: "", value: "", selectedData: null }, userInput: "" },
            {
              disableDebounce: true,
            }
          );
          return;
        }
        if (reason === "input" || !multiple) {
          setUserInput(newInputValue);
          if (config?.suggestionEndpoint?.suggestionSourceType === "API" && suggestionList) {
            debouncedFetchRef.current(newInputValue);
          }
          onChange?.(
            { ...value, userInput: newInputValue, noChange: true },
            {
              disableDebounce: true,
            }
          );
          setRefreshKey(prev => prev + 1);
        }
      }}
      value={selectedValues}
      getOptionLabel={option => (typeof option === "string" ? option : option.label)}
      onChange={handleChange}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {showCheckbox && <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />}
          {option.label}
        </li>
      )}
      renderInput={params => {
        let handleOnClick;
        if (React.isValidElement(params.InputProps.endAdornment)) {
          handleOnClick = params?.InputProps?.endAdornment?.props?.children[1]?.props?.onClick;
        }
        const inputPropsWithIcon = addIcon
          ? {
              ...params.InputProps,
              startAdornment: (
                <>
                  {!iconRight && iconElement}
                  {arrowIcon && !freeSolo && <ArrowDropDownIcon onClick={handleOnClick} sx={{ cursor: "pointer" }} />}
                  {params.InputProps.startAdornment}
                </>
              ),
              endAdornment: (
                <>
                  {iconRight && iconElement}
                  {params.InputProps.endAdornment}
                </>
              ),
            }
          : arrowIcon && !freeSolo
          ? {
              ...params.InputProps,
              startAdornment: (
                <>
                  <ArrowDropDownIcon onClick={handleOnClick} sx={{ cursor: "pointer" }} />
                  {params.InputProps.startAdornment}
                </>
              ),
            }
          : params.InputProps;

        return (
          <TextField
            {...params}
            sx={{
              "& .MuiInputLabel-root": {
                ...(!isFocused && !params.inputProps?.value && !value?.userOptions?.length && { top: "50%", left: "14px" }),
                ...(!isFocused && !params.inputProps?.value && !value?.userOptions?.length && { transform: "translateY(-50%)" }),
              },
            }}
            label={placeholder}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            size='small'
            InputProps={{
              ...inputPropsWithIcon,
              sx: {
                width: "100%",
                height: "100%",
              },
            }}
            InputLabelProps={{
              shrink: params.inputProps?.value || value?.userOptions?.length > 0 || isFocused ? true : false,
              sx: {
                marginLeft:
                  params.inputProps?.value || value?.userOptions?.length > 0 || isFocused
                    ? 0
                    : addIcon && !iconRight
                    ? arrowIcon && !freeSolo
                      ? "60px"
                      : "35px"
                    : arrowIcon && !freeSolo
                    ? "20px"
                    : 0,
              },
            }}
          />
        );
      }}
      renderTags={(selectedOptions, getTagProps) => {
        if (selectedOptions.length > Number(numShowMore) && showMore) {
          const firstOptions = selectedOptions.slice(0, Number(numShowMore));
          const moreCount = selectedOptions.length - Number(numShowMore);
          return [
            ...firstOptions.map((option, index) => {
              // const truncatedLabel = option.label.length > 10 ? option.label.slice(0, 10) + "..." : option.label;
              return <Chip label={option.label} {...getTagProps({ index })} />;
            }),
            <Chip label={`${moreCount} more`} {...getTagProps({ index: Number(numShowMore) })} disabled />,
          ];
        } else {
          return selectedOptions.map((option, index) => {
            // const truncatedLabel = option.label.length > 10 ? option.label.slice(0, 10) + "..." : option.label;
            return <Chip label={option.label} {...getTagProps({ index })} />;
          });
        }
      }}
      filterOptions={(options, state) => {
        const inputValue = state.inputValue.trim().toLowerCase();
        if (!inputValue) {
          return freeSolo && !listFreeSolo ? [] : options;
        }

        const filteredOptions = options.filter(option => option.label.toLowerCase().startsWith(inputValue));

        const validAdditionalData = additionalData?.current
          ?.filter(option => option && option.label)
          .map(option => ({
            label: option.label,
            value: option.value,
            selectedData: option,
          }));

        const filteredAdditionalData = validAdditionalData?.filter(
          option =>
            option?.label?.toLowerCase().startsWith(inputValue) && !options?.some(existingOption => existingOption?.value === option?.value)
        );

        const isExisting = options?.some(option => inputValue === option.label.toLowerCase());

        if (inputValue !== "" && !isExisting && addWord && freeSolo) {
          filteredOptions.push({
            label: `Add ${inputValue}`,
            value: inputValue,
          });
        }

        if (config?.suggestionEndpoint?.suggestionSourceType == "SIMPLE") {
          return [...filteredOptions, ...filteredAdditionalData];
        } else if (config?.suggestionEndpoint?.suggestionSourceType == "API") {
          const combinedOptions = [...filteredOptions, ...filteredAdditionalDataApi];
          const uniqueOptions = combinedOptions.filter(
            (option, index, self) => index === self.findIndex(o => o.value === option.value || o.label === option.label)
          );
          return uniqueOptions;
        } else {
          return [...filteredOptions];
        }
      }}
      componentsProps={{
        popupIndicator: {
          sx: {
            ...(arrowIcon && { display: "none" }),
          },
        },
      }}
      sx={{
        width: "100%",
        height: "100%",
        "& .MuiFormControl-root": {
          height: "100%",
          minWidth: minWidthAutoComplete || 0,
          ...(maxWidthAutoComplete && { maxWidth: maxWidthAutoComplete }),
        },
      }}
    />
  );
};
