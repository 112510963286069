import {
  Box,
  Button,
  Card,
  DialogActions,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import { IconPencil, IconPlus, IconTrashX } from "@tabler/icons-react";
import _ from "lodash";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useBXBuilderContext } from "src/BXEngine/BXBuilderContext";
import { BXConfirmationDialog } from "src/components/BXUI/AlertDialog/ConfirmationDialog";
import { setProviderState } from "src/features/buildxProvider/buildxProviderSlice";
import store from "src/store/store";
import { BXApp, ILayout } from "src/types/BXAppType";
import { v4 as uuid } from "uuid";
import { ComponentItemType } from "../../FormBuilder/types";
import CreateLayoutModal from "./CreateLayoutModal";

const columns: any[] = [
  { id: "id", label: "ID", minWidth: 270 },
  { id: "name", label: "name", minWidth: 220 },
];

type LayoutTableProps = { onCancel: Function; onSave: Function; app: BXApp; selectedLayoutID?: string };

const LayoutTable: FC<LayoutTableProps> = ({ app, onSave, onCancel, selectedLayoutID }) => {
  const navigate = useNavigate();
  const { palette } = useTheme();

  const { editApp } = useBXBuilderContext();

  const [openEditModal, setOpenEditModal] = useState(false);
  const [openAddLayoutModal, setOpenAddLayoutModal] = useState(false);
  const [selectedLayout, setSelectedLayout] = useState<ILayout | undefined>();

  const [isLoading, setIsLoading] = useState(false);

  const onRedirectToLayout = (appId: string, layoutId: string) => {
    navigate(`/buildx/form-builder/layout/${appId}/${layoutId}`);
    store.dispatch(
      setProviderState({
        currentApp: app,
      })
    );
  };

  useEffect(() => {
    if (selectedLayoutID) {
      const layout = app?.templateConfig?.layouts?.find(layout => layout.id === selectedLayoutID);
      if (layout) {
        setSelectedLayout(layout);
        setOpenEditModal(true);
      }
    }
  }, []);

  return (
    <>
      <CreateLayoutModal
        openAddLayoutModal={openAddLayoutModal}
        title='Add New Layout'
        onOpen={() => {
          setOpenAddLayoutModal(true);
        }}
        onClose={() => {
          setOpenAddLayoutModal(false);
        }}
        isLoading={isLoading}
        onSubmit={formData => {
          setIsLoading(true);
          const layoutId = uuid();
          const id = uuid();
          const layout = {
            dataSource: {
              formBuilder: [
                {
                  id,
                  type: ComponentItemType.BXLayout,
                  props: {
                    id: `Component-${id}`,
                    key: `Component-${id}`,
                    sx: {
                      width: "100%",
                      height: "100%",
                      backgroundColor: "primary.main",
                    },
                  },
                  left: {
                    xs: 0,
                    lg: 0,
                  },
                  top: {
                    xs: 0,
                    lg: 0,
                  },
                  config: {
                    defaultWidth: 150,
                    defaultHeight: 400,
                    heightPx: {
                      xs: 400,
                      lg: 400,
                    },
                    widthPercentage: {
                      xs: "100%",
                    },
                    fixedWidth: false,
                    isPercentageHeight: true,
                    disableResizeHeight: false,
                  },
                },
              ],
            },
            id: layoutId,
            name: formData?.name,
          };
          const _app = {
            ...app,
            templateConfig: {
              ...app?.templateConfig,
              layouts: app?.templateConfig?.layouts?.length ? [...app?.templateConfig?.layouts, layout] : [layout],
            },
          } as BXApp;
          editApp(app?.id, _app, () => {
            onSave(_app);
            setIsLoading(false);
            onRedirectToLayout(_app?.id, layoutId);
          });
        }}
      />
      <Card style={{ padding: 24, paddingTop: 8 }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={() => {
              setOpenAddLayoutModal(true);
            }}
            variant='contained'
            startIcon={<IconPlus />}
          >
            Layout
          </Button>
        </Box>
        <TableContainer style={{ backgroundColor: palette.background.paper }}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                <TableCell style={{ backgroundColor: palette.background.paper }} />

                {columns.map(column => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, backgroundColor: palette.background.paper }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!app?.templateConfig?.layouts?.length && (
                <TableRow>
                  <TableCell colSpan={columns.length + 1} align={"center"}>
                    No layouts yet
                  </TableCell>
                </TableRow>
              )}
              {app?.templateConfig?.layouts?.map((row: any) => {
                return (
                  <TableRow hover role='checkbox' tabIndex={-1} key={row.code}>
                    <TableCell sx={{ width: 130 }}>
                      <DialogActions sx={{ padding: 0 }}>
                        <IconButton
                          onClick={() => {
                            setSelectedLayout(row);
                            setOpenEditModal(true);
                          }}
                        >
                          <IconPencil color={palette.primary.main} height={26} width={26} style={{ padding: 4 }} />
                        </IconButton>

                        <Box marginInlineStart={1}>
                          <BXConfirmationDialog
                            title={"Are you sure you want to delete this layout?"}
                            iconButton
                            buttonProps={{
                              color: "error",
                              children: <IconTrashX height={20} width={20} style={{ padding: 0 }} />,
                            }}
                            isLoading={isLoading}
                            onConfirm={() => {
                              return new Promise((resolve, reject) => {
                                setIsLoading(true);
                                const _app = {
                                  ...app,
                                  templateConfig: {
                                    ...app?.templateConfig,
                                    layouts: app?.templateConfig?.layouts?.filter(layout => layout?.id != row?.id),
                                  },
                                } as BXApp;
                                editApp(app?.id, _app, () => {
                                  onSave(_app);
                                  setIsLoading(false);
                                  resolve(null);
                                });
                              });
                            }}
                          />
                        </Box>
                      </DialogActions>
                    </TableCell>

                    {columns.map(column => {
                      const value = _.get(row, column.id);
                      return (
                        <TableCell key={column.id}>
                          <Grid container alignItems={"center"}>
                            {value}
                          </Grid>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}

              <CreateLayoutModal
                layout={selectedLayout}
                openAddLayoutModal={openEditModal}
                isUpdate
                title='Edit Layout'
                isLoading={isLoading}
                onClose={() => {
                  setOpenEditModal(false);
                }}
                appId={app?.id}
                onSubmit={formData => {
                  setIsLoading(true);
                  const _app = {
                    ...app,
                    templateConfig: {
                      ...app?.templateConfig,
                      layouts: app?.templateConfig?.layouts?.map(layout =>
                        layout?.id == selectedLayout?.id ? { ...layout, name: formData?.name } : layout
                      ),
                    },
                  } as BXApp;
                  editApp(app?.id, _app, () => {
                    onSave(_app);
                    setIsLoading(false);
                    setOpenEditModal(false);
                  });
                }}
                openViewBuilder={onRedirectToLayout}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
};

export default LayoutTable;
