import { PaymentElement } from "@stripe/react-stripe-js";
import isEqual from "lodash/isEqual";
import { memo, useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setProviderState } from "src/features/buildxProvider/buildxProviderSlice";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";

export const StripePaymentElements = memo((props: any) => {
  const { stripeTheme, stripeVariables, stripeLabels, stripeRules } = props;
  const clientSecrets = useBuildxProviderValue("clientSecrets");
  const stripePromises = useBuildxProviderValue("stripePromises");
  const location = useLocation();
  const builderView = location.pathname.startsWith("/buildx/form-builder");
  const parentId = builderView ? props?.$config?.parent?.id : props?.__config?.parent?.id;
  const matchingClientSecret = clientSecrets?.find(clientSecret => clientSecret.id === parentId);
  const matchingStripePromise = stripePromises?.find(stripePromise => stripePromise.id === parentId);
  const dispatch = useDispatch();

  const appearance = useMemo(
    () => ({
      theme: stripeTheme,
      labels: stripeLabels,
      variables: stripeVariables,
      rules: stripeRules,
    }),
    [stripeTheme, stripeLabels, stripeVariables, stripeRules]
  );

  const prevAppearance = useRef(appearance);

  useEffect(() => {
    if (!isEqual(prevAppearance.current, appearance)) {
      dispatch(setProviderState({ stripeAppearance: appearance }));
      prevAppearance.current = appearance;
    }
  }, [appearance]);

  return <>{matchingClientSecret && matchingStripePromise && <PaymentElement />}</>;
});
