import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { isHostAvailable } from "src/App";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import useAuth from "src/hooks/useAuth";

interface RedirectGuardProps {
  children: React.ReactNode;
  protectedLandingPage?: string;
}
/**
 * Redirection guard middleware for pages that should send user to landing page if logged in
 */
export const RedirectGuard: FC<RedirectGuardProps> = ({ children, protectedLandingPage }) => {
  const { isLoggedIn } = useAuth();
  const currentApp = useBuildxProviderValue("currentApp");
  const fqdnApp = useBuildxProviderValue("fqdnApp");
  const currentProfileId = useBuildxProviderValue("currentProfileId");
  const appTokens = useBuildxProviderValue("appTokens");

  const navigate = useNavigate();

  //Org and FQDN variables to check if the app and app token are ready
  const tokenKey = `${currentApp?.id}-${currentProfileId}-accessToken`;
  const isAppTokenAvailable = Boolean(appTokens?.[tokenKey]);
  const isAppLoaded = isHostAvailable || !!fqdnApp;

  if (isLoggedIn && isAppLoaded && isAppTokenAvailable) {
    navigate(`${protectedLandingPage ?? "/"}`, { replace: true });
  }

  return <>{children}</>;
};
