import { Box, Grid, GridProps, Popover, TextField, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import colorNameList from "color-name-list";
import { useEffect, useState } from "react";
import { SketchPicker } from "react-color";
import { Control, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { BXInput } from "src/components/BXUI/FormControls";
import { RGBVariants, colorToRgbVariants } from "src/utils/generalUtils";

interface BaseProps {
  label: string;
  gridProps?: GridProps;
  placeholder?: string;
  disabled?: boolean;
  borderRadius?: string;
  borderColorIconPicker?: string;
}

interface ControlProps extends BaseProps {
  control: Control<any>;
  name: string;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
  errors: any;
}

type ColorPickerProps = BaseProps & Partial<ControlProps>;

const useStyles = makeStyles((theme: Theme) => ({
  pickerRoot: {
    backgroundColor: `${theme.palette.background.paper} !important`,
  },
}));

export function ColorPicker({
  control,
  setValue,
  watch,
  errors,
  name,
  label,
  gridProps,
  placeholder,
  disabled,
  borderRadius,
  borderColorIconPicker,
  ...props
}: ColorPickerProps) {
  const { onChange, defaultValue, isReadOnly, isLeft, disableAlpha } = props as any;
  const defaultColor = colorToRgbVariants(defaultValue);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [localColor, setLocalColor] = useState<RGBVariants>(defaultColor);
  const [inputText, setInputText] = useState<string>(defaultColor?.hex || watch?.(name as any));

  const classes = useStyles();

  useEffect(() => {
    setLocalColor(defaultColor);
    setInputText(defaultColor?.hex || watch?.(name as any));
  }, [defaultValue, watch, name]);

  const handleColorChange = (color: string) => {
    const colorHex = colorNameList?.find(c => c?.name?.toLowerCase() === color)?.hex || color;
    const variants = colorToRgbVariants(colorHex);

    if (setValue && watch && name) {
      setValue(name, colorHex);
    } else {
      setLocalColor(variants);
    }
    onChange?.(variants as any);
  };

  const getColor = () => (watch && name && watch(name)) || localColor?.hex || "";

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (!isReadOnly && !disabled) {
      setAnchorEl({ target: event.currentTarget, path: name });
    }
  };

  const startAdornment = (
    <Box
      onClick={handlePopoverOpen}
      sx={{
        backgroundColor: getColor(),
        minWidth: 20,
        minHeight: 20,
        border: `1px solid ${borderColorIconPicker || "white"} !important`,
        marginInlineEnd: isLeft ? 1 : 0,
        marginInlineStart: !isLeft ? 1 : 0,
        borderRadius: `${borderRadius || 0} !important`,
      }}
    />
  );

  const renderInputComponent = () => {
    if (isReadOnly) {
      return (
        <>
          {isLeft && startAdornment}
          <Typography>{localColor?.hex}</Typography>
          {!isLeft && startAdornment}
        </>
      );
    }

    if (control && name) {
      return (
        <BXInput
          variant='outlined'
          required
          label={label}
          control={control}
          name={name}
          placeholder={placeholder}
          error={!!errors}
          disabled={disabled}
          InputProps={{
            startAdornment: isLeft && startAdornment,
            endAdornment: !isLeft && startAdornment,
          }}
        />
      );
    }

    return (
      <TextField
        variant='outlined'
        label={label}
        value={inputText}
        onChange={e => {
          setInputText(e.target.value);
          handleColorChange(e.target.value);
        }}
        placeholder={placeholder}
        error={!!errors}
        required
        fullWidth
        disabled={disabled}
        InputProps={{
          startAdornment: isLeft && startAdornment,
          endAdornment: !isLeft && startAdornment,
        }}
      />
    );
  };

  return (
    <>
      <Popover
        open={Boolean(anchorEl?.target && !isReadOnly && !disabled)}
        anchorEl={anchorEl?.target}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <SketchPicker
          onChange={e => {
            handleColorChange(e.hex);
            setInputText(e.hex);
          }}
          color={getColor()}
          className={classes.pickerRoot}
          disableAlpha={disableAlpha}
        />
      </Popover>
      <Grid item display='flex' alignItems='center' height='100%' {...gridProps}>
        {renderInputComponent()}
      </Grid>
    </>
  );
}
