import _, { cloneDeep } from "lodash";
import { BXApp } from "src/types/BXAppType";
import { compressData, decompressData } from "src/utils/services";
import { ComponentItemType } from "src/views/pages/BuildX/FormBuilder/types";
import { setStepperParents } from "src/views/pages/BuildX/FormBuilder/utils";
import { v4 as uuid } from "uuid";

export function migrateTo1_0_2(_app: BXApp) {
  const app: any = {
    ..._app,
    appConfig: decompressData(_app?.appConfig),
    templateConfig: decompressData(_app?.templateConfig),
    upTemplateConfig: decompressData(_app?.upTemplateConfig),
  };

  const templateCollections = _.get(app, "templateConfig.collections", []);
  const upTemplateCollections = _.get(app, "upTemplateConfig.collections", []);

  traverseApp(app);
  applyMigrationsFunctionLogic(app);
  migrateBuilderDimensions(app);
  renameDuplicateKeys(app);
  cleanUpResponsiveProperties(app);
  stepperMigration(templateCollections);
  stepperMigration(upTemplateCollections);
  _.set(app, "appVersion", "1.0.2");

  const compressedApp = {
    ...app,
    appConfig: compressData(app?.appConfig),
    templateConfig: compressData(app?.templateConfig),
    upTemplateConfig: compressData(app?.upTemplateConfig),
  };

  return compressedApp;
}

function renameDuplicateKeys(app) {
  if (!app) return;

  const traverseForDuplicates = elements => {
    const keyCount = new Map();

    if (!elements || !Array.isArray(elements)) return;

    const checkAndUpdateKey = element => {
      if (!element) return;

      const key = element?.props?.key;
      if (key) {
        keyCount.set(key, (keyCount.get(key) || 0) + 1);

        if (keyCount.get(key) > 1) {
          element.props.key = uuid();
        }
      }
      if (element?.props?.isMapValues && element?.optionMap) {
        Object.values(element.optionMap).forEach(child => {
          if (child) {
            checkAndUpdateKey(child);
          }
        });

        if (element?.optionMap[element?.selectedType]?.children) {
          element.children = _.cloneDeep(element.optionMap[element.selectedType].children);
        }
        return;
      }

      if (element.children && Array.isArray(element.children)) {
        element.children.forEach(child => checkAndUpdateKey(child));
      }
    };

    elements.forEach(element => checkAndUpdateKey(element));
  };

  const templateCollections = _.get(app, "templateConfig.collections", []);
  const upTemplateCollections = _.get(app, "upTemplateConfig.collections", []);

  [templateCollections, upTemplateCollections].forEach(collections => {
    if (!collections) return;
    collections.forEach(collection => {
      if (!collection?.pages) return;
      collection.pages.forEach(page => {
        if (page?.layout === "custom" && page?.dataSource?.formBuilder) {
          traverseForDuplicates(page.dataSource.formBuilder);
        }
        if (!page?.views) return;
        page.views.forEach(view => {
          if (view?.type === "form-builder" && view?.dataSource?.formBuilder) {
            traverseForDuplicates(view.dataSource.formBuilder);
          }
        });
      });
    });
  });

  const upLayouts = _.get(app, "upTemplateConfig.layouts", []);
  upLayouts.forEach(layout => {
    if (layout?.dataSource?.formBuilder) {
      traverseForDuplicates(layout.dataSource.formBuilder);
    }
  });

  const layouts = _.get(app, "templateConfig.layouts", []);
  layouts.forEach(layout => {
    if (layout?.dataSource?.formBuilder) {
      traverseForDuplicates(layout.dataSource.formBuilder);
    }
  });
}

const stepperMigration = (collections: any) => {
  collections.forEach(collection => {
    collection.pages.forEach(page => {
      page.views.forEach(view => {
        if (view.type === "form-builder") {
          let stepperComponentMap = {};
          if (view.stepperGroups) {
            stepperComponentMap = cloneDeep(view.stepperGroups);
            delete view.stepperGroups;
          }
          const previewPageMap = {};
          const traverseChildren = (children: any[]) => {
            if (!children) return;
            children.forEach((child: any) => {
              const traverseAndFindStepperGroupReference = (obj: any) => {
                if (!obj) return;

                if (Array.isArray(obj)) {
                  obj.forEach(item => traverseAndFindStepperGroupReference(item));
                } else if (typeof obj === "object") {
                  if (obj.actionConfig?.stepperGroupReference) {
                    const stepperKey = obj.actionConfig.stepperGroupReference;
                    const stepperGroupId = Object.keys(stepperComponentMap).find(id => stepperComponentMap[id].groupName === stepperKey);
                    obj.actionConfig.stepperGroupReference = {
                      id: stepperGroupId,
                      key: stepperKey,
                    };
                  }

                  Object.values(obj).forEach(value => traverseAndFindStepperGroupReference(value));
                }
              };

              if (child.actionsMap) {
                traverseAndFindStepperGroupReference(child.actionsMap);
              }

              if (child.optionMap) {
                traverseAndFindStepperGroupReference(child.optionMap);
              }

              if (_.has(previewPageMap, child.props?.key)) {
                child.config = child.config || {};
                child.config.isVisiblePreviewByStepper = previewPageMap[child.props.key];
              }

              if (child.type === ComponentItemType.StepperContainer) {
                child.config = child.config || {};
                child.config.stepperGroup = stepperComponentMap[child.id];
                child.config.stepperGroup?.pages?.forEach((page, index) => {
                  if (child.config.stepperGroup.previewIndex === index) {
                    previewPageMap[page.pageReference] = true;
                  } else {
                    previewPageMap[page.pageReference] = false;
                  }
                });
                _.set(child, ["props", "defaultValue"], _.cloneDeep(child.config.stepperGroup));
                delete child.config.stepperGroup;
              }

              if (child?.config?.stepperParent) {
                child.config = child.config || {};
                const stepperGroupId: any = Object.keys(stepperComponentMap).find(
                  (groupId: any) => stepperComponentMap[groupId]?.groupName === child?.config?.stepperParent
                );

                if (stepperGroupId) {
                  child.config.stepperParent = {
                    id: stepperGroupId,
                    key: stepperComponentMap[stepperGroupId]?.groupName,
                  };
                }
              }

              if (child.children && child.children.length > 0) {
                traverseChildren(child.children);
              }
            });
          };
          traverseChildren(view?.dataSource?.formBuilder);
        }
      });
    });
  });
};

function applyMigrationsFunctionLogic(app) {
  const templateCollections = _.get(app, "templateConfig.collections", []);
  const upTemplateCollections = _.get(app, "upTemplateConfig.collections", []);

  mappingDataValues(templateCollections);
  mappingDataValues(upTemplateCollections);

  addStepperParents(templateCollections);
  addStepperParents(upTemplateCollections);
}

function mappingDataValues(collections: any) {
  collections.forEach(collection => {
    collection.pages.forEach(page => {
      page.views.forEach(view => {
        if (view.type === "data-table") {
          let actions = _.get(view, "config.actions", []);
          actions?.forEach((ac, index) => (ac.actionIndex = index));
          const columns = _.get(view, "config.columns", []);
          const hasActionsColumn = columns.some(column => column.type === "Actions");
          const actionHasNonGlobal = actions.some(action => action.isGlobal === false);
          // Handle Actions column to add Action Row if there is any non-global action
          if (actionHasNonGlobal && !hasActionsColumn) {
            columns.push({
              id: uuid(),
              name: "Actions",
              type: "Actions",
              headerCellType: "string",
              customWidth: "",
              alignColumn: "left",
              autoWidth: false,
            });
          }

          // change the view type to FROM PARENT if the view source type is NONE and and the view is child to a table
          columns.forEach(column => {
            if (column.type === "View Builder" && column.viewBuilderId) {
              page.views.forEach(otherView => {
                if (otherView.id === column.viewBuilderId) {
                  if (otherView.dataSource && otherView.dataSource.sourceType === "NONE") {
                    otherView.dataSource.sourceType = "FROM PARENT";
                  }
                }
              });
            }
          });
        }
      });
    });
  });
}

function addStepperParents(collections: any): void {
  collections.forEach(collection => {
    collection.pages.forEach(page => {
      page.views.forEach(view => {
        if (view.type === "form-builder") {
          const updatedFormBuilder = setStepperParents(view.dataSource.formBuilder, view.stepperGroups);
          view.dataSource = {
            ...view.dataSource,
            formBuilder: updatedFormBuilder,
          };
        }
      });
    });
  });
}

function migrateBuilderDimensions(app) {
  const changeSizeProperties = obj => {
    const populateProperties = element => {
      if (!element || !element.config) return;

      const ensureResponsiveValue = (key, defaultLg, defaultXs) => {
        if (!element.config[key]) {
          element.config[key] = { lg: defaultLg, xs: defaultXs };
        } else if (typeof element.config[key] !== "object") {
          element.config[key] = { lg: element.config[key] ?? defaultLg, xs: element.config[key] ?? defaultXs };
        } else {
          element.config[key] = {
            lg: element.config[key]?.lg ?? defaultLg,
            xs: element.config[key]?.xs ?? defaultXs,
          };
        }
      };

      ensureResponsiveValue("fixedWidth", false, false);
      ensureResponsiveValue("isDynamicWidth", false, false);
      ensureResponsiveValue("isPercentageHeight", false, false);
      ensureResponsiveValue("isDynamicHeight", false, false);

      if (Array.isArray(element.children)) {
        element.children.forEach(child => populateProperties(child));
      }

      if (element?.props?.isMapValues && element?.optionMap) {
        Object.values(element.optionMap).forEach(child => populateProperties(child));
      }
    };

    if (Array.isArray(obj)) {
      obj.forEach(item => populateProperties(item));
    } else {
      populateProperties(obj);
    }
  };

  const traverseAndApply = obj => {
    if (!obj) return;

    if (Array.isArray(obj)) {
      obj.forEach(item => traverseAndApply(item));
    } else if (typeof obj === "object") {
      for (const key in obj) {
        if (key === "formBuilder" && (Array.isArray(obj[key]) || typeof obj[key] === "object")) {
          changeSizeProperties(obj[key]);
        } else {
          traverseAndApply(obj[key]);
        }
      }
    }
  };

  traverseAndApply(app);
}

function cleanUpResponsiveProperties(obj) {
  if (obj && typeof obj === "object") {
    for (const key in obj) {
      if (key === "md" || key === "xs") {
        delete obj[key];
      } else if (typeof obj[key] === "object") {
        cleanUpResponsiveProperties(obj[key]);
      }
    }
  }
}

function flipSyntaxByKeyword(placeholder, keyword) {
  placeholder = placeholder.replace(/[{}]/g, "");
  const keys = placeholder?.split(".");
  const keyWordIndex = keys?.findIndex(key => key === keyword);
  [keys[keyWordIndex], keys[keyWordIndex + 1]] = [keys[keyWordIndex + 1], keys[keyWordIndex]];

  return `{${keys.join(".")}}`;
}

function convertToNewSyntax(data, path) {
  if (typeof data !== "string") {
    return data;
  }

  const regex = /\{([^{}]+)\}/g;
  const placeholdersList = data.match(regex);
  if (placeholdersList === null || placeholdersList.length === 0) {
    return data;
  }

  let modifiedData = data;
  const targetPathPattern = /^templateConfig\.collections\[\d+\]\.pages\[\d+\]\.views\[\d+\]\.config\.columns\[\d+\]\.source$/;
  const targetPathActions =
    /^templateConfig\.collections\[\d+\]\.pages\[\d+\]\.views\[\d+\]\.config\.actions\[\d+\]\.actionsMap\.default\[\d+\]\.actionConfig\.dialog\.message$/;
  const targetPathActionCondition =
    /^templateConfig\.collections\[\d+\]\.pages\[\d+\]\.views\[\d+\]\.config\.actions\[\d+\]\.actionsMap\.default\[\d+\]\.actionConfig\.actionCondition$/;
  const selectedItemRegex = /{([^{}]+)_selectedItem([^{}]*)}/;
  const actionConfigPathPattern = /^templateConfig(?:\.\w+|\[\d+\])*(?:\.actionConfig)?\.(linkUrl|condition)$/;
  const requestStatusCodePattern = /\{this\.response\.([^{}]+)\.requestStatusCode\}/;
  const dataCompPattern = /{this\.dataComp\.(\w+)(?:\.(\w+(?:\.\w+)*)(\[\*\])?)?}/;
  const dataSourcePathPattern = /\.dataSource\./;

  // Be careful with the order of the syntax cases in the switch statement
  placeholdersList.forEach(placeholder => {
    if (targetPathActionCondition.test(path) && placeholder.startsWith("{this.data.")) {
      const variable = placeholder.slice("{this.data.".length, -1);
      const newPlaceholder = `{$.${variable}}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (placeholder.startsWith("{browser.localStorage.")) {
      const remainingPath = placeholder.slice("{browser.localStorage.".length, -1);
      const newPlaceholder = `{$global.localStorage.${remainingPath}}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
      return;
    }

    if (targetPathPattern.test(path) && placeholder.startsWith("{this.data.")) {
      const variable = placeholder.slice("{this.data.".length, -1);
      const newPlaceholder = `{$.${variable}}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (targetPathActions.test(path) && placeholder.startsWith("{this.data.")) {
      const variable = placeholder.slice("{this.data.".length, -1);
      const newPlaceholder = `{$.${variable}}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    // <Stepper-Case>
    const stepperPattern = /{this\.state\.(\w+)\.(ref|index)}/;
    const stepperMatch = placeholder.match(stepperPattern);
    if (stepperMatch) {
      const stepperName = stepperMatch[1];
      const property = stepperMatch[2];
      const newPlaceholder = `{this.${stepperName}.state.selected.${property}}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
      return;
    }
    // </Stepper-Case>

    // Handle {this.state.<fieldRef>.maxItems} => {this.<fieldRef>.config.repeated.maxItems}
    const maxItemsPattern = /{this\.state\.(\w+)\.maxItems}/;
    const maxItemsMatch = placeholder.match(maxItemsPattern);
    if (maxItemsMatch) {
      const fieldRef = maxItemsMatch[1];
      const newPlaceholder = `{this.${fieldRef}.config.repeated.maxItems}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
      return;
    }
    const appLayoutPattern = "{this.appLayout.";
    if (placeholder.startsWith(appLayoutPattern)) {
      const remainingPath = placeholder.slice(appLayoutPattern.length, -1);
      const newPlaceholder = `{$app.${remainingPath}}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
      return;
    }

    // Handle {this.state.<compRef>.value} => {this.<compRef>.state}

    const valuePatternWithIndex = /{this\.state\.(\w+)\.value\[(\d*|\*)\]}/;
    const valueMatchWithIndex = placeholder.match(valuePatternWithIndex);
    if (valueMatchWithIndex) {
      const compRef = valueMatchWithIndex[1];
      const index = valueMatchWithIndex[2];
      const newPlaceholder = `{this.${compRef}.repeatedState[${index}]}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
      return;
    }

    const valuePattern = /{this\.state\.(\w+)\.value}/;
    const valueMatch = placeholder.match(valuePattern);
    if (valueMatch) {
      const compRef = valueMatch[1];
      const newPlaceholder = `{this.${compRef}.repeatedState}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
      return;
    }

    // <Repeated-Cases>
    if (placeholder.match(/\{this\.state\.(\w+)\[(\d+)\]\.(\w+)\}/)) {
      const newPlaceholder = placeholder.replace(/\{this\.state\.(\w+)\[(\d+)\]\.(\w+)\}/, "{this.$1.state[$2].$3}");
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (placeholder.match(/\{this\.state\.(\w+)\.(\w+)\}/)) {
      const newPlaceholder = placeholder.replace(/\{this\.state\.(\w+)\./, "{this.$1.state.");
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (placeholder.match(/\{this\.state\[\d+\]\.(\w+)\.(\w+)\}/)) {
      const newPlaceholder = placeholder.replace(/\{this\.state\[(\d+)\]\.(\w+)\./, "{this.$2.state[$1].");
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }
    if (placeholder.match(/\{this\.state\.(\w+)-\d+\}/)) {
      const newPlaceholder = placeholder.replace(/\{this\.state\.(\w+)-/, "{this.$1.state-");
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }
    // </Repeated-Cases>

    // Handle placeholders like {this.response.<compRef>.requestStatusCode} in complex expressions
    if (placeholder.match(/\{this\.response\.(\w+)\.requestStatusCode\}/)) {
      const matchResult = placeholder?.match(/\{this\.response\.(\w+)\.requestStatusCode\}/);
      const compRef = matchResult ? matchResult[1] : "";
      const newPlaceholder = `{this.${compRef}.response._status}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (actionConfigPathPattern.test(path) && placeholder.startsWith("{this.data.")) {
      if (dataSourcePathPattern.test(path)) {
        return;
      }
      const variable = placeholder.slice("{this.data.".length, -1);
      const newPlaceholder = `{$.${variable}}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (
      path.match(
        /^templateConfig\.collections\[\d+\]\.pages\[\d+\]\.views\[\d+\]\.config\.actions\[\d+\]\.actionsMap\.default\[\d+\]\.actionConfig\.source$/
      ) &&
      placeholder.startsWith("{this.data.")
    ) {
      const variable = placeholder.slice("{this.data.".length, -1);
      const newPlaceholder = `{$.${variable}}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }
    if (
      path.match(/^templateConfig\.collections\[\d+\]\.pages\[\d+\]\.views\[\d+\]\.config\.actions\[\d+\]\.visibilityCondition$/) &&
      placeholder.startsWith("{this.data.")
    ) {
      const variable = placeholder.slice("{this.data.".length, -1);
      const newPlaceholder = `{$.${variable}}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    // {this.actionResponse.requestStatusCode} => {@._status}
    if (placeholder === "{this.actionResponse.requestStatusCode}") {
      modifiedData = modifiedData.replace(placeholder, "{@.response._status}");
      return;
    }

    if (placeholder.startsWith("{this.error.")) {
      const compKey = placeholder.slice("{this.error.".length, -1);
      const newPlaceholder = `{this.${compKey}.errors.hasError}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (placeholder.includes(".state.")) {
      const newPlaceholder = flipSyntaxByKeyword(placeholder, "state");
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (placeholder === "{this.dirty._page}") {
      modifiedData = modifiedData.replace(placeholder, "{$page.dirty.isDirty}");
    }

    if (placeholder.includes("this.location.")) {
      const newPlaceholder = placeholder.replace("this.location", "$global.location");
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (placeholder.includes(".queryStrings.")) {
      const newPlaceholder = placeholder.replace("queryStrings", "queryParams");
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (placeholder.includes("{#.data.")) {
      const newPlaceholder = placeholder.replace("{#.data.", "{#.");
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (placeholder === "{this.login.email}") {
      modifiedData = modifiedData.replace(placeholder, "{buildxLogin.email}");
    }
    if (placeholder === "{this.login.password}") {
      modifiedData = modifiedData.replace(placeholder, "{buildxLogin.password}");
    }
    if (placeholder === "{this.error}") {
      modifiedData = modifiedData.replace(placeholder, "{this.errors.hasError}");
    }
    if (placeholder === "{this.login.recaptcha}") {
      modifiedData = modifiedData.replace(placeholder, "{buildxLogin.recaptcha}");
    }

    if (placeholder === "{this.config.appVersion}") {
      modifiedData = modifiedData.replace(placeholder, "{$buildx.version}");
    }
    if (placeholder === "{this.config.deviceType}") {
      modifiedData = modifiedData.replace(placeholder, "{$global.browser.deviceType}");
    }
    if (placeholder === "{this.config.osVersion}") {
      modifiedData = modifiedData.replace(placeholder, "{$global.browser.osVersion}");
    }
    if (placeholder === "{this.config.timeZone}") {
      modifiedData = modifiedData.replace(placeholder, "{$global.browser.timeZone}");
    }
    if (placeholder === "{this.config.deviceUDID}") {
      modifiedData = modifiedData.replace(placeholder, "{$global.localStorage.device_uuid}");
    }
    if (placeholder === "{this.device.accessToken}") {
      modifiedData = modifiedData.replace(placeholder, "{$global.localStorage.deviceAccessToken}");
    }

    if (placeholder === "{this._page}") {
      modifiedData = modifiedData.replace(placeholder, "{$page}");
    }

    if (placeholder.includes(".state.")) {
      const newPlaceholder = flipSyntaxByKeyword(placeholder, "state");
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }
    if (placeholder.includes(".response.")) {
      const newPlaceholder = flipSyntaxByKeyword(placeholder, "response");
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (placeholder.startsWith("{this.location.")) {
      const remainingPath = placeholder.slice("{this.location.".length, -1);
      const newPlaceholder = `{$global.location.${remainingPath}}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (placeholder.startsWith("{this.localStorage.")) {
      const remainingPath = placeholder.slice("{this.localStorage.".length, -1);
      const newPlaceholder = `{$global.localStorage.${remainingPath}}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (placeholder.startsWith("{this.response.")) {
      const compKey = placeholder.slice("{this.response.".length, -1);
      const newPlaceholder = `{this.${compKey}.response}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (placeholder.startsWith("{this.state.")) {
      const compKey = placeholder.slice("{this.state.".length, -1);
      const newPlaceholder = `{this.${compKey}.state}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    if (placeholder.startsWith("{this.dirty.")) {
      const compKey = placeholder.slice("{this.dirty.".length, -1);
      const newPlaceholder = `{this.${compKey}.dirty.isDirty}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    const dataCompMatch = placeholder.match(dataCompPattern);
    if (dataCompMatch) {
      const compKey = dataCompMatch[1];
      const restOfPath = dataCompMatch[2] || "_body";
      const hasWildcard = dataCompMatch[3] || "";
      const newPlaceholder = `{this.${compKey}.data.${restOfPath}${hasWildcard}}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }
    if (selectedItemRegex.test(placeholder)) {
      const newPlaceholder = placeholder.replace("_selectedItem", ".selectedItem").replace(/\.{2,}/g, ".");
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }

    const requestStatusCodeMatch = placeholder.match(requestStatusCodePattern);
    if (requestStatusCodeMatch) {
      const compKey = requestStatusCodeMatch[1];
      const newPlaceholder = `{this.${compKey}.response._status}`;
      modifiedData = modifiedData.replace(placeholder, newPlaceholder);
    }
  });

  return modifiedData;
}

function traverseApp(obj, currentPath = "") {
  if (obj === null) {
    return;
  }

  if (Array.isArray(obj)) {
    obj.forEach((el, index) => {
      const path = `${currentPath}[${index}]`;
      if (typeof el === "object" || Array.isArray(el)) {
        traverseApp(el, path);
      } else {
        obj[index] = convertToNewSyntax(el, path);
      }
    });
    return;
  }

  if (typeof obj === "object") {
    Object.keys(obj).forEach(key => {
      const path = currentPath ? `${currentPath}.${key}` : key;
      if (typeof obj[key] === "object" || Array.isArray(obj[key])) {
        traverseApp(obj[key], path);
      } else {
        obj[key] = convertToNewSyntax(obj[key], path);
      }
    });
    return;
  }
}
