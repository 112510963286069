import { Breakpoint } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BXPermissionList } from "src/BXEngine/types";
import { BXApp, BXEnv } from "src/types/BXAppType";
import { IViewState, UIElement } from "src/types/UIElement";

export interface BuildxProviderState {
  appDescriptor: BXApp[];
  allowedApps: BXApp[];
  fqdnApp?: BXApp;
  currentApp?: BXApp | null;
  multiLingual?: any;
  envs?: BXEnv[] | null;
  loadingApps?: boolean;
  isAdministrationMode: boolean;
  isSwitchingMode: boolean;
  appProfiles: { [key: string]: any };
  appTokens: { [key: string]: any };
  currentProfileId?: string | null;
  viewStacks: UIElement[];
  currentBreakpoint: Breakpoint;
  isRTL: boolean;
  viewsState: IViewState;
  loadingViews: any;
  queriesStateGraphQL?: any;
  selectedAppId?: any;
  selectedCollectionId?: any;
  selectedPageId?: any;
  selectedViewId?: any;
  leftDrawerOpened: boolean;
  clientSecrets: any;
  stripePromises: any;
  stripeAppearance: any;
  stripeEndpoints: any;
  currentProfilePermissions?: BXPermissionList | null;
  isConfirmAction: any;
  actionConfirmControl: any;
}

const initialState: BuildxProviderState = {
  appDescriptor: [],
  allowedApps: [],
  fqdnApp: undefined,
  currentApp: undefined,
  multiLingual: undefined,
  envs: undefined,
  loadingApps: false,
  isAdministrationMode: false,
  isSwitchingMode: false,
  appProfiles: {},
  appTokens: {},
  currentProfileId: undefined,
  viewStacks: [],
  currentBreakpoint: "lg",
  isRTL: false,
  viewsState: {},
  loadingViews: {},
  queriesStateGraphQL: {},
  selectedAppId: "",
  selectedCollectionId: "",
  selectedPageId: "",
  selectedViewId: "",
  leftDrawerOpened: false,
  clientSecrets: null,
  stripePromises: null,
  stripeAppearance: null,
  stripeEndpoints: [],
  currentProfilePermissions: null,
  isConfirmAction: null,
  actionConfirmControl: {},
};

const buildxProvider = createSlice({
  name: "buildxProvider",
  initialState,
  reducers: {
    setProviderState(state, action: PayloadAction<Partial<BuildxProviderState>>) {
      Object.entries(action.payload).forEach(([key, value]) => {
        if (Array.isArray(state[key]) && !Array.isArray(value)) {
          (state[key] as any[]).push(value);
        } else {
          state[key] = value;
        }
      });
    },
  },
});

export const { setProviderState } = buildxProvider.actions;

export default buildxProvider.reducer;
