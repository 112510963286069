import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useNormalizedRoute = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    //Apply normalization rules to the current URL path.
    const normalizedPath = location.pathname.replace(/\/{2,}/g, "/");

    if (normalizedPath !== location.pathname) {
      navigate(normalizedPath, { replace: true });
    }
  }, [location, navigate]);
};
