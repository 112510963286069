/* eslint-disable react-hooks/rules-of-hooks */
import _, { debounce, isArray } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import store, { AppDispatch, RootState } from "../../store/store";
import {
  cleanDirtyFields,
  removeAppValue,
  resetAppState,
  setAppError,
  updateAppState,
  updateAppStateBulk,
  validationRegistry,
} from "../endUser/endUserSlice";
// import {
//   cleanDirtyFields,
//   clearErrors,
//   clearTrigger,
//   mergeAppValue,
//   removeAppField,
//   resetAppState,
//   traverseOnChildren,
// } from "./appStateSlice";
import {
  determineLevelAndTargetKey,
  getNextSegment,
  getStringAfterNextKey,
  getTargetState,
  safeConcatPath,
  traverseOnChildren,
} from "./utils";

let queue = [] as any;

const flushQueue = () => {
  if (queue.length > 0) {
    store.dispatch(updateAppStateBulk(queue));
    queue = [];
  }
};

const debounced = debounce(flushQueue, 300);

// Custom hook to provide state management functions
export const useAppState = () => {
  const dispatch = useDispatch<AppDispatch>();

  // Function to get the value of a specific field
  const getValue = (name: string, pathVariables?: any) => {
    const { pageId, viewName } = pathVariables || {};
    const viewFromName: any = name?.split(".")[1];
    const entities = store?.getState()?.endUser?.application?.pages?.[pageId]?.entities || {};
    const isExist = Object.values(entities).some((entity: any) => entity.viewName === viewFromName && viewFromName);
    const viewIsExist = store?.getState()?.endUser?.application?.pages?.[pageId]?.views?.[viewFromName] || isExist;

    const { level, targetKey } = determineLevelAndTargetKey(name, pathVariables, viewIsExist);

    const state = store.getState().endUser;
    const targetState = getTargetState(level, state, pageId, viewName);
    const nextKey = getNextSegment(name, targetKey);
    const statePath = getStringAfterNextKey(name, nextKey);

    //Access as component key
    if (targetState?.entities?.[nextKey]) {
      const component = targetState?.entities?.[nextKey];
      if (_.isEmpty(statePath)) {
        return component;
      }

      return _.get(component, statePath);
    }

    //If not, level direct variable
    const pathToWatch = safeConcatPath(nextKey, statePath);
    if (_.isEmpty(pathToWatch)) {
      return targetState;
    }

    return _.get(targetState, pathToWatch);
  };

  // Function to get all values from the app state
  const getValues = () => {
    const state = store.getState();
    return state.endUser;
  };

  const setValue = (
    name: string,
    value: any,
    options?: {
      enableDirtyCheck?: boolean;
      reEvaluateErrorsAndDirty?: boolean;
      error?: string;
      viewName: string | undefined;
      pageId: string | undefined;
      repeatedComponentIndex?: number;
      setWithInitialValue?: boolean;
    },
    isQueue?: boolean
  ) => {
    if (!isQueue) {
      dispatch(
        updateAppState({
          name,
          value,
          options,
        })
      );
    } else {
      queue.push({ name, value, options });
      debounced();
    }
  };

  const mergeValue = (name: string, value: any) => {
    // dispatch(mergeAppValue({ name, value }));
  };

  const removeDirty = (name: string, pageId, viewName, cleanDirtyLevel) => {
    dispatch(cleanDirtyFields({ name, pageId, viewName, cleanDirtyLevel }));
  };

  const removeValue = (name: string, pathVariables?: any) => {
    const { pageId, viewName, repeatedComponentIndex } = pathVariables || {};

    dispatch(
      removeAppValue({
        name,
        pageId,
        viewName,
        repeatedComponentIndex,
      })
    );
  };

  // Function to watch a specific field's value dynamically or the entire state
  const watch = (name?: string, pathVariables?: any) => {
    const { pageId, viewName } = pathVariables || {};

    /* handle absolute path */

    const viewFromName: any = name?.split(".")[1];
    const entities = store?.getState()?.endUser?.application?.pages?.[pageId]?.entities || {};
    const isExist = Object.values(entities).some((entity: any) => entity.viewName === viewFromName && viewFromName);
    const viewIsExist = store?.getState()?.endUser?.application?.pages?.[pageId]?.views?.[viewFromName] || isExist;

    const { level, targetKey } = determineLevelAndTargetKey(name, pathVariables, viewIsExist);

    try {
      return useSelector((state: RootState) => {
        const targetState = getTargetState(level, state.endUser, pageId, viewName, name, viewIsExist);
        const nextKey = getNextSegment(name, targetKey);
        const statePath = getStringAfterNextKey(name, nextKey);

        const handleArrayPath = (obj: any, path: string) => {
          const elementBeforeLastOne = _.get(obj, path.split(".").slice(0, -1).join("."));
          if (isArray(elementBeforeLastOne)) {
            const lastElementName = path.split(".").slice(-1)[0];
            return elementBeforeLastOne.map(element => _.get(element, lastElementName));
          }
          return _.get(obj, path);
        };

        //Access as component key
        if (targetState?.entities?.[nextKey]) {
          const component = targetState?.entities?.[nextKey];
          if (_.isEmpty(statePath)) {
            return component;
          }
          return handleArrayPath(component, statePath);
        }

        //If not, level direct variable
        const pathToWatch = safeConcatPath(nextKey, statePath);
        if (_.isEmpty(pathToWatch) && level !== "application") {
          return targetState;
        }
        return handleArrayPath(targetState, pathToWatch);
      });
    } catch {
      const state = store.getState().endUser;
      const targetState = getTargetState(level, state, pageId, viewName, name, viewIsExist);
      const nextKey = getNextSegment(name, targetKey);
      const statePath = getStringAfterNextKey(name, nextKey);

      const handleArrayPath = (obj: any, path: string) => {
        const elementBeforeLastOne = _.get(obj, path.split(".").slice(0, -1).join("."));
        if (isArray(elementBeforeLastOne)) {
          const lastElementName = path.split(".").slice(-1)[0];
          return elementBeforeLastOne.map(element => _.get(element, lastElementName));
        }
        return _.get(obj, path);
      };

      //Access as component key
      if (targetState?.entities?.[nextKey]) {
        const component = targetState?.entities?.[nextKey];
        if (_.isEmpty(statePath)) {
          return component;
        }
        return handleArrayPath(component, statePath);
      }

      //If not, level direct variable
      const pathToWatch = safeConcatPath(nextKey, statePath);
      if (_.isEmpty(pathToWatch) && level !== "application") {
        return targetState;
      }
      return handleArrayPath(targetState, pathToWatch);
    }
  };

  const runAllValidations = (name = "", validationLevel: "page" | "view" | "component"): boolean => {
    let isValid = true;
    const [pageId, viewName = "", componentKey = "", ...rest] = name.split(".");

    const runValidation = (_node, _nodeKey, _state, nodePath) => {
      const [selectedPage, selectedView = "", , ...rest] = nodePath?.split(".");
      const validationFns: { [key: string]: (value: any) => true | string } = _.get(validationRegistry, nodePath);

      const traverseValidationFns = (validationFns, currentPath) => {
        const validateValue = (value, index?: number) => {
          const result = validationFns(value);
          if (result !== true && result !== undefined) {
            isValid = false;
            dispatch(
              setAppError({ name: currentPath, error: result, pageId: selectedPage, viewName: selectedView, repeatedComponentIndex: index })
            );
          }
        };

        if (typeof validationFns === "function") {
          const valueToValidate = getValue(`${currentPath}.state`, { pageId: selectedPage, viewName: selectedView });

          // Handle repeated state or single state value validation
          if (Array.isArray(valueToValidate)) {
            valueToValidate.forEach(validateValue);
          } else {
            validateValue(valueToValidate);
          }
        } else if (Array.isArray(validationFns)) {
          for (let i = 0; i < validationFns.length; i++) {
            traverseValidationFns(validationFns[i], currentPath);
          }
        } else if (typeof validationFns === "object" && validationFns !== null) {
          for (const [key, value] of Object.entries(validationFns)) {
            traverseValidationFns(value, currentPath);
          }
        }
      };

      if (validationFns) {
        traverseValidationFns(validationFns, nodePath);
      }
    };

    if (validationLevel === "page") {
      //Run validation for all components in the page
      const state = store.getState().endUser;
      const pageViews = Object.keys(state.application.pages[pageId].views);

      for (const view of pageViews) {
        const viewComponents = state.application.pages[pageId].views[view].entities;
        let viewComponentsKeys;
        if (viewComponents) {
          viewComponentsKeys = Object.keys(viewComponents);
        }
        const basePath = `${pageId}.${view}`;
        const targetState = getTargetState("views", state, pageId, view);
        for (const componentKey of viewComponentsKeys) {
          runValidation(viewComponents[componentKey], componentKey, targetState, `${basePath}.${componentKey}`);
        }
      }
    } else if (validationLevel === "view") {
      //Run validation for all view components
      const state = store.getState().endUser;
      const viewComponents = state?.application?.pages[pageId]?.views[viewName]?.entities;
      const targetState = getTargetState("views", state, pageId, viewName);
      let viewComponentsKeys;
      if (viewComponents) {
        viewComponentsKeys = Object.keys(viewComponents);

        const basePath = `${pageId}.${viewName}`;
        for (const componentKey of viewComponentsKeys) {
          runValidation(viewComponents[componentKey], componentKey, targetState, `${basePath}.${componentKey}`);
        }
      }
    } else if (validationLevel === "component") {
      const state = store.getState().endUser;
      const targetState = getTargetState("views", state, pageId, viewName);

      const basePath = `${pageId}.${viewName}`;
      traverseOnChildren(targetState, componentKey, runValidation, undefined, basePath);
    }

    return isValid;
  };

  //Function to clear the validation on specific fields of the app hierarchy
  const clearValidation = (name: string) => {
    // dispatch(clearErrors({ name }));
  };

  // Function to handle form submission
  const handleSubmit = () => {
    // If no errors, proceed with the callback
    const values = getValues();

    return { values };
  };

  // Function to re1   the entire app state
  const resetState = (name: string, pageId: string, viewName: string, resetLevel: string) => {
    dispatch(resetAppState({ name, pageId, viewName, resetLevel }));
  };

  const produceTrigger = (name?: string, type?: string, eventPayload?: any, pathVariables?: any) => {
    const { pageId, viewName, componentKey, viewIsExist } = pathVariables;
    const value = {
      type, // Event type (e.g., 'refetch', 'reset')
      payload: eventPayload, // Any additional data needed for handling the event
    };

    dispatch(
      updateAppState({
        name: `${name}.trigger`,
        value,
        options: {
          pageId,
          viewName,
          componentKey,
          viewIsExist,
        },
      })
    );
  };

  const cleanTrigger = (name: string, pathVariables?: any) => {
    const { pageId, viewName } = pathVariables || {};
    const value = "";

    dispatch(
      updateAppState({
        name: `${name}.trigger`,
        value,
        options: {
          pageId,
          viewName,
        },
      })
    );
  };

  return {
    getValue,
    getValues,
    setValue,
    watch,
    handleSubmit,
    runAllValidations,
    resetState,
    removeDirty,
    removeValue,
    // getDirty,
    clearValidation,
    mergeValue,
    produceTrigger,
    cleanTrigger,
  };
};
