import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, DialogActions, Grid, Typography } from "@mui/material";
import axios from "axios";
import _ from "lodash";
import { FC } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { BXInput } from "src/components/BXUI/FormControls";
import { Upload } from "src/components/BXUI/Upload";
import { getAuthorizationHeader } from "src/utils/generalUtils";
import * as yup from "yup";

type CreateOASFormProps = {
  onSave: SubmitHandler<FieldValues>;
  height?: string | number;
  row?: any;
};

export const CreateOASForm: FC<CreateOASFormProps> = ({ onSave = _.noop, height, row }) => {
  const schema = yup
    .object({
      fileName: yup.string().required(),
      url: yup
        .string()
        .url("Must be a valid URL")
        .test("fileOrUrl", "Either file or URL is required", (value, context) => {
          const { file } = context.parent;
          if (!file && !value) {
            return context.createError({ message: "Either file or URL is required" });
          }
          return true;
        }),
      file: yup.mixed().test("fileOrUrl", "Either file or URL is required", (value, context) => {
        const { url } = context.parent;
        if (!url && !value) {
          return context.createError({ message: "Either file or URL is required" });
        }
        return true;
      }),
    })
    .required();

  const defaultValues = { fileName: row?.name };

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    setValue,
  } = useForm<FieldValues>({
    defaultValues,
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const handleSaveClick = (values: any) => {
    if (values?.url) {
      fetch(values?.url)
        .then(resp => resp.blob())
        .then(async blob => {
          const file = new File([blob], "filename", { type: blob.type });

          const { data } = await axios.get(process.env.REACT_APP_HOST_API_KEY + "/api/uploads/s3-url", {
            headers: { ...getAuthorizationHeader(null, localStorage.getItem("accessToken")) },
          });

          const { key, url } = data;

          const extn = file.type.split("/")[1];
          await axios.put(url, file, {
            headers: {
              "Content-Type": file.type,
            },
          });
          onSave({ ...values, data: { key, extension: extn } });
        })
        .catch(() => alert("Something went wrong!"));
    } else {
      onSave(values);
    }
  };
  return (
    <Box component='form' noValidate autoComplete='off' flex={1} height={height}>
      <Grid container spacing={3} height={height}>
        <Grid item xs={6} my={2}>
          <BXInput name={"fileName"} control={control} label='File name' variant='outlined' error={errors.fileName} />
        </Grid>
        <Grid item xs={12}>
          <Upload
            auth={{}}
            uploadConfig={{
              signedUrl: process.env.REACT_APP_HOST_API_KEY + "/api/uploads/s3-url",
              uploadUrlType: "aws",
              uploadAllowedTypes: [".json"],
              customToken: localStorage.getItem("accessToken"),
              isInput: "Yes",
              withPreview: false,
              allDetails: true,
            }}
            uploadData={{
              control,
              inputValidationName: "data",
            }}
            onSuccess={(data: any) => {
              const file = data.file;
              // Load the file into memory
              var reader = new FileReader();
              reader.onload = function (event: any) {
                var json = event.target.result;
                // parse the JSON string
                var jsonData = JSON.parse(json);
                const fileName = jsonData?.info?.title || data?.fileName;
                data.fileName = fileName;
                // do something with the data
                setValue("fileName", fileName);
              };

              reader.readAsText(file);
            }}
            layout={""}
            info={{
              name: "",
            }}
            views={[]}
            id={"1"}
            errorText={errors.file}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>Or</Typography>
        </Grid>
        <Grid item xs={12}>
          <BXInput name={"url"} control={control} label='Url' variant='outlined' error={errors.url} />
        </Grid>

        <Grid item xs={12}>
          <DialogActions style={{ padding: 0, marginTop: 16, justifyContent: "center" }}>
            <Button onClick={handleSubmit(handleSaveClick)} variant={"contained"} aria-label={"save"}>
              Save
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Box>
  );
};
