import { Box, Typography } from "@mui/material";
import { BXEngine } from "src/BXEngine";
import { CustomLoadingView } from "./CustomLoadingView";

const BXView = props => {
  const { $views, $config, __config, metaData, $parentRef, disabled, config, ...rest } = props;
  const { viewRef } = $config || {};
  const { __data, pageId, path, item = {} } = metaData || {};
  const view = $views?.find(view => view?.id === ($config ? viewRef?.id : __config?.viewRef?.id));
  if (__config) {
    return (
      <Box position='relative' width={"100%"} height={"100%"} parent-id={`${pageId}.${view?.info?.viewName}`}>
        {config?.loadingConfig?.isLoadingViewEnabled && (
          <CustomLoadingView
            loadingConfig={config.loadingConfig}
            componentKey={`${pageId}.${view?.info?.viewName}`}
            pageId={pageId}
            viewName={view?.info?.viewName}
          />
        )}

        <BXEngine
          auth={{}}
          pageId={pageId}
          page={{ views: $views, layout: view, id: pageId } as any}
          path={[path, view?.info?.name]?.join(".")}
          layout={[{ ...(view as any), type: view?.type }]}
          data={item}
          __data={__data}
          parentRef={!__config?.isPercentageHeight && !__config?.isDynamicHeight ? $parentRef : undefined}
          isCustomLayout={true}
          disabled={disabled}
        />
      </Box>
    );
  }

  return (
    <Box {...rest}>
      <Box style={{ display: "flex", height: "100%", width: "100%", justifyContent: "center", alignItems: "center" }}>
        <Typography fontWeight={"bold"} fontSize={"24px"}>
          {view?.info?.name}
        </Typography>
      </Box>
    </Box>
  );
};

export { BXView };
