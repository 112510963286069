import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

interface StyleProps {
  unselectedPageColor?: string;
  selectedPageColor?:string;
  unselectedPageBackgroundColor?:string;
  selectedPageBackgroundColor?:string;
  selectedPageColorHover?:string;
  unselectedPageColorHover?:string;
  pagesOrientation?:any
}
export const paginationStyles = makeStyles<Theme, StyleProps>((theme) => ({
  paginationContainer: {
    display: "flex",
    "list-style-type": "none",
    flexDirection:(props)=> props.pagesOrientation ||"row",
    padding: 0,
    margin: 0,
  },
  paginationItem: {
    height: "32px",
    "text-align": "center",
    margin: "auto 4px",
    color: (props) => props.unselectedPageColor ||theme.palette.text.primary,
    display: "flex",
    backgroundColor:(props) => props.unselectedPageBackgroundColor || "",
    "box-sizing": "border-box",
    "align-items": "center",
    "letter-spacing": "0.01071em",
    "border-radius": "16px",
    "line-height": "1.43",
    "font-size": "13px",
    "min-width": "32px",
    justifyContent: "center",


    "&.dots:hover": {
      "background-color": "transparent",
      cursor: "default",
    },
    "&:hover": {
      "background-color": (props) =>  props.unselectedPageColorHover || theme.palette.primary.light,
      cursor: "pointer",
    },

    "&.selected": {
      "background-color":(props) => props.selectedPageBackgroundColor|| theme.palette.primary.light,
       "color": (props) => props.selectedPageColor ||theme.palette.text.primary,
       "&:hover": {
        backgroundColor: (props) =>
          props.selectedPageColorHover || "",
      },
    },

    "&.disabled": {
      "pointer-events": "none",
      color: theme.palette.primary.light,
      "&:hover": {
        "background-color": "transparent",
        cursor: "default",
      },
    },
  },
}));
