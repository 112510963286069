import { removeChildFromParent, upsertComponents } from "src/features/builder/builderSlice";
import { selectComponentById, selectComponentParent } from "src/features/builder/selectors";
import store from "src/store/store";

export type DIRECTION = "top" | "bottom" | "middle";

const findBlock = (id: string) => {
  const state = store.getState();
  return selectComponentById(state, id); // Fetch block by id from normalized entities
};

const removeBlocks = (idsToRemove: string[]) => {
  const state = store.getState();

  //Remove the dragged blocks from their old parents
  idsToRemove.forEach(id => {
    const parentId = selectComponentParent(state, id);
    const childId = id;

    store.dispatch(removeChildFromParent({ parentId: parentId, childId: childId }));
  });
};

const filterOutNestedBlocks = (blocks: any[]) => {
  // Extract all block IDs to determine parent-child relationships
  const blockIds = blocks.map(block => block.id);

  // Filter out blocks that are children of other blocks in blocksToMove
  return blocks.filter(block => {
    const state = store.getState();

    // Recursive function to check if the block is a child of any other block
    const isChildOfOtherBlock = (blockId: string): boolean => {
      const parent = selectComponentParent(state, blockId);
      if (!parent) return false; // No parent, not a child
      if (blockIds.includes(parent)) return true; // Parent is part of blocksToMove
      return isChildOfOtherBlock(parent); // Recursively check up the hierarchy
    };

    return !isChildOfOtherBlock(block.id); // Include only blocks that are not children of others
  });
};

const insertBlocksAtPosition = (targetId: string, blocksToMove: any[], position: DIRECTION) => {
  const state = store.getState();

  // Find the target block
  const targetBlock = findBlock(targetId);
  if (!targetBlock) return;

  const targetBlockChildren = targetBlock.children || [];
  const targetBlockParentId = selectComponentParent(state, targetBlock.id);
  const targetBlockParent = targetBlockParentId ? selectComponentById(state, targetBlockParentId) : null;

  // Extract new block IDs
  const blocksToMoveIds = blocksToMove.map(block => block.id);

  // Helpers to update parent or target blocks with new children or parent
  const createUpdatedParent = (position: DIRECTION) => {
    if (!targetBlockParent) return null;

    const targetBlockId = targetBlock.id;
    const existingChildren = targetBlockParent.children || [];
    // Filter out nested blocks to move only the top-level blocks
    const filteredBlocksToMoveIds = filterOutNestedBlocks(blocksToMove).map(block => block.id);

    // Find the index of the target block in the existing children
    const targetBlockIndex = existingChildren.indexOf(targetBlockId);

    if (targetBlockIndex === -1) {
      // If the target block is not found, just append blocksToMoveIds at the top
      return {
        ...targetBlockParent,
        children: [...filteredBlocksToMoveIds, ...existingChildren],
      };
    }

    const isTop = position === "top";
    const beforeTarget = existingChildren.slice(0, isTop ? targetBlockIndex : targetBlockIndex + 1);
    const afterTarget = existingChildren.slice(isTop ? targetBlockIndex : targetBlockIndex + 1);

    return {
      ...targetBlockParent,
      children: [...beforeTarget, ...filteredBlocksToMoveIds, ...afterTarget],
    };
  };

  const createMovedBlocks = (parentId: string | null) => {
    // Filter out nested blocks to move only the top-level blocks
    const filteredBlocks = filterOutNestedBlocks(blocksToMove);
    return filteredBlocks.map(block => ({
      ...block,
      parentId,
    }));
  };

  switch (position) {
    case "top": {
      const updatedParent = createUpdatedParent(position);
      const movedBlocks = createMovedBlocks(targetBlockParentId);
      const dispatchPayload = updatedParent ? [...movedBlocks, updatedParent] : [...movedBlocks];
      store.dispatch(upsertComponents(dispatchPayload));
      break;
    }

    case "bottom": {
      const updatedParent = createUpdatedParent(position);
      const movedBlocks = createMovedBlocks(targetBlockParentId);

      const dispatchPayload = updatedParent ? [...movedBlocks, updatedParent] : [...movedBlocks];
      store.dispatch(upsertComponents(dispatchPayload));
      break;
    }

    case "middle": {
      // Determine the middle position
      const middleIndex = Math.floor(targetBlockChildren.length / 2);

      // Update target block's children to include new blocks
      const updatedChildren = [...targetBlockChildren.slice(0, middleIndex), ...blocksToMoveIds, ...targetBlockChildren.slice(middleIndex)];

      const movedBlocks = createMovedBlocks(targetBlock.id);

      const updatedBlock = {
        ...targetBlock,
        children: updatedChildren,
      };

      store.dispatch(upsertComponents([...movedBlocks, updatedBlock]));
      break;
    }
  }
};

// Check if the target block is a child of the dragged block
const checkChildrenRecursively = (blockId: string, targetId: string): boolean => {
  const block = findBlock(blockId);
  if (!block || !block.children) return false;
  if (block.children.includes(targetId)) return true;
  return block.children.some(childId => checkChildrenRecursively(childId, targetId));
};

export const moveBlocks = (draggedIds: string[], targetId: string, direction: DIRECTION) => {
  // Find the blocks to move by their ids
  const blocksToMove = draggedIds.map(id => findBlock(id)).filter(Boolean);

  // Prevent moving blocks into their
  if (draggedIds.includes(targetId)) {
    return;
  }

  // Prevent moving blocks into their
  const hasTargetInChildren = blocksToMove.some(block => {
    return checkChildrenRecursively(block.id, targetId);
  });

  if (hasTargetInChildren) {
    return;
  }

  if (blocksToMove.length === 0) return;

  // Remove the dragged blocks from the current structure
  removeBlocks(draggedIds);

  // Insert the dragged blocks at the target position
  return insertBlocksAtPosition(targetId, blocksToMove, direction);
};
