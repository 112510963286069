import {
  Box,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { IconArrowBackUp, IconChecks, IconExclamationCircle, IconGitCompare, IconHistory } from "@tabler/icons-react";
import _ from "lodash";
import React, { FC, useCallback, useMemo, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useInfiniteQuery } from "react-query";
import { handleMigrateApp } from "src/Migrations";
import BXModal from "src/components/BXUI/Modal";
import { queryClient } from "src/features/buildxProvider/buildxProviderUtils";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import axios from "src/utils/axios";
import { checkVersion } from "src/utils/generalUtils";
import AppHistory from "./AppHistory";
import BackUpAll from "./BackUpAll";

type MigrationsProps = {
  children?: React.ReactNode;
};

const columns: any[] = [
  { id: "id", label: "App ID", minWidth: 270 },
  { id: "name", label: "App Name", minWidth: 270 },
  { id: "org.name", label: "Organization Name", minWidth: 270 },
  { id: "appVersion", label: "App Version", minWidth: 270 },
];
const logColumns: any[] = [
  { id: "id", label: "History ID", minWidth: 270 },
  { id: "name", label: "Name", minWidth: 270 },
  { id: "organizationName", label: "Organization Name", minWidth: 270 },
  { id: "status", label: "Status", minWidth: 270 },
];
export const Migrations: FC<MigrationsProps> = ({ children }) => {
  const { palette } = useTheme();

  const [migrationLogs, setMigrationLogs] = useState<any>([]);
  const [searchText, setSearchText] = useState("");
  const [loadingRowId, setLoadingRowId] = useState<string | null>(null);

  const debouncedSearchText = useMemo(
    () =>
      _.debounce((value: string) => {
        setSearchText(value);
      }, 500),
    []
  );

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      debouncedSearchText(e.target.value);
    },
    [debouncedSearchText]
  );

  let queryKey = ["apps"];

  if (searchText.trim()) {
    queryKey.push(searchText.trim());
  }
  const { data, hasNextPage, fetchNextPage, isFetching, isError } = useInfiniteQuery(
    queryKey,
    ({ pageParam, queryKey }) => {
      if (queryKey[1] && queryKey[1].length < 3) {
        return {
          data: {
            items: [],
          },
        } as any;
      }
      return axios.get(process.env.REACT_APP_HOST_API_KEY + "/api/admin/app-history/apps", {
        params: {
          cursor: pageParam,
          limit: 10,
          appName: searchText || undefined,
        },
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      });
    },
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage: any) => (lastPage?.data?.hasMore ? lastPage?.data?.cursor : undefined),
      refetchOnWindowFocus: false,
    }
  );

  const [sentryRef] = useInfiniteScroll({
    loading: isFetching,
    hasNextPage: hasNextPage || false,
    onLoadMore: () => fetchNextPage(),
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: isError,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 400px 0px",
  });

  const handleMigrateClick = () => {
    const getApps = async (pageParam?: string) => {
      axios
        .get(process.env.REACT_APP_HOST_API_KEY + "/api/admin/app-history/apps", {
          params: {
            cursor: pageParam,
            limit: 10,
          },
          headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
        })
        .then(({ data }) => {
          const appsNeedToMigrate: any = [];
          const upToDateApps: any = [];
          data?.items?.forEach((app: any) => {
            const appVersion = app?.appVersion;
            if (checkVersion(appVersion)) {
              upToDateApps.unshift({ id: app?.id, name: app?.name, status: "Already up to date" });
            } else {
              const migratedApp = handleMigrateApp(app);
              appsNeedToMigrate.unshift(migratedApp);
            }
          });

          const inProgressApps = appsNeedToMigrate?.map((app: any) => ({ id: app?.id, name: app?.name, status: "In Progress" }));
          setMigrationLogs((prev: any) => [...inProgressApps, ...upToDateApps, ...prev]);

          const handleNextCall = () => {
            if (data?.hasMore) {
              getApps(data?.cursor);
            } else {
              queryClient.refetchQueries(["apps"]);
              enqueueSnackbarRef?.("Migrated Success", {
                variant: "success",
              });
            }
          };
          if (!appsNeedToMigrate.length) {
            handleNextCall();
          }

          appsNeedToMigrate.map(async (app: any, index: number) => {
            try {
              const res = await axios.post(process.env.REACT_APP_HOST_API_KEY + `/api/admin/app-history/${app.id}/migrate`, app);
              setMigrationLogs((prev: any) => prev?.map((log: any) => (log?.id != app?.id ? log : { ...log, status: "Migrated" })));
              if (appsNeedToMigrate.length === index + 1) {
                handleNextCall();
              }
            } catch (e) {
              setMigrationLogs((prev: any) => prev?.map((log: any) => (log?.id != app?.id ? log : { ...log, status: "Failed" })));
              if (appsNeedToMigrate.length === index + 1) {
                handleNextCall();
              }
            }
          });
        });
    };
    getApps();
  };

  const handleSingleMigrateClick = async (row: any) => {
    if (loadingRowId === row.id) {
      return;
    }
    try {
      setLoadingRowId(row.id);

      const appVersion = row?.appVersion;

      if (checkVersion(appVersion)) {
        enqueueSnackbarRef?.(`App "${row.name}" is already up to date`, {
          variant: "info",
        });
        setLoadingRowId(null);
        return;
      }

      const migratedApp = handleMigrateApp(row);
      const res = await axios.post(`${process.env.REACT_APP_HOST_API_KEY}/api/admin/app-history/${row.id}/migrate`, migratedApp, {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      });

      setMigrationLogs((prev: any) => prev.map((log: any) => (log.id !== row.id ? log : { ...log, status: "Migrated" })));
      enqueueSnackbarRef?.(`App "${row.name}" migrated successfully`, {
        variant: "success",
      });
    } catch (e) {
      console.error(e);
      setMigrationLogs((prev: any) => prev.map((log: any) => (log.id !== row.id ? log : { ...log, status: "Failed" })));
      enqueueSnackbarRef?.(`Failed to migrate app "${row.name}"`, {
        variant: "error",
      });
    } finally {
      setLoadingRowId(null);
    }
  };

  const handleBackupClick = (app: any) => {
    axios
      .post(process.env.REACT_APP_HOST_API_KEY + `/api/admin/app-history/${app?.id}/backup`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      })
      .then(({ data }) => {
        enqueueSnackbarRef?.("Backup Success", {
          variant: "success",
        });
      });
  };

  const entries = _.flatten(data?.pages?.map((p: any) => _.get(p.data, "items")));

  return (
    <>
      <Grid container style={{ height: "100%" }}>
        <Grid style={{ height: "100%", padding: 10, paddingTop: 0 }} item md={12}>
          <Grid container style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 2 }}>
            <Grid item xs={12}></Grid>
          </Grid>

          <Grid container marginBottom={1}>
            <Grid container flex={1} spacing={2} alignItems={"center"}>
              <Grid item>
                <Typography fontSize={"20px"} color='textPrimary' fontWeight={600}>
                  Migrations
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Card style={{ padding: 24 }}>
            <Grid
              container
              style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 2, flexWrap: "nowrap" }}
            >
              <Grid item spacing={2} xs={8} md={6} alignItems='center'>
                <TextField size='small' fullWidth label={"Search"} onChange={handleSearchChange} />
              </Grid>

              <Box width={"100%"} display={"flex"} justifyContent={"flex-end"} gap={2}>
                <BXModal
                  maxWidth={"lg"}
                  label={"Migrate All"}
                  buttonProps={{ variant: "contained", onClick: handleMigrateClick }}
                  onClose={() => {
                    setMigrationLogs([]);
                  }}
                  title={"Migrate All"}
                >
                  <TableContainer style={{ backgroundColor: palette.background.paper }}>
                    <Table stickyHeader aria-label='sticky table'>
                      <TableHead>
                        <TableRow>
                          {logColumns.map(column => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth, backgroundColor: palette.background.paper }}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                          <TableCell />
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {migrationLogs?.length === 0 && (
                          <TableRow>
                            <TableCell colSpan={logColumns.length + 1} align={"center"}>
                              No migrations yet
                            </TableCell>
                          </TableRow>
                        )}
                        {migrationLogs?.map((row: any) => {
                          return (
                            <TableRow hover role='checkbox' tabIndex={-1} key={row?.id}>
                              {logColumns.map(column => {
                                const value = _.get(row, column.id);

                                return (
                                  <TableCell key={column.id}>
                                    <Grid container alignItems={"center"}>
                                      {value === "In Progress" ? (
                                        <CircularProgress size={20} />
                                      ) : value === "Failed" ? (
                                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                          <IconExclamationCircle color='red' />

                                          <Typography marginInlineStart={1} color='error'>
                                            Failed
                                          </Typography>
                                        </Box>
                                      ) : value === "Migrated" || value === "Already up to date" ? (
                                        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                          <IconChecks color='green' />
                                          <Typography marginInlineStart={1} color='green'>
                                            {value}
                                          </Typography>
                                        </Box>
                                      ) : (
                                        value
                                      )}
                                    </Grid>
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </BXModal>
                <BackUpAll />
              </Box>
            </Grid>
            <TableContainer style={{ backgroundColor: palette.background.paper }}>
              <Table stickyHeader aria-label='sticky table'>
                <TableHead>
                  <TableRow>
                    {columns.map(column => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, backgroundColor: palette.background.paper }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isFetching && entries?.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={columns.length + 1} align={"center"}>
                        No apps yet
                      </TableCell>
                    </TableRow>
                  )}
                  {entries?.map((row: any) => {
                    return (
                      <TableRow hover role='checkbox' tabIndex={-1} key={row?.id}>
                        {columns.map(column => {
                          let value = _.get(row, column.id);
                          return (
                            <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                              <Grid container alignItems={"center"}>
                                {value || "-"}
                              </Grid>
                            </TableCell>
                          );
                        })}
                        <TableCell align='left'>
                          <Box display='flex' gap={1.5}>
                            <Tooltip title='Migrate'>
                              <IconButton onClick={() => handleSingleMigrateClick(row)} disabled={loadingRowId === row.id}>
                                <IconGitCompare size={18} />
                              </IconButton>
                            </Tooltip>
                            <BXModal
                              maxWidth={"lg"}
                              icon={<IconHistory size={18} />}
                              buttonProps={{
                                startIcon: <IconHistory size={18} />,
                              }}
                              withoutLabel
                              title={`History -> ${row?.name}`}
                              label='History'
                            >
                              {(handleClose: any) => <AppHistory app={row} handleClose={handleClose} />}
                            </BXModal>
                            <Tooltip title='Backup' onClick={() => handleBackupClick(row)}>
                              <IconButton>
                                <IconArrowBackUp size={18} />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {isFetching && (
                    <TableRow ref={sentryRef}>
                      <TableCell colSpan={columns.length || 1}>Loading</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
