import {
  Box,
  Card,
  DialogActions,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { IconFileCode, IconFileExport, IconPencil, IconTrashX } from "@tabler/icons-react";
import React, { FC, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useInfiniteQuery, useMutation } from "react-query";
import { BXConfirmationDialog } from "src/components/BXUI/AlertDialog/ConfirmationDialog";
import BXModal from "src/components/BXUI/Modal";
import { queryClient } from "src/features/buildxProvider/buildxProviderUtils";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import axios from "src/utils/axios";
import { dereferenceOAS } from "src/utils/generalUtils";
import SwaggerUIModal from "../AppBuilder/components/SwaggerUIModal";
import { CreateOASForm } from "./CreateOASForm";

type ManageOASProps = {
  children?: React.ReactNode;
  swaggerProps?: any;
};

const columns: any[] = [
  { id: "id", label: "ID", minWidth: 270 },
  { id: "name", label: "Name", minWidth: 270 },
];
export const ManageOAS: FC<ManageOASProps> = ({ children, swaggerProps = {} }) => {
  const { palette } = useTheme();
  const [searchText, setSearchText] = useState("");

  let querykey = ["oas-list"];
  if (searchText.trim()) {
    querykey.push(searchText.trim());
  }
  const { data, hasNextPage, fetchNextPage, isFetching, isError } = useInfiniteQuery(
    querykey,
    ({ pageParam }) => {
      return axios.get(process.env.REACT_APP_HOST_API_KEY + "/api/admin/oas-def", {
        params: {
          cursor: pageParam,
          keyword: searchText || undefined,
        },
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      });
    },
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage: any) => (lastPage?.data?.hasMore ? lastPage?.data?.cursor : undefined),
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );

  const { mutate } = useMutation(
    (data: any) => {
      return axios.post(
        process.env.REACT_APP_HOST_API_KEY + "/api/admin/oas-def",
        {
          name: data.fileName,
          extension: data.extension,
        },
        { params: { oasDefKey: data.key }, headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` } }
      );
    },
    {
      onSuccess: data1 => {
        queryClient.setQueryData(querykey, ({ pages: [page1, ...rest] }) => {
          return {
            // @ts-ignore
            pages: [{ ...page1, data: { items: [data1.data, ...page1.data.items] } }, ...rest],
          };
        });
      },
      onError: (e: any) => {
        enqueueSnackbarRef?.(e.message || "Wrong Services", {
          variant: "error",
        });
      },
    }
  );

  const { mutate: editOAS } = useMutation(
    (editedData: any) => {
      return axios.put(
        process.env.REACT_APP_HOST_API_KEY + `/api/admin/oas-def/${editedData.fileId}`,
        {
          name: editedData.fileName,
          extension: editedData.extension,
        },
        {
          params: {
            oasDefKey: editedData.key,
          },
        }
      );
    },
    {
      onSuccess: (data1, editedData: any) => {
        queryClient.setQueryData(querykey, ({ pages: [page1, ...rest] }) => {
          return {
            // @ts-ignore
            pages: [
              {
                ...page1,
                data: { items: page1.data.items.map((item: any) => (item.id === data1.data.id ? { ...editedData, ...data1.data } : item)) },
              },
              ...rest,
            ],
          };
        });
      },
      onError: (e: any) => {
        enqueueSnackbarRef?.(e.message || "Wrong Services", {
          variant: "error",
        });
      },
    }
  );

  const { mutate: deleteOASFile } = useMutation(
    (itemToDelete: any) => {
      return axios.delete(process.env.REACT_APP_HOST_API_KEY + `/api/admin/oas-def/${itemToDelete?.id}`);
    },
    {
      onSuccess: (_, editedData: any) => {
        enqueueSnackbarRef?.("Deleted successfully", {
          variant: "success",
        });
        queryClient.setQueryData(["oas-list"], ({ pages: [page1, ...rest] }) => {
          return {
            // @ts-ignore
            pages: [{ ...page1, data: { items: page1.data.items.filter(item => item.id !== editedData?.id) } }, ...rest],
          };
        });
      },
      onError: (e: any) => {
        enqueueSnackbarRef?.(e.message || "Wrong Services", {
          variant: "error",
        });
      },
    }
  );

  const handleExportOAS = OASFileID => {
    axios
      .get(process.env.REACT_APP_HOST_API_KEY + `/api/admin/oas-def/${OASFileID}/url`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("accessToken")}` },
      })
      .then(res => {
        axios.get(res?.data?.url).then(res => {
          const OASData = dereferenceOAS(res?.data);
          const filename = OASData.info.title;
          const jsonStr = JSON.stringify(OASData, null, 2);
          const blob = new Blob([jsonStr], { type: "application/json" });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.download = filename;
          link.href = url;
          link.click();
        });
      });
  };

  const onSubmit = (values: any) => {
    return mutate(values, {});
  };

  const [sentryRef] = useInfiniteScroll({
    loading: isFetching,
    hasNextPage: hasNextPage || false,
    onLoadMore: () => fetchNextPage(),
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: isError,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 400px 0px",
  });

  return (
    <>
      <Grid container style={{ height: "100%" }}>
        <Grid style={{ height: "100%", padding: 10, paddingTop: 0 }} item md={12}>
          <Grid container style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 2 }}>
            <Grid item xs={12}></Grid>
          </Grid>

          <Grid container marginBottom={1}>
            <Grid container flex={1} spacing={2} alignItems={"center"}>
              <Grid item>
                <Typography fontSize={"20px"} color='textPrimary' fontWeight={600}>
                  Manage OAS
                </Typography>
              </Grid>
            </Grid>

            <DialogActions>
              <BXModal
                title={"Add OAS"}
                icon={<IconFileCode />}
                label={"Add OAS"}
                buttonProps={{
                  startIcon: <IconFileCode />,
                  color: "secondary",
                  variant: "contained",
                  style: { backgroundColor: palette.primary.main, borderRadius: 24 },
                  size: "small",
                }}
              >
                {(handleClose: Function) => {
                  return (
                    <CreateOASForm
                      onSave={(formData, event) => {
                        const newData = {
                          fileName: formData?.fileName,
                          key: formData?.data?.key,
                          extension: formData?.data?.extension,
                        };

                        onSubmit(newData);
                        handleClose?.();
                      }}
                    />
                  );
                }}
              </BXModal>
            </DialogActions>
          </Grid>
          <Card style={{ padding: 24 }}>
            <Grid xs={12} item container spacing={2}>
              <Grid item spacing={2} xs={12} md={4} alignItems='center'>
                <TextField
                  size='small'
                  fullWidth
                  label={"Search"}
                  onChange={e => {
                    setSearchText(e.target.value.length > 2 ? e.target.value : "");
                  }}
                />
              </Grid>
            </Grid>
            <TableContainer style={{ backgroundColor: palette.background.paper }}>
              <Table stickyHeader aria-label='sticky table'>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ backgroundColor: palette.background.paper }}>Actions</TableCell>

                    {columns.map(column => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, backgroundColor: palette.background.paper }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isFetching && data?.pages?.[0]?.data.items?.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={columns.length + 1} align={"center"}>
                        No OAS yet
                      </TableCell>
                    </TableRow>
                  )}
                  {data?.pages?.[0]?.data.items?.map((row: any) => {
                    return (
                      <TableRow hover role='checkbox' tabIndex={-1} key={row.code}>
                        <TableCell>
                          <DialogActions sx={{ padding: 0, display: "flex", justifyContent: "flex-start" }}>
                            <SwaggerUIModal swaggerId={row.id} isJustView {...swaggerProps} />

                            <IconButton title='Export OAS Document' onClick={() => handleExportOAS(row.id)}>
                              <IconFileExport height={20} width={20} style={{ padding: 0 }} />
                            </IconButton>

                            <BXModal
                              title={"Edit OAS File"}
                              icon={<IconPencil color={palette.primary.main} height={26} width={26} style={{ padding: 4 }} />}
                              withoutLabel
                              buttonProps={{
                                startIcon: <IconFileCode />,
                                color: "primary",
                                size: "small",
                                variant: "contained",
                              }}
                            >
                              {(handleClose: Function) => {
                                return (
                                  <CreateOASForm
                                    row={row}
                                    onSave={formData => {
                                      const newData = {
                                        fileName: formData?.fileName,
                                        key: formData?.data?.key,
                                        extension: formData?.data?.extension,
                                      };
                                      handleClose?.(true);
                                      editOAS({ ...newData, fileId: row.id });
                                    }}
                                  />
                                );
                              }}
                            </BXModal>
                            <Box marginInlineStart={1}>
                              <BXConfirmationDialog
                                title={"Are you sure you want to delete this OAS?"}
                                iconButton
                                buttonProps={{
                                  color: "error",
                                  children: <IconTrashX height={20} width={20} style={{ padding: 0 }} />,
                                }}
                                onConfirm={() => {
                                  deleteOASFile(row);
                                }}
                              />
                            </Box>
                          </DialogActions>
                        </TableCell>

                        {columns.map(column => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id}>
                              <Grid container alignItems={"center"}>
                                {value}
                              </Grid>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                  {(isFetching || hasNextPage) && (
                    <TableRow ref={sentryRef}>
                      <TableCell colSpan={columns.length || 1}>Loading</TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};
