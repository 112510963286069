import axios from "axios";
import { enqueueSnackbarRef } from "./SnackbarUtilsConfigurator";

const axiosServices = axios.create({
  baseURL: process.env.REACT_APP_HOST_API_KEY_API,
  timeout: 100000,
});

axiosServices.defaults.headers.common.Authorization = "Bearer " + localStorage.getItem("accessToken");
axiosServices.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

// // interceptor for http - ToDo
// axios.interceptors.request.use(
//     (request) => {
//         // Edit request config
//         return request;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// );

axiosServices.interceptors.response.use(
  response => response,
  error => {
    if (
      (error?.response?.status == 401 || error?.response?.status == 403) &&
      !window.location.host.endsWith(process.env.REACT_APP_ADMIN_HOST as string)
    ) {
      if (!error.response.config?.params?.skipAuthErrorClear) {
        localStorage.clear();
        window.location.href = window.location.origin;
      }
    }
    if (!error?.response.config?.params?.skipError) {
      enqueueSnackbarRef?.(
        error?.response?.data?.error ||
          JSON.parse(error.response.config.data || "{}")?.errorMessage ||
          error?.response?.data?.errorMessage ||
          "Wrong Services",
        {
          variant: "error",
        }
      );
    }

    return Promise.reject(
      (error?.response && error?.response?.data && { requestStatusCode: error?.request?.status, ...error.response.data }) ||
        "Wrong Services"
    );
  }
);

export default axiosServices;
