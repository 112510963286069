import {
  Box,
  Button,
  Card,
  CircularProgress,
  DialogActions,
  Grid,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useTheme,
} from "@mui/material";
import { IconFileAlert, IconPlus, IconTrashX } from "@tabler/icons-react";
import _ from "lodash";
import { FC, useEffect, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { BXConfirmationDialog } from "src/components/BXUI/AlertDialog/ConfirmationDialog";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import axios from "src/utils/axios";

const columns: any[] = [
  { id: "id", label: "ID", minWidth: 270 },
  { id: "url", label: "Asset", minWidth: 150 },
  { id: "name", label: "Name", minWidth: 220 },
  { id: "type", label: "Type", minWidth: 100 },
  { id: "visibility", label: "Visibility", minWidth: 100 },
];

type MediaTableProps = {
  isImport: boolean;
  addNewMedia?: boolean;
  setOpenMediaList?: (openMediaList: boolean) => void;
  setSelectedRow?: (selectedRow: object) => void;
  setOpenReplaceModal?: (openReplaceModal: boolean) => void;
  addMedia?: (template: any, replaceAll: boolean) => void;
  isEmptyView?: boolean;
  mediaId?: string;
};

const MediaTable: FC<MediaTableProps> = ({
  isImport,
  addNewMedia,
  setOpenMediaList,
  setSelectedRow,
  setOpenReplaceModal,
  addMedia,
  isEmptyView,
  mediaId,
}) => {
  const [searchText, setSearchText] = useState("");
  const [typeFilter, setTypeFilter] = useState("Any");
  const [visibilityFilter, setVisibilityFilter] = useState("Any");
  const { palette } = useTheme();
  const accessToken = localStorage.getItem("accessToken");
  const deviceToken = localStorage.getItem("accessToken-device");
  const token = accessToken || deviceToken;

  let queryKey = ["media-list", visibilityFilter.trim(), typeFilter.trim()];

  if (searchText.trim()) {
    queryKey.push(searchText.trim());
  }

  const { data, hasNextPage, fetchNextPage, refetch, isFetching, isError } = useInfiniteQuery(
    queryKey,
    ({ pageParam, queryKey }) => {
      if (queryKey[1] && queryKey[1].length < 3) {
        return {
          data: {
            items: [],
          },
        } as any;
      }
      return axios.get(process.env.REACT_APP_HOST_API_KEY + "/api/media", {
        params: {
          cursor: pageParam,
          keyword: searchText || undefined,
          visibility: visibilityFilter === "Any" ? undefined : visibilityFilter.toLocaleUpperCase(),
          type: typeFilter === "Any" ? undefined : typeFilter.toLocaleUpperCase(),
          strategy: "detailed",
        },
      });
    },
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage: any) => (lastPage?.data?.hasMore ? lastPage?.data?.cursor : undefined),
      refetchOnWindowFocus: false,
    }
  );

  let queryKeyMediaTypes = ["media-types"];

  const { data: mediaTypes, refetch: refetchMediaTypes } = useQuery(
    queryKeyMediaTypes,
    ({ pageParam }) => {
      return axios.get(process.env.REACT_APP_HOST_API_KEY + `/api/admin/media/types`, {
        params: {
          cursor: pageParam,
          limit: 20,
        },
        headers: { Authorization: `Bearer ${token}` },
      });
    },
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage: any) => (lastPage?.data?.hasMore ? lastPage?.data?.cursor : undefined),
      refetchOnWindowFocus: false,
    }
  );
  const typeOptions = ["Any", ...(mediaTypes?.data?.items || [])];

  useEffect(() => {
    refetch();
    refetchMediaTypes();
  }, [addNewMedia]);

  const { mutate: deleteMedia } = useMutation(
    (itemToDelete: any) => {
      return axios.delete(process.env.REACT_APP_HOST_API_KEY + `/api/admin/media/${itemToDelete?.id}`);
    },
    {
      onSuccess: (_, editedData: any) => {
        refetch();
        refetchMediaTypes();
        enqueueSnackbarRef?.("Deleted successfully", {
          variant: "success",
        });
      },
    }
  );

  const [sentryRef] = useInfiniteScroll({
    loading: isFetching,
    hasNextPage: hasNextPage || false,
    onLoadMore: () => fetchNextPage(),
    disabled: isError,
    rootMargin: "0px 0px 400px 0px",
  });

  const entities = _.flatten(data?.pages?.map((p: any) => _.get(p.data, "items")));

  return (
    <Card style={{ padding: 24 }}>
      <Grid xs={12} item container spacing={2}>
        <Grid item spacing={2} xs={8} md={4} alignItems='center'>
          <TextField
            size='small'
            fullWidth
            label={"Search"}
            onChange={e => {
              setSearchText(e.target.value);
            }}
          />
        </Grid>
        <Grid item direction='column' justifyContent='center' alignItems='center' xs={2}>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              select
              label={"Type"}
              fullWidth
              size='small'
              defaultValue={"Any"}
              onChange={e => setTypeFilter(e.target.value)}
            >
              {typeOptions.map(item => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Grid item direction='column' justifyContent='center' alignItems='center' xs={2}>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              select
              label={"Visibility"}
              fullWidth
              size='small'
              defaultValue={"Any"}
              onChange={e => setVisibilityFilter(e.target.value)}
            >
              {["Any", "Private", "Public"]?.map(item => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </Grid>
      <TableContainer style={{ backgroundColor: palette.background.paper }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {!isImport && <TableCell style={{ backgroundColor: palette.background.paper }}>Actions</TableCell>}
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, backgroundColor: palette.background.paper }}
                >
                  {column.label}
                </TableCell>
              ))}
              {isImport && <TableCell style={{ backgroundColor: palette.background.paper }} />}
            </TableRow>
          </TableHead>
          <TableBody>
            {!isFetching && entities?.length === 0 && (
              <TableRow>
                <TableCell colSpan={columns.length + 1} align={"center"} width={"100%"}>
                  No Media yet
                </TableCell>
              </TableRow>
            )}
            {entities?.map((row: any) => {
              if (row.id === mediaId && isImport) return <></>;
              return (
                <TableRow hover role='checkbox' tabIndex={-1} key={row.code}>
                  {!isImport && (
                    <TableCell sx={{ width: 130, display: "flex", justifyContent: "flex-start" }}>
                      <DialogActions sx={{ padding: 0 }}>
                        <Box>
                          <BXConfirmationDialog
                            title={"Are you sure you want to delete this media?"}
                            iconButton
                            buttonProps={{
                              color: "error",
                              children: <IconTrashX height={20} width={20} style={{ padding: 0 }} />,
                            }}
                            onConfirm={() => {
                              deleteMedia(row);
                            }}
                          />
                        </Box>
                      </DialogActions>
                    </TableCell>
                  )}
                  {columns.map(column => {
                    const value = _.get(row, column.id);
                    return (
                      <TableCell key={column.id}>
                        <Grid container alignItems={"center"}>
                          {column.id === "url" ? (
                            value.toLowerCase().endsWith(".mp4") || value.toLowerCase().endsWith(".mov") ? (
                              <a href={value} target='_blank' rel='noopener noreferrer'>
                                <video style={{ width: 32, height: 32, borderRadius: 4, cursor: "pointer" }}>
                                  <source src={value} type='video/mp4' />
                                  <source src={value} type='video/quicktime' />
                                  Your browser does not support the video tag.
                                </video>
                              </a>
                            ) : ["jpeg", "jpg", "png", "webp", "gif", "svg"].some(ext => value.toLowerCase().endsWith(`.${ext}`)) ? (
                              <a href={value} target='_blank' rel='noopener noreferrer'>
                                <img src={value} alt='Image' style={{ width: 32, height: 32, borderRadius: 4, cursor: "pointer" }} />
                              </a>
                            ) : (
                              <a href={value} target='_blank' rel='noopener noreferrer'>
                                <IconFileAlert color='grey' width={32} height={32} style={{ cursor: "pointer" }} />
                              </a>
                            )
                          ) : (
                            value
                          )}
                        </Grid>
                      </TableCell>
                    );
                  })}

                  {isImport && (
                    <TableCell>
                      <Button
                        variant='contained'
                        startIcon={<IconPlus />}
                        onClick={() => {
                          if (setOpenMediaList && setSelectedRow && setOpenReplaceModal && addMedia) {
                            setOpenMediaList(false);
                            setSelectedRow(row);
                            if (!isEmptyView) setOpenReplaceModal(true);
                            else addMedia(row, true);
                          }
                        }}
                      >
                        Add
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
            {isFetching || hasNextPage ? (
              <TableRow ref={sentryRef} sx={{ width: "100%", justifyContent: "center" }}>
                <TableCell colSpan={columns.length + 1 || 1} style={{ textAlign: "center" }}>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};

export default MediaTable;
