import { yupResolver } from "@hookform/resolvers/yup";
import MonacoEditor from "@monaco-editor/react";
import { Box, Button, DialogActions, Divider, Grid, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import _ from "lodash";
import { FC } from "react";
import { Controller, FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { ApiInput } from "src/components/ApiInput";
import { formatJSON } from "src/components/BXUI/DataTable/ActionButton";
import { BXInput } from "src/components/BXUI/FormControls";
import * as yup from "yup";
import OASSelector from "../OASSelector";
import { useSelector } from "react-redux";
import { selectFullComponentItemById } from "src/features/builder/selectors";

type SuggestedValuesFormProps = {
  onSave: SubmitHandler<FieldValues>;
  row?: any;
  componentId: string;
};

export const SuggestedValuesForm: FC<SuggestedValuesFormProps> = ({ onSave = _.noop, componentId }) => {
  const item = useSelector(selectFullComponentItemById(componentId));

  const row = { name: item?.name, config: item?.config?.suggestionEndpoint };
  const schema = yup
    .object({
      config: yup.object().shape({
        suggestionEndpoint: yup.string().when("suggestionSourceType", {
          is: "API",
          then: yup.string(),
        }),
      }),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    getValues,
    setValue,
  } = useForm<FieldValues>({
    defaultValues: {
      ...row,
      config: {
        ...row?.config,
        suggestionSourceType: row?.config?.suggestionSourceType || "SIMPLE",
      },
    },
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const handleSave = (formData: any) => {
    const payload = {
      ...formData,
    };
    if (formData?.config?.suggestedValues && typeof formData?.config?.suggestedValues == "string") {
      payload.config.suggestedValues = JSON.parse(formData?.config?.suggestedValues || "{}");
    }
    onSave(formData);
  };

  return (
    <Box component='form' noValidate autoComplete='off' flex={1}>
      <Grid container spacing={3} px={2} py={1}>
        <Grid item xs={12}>
          <Divider />
          <Typography fontWeight={"400"} mt={1}>
            Suggested values
          </Typography>
        </Grid>

        <Grid item xs={12} mb={2}>
          {["API", "SIMPLE"].map((type: any) => (
            <Controller
              key={type}
              control={control}
              name={`config.suggestionSourceType`}
              render={({ field: { onChange, value } }) => (
                <ToggleButtonGroup color='primary' value={value} exclusive>
                  <ToggleButton
                    style={{ marginInlineEnd: 12 }}
                    value={value}
                    onClick={() => {
                      onChange(type);
                    }}
                    selected={value === type}
                    key={type}
                  >
                    {type}
                  </ToggleButton>
                </ToggleButtonGroup>
              )}
            />
          ))}
        </Grid>

        {watch("config.suggestionSourceType") === "API" && (
          <>
            <Grid item xs={6}>
              <ApiInput
                watch={watch}
                apiLabel='Endpoint'
                control={control}
                path='config.source.payload'
                pathURL='config.source.apiUrl'
                OASElement={
                  <OASSelector
                    swaggerProps={{
                      template: watch(".template"),
                      formBuilder: watch("source.formBuilder"),
                      onSuccess: (values: any, data: any) => {
                        setValue("config.source.template", values.template);
                        setValue("config.source.url", data?.path);
                        setValue("config.source.apiUrl", data?.path);
                        setValue("config.source.payload.uri", data?.path);
                        setValue("config.source.payload.method", (data?.method as string).toUpperCase());
                        setValue("config.source.payload.body", formatJSON(JSON.stringify(data?.body)) || {});
                        setValue("config.source.dataEntry", values.dataEntry);
                      },
                    }}
                  />
                }
                getValues={getValues}
                setValue={setValue}
              />
            </Grid>
            <Grid item xs={6}>
              <BXInput
                name={"config.suggestionDataEntry"}
                control={control}
                label='Data Entry'
                variant='outlined'
                error={(errors as any)?.config?.suggestionDataEntry}
              />
            </Grid>
            <Grid item xs={6}>
              <BXInput
                name={"config.debounce"}
                control={control}
                label='Time debounce'
                variant='outlined'
                error={(errors as any)?.config?.debounce}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          {watch("config.suggestionSourceType") === "SIMPLE" && (
            <Controller
              control={control}
              name={"config.suggestedValues"}
              render={({ field: { onChange, value } }) => (
                <>
                  <MonacoEditor
                    height='150px'
                    language='json'
                    theme='vs-dark'
                    value={!_.isString(value) ? JSON.stringify(value) : value}
                    options={{ colorDecorators: true }}
                    onChange={(newValue = "") => {
                      try {
                        onChange(newValue);
                        JSON.parse(newValue);
                      } catch (e: any) {
                        // handle error here if necessary
                      }
                    }}
                  />
                  <Grid item xs={6}>
                    <BXInput
                      name={"config.suggestionDataEntryList"}
                      control={control}
                      label='Data Entry'
                      variant='outlined'
                      error={(errors as any)?.config?.suggestionDataEntryList}
                    />
                  </Grid>
                </>
              )}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <DialogActions style={{ padding: 0, marginTop: 16, justifyContent: "center" }}>
            <Button onClick={handleSubmit(handleSave)} variant={"contained"} aria-label={"save"}>
              Save
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Box>
  );
};
