import { Tab, Tabs } from "@mui/material";
import { FC, useEffect, useState } from "react";

export const CustomTabs: FC<any> = componentProps => {
  const { __config, onChange, ...props } = componentProps;
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const getSelectedTabLabel = tabIndex => {
    return props?.tabs?.[tabIndex]?.label;
  };

  const setSelectedTab = tab => {
    setSelectedTabIndex(() => {
      onChange?.(getSelectedTabLabel(tab));
      return tab;
    });
  };

  const updateComponentRecursively = (components, parentId, updateFunction) => {
    return components?.map(el => {
      if (el.id === parentId) {
        return updateFunction(el);
      } else if (el.children) {
        return {
          ...el,
          children: updateComponentRecursively(el.children, parentId, updateFunction),
        };
      }
      return el;
    });
  };

  const handleChange = (event: React.SyntheticEvent, newSelectedTabIndex: number) => {
    setSelectedTab(newSelectedTabIndex);
    props?.metaData?.setFormBuilderState &&
      props.metaData?.setFormBuilderState((prev: any) =>
        updateComponentRecursively(prev, __config?.parent?.id, (el: any) =>
          el.id === __config?.parent?.id
            ? {
                ...el,
                children: [
                  { ...el.children[0], props: { ...el.children[0].props, value: newSelectedTabIndex } },
                  ...(el.children.slice(1) || []).map((el: any) =>
                    el.config.tabValue === newSelectedTabIndex
                      ? {
                          ...el,
                          config: {
                            ...el.config,
                            defaultWidth: "100%",
                            defaultHeight: "100%",
                            hideElement: false,
                          },
                          props: { ...el.props, sx: { ...el.props.sx, display: "unset" } },
                        }
                      : {
                          ...el,
                          config: {
                            ...el.config,
                            defaultWidth: "0%",
                            defaultHeight: "0%",
                            hideElement: true,
                          },
                          props: { ...el.props, sx: { ...el.props.sx, display: "none" } },
                        }
                  ),
                ],
              }
            : el
        )
      );
  };

  useEffect(() => {
    setSelectedTab(props?.value);
  }, [props.value]);

  return (
    <Tabs {...props} onChange={handleChange} value={selectedTabIndex}>
      {props?.tabs?.map((tab, index) => (
        <Tab label={tab.label} key={index} />
      ))}
    </Tabs>
  );
};
