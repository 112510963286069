import _ from "lodash";

function parsePath(path: string): (string | number)[] {
  const regex = /([^[.\]]+)|\[(\d+)\]/g; // Match keys or indices
  const matches: (string | number)[] = [];

  let match;
  while ((match = regex.exec(path)) !== null) {
    if (match[1]) {
      // Dot-separated key
      matches.push(match[1]);
    } else if (match[2]) {
      // Numeric index in square brackets
      matches.push(Number(match[2]));
    }
  }

  return matches;
}

export function setNestedValue(object: any, path: string | null, value: any, override = false) {
  if (path === null) return;
  const keys = parsePath(path);
  let current = object;

  keys.slice(0, -1)?.forEach((key, index) => {
    const nextKey = keys[index + 1];
    if (typeof nextKey === "number") {
      // Next key is a number, so current key should be an array
      if (!Array.isArray(current[key])) {
        current[key] = [];
      }
    } else {
      // Next key is not a number, current key should be an object
      if (!current[key] || typeof current[key] !== "object" || Array.isArray(current[key])) {
        // Check if current[key] is an array and needs to be converted to an object
        if (Array.isArray(current[key])) {
          current[key] = [...current[key]]; // Convert array to object
        } else {
          current[key] = {};
        }
        if (!current[key] || typeof current[key] !== "object" || Array.isArray(current[key])) {
          // Check if current[key] is an array and needs to be converted to an object
          if (Array.isArray(current[key])) {
            current[key] = [...current[key]]; // Convert array to object
          } else {
            current[key] = {};
          }
        }
      }
    }
    current = current[key];
  });

  if (override && _.isObject(current[keys[keys.length - 1]])) {
    return;
  }

  const finalKey = keys[keys.length - 1];
  if (Object.isFrozen(current)) {
    current = { ...current };
  }
  current[finalKey] = value;
}

export const selectAppSlice = state => state?.application;

export const selectPageSlice = (state, pageId) => state?.application?.pages?.[pageId];

export const selectViewSlice = (state, pageId, viewName) => state?.application?.pages?.[pageId]?.views?.[viewName];

export function getNextSegment(inputString, currentSegment) {
  if (!inputString) {
    return null;
  }
  let name = inputString;
  name = name.replace("undefined.undefined", "application");
  //TODO: Look into this case for pages and stateless components like views of page builders
  name = name.replace("undefined", "");

  const segments = name?.split(".");
  const index = segments.indexOf(currentSegment);
  return index !== -1 && index < segments.length - 1 ? segments[index + 1] : null;
}

export const getTargetState = (level, state, pageId, viewName, name = "", viewIsExist = false) => {
  const viewFromName: any = name?.split(".")[1];
  if (level === "application") {
    return selectAppSlice(state);
  }

  if (level === "pages") {
    return selectPageSlice(state, pageId);
  }

  if (level === "views") {
    const view = !name ? viewName : viewFromName?.includes(viewName) ? viewName : viewFromName;
    return selectViewSlice(state, pageId, view);
  }
};

export function getStringAfterNextKey(name, nextKey) {
  if (!nextKey) {
    return "";
  }

  const nextKeyIndex = name.indexOf("." + nextKey);

  if (nextKeyIndex === -1) {
    return "";
  }

  const substringAfterNextKey = name.slice(nextKeyIndex + nextKey.length + 1);

  if (substringAfterNextKey.startsWith(".")) {
    return substringAfterNextKey.slice(1);
  }

  return substringAfterNextKey;
}

export function safeConcatPath(...segments) {
  const validSegments = segments.filter(segment => segment !== undefined && segment !== null);
  const path = validSegments.join(".");
  return path.endsWith(".") ? path.slice(0, -1) : path;
}

// Utility function to determine level and targetKey
export const determineLevelAndTargetKey = (name, pathVariables, viewIsExist?: any) => {
  const { pageId, viewName } = pathVariables || {};
  const viewFromName: any = name?.split(".")[1];

  let level = "application"; //Determine the slice state
  let targetKey = "application"; //Identifier inside the slice

  if (pageId || viewName) {
    level = viewFromName?.includes(viewName) || !!viewIsExist ? "views" : name?.includes(pageId) ? "pages" : "application";
  }

  switch (level) {
    case "views":
      targetKey = viewFromName?.includes(viewName) ? viewName : viewFromName;
      break;
    case "pages":
      targetKey = pageId;
      break;
    case "application":
    default:
      targetKey = "application";
      break;
  }

  return { level, targetKey };
};

export function populateVars(targetState, vars) {
  if (!vars) {
    return;
  }

  vars?.forEach(varItem => {
    if (varItem?.varKey?.trim() !== "") {
      const currentValue = targetState.vars?.[varItem?.varKey]?.value;

      if (currentValue) {
        return;
      }

      //If vars are empty, initialize them.
      if (!targetState?.vars) {
        targetState.vars = {};
      }

      targetState.vars[varItem.varKey] = varItem?.value;
    }
  });
}

export function populateInitialState(targetState, vars) {
  targetState.dirty = { isDirty: false, fields: [] as string[] };
  targetState.errors = { hasError: false, list: [], error: "" };
  populateVars(targetState, vars);
}

export function traverseOnParents(state: any, startKey: string, callback: Function) {
  const visitedNodes = new Set();

  function traverse(nodeKey: string) {
    if (visitedNodes.has(nodeKey)) {
      return;
    }
    visitedNodes.add(nodeKey);

    const node = state.entities[nodeKey];
    if (!node) return;

    const shouldContinue = callback(node, nodeKey, state);
    if (shouldContinue === false) {
      return;
    }

    if (node.parentKey) {
      traverse(node.parentKey);
    }
  }

  traverse(startKey);
}

export function traverseOnChildren(
  state: any,
  startKey: string,
  callback?: (node: any, nodeKey: string, state: any, nodePath?: string) => boolean | void,
  postCallBack?: (node: any, nodeKey: string, state: any, prevValue: any) => void,
  basePath?: string
) {
  const visitedNodes = new Set();

  function traverse(nodeKey: string) {
    if (visitedNodes.has(nodeKey)) {
      return;
    }
    visitedNodes.add(nodeKey);

    const node = state.entities[nodeKey];
    if (!node) return;

    const nodePath = `${basePath}.${nodeKey}`;
    const shouldContinue = callback?.(node, nodeKey, state, nodePath);
    if (shouldContinue === false) {
      return;
    }

    let prevValue: any[] = [];

    //Handle pages of stepper or traverse the children directly
    if (node.state?.pages) {
      const worksWith = node.state?.worksWith;
      if (worksWith === "customPages") {
        for (let page of node.state.pages) {
          let pageRef = page.pageReference;
          if (page.customPageSelected) {
            prevValue.push(traverse(pageRef));
          }
        }
      } else if (worksWith === "currentPage") {
        const group = node.state;
        const selectedPage = group?.selectedPage;
        const pages = node.state.pages;
        let currentPageIndex = _.toNumber(selectedPage || 0);
        if (_.isNaN(currentPageIndex)) {
          currentPageIndex = _.findIndex(group.pages, (page: any) => page.pageReference === group.selectedPage);
        }
        currentPageIndex = _.clamp(currentPageIndex, 0, pages?.length - 1);
        for (let i = 0; i < pages.length; i++) {
          const page = pages[i];
          if (i === currentPageIndex) {
            const key = page.pageReference;
            prevValue.push(traverse(key));
          }
        }
      } else if (worksWith === "allPages") {
        for (let page of node.state.pages) {
          let pageRef = page.pageReference;
          prevValue.push(traverse(pageRef));
        }
      }
    } else if (node.children?.length > 0) {
      for (let childId of node.children) {
        prevValue.push(traverse(childId));
      }
    }

    return postCallBack?.(node, nodeKey, state, prevValue);
  }

  traverse(startKey);
}

export const getReferenceLevel = (reference: string): "page" | "view" | "component" => {
  if (reference.startsWith("{this")) {
    return reference === "{this}" ? "view" : "component";
  } else if (reference === "{$page}") {
    return "page";
  } else {
    return reference.includes(".") ? "component" : "view";
  }
};

export const isComponentRepeated = component => {
  return component?.isRepeated;
};

export const isComponentInsideTargetStepper = component => {
  //TODO: Implement this function for stepper validation, dirty, etc...
};
