import { CardMedia, Checkbox, Switch } from "@mui/material";
import { IconVideo } from "@tabler/icons-react";
import { FC, memo } from "react";
import { BXEngine } from "src/BXEngine";
import { BXIcon } from "../Icon";

type Props = {
  col: any;
  value: any;
  __data: any;
  item: any;
  index: any;
  pageId: any;
  views: any;
  path?: string;
  dataEntry?: any;
  viewName?: string;
};

const CellValue: FC<Props> = ({ col, value, __data, item, index, pageId, views, path, dataEntry, viewName }) => {
  const type = col?.type;
  const builderView = views?.find((view: any) => view?.id === col?.viewBuilderId);

  const handleClick = (url: string) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const renderNumber = () => {
    let numberValue = parseFloat(value);

    if (isNaN(numberValue)) {
      return <span>N/A</span>;
    }

    if (col?.round !== undefined && col.round !== "") {
      const roundingFactor = parseInt(col.round, 10);
      if (!isNaN(roundingFactor)) {
        const factor = Math.pow(10, roundingFactor);
        numberValue = Math.round(numberValue * factor) / factor;
      }
    }

    if (col?.minValue !== undefined && col.minValue !== "" && numberValue < parseFloat(col.minValue)) {
      numberValue = parseFloat(col.minValue);
    }
    if (col?.maxValue !== undefined && col.maxValue !== "" && numberValue > parseFloat(col.maxValue)) {
      numberValue = parseFloat(col.maxValue);
    }

    return <span>{numberValue}</span>;
  };

  const renderBoolean = () =>
    col?.dataAppearance === "Switch" ? <Switch checked={value === "true"} disabled /> : <Checkbox checked={value === "true"} disabled />;

  const renderImage = () =>
    value?.split(".")?.pop() === "mp4" ? (
      <IconVideo size={32} />
    ) : (
      <img src={value} alt='img' style={{ width: 32, height: 32, borderRadius: 4, cursor: "pointer" }} onClick={() => handleClick(value)} />
    );

  const renderMedia = () =>
    type === "Video" && col?.allowHyperlink ? (
      <BXIcon icon={col?.videoHyperlinkIcon || value} onClick={() => handleClick(value)} />
    ) : (
      <CardMedia sx={{ border: 0, maxHeight: 320, width: "100%" }} component={type === "Video" ? "video" : "audio"} src={value} controls />
    );

  const renderViewBuilder = () => {
    return (
      <BXEngine
        auth={{}}
        path={[path, builderView?.name].join(".")}
        pageId={pageId}
        page={{ views, layout: builderView, id: pageId } as any}
        layout={[{ ...(builderView as any), type: builderView?.type }]}
        data={item}
        __data={__data}
        insideCell
        index={index}
        dataEntry={dataEntry}
        viewName={viewName}
      />
    );
  };

  const renderDefault = () => value;

  const renderContent = () => {
    switch (type) {
      case "Boolean":
        return renderBoolean();
      case "Image":
        return renderImage();
      case "Video":
      case "Audio":
        return renderMedia();
      case "View Builder":
        return renderViewBuilder();
      case "Number":
        return renderNumber();
      case "Date Time":
      default:
        return renderDefault();
    }
  };

  return renderContent();
};

export default memo(CellValue);
