import { useState } from "react";
import { setKey } from "react-geocode";
import Autocomplete from "react-google-autocomplete";

export const GoogleMapAutocomplete = ({ onPlaceSelected, onChange, googlePlacesApiKey }) => {
  const [address, setAddress] = useState("");
  const localGooglePlacesApiKey = googlePlacesApiKey;

  setKey(localGooglePlacesApiKey);

  const handlePlaceSelected = place => {
    if (onPlaceSelected) {
      onPlaceSelected(place);
    }

    if (onChange) {
      onChange(place);
    }

    if (place.formatted_address) {
      setAddress(place.formatted_address);
    }
  };

  return (
    <Autocomplete
      style={{
        width: "100%",
        height: "40px",
        paddingLeft: "16px",
        marginTop: "2px",
        marginBottom: "100px",
      }}
      onPlaceSelected={handlePlaceSelected}
      options={{ types: [] }}
      value={address}
      onChange={(e: any) => setAddress(e.target.value)}
    />
  );
};

export default GoogleMapAutocomplete;
