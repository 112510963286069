import { Button, FormControlLabel, Grid, Switch } from "@mui/material";
import _ from "lodash";
import { FC, useState } from "react";
import { changeIdsOfObject } from "../index";

type CreateAppFormProps = {
  onCancel: Function;
  app?: any;
  selectedApp: any;
  onSave: any;
};

export const SyncApp: FC<CreateAppFormProps> = ({ onCancel = _.noop, app = undefined, selectedApp, onSave }) => {
  const [overrideTheme, setOverrideTheme] = useState(true);
  const [overrideSnackBar, setOverrideSnackBar] = useState(true);
  const [overrideScript, setOverrideScript] = useState(false);
  const [overrideAuth, setOverrideAuth] = useState(false);
  const [overrideLoginCustomizations, setOverrideLoginCustomizations] = useState(false);

  const syncApp = app => {
    const updatedApp = {
      ...selectedApp,
      templateConfig: changeIdsOfObject(_.cloneDeep(app.templateConfig)),
    };

    if (overrideTheme) {
      updatedApp.appConfig.theme = app.appConfig.theme;
    }
    if (overrideSnackBar) {
      updatedApp.appConfig.snackbar = app.appConfig.snackbar;
    }
    if (overrideScript) {
      updatedApp.appConfig.script = app.appConfig.script;
    }
    if (overrideAuth) {
      updatedApp.appConfig.auth = app.appConfig.auth;
    }
    if (overrideLoginCustomizations) {
      updatedApp.appConfig.loginCustomizations = app.appConfig.loginCustomizations;
    }
    onSave(updatedApp);
    onCancel();
  };

  return (
    <Grid container>
      <Grid item xs={12} marginInlineStart={2}>
        <FormControlLabel
          control={<Switch checked={overrideTheme} onChange={() => setOverrideTheme(prev => !prev)} />}
          label='Override Theme'
        />
      </Grid>
      <Grid item xs={12} marginInlineStart={2}>
        <FormControlLabel
          control={<Switch checked={overrideSnackBar} onChange={() => setOverrideSnackBar(prev => !prev)} />}
          label='Override SnackBar'
        />
      </Grid>
      <Grid item xs={12} marginInlineStart={2}>
        <FormControlLabel
          control={<Switch checked={overrideScript} onChange={() => setOverrideScript(prev => !prev)} />}
          label='Override Script/Noscript'
        />
      </Grid>
      <Grid item xs={12} marginInlineStart={2}>
        <FormControlLabel
          control={
            <Switch
              checked={overrideAuth}
              onChange={() => {
                setOverrideAuth(prev => !prev);
                if (!overrideAuth) {
                  setOverrideLoginCustomizations(false);
                }
              }}
            />
          }
          label='Override Auth'
        />
      </Grid>
      {overrideAuth && (
        <Grid item xs={12} marginInlineStart={4}>
          <FormControlLabel
            control={<Switch checked={overrideLoginCustomizations} onChange={() => setOverrideLoginCustomizations(prev => !prev)} />}
            label='Override Login Customizations'
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Grid container justifyContent='center'>
          <Button
            onClick={async () => {
              syncApp(app);
            }}
            variant='contained'
            aria-label={"save"}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
