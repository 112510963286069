import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from "@apollo/client";
import { isArray } from "lodash";
import { QueryClient } from "react-query";
import { BXApp } from "src/types/BXAppType";

const dynamicUriLink = new ApolloLink((operation, forward) => {
  const context = operation.getContext();
  const { uri, apolloHeaders } = context;

  const httpLink = new HttpLink({
    uri: uri || "",
  });

  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...apolloHeaders,
    },
  }));

  return httpLink.request(operation, forward);
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const apolloClient = new ApolloClient({
  link: dynamicUriLink,
  cache: new InMemoryCache(),
});

export const encodeURLParams = (url: string) => {
  const [path, queryString] = url?.split("?");

  if (!queryString) return url;
  const params = queryString.split("&");

  const encodedParams = params.map(param => {
    const [key, value] = param.split("=");

    if (value !== undefined) {
      return `${key}=${encodeURIComponent(value)}`;
    } else {
      return key;
    }
  });

  const encodedQueryString = encodedParams.join("&");

  return `${path}?${encodedQueryString}`;
};

export const replaceBaseUrl = (url: any, app?: BXApp, slug?: string) => {
  if (!url || isArray(url)) return url;

  const newPath = url?.startsWith("/") ? `${(slug ?? app?.baseUrl) || ""}${url}` : url;

  return newPath;
};
