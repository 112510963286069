import { Box, FormHelperText, SxProps, Tooltip } from "@mui/material";
import { id } from "date-fns/locale";
import { joinObjects } from "hd-utils";
import _ from "lodash";
import { FC, Fragment, memo, useCallback, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { KeysToComponentMap } from "src/BXEngine";
import { PermissibleRender } from "src/components/PermissionValidation/PermissibleRender";
import { useAppState } from "src/features/appState/hooks";
import { setProviderState } from "src/features/buildxProvider/buildxProviderSlice";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import { selectComponentById } from "src/features/endUser/selectors";
import store from "src/store/store";
import { validationRules } from "src/utils/BXValidate/validationRules";
import { MUI_COMPONENTS } from "src/views/pages/BuildX/FormBuilder";
import { ComponentItemType, StepperComponentState } from "src/views/pages/BuildX/FormBuilder/types";
import { mapValuesRecursively, resolveResponsiveProperties, stepperGroupComponentState } from "src/views/pages/BuildX/FormBuilder/utils";
import { AppController } from "../AppController";
import { ActionButton, useReplaceDataPlaceholders } from "../DataTable/ActionButton";
import ChildrenRenderer from "./ChildrenRenderer";
import OnTriggerHandler from "./OnTriggerHandler";
import RepeatedItemsComponent from "./RepeatedItemsComponent";
import { getControllerName, handleChange, handleClick, handleKeyDown, isParentFlexEndUser } from "./utils";

const RenderChildren: FC<any> = ({ elementId, index, dataEntry, viewName, ...props }) => {
  const {
    hiddenByStepper: repeatedHiddenByStepper,
    pageId,
    getValues,
    __data,
    parentIds,
    closeModal,
    info,
    item,
    queryKey,
    dataSource,
    path,
    handleSelectRow,
    pageOutlet,
    disabled,
    parentKey,
    stripe,
    currentRepeatedItem,
    referenceStepperGroups,
    repeatedParentKey,
    isRepeated,
    parentDataIndex,
    repeatedComponent,
  } = props;
  const leftDrawerOpened = useBuildxProviderValue("leftDrawerOpened");
  const currentBreakpoint = useBuildxProviderValue("currentBreakpoint");
  const viewsState = useBuildxProviderValue("viewsState");
  const currentApp = useBuildxProviderValue("currentApp");
  const fqdnApp = useBuildxProviderValue("fqdnApp");

  const multiLingual = useBuildxProviderValue("multiLingual");

  let views = [];

  if (currentApp) {
    views = currentApp?.templateConfig?.collections?.flatMap(col => col.pages).flatMap(page => page.views);
  } else {
    views = fqdnApp?.upTemplateConfig?.collections?.flatMap(col => col.pages).flatMap(page => page.views);
  }

  const metaData = {
    __data,
    item,
    queryKey,
    pageId,
    path,
    viewName: info?.viewName,
  };

  const viewNameOption =
    dataSource?.sourceType !== "NONE"
      ? dataSource?.sourceType === "FROM PARENT"
        ? { viewName: viewName }
        : { viewName: info?.viewName }
      : { viewName: info?.viewName };

  let element = useSelector(state => {
    return {
      ...selectComponentById(state, pageId, info?.viewName, elementId),
      dirty: undefined,
      errors: undefined,
    };
  }, shallowEqual);

  const { replaceDataPlaceholdersRecursively, replaceDataPlaceholders } = useReplaceDataPlaceholders(viewNameOption);
  const { handleSubmit, watch, produceTrigger } = useAppState();
  const dispatch = useDispatch();

  const queryKeys = [`${pageId}-${info?.viewName}-${element?.props?.key}`];

  const updateElementBasedOnMapKey = (element, item, viewsState, pageId, __data, currentAppEnv) => {
    if (element?.props?.isMapValues) {
      const mapKey = replaceDataPlaceholders({
        queryString: element?.config?.mapKey || "",
        item,
        viewsState,
        pageId,
        __data,
        env: currentAppEnv,
        fallback: "",
      });

      const condition = element?.config?.conditionMapKey || "equal";
      let selectedOption;
      if (condition === "equal") {
        selectedOption = element?.optionMap?.[mapKey];
      } else if (condition === "startWith") {
        const mapKeyStr = String(mapKey);
        const matchingKey = Object.keys(element?.optionMap || {}).find(key => mapKeyStr.startsWith(key));
        if (matchingKey) {
          selectedOption = element?.optionMap?.[matchingKey];
        }
      }

      return selectedOption
        ? {
            ...selectedOption,
            optionMap: element?.optionMap || {},
            selectedType: element?.selectedType,
          }
        : {
            ...(element?.optionMap?.default || {}),
            optionMap: element?.optionMap || {},
            selectedType: element?.selectedType,
          };
    } else {
      return _.cloneDeep(element);
    }
  };
  const dataSourceMapKey = replaceDataPlaceholders({
    queryString: element?.config?.dataSourceMapKey,
    item,
    viewsState,
    pageId,
    __data,
    env: currentApp?.env,
    fallback: "",
  });

  const updateDataSourceBasedOnMapKey = (element, item, viewsState, pageId, __data, currentAppEnv) => {
    if (element?.config?.isMapDataSource) {
      const dataSourceMapKey = replaceDataPlaceholders({
        queryString: element?.config?.dataSourceMapKey,
        item,
        viewsState,
        pageId,
        __data,
        env: currentAppEnv,
        fallback: "",
      });

      const condition = element?.config?.conditionDataSourceMap || "equal";

      let selectedOption;
      if (condition === "equal") {
        selectedOption = element?.dataSourceMap?.[dataSourceMapKey];
      } else if (condition === "startWith") {
        const dataSourceMapKeyStr = String(dataSourceMapKey);
        const matchingKey = Object.keys(element?.dataSourceMap || {}).find(key => dataSourceMapKeyStr.startsWith(key));
        if (matchingKey) {
          selectedOption = element?.dataSourceMap?.[matchingKey];
        }
      }

      return selectedOption
        ? {
            ...element,
            configData: { ...selectedOption },
          }
        : {
            ...element,
            configData: { ...element?.dataSourceMap?.default },
          };
    } else {
      return element;
    }
  };

  element = updateElementBasedOnMapKey(element, item, viewsState, pageId, __data, currentApp?.env);
  element = updateDataSourceBasedOnMapKey(element, item, viewsState, pageId, __data, currentApp?.env);

  const resolvedElement = _.cloneDeep(element);
  resolveResponsiveProperties(resolvedElement, currentBreakpoint);

  element = resolvedElement;

  const ref = useRef(null);
  const handleLeftDrawerToggle = useCallback(() => {
    dispatch(setProviderState({ leftDrawerOpened: !leftDrawerOpened }));
  }, [leftDrawerOpened]);

  const elementDisabled = replaceDataPlaceholders({
    queryString: element?.config?.disable?.disableCondition || "test",
    item,
    pageId,
    __data,
    env: currentApp?.env,
    index,
    fromParent: dataSource?.sourceType === "FROM PARENT",
    dataEntry,
    canWatch: true,
  });
  const isDisableConditionMet = (disableConfig, item, pageId, __data, currentAppEnv) => {
    try {
      if (!disableConfig?.disableCondition) return false;

      const isConditionMet = eval(elementDisabled);
      return !!isConditionMet;
    } catch (error) {
      return false;
    }
  };
  const elementVisibility = replaceDataPlaceholders({
    queryString: element?.config?.visibility?.visibilityCondition || "test",
    item,
    pageId,
    __data,
    env: currentApp?.env,
    index,
    fallback: undefined,
    fromParent: dataSource?.sourceType === "FROM PARENT",
    dataEntry,
    canWatch: true,
  });
  const visibilityState = (visibilityConfig, item, pageId, __data, currentAppEnv) => {
    try {
      if (!visibilityConfig?.visibilityCondition) return "visible";

      const isConditionMet = eval(elementVisibility);
      if (isConditionMet) {
        const visibilityType = visibilityConfig?.selectedVisibilityType || "Hidden";

        switch (visibilityType) {
          case "Hidden":
            return "hidden";
          case "None":
            return "none";
          default:
            return "visible";
        }
      }
    } catch (error) {}
    return "visible";
  };
  const componentVisibility = visibilityState(element?.config?.visibility, item, pageId, __data, currentApp?.env);

  let isComponentDisabled =
    (isDisableConditionMet(element?.config?.disable, item, pageId, __data, currentApp?.env) && !element?.config?.disable?.withOverlay) ||
    element?.props?.disabled ||
    disabled;

  const overlay = element?.config?.disable?.withOverlay &&
    (isDisableConditionMet(element?.config?.disable, item, pageId, __data, currentApp?.env) || element?.props?.disabled) && (
      <Box
        sx={{
          backgroundColor: element?.config?.disable?.overlayColor || "#000",
          opacity: element?.config?.disable?.overlayOpacity || "0.5",
          position: "absolute",
          userSelect: "none",
          zIndex: 5,
          width: "100%",
          height: "100%",
        }}
      />
    );
  const viewRef = `${pageId}-${info?.viewName}`;
  const toggle = viewsState?.visibility?.[viewRef]?.[element?.props?.key]?.toggle;

  const displayRef = viewsState?.visibility?.[viewRef]?.[element?.props?.key]?.display ?? "none";
  let boxStyles: SxProps = {
    position: dataSource?.formBuilderConfig?.isDynamicHeight ? "unset" : "absolute",
    top: element?.top,
    left: element?.leftPercentage,
    width: element?.config?.isDynamicWidth
      ? "auto"
      : element?.config?.fixedWidth
      ? element?.config?.widthPx
      : element?.config?.widthPercentage,
    height: !element?.config?.isPercentageHeight ? element?.config?.heightPx : element?.config?.heightPercentage,
    overflow: element?.config?.overflow || "visible",
    ...element?.config?.sx,
  };

  if (displayRef === "none") {
    (boxStyles as any).display = !toggle ? (element?.config?.hideElement ? "none" : "block") : "none";
  }

  if (displayRef === "hidden") {
    (boxStyles as any).visibility = !toggle ? (element?.config?.hideElement ? "hidden" : "visible") : "hidden";
  }

  if (
    [ComponentItemType.FlexContainer, ComponentItemType.GridContainer, ComponentItemType.StepperContainer].includes(
      selectComponentById(store.getState(), pageId, info?.viewName, element?.parentKey)?.type
    )
  ) {
    boxStyles = joinObjects<SxProps>(
      {
        position: "static",
        width: element?.config?.isDynamicWidth
          ? "auto"
          : element?.config?.fixedWidth
          ? element?.config?.widthPx
          : element?.config?.widthPercentage,
        ...(selectComponentById(store.getState(), pageId, info?.viewName, element?.parentKey)?.type === ComponentItemType.GridContainer && {
          width: "100%",
        }),
      },
      element?.config?.parentStyle
    )!;
  }

  if (isParentFlexEndUser(element, pageId, info?.viewName)) {
    boxStyles = joinObjects<SxProps>(
      {
        position: "static",
        width: element?.config?.isDynamicWidth
          ? "auto"
          : element?.config?.fixedWidth
          ? element?.config?.widthPx
          : element?.config?.widthPercentage,
        height: !element?.config?.isPercentageHeight ? element?.config?.heightPx : element?.config?.heightPercentage,
        ...element?.config?.sx,
        boxSizing: "border-box",
      },
      element?.config?.parentStyle
    )!;
  }

  const replacePropsPlaceholders = replaceDataPlaceholdersRecursively({
    obj: _.cloneDeep(element?.props),
    item,
    viewsState,
    pageId,
    formData: getValues,
    __data,
    viewName: props?.info?.viewName,
    env: currentApp?.env,
    index,
    parentDataIndex,
    fallback: "",
    multiLingual: multiLingual,
    keyComp: element?.props?.key,
    dataEntry,
    fromParent: dataSource?.sourceType === "FROM PARENT",
  });

  const resolvedProps = mapValuesRecursively(replacePropsPlaceholders);
  const CheckToolTipParent = element?.actionConfig?.label || resolvedProps.toolTip?.value ? Tooltip : Fragment;
  const toolTipParentProps: any =
    element?.actionConfig?.label || resolvedProps?.toolTip ? { title: element?.actionConfig?.label || resolvedProps?.toolTip?.value } : {};

  const isContainer = [
    ComponentItemType.FlexContainer,
    ComponentItemType.CustomContainer,
    ComponentItemType.GridContainer,
    ComponentItemType.StepperContainer,
  ].includes(element.type);

  const viewKey: string = getControllerName(element, repeatedParentKey, index, pageId, info?.viewName);

  const Component = MUI_COMPONENTS[element?.type];
  const CustomComponent = KeysToComponentMap[element?.type];

  if (!Component && !CustomComponent) return <></>;
  if (componentVisibility === "none") {
    return <></>;
  }

  let hiddenByStepper = _.isNil(repeatedHiddenByStepper) ? !!element?.config?.stepperParent : repeatedHiddenByStepper;
  if (element?.config?.stepperParent?.key && _.isNil(repeatedHiddenByStepper)) {
    const stepperParentKey = `${pageId}.${info?.viewName}.${element?.config?.stepperParent?.key}.state`;
    const parentStepperGroup = watch(stepperParentKey, { pageId, viewName: info?.viewName });

    if (parentStepperGroup) {
      const stepperGroup = replaceDataPlaceholdersRecursively({
        obj: _.cloneDeep(parentStepperGroup),
        item,
        viewsState,
        pageId,
        __data,
        env: currentApp?.env,
        fallback: null,
      });

      const componentStepperState = stepperGroupComponentState(element?.props?.key, stepperGroup);
      hiddenByStepper = componentStepperState === StepperComponentState.FoundButNotSelected;
    }
  }

  if (element?.config?.repeated?.enabled && !repeatedComponent) {
    return (
      <RepeatedItemsComponent
        key={element?.id + "-" + index + "-"}
        elementId={element?.props?.key}
        element={element}
        item={item}
        viewsState={viewsState}
        pageId={pageId}
        __data={__data}
        props={props}
        currentApp={currentApp}
        multiLingual={multiLingual}
        viewName={info?.viewName}
        referenceStepperGroups={referenceStepperGroups}
        index={index}
      />
    );
  }

  return (
    <PermissibleRender action={["VIEW"]} path={[path, element?.props?.key].join(".")}>
      {({ permitted }) =>
        permitted && (
          <>
            <ActionButton
              _key={element?.props?.key}
              path={[path, element?.props?.key].join(".")}
              queryKeys={queryKeys}
              pageId={pageId}
              viewName={info?.viewName}
              item={item}
              element={element}
              action={element?.actionConfig}
              interactionConfig={element?.interactionConfig}
              handleSubmit={handleSubmit}
              tableId={id}
              actionsMap={element?.actionsMap}
              iconButton={false}
              formData={getValues}
              __data={__data}
              closeModal={closeModal}
              parentIds={parentIds}
              disabled={!permitted}
              onSelectRow={handleSelectRow}
              index={index}
              isChainMapped={element?.config?.actionMappingConfig?.isChainMapped}
              actionsKey={element?.config?.actionMappingConfig?.actionsKey}
              isLoadingForEntireChain={element?.config?.actionMappingConfig?.isLoadingForEntireChain}
              stripe={stripe}
              currentRepeatedItem={currentRepeatedItem}
              fromParent={dataSource?.sourceType === "FROM PARENT"}
              dataEntry={dataEntry}
              viewNameOption={viewNameOption}
              views={views}
            >
              {({ actionHandlerMapper, isLoading }) => (
                <>
                  <OnTriggerHandler
                    element={element}
                    actionHandlerMapper={actionHandlerMapper}
                    pageId={pageId}
                    viewName={props?.info?.viewName}
                    viewsState={viewsState}
                    viewKey={viewKey}
                    watch={watch}
                    produceTrigger={produceTrigger}
                    __data={__data}
                    id={id}
                    info={info}
                    path={path}
                    dataSourceMapKey={dataSourceMapKey}
                  />
                  <AppController
                    viewNameOption={viewNameOption}
                    viewName={info?.viewName}
                    element={element}
                    isRepeated={isRepeated}
                    index={index}
                    defaultValue={element?.props?.defaultValue}
                    replaceDataPlaceholdersProps={{
                      item,
                      viewsState,
                      pageId,
                      index,
                      __data,
                      env: currentApp?.env,
                      fallback: "",
                      multiLingual: multiLingual,
                      keyComp: element?.props?.key,
                      dataEntry,
                      fromParent: dataSource?.sourceType === "FROM PARENT",
                    }}
                    name={getControllerName({ ...element, props: resolvedProps }, repeatedParentKey, index, pageId, info?.viewName)}
                    enableDirtyCheck={props?.enableDirtyCheck || isContainer}
                    disabled={props?.disablePageDirty}
                    matchValidationField={replaceDataPlaceholders({
                      queryString: element?.props?.matchValue,
                      item,
                      viewsState,
                      pageId,
                      index,
                      __data,
                      env: currentApp?.env,
                      fallback: "",
                      multiLingual: multiLingual,
                    })}
                    validate={validationRules(element, value => {
                      return replaceDataPlaceholders({
                        queryString: value,
                        item,
                        viewsState,
                        pageId,
                        index,
                        __data,
                        env: currentApp?.env,
                        fallback: "",
                        multiLingual: multiLingual,
                      });
                    })}
                    render={({ onChange, value, error }) => (
                      <Box
                        sx={joinObjects(boxStyles, {
                          height: element?.config?.isDynamicHeight
                            ? "auto"
                            : [ComponentItemType.GridContainer].includes(
                                selectComponentById(store.getState(), pageId, info?.viewName, element?.parentKey)?.type
                              )
                            ? "100%"
                            : !element?.config?.isPercentageHeight
                            ? element?.config?.heightPx
                            : element?.config?.heightPercentage,
                          overflow: element?.config?.overflow || "visible",
                          ...((!leftDrawerOpened && element.type === ComponentItemType.BXSideBar) || hiddenByStepper
                            ? { display: "none" }
                            : {}),
                          contentVisibility: componentVisibility,
                          ...(element?.config?.transition?.enabled && {
                            transition: "all 0.5s ease",
                          }),
                          ...(element?.config?.position?.enabled || element?.config?.sticky?.enabled
                            ? {
                                position: element?.config?.position?.type || "sticky",
                                top: element?.config?.position?.top || element?.config?.sticky?.top,
                                right: element?.config?.position?.right || element?.config?.sticky?.right,
                                left: element?.config?.position?.left || element?.config?.sticky?.left,
                                bottom: element?.config?.position?.bottom || element?.config?.sticky?.bottom,
                                zIndex: (element?.config?.position?.zIndex || element?.config?.sticky?.zIndex) ?? 1200,
                              }
                            : {}),
                        })}
                        aria-invalid={!!error}
                      >
                        <CheckToolTipParent {...toolTipParentProps}>
                          <div style={{ position: "relative", width: "100%", height: "100%" }}>
                            {overlay}
                            <>
                              <Component
                                {...resolvedProps}
                                hover={element?.config?.hover}
                                appId={currentApp?.id}
                                config={element?.config}
                                __config={element?.config}
                                $views={views}
                                $parentRef={ref}
                                $configData={element?.configData}
                                $pageOutlet={pageOutlet}
                                $drawerOpen={leftDrawerOpened}
                                $drawerToggle={handleLeftDrawerToggle}
                                value={value}
                                loading={element?.props?.enableLoading ? isLoading : false}
                                disabled={isComponentDisabled}
                                metaData={metaData}
                                pageId={pageId}
                                info={info}
                                id={element?.props?.testId}
                                renderId={"renderedView"}
                                data-bx-key={`${pageId}.${props?.info?.viewName}.${element?.props?.key}`}
                                data-testid={element?.props?.testId}
                                onKeyDown={event =>
                                  handleKeyDown(event, element, actionHandlerMapper, pageId, info?.viewName, viewsState, produceTrigger)
                                }
                                onChange={(event: any, disabledDirty?: boolean) =>
                                  handleChange(
                                    event,
                                    element,
                                    onChange,
                                    actionHandlerMapper,
                                    pageId,
                                    props?.info?.viewName,
                                    viewsState,
                                    disabledDirty,
                                    produceTrigger
                                  )
                                }
                                onClick={event => handleClick(event, element, pageId, props?.info?.viewName, viewsState, produceTrigger)}
                                isLoadingImage
                                error={!!error}
                              >
                                {!element?.children?.length ? (
                                  resolvedProps.children
                                ) : (
                                  <ChildrenRenderer
                                    element={element}
                                    stripe={stripe}
                                    index={index}
                                    parentKey={parentKey}
                                    isComponentDisabled={isComponentDisabled}
                                    dataEntry={dataEntry}
                                    viewName={viewName}
                                    dataSource={dataSource}
                                    isRepeated={isRepeated}
                                    __data={__data}
                                    pageId={pageId}
                                    getValues={getValues}
                                    parentIds={parentIds}
                                    closeModal={closeModal}
                                    info={info}
                                    pageOutlet={pageOutlet}
                                    handleSelectRow={handleSelectRow}
                                    path={path}
                                    parentDataIndex={parentDataIndex}
                                  />
                                )}
                              </Component>

                              {!!error && !element?.props?.isChildContainerGroup && (element?.props?.showError ?? true) && (
                                <FormHelperText error>
                                  {replaceDataPlaceholders({
                                    obj: _.cloneDeep(element?.props),
                                    queryString: error,
                                    validationRules: element?.props,
                                    item,
                                    viewsState,
                                    index,
                                    pageId,
                                    __data,
                                    env: currentApp?.env,
                                    fallback: "",
                                    multiLingual: multiLingual,
                                  })}
                                </FormHelperText>
                              )}
                            </>
                          </div>
                        </CheckToolTipParent>
                      </Box>
                    )}
                  />
                </>
              )}
            </ActionButton>
          </>
        )
      }
    </PermissibleRender>
  );
};

export default memo(RenderChildren, _.isEqual);
