import { Button, CardMedia, DialogActions } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { IconCloudUpload, IconPictureInPicture, IconXboxX } from "@tabler/icons-react";
import axios from "axios";
import { FC, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import ReactCrop, { Crop, PixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import { ElementBaseProps } from "src/types/UIElement";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import { getAuthorizationHeader, getCroppedImageFile } from "src/utils/generalUtils";
import { useReplaceDataPlaceholders } from "../DataTable/ActionButton";
import BXModal from "../Modal";
import { replaceBaseUrl } from "src/features/buildxProvider/buildxProviderUtils";

type Props = {
  actions?: any[];
  errorText: any;
  auth: any;
  layout: string;
  info?: {
    name?: string;
    showApiMode?: string;
  };
  uploadConfig?: {
    uploadAllowedTypes?: string[];
    uploadUrlType?: "aws" | "custom";
    signedUrl?: string;
    finalizedUrl?: string;
    customToken?: string | null;
    customUrl?: string;
    isInput?: "Yes" | "No";
    multipleFiles?: boolean;
    isCropImage?: boolean;
    withPreview?: boolean;
    allDetails?: boolean;
    fileNumber?: number | string;
  };
  views: any;
  uploadData?: {
    inputName?: string;
    inputValidationName?: string;
    control?: any;
    error?: any;
    isRequired?: boolean;
    key?: string;
  };
  onSuccess?: any;
  pageId?: string;
  __data?: any;
} & ElementBaseProps;

const Upload: FC<Props> = props => {
  const { pageId, uploadConfig, info, dataSource, selectedViewId, uploadData, __data = {}, onSuccess = () => {}, errorText } = props;

  const { inputName, inputValidationName, key, control, error, isRequired } = uploadData || {};
  const { fileNumber = 1, multipleFiles = false, isCropImage = false, withPreview = true, allDetails } = uploadConfig || {};
  const [croppedImage, setCroppedImage] = useState<any>();
  const [crop, setCrop] = useState<Crop>();
  const imgRef = useRef<HTMLImageElement>(null);
  const [imageData, setImageData] = useState({ imageSrc: "", onChange: () => {} });
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const currentApp = useBuildxProviderValue("currentApp");
  const viewsState = useBuildxProviderValue("viewsState");
  const currentProfileId = useBuildxProviderValue("currentProfileId");
  const { replaceDataPlaceholders } = useReplaceDataPlaceholders();
  const fileInputRef = useRef<any>();
  const [files, setFiles] = useState<any>([]);
  const [maxFiles, setMaxFiles] = useState<any>(1);
  const [maxFilesError, setMaxFilesError] = useState<any>("");
  const [isLoading, setIsLoading] = useState<any>(null);

  useEffect(() => {
    if (fileNumber) {
      setMaxFiles(Number(fileNumber));
    }
  }, []);

  useEffect(() => {
    if (completedCrop?.width && completedCrop?.height && imgRef.current && previewCanvasRef.current) {
      // We use canvasPreview as it's much faster than imgPreview.
      getCroppedImageFile(imgRef.current, previewCanvasRef.current, completedCrop, (croppedImage: File) => {
        setCroppedImage(croppedImage);
      });
    }
  }, [completedCrop]);

  const handleUploadClick = () => {
    fileInputRef.current?.click();
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>, onChange?: any) => {
    if (!event.target.files?.length) return;

    if (maxFiles - files?.length < 1 && multipleFiles) {
      event.target.value = "";
    }
    const fileCount = !multipleFiles
      ? 1
      : event.target.files?.length < maxFiles - files?.length
      ? event.target.files?.length
      : maxFiles - files?.length;

    if (!fileCount) {
      setMaxFilesError(true);
    } else {
      setMaxFilesError(false);
    }

    let _files: any = files;
    for (let i = 0; i < fileCount; i++) {
      const file = event.target.files[i];
      if (!file) return;
      const urlObject = URL.createObjectURL(file);
      const fileObject = { url: urlObject, file: { ...file, type: file.type.split("/")[0] } };
      _files = !multipleFiles ? [fileObject] : [..._files, fileObject];

      setFiles((prev: any) => (!multipleFiles ? [fileObject] : [...prev, fileObject]));
      setIsLoading(true);

      const token = uploadConfig?.customToken || localStorage.getItem(currentApp?.id + `-${currentProfileId}-accessToken`);

      let key: any = null;
      let finalizedResponseUrl: any = null;

      try {
        if (uploadConfig?.uploadUrlType == "aws") {
          const { data } = await axios.get(replaceBaseUrl(uploadConfig?.signedUrl, currentApp), {
            headers: { ...getAuthorizationHeader(uploadConfig?.customToken ? null : currentApp?.appConfig?.auth, token) },
          });

          const { key: responseKey, url } = data;
          key = responseKey;
          const extn = file.type.split("/")[1];

          await axios.put(url, file, {
            headers: {
              "Content-Type": file.type,
            },
          });
          if (uploadConfig?.finalizedUrl) {
            const { data } = await axios.post(replaceBaseUrl(uploadConfig?.finalizedUrl, currentApp), undefined, {
              params: {
                key,
                extn,
              },
              headers: { ...getAuthorizationHeader(uploadConfig?.customToken ? null : currentApp?.appConfig?.auth, token) },
            });
            finalizedResponseUrl = data?.url;
          }
        } else {
          await axios.post(replaceBaseUrl(uploadConfig?.customUrl, currentApp), file, {
            headers: {
              "Content-Type": file.type,
            },
          });
        }
        if (uploadConfig?.isInput != "Yes") {
          onSuccess?.({ url: finalizedResponseUrl, key, extension: file.type.split("/")[1], fileName: file.name, file });

          enqueueSnackbarRef?.("Uploaded Successfully", {
            variant: "success",
          });
        } else {
          _files = _files.map((item: any) => {
            const newData = {
              ...item,
              value: { extn: file.type.split("/")[1], key, ...(finalizedResponseUrl && { url: finalizedResponseUrl }) },
            };
            return item?.url == urlObject ? newData : item;
          });

          if (i == fileCount - 1) {
            onSuccess?.({ url: finalizedResponseUrl, key, extension: file.type.split("/")[1], fileName: file.name, file });

            onChange?.(
              !multipleFiles
                ? allDetails
                  ? { url: finalizedResponseUrl, key, extension: file.type.split("/")[1], fileName: file.name, file }
                  : finalizedResponseUrl || key
                : _files.map((item: any) => item?.value)
            );
            setFiles(_files);
          }
        }
      } catch (e) {
        enqueueSnackbarRef?.("Something went wrong", {
          variant: "error",
        });
        event.target.value = "";
        setFiles((prev: any) => prev.filter((item: any) => item?.url != urlObject));
      } finally {
        if (uploadConfig?.isInput != "Yes" || multipleFiles) {
          if (i == fileCount - 1) {
            event.target.value = "";
          }
        }
        setIsLoading(false);
      }
    }
  };

  if (uploadConfig?.isInput == "Yes") {
    return (
      <>
        <BXModal
          open={!!imageData?.imageSrc}
          label={"Crop Image"}
          icon={<IconPictureInPicture />}
          buttonProps={{ variant: "text", color: "secondary", startIcon: <IconPictureInPicture /> }}
          title={"Crop Image"}
          onClose={() => {
            setImageData(prev => ({ ...prev, imageSrc: "" }));
          }}
        >
          {(handleClose: Function) => {
            return (
              <>
                <ReactCrop
                  crop={crop}
                  onComplete={c => {
                    setCompletedCrop(c);
                  }}
                  aspect={16 / 9}
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                >
                  <img alt='Crop me' ref={imgRef} src={imageData.imageSrc} />
                </ReactCrop>
                <Grid item xs={12}>
                  <DialogActions style={{ padding: 0, marginTop: 16, justifyContent: "center" }}>
                    <Button
                      variant={"contained"}
                      onClick={() => {
                        handleClose();
                        setImageData(prev => ({ ...prev, imageSrc: "" }));
                        handleChange({ target: { files: [croppedImage] } } as any, imageData.onChange);
                      }}
                    >
                      Done
                    </Button>
                  </DialogActions>
                </Grid>
                <div style={{ display: "none" }}>
                  {!!completedCrop && (
                    <canvas
                      ref={previewCanvasRef}
                      style={{
                        border: "1px solid black",
                        objectFit: "contain",
                        width: completedCrop.width,
                        height: completedCrop.height,
                      }}
                    />
                  )}
                </div>
              </>
            );
          }}
        </BXModal>

        <Controller
          control={control}
          name={inputValidationName!}
          rules={{
            required: isRequired,
          }}
          render={({ field: { onChange, value } }) => (
            <FormControl fullWidth>
              <TextField
                ref={fileInputRef}
                fullWidth
                inputProps={{
                  accept: uploadConfig?.uploadAllowedTypes?.map(item => `${item.toLowerCase()}${item[0] != "." ? "/*" : ""}`).join(","),
                  multiple: multipleFiles && !isCropImage,
                }}
                type='file'
                label={inputName || key || "File"}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={!!isLoading}
                required={isRequired}
                error={Boolean(error)}
                aria-errormessage={"error message"}
                onChange={(e: any) => {
                  if (!isCropImage) {
                    handleChange(e, onChange);
                  } else {
                    const urlObject = URL.createObjectURL(e.target.files[0]);
                    setImageData({ imageSrc: urlObject, onChange });
                  }
                }}
                size={"medium"}
                // value={value || ""}
              />
              <Box minHeight={"22px"}>
                {maxFilesError && (
                  <FormHelperText error id='standard-weight-helper-text-email-login'>
                    You have reached the maximum number of files
                  </FormHelperText>
                )}
              </Box>
              {!!withPreview && (
                <Grid container flexWrap={"wrap"} spacing={2} mt={0}>
                  {!!files?.length &&
                    files.map((file: any, index: number) => {
                      return (
                        <Grid item key={`${file?.url}-${index}`} paddingTop={"0px !important"}>
                          <Box position='relative'>
                            <Box
                              sx={{
                                position: "absolute",
                                top: 1,
                                left: 1,
                                cursor: "pointer",
                                zIndex: 999,
                              }}
                              onClick={() => {
                                setFiles((prev: any) => {
                                  const values = [
                                    ...prev.map((item: any) => (item?.url == file?.url ? null : item?.value)).filter(Boolean),
                                  ];
                                  onChange?.(!multipleFiles ? values.join("") : values);

                                  return prev.filter((item: any) => item?.url != file?.url);
                                });
                              }}
                            >
                              <IconXboxX color={"red"} />
                            </Box>
                            {file?.file?.type == "image" ? (
                              <img
                                src={file?.url}
                                alt=''
                                style={{
                                  height: 120,
                                  width: 120,
                                  objectFit: "cover",
                                  border: "1px solid #5a5a5a",
                                  borderRadius: " 2px",
                                }}
                              />
                            ) : (
                              <CardMedia
                                sx={{ border: `0px` }}
                                style={{
                                  maxHeight: file?.file?.type == "audio" ? 50 : 150,
                                  marginTop: 12,
                                }}
                                component='video'
                                src={file?.url}
                                controls
                              />
                            )}
                          </Box>
                        </Grid>
                      );
                    })}
                </Grid>
              )}
              {errorText && <FormHelperText error>{errorText?.message}</FormHelperText>}
            </FormControl>
          )}
        />
      </>
    );
  }

  return (
    <Box marginY={1} paddingX={1}>
      <Card>
        <Grid container paddingX={2} mt={2} mb={2}>
          <Grid container xs={12}>
            <Typography flex={1} fontSize={"16px"} lineHeight={2}>
              {replaceDataPlaceholders({
                queryString: info?.name,
                viewsState,
                pageId,
                __data,
                env: currentApp?.env,
              })}
            </Typography>
          </Grid>
        </Grid>

        <Grid container padding={2} minHeight='50vh' spacing={2} alignItems='center' justifyContent='center'>
          {!isLoading ? (
            <Box style={{ cursor: "pointer" }} onClick={handleUploadClick}>
              <IconCloudUpload size={60} />
              <Typography textAlign={"center"}>Upload</Typography>
            </Box>
          ) : (
            <Box sx={{ width: "50%" }}>
              <Typography textAlign={"center"} mb={6}>
                upload {files?.[files?.length - 1]?.file?.file?.name}
              </Typography>
              <LinearProgress />
            </Box>
          )}
          <Box display='none'>
            <input
              ref={fileInputRef}
              accept={uploadConfig?.uploadAllowedTypes?.map(item => `${item.toLowerCase()}${item[0] != "." ? "/*" : ""}`).join(",")}
              onChange={handleChange}
              type='file'
            />
          </Box>
        </Grid>
      </Card>
    </Box>
  );
};

export default Upload;
