import {
  Avatar,
  Box,
  CircularProgress,
  DialogActions,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { IconLock, IconPencil, IconRefresh, IconTrashX, IconUserPlus } from "@tabler/icons-react";
import _ from "lodash";
import React, { FC, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useInfiniteQuery, useMutation } from "react-query";
import { BXConfirmationDialog } from "src/components/BXUI/AlertDialog/ConfirmationDialog";
import BXModal from "src/components/BXUI/Modal";
import { queryClient } from "src/features/buildxProvider/buildxProviderUtils";
import useAuth from "src/hooks/useAuth";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import axios from "src/utils/axios";
import { CreateUserForm } from "src/views/pages/BuildX/ManageUsers/CreateUserForm";

type ManageUsersProps = {
  children?: React.ReactNode;
};

const columns: any[] = [
  { id: "name", label: "Name", minWidth: 270 },
  { id: "handle", label: "handle", minWidth: 270 },
  { id: "email", label: "Email", minWidth: 200 },
  { id: "applicationName", label: "App Name", minWidth: 200 },
  { id: "organization.name", label: "Org Name", minWidth: 200 },
  { id: "status", label: "Status", minWidth: 200 },
];
export const ManageUsers: FC<ManageUsersProps> = ({ children }) => {
  const [isDirty, setIsDirty] = useState(false);
  const { palette } = useTheme();
  const { user } = useAuth();

  const [isRefreshing, setIsRefreshing] = useState(false);

  // Start fetch users
  const { data, hasNextPage, fetchNextPage, isFetching, isError } = useInfiniteQuery(
    ["users-list"],
    ({ pageParam }) => {
      return axios.get("/admin/user", {
        params: {
          cursor: pageParam,
        },
      });
    },
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage: any) => (lastPage?.data?.hasMore ? lastPage?.data?.cursor : undefined),
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );
  // Finish fetch users

  const { mutate } = useMutation(
    newTodo => {
      return axios.post("/admin/user", newTodo);
    },
    {
      onSuccess: data1 => {
        queryClient.setQueryData(["users-list"], ({ pages: [page1, ...rest] }) => {
          return {
            // @ts-ignore
            pages: [{ ...page1, data: { items: [data1.data, ...page1.data.items] } }, ...rest],
          };
        });
      },
    }
  );

  const { mutate: editUser } = useMutation(
    async (editedData: any) => {
      return axios.put(`/admin/user`, { ...editedData, handle: undefined });
    },
    {
      onSuccess: (data1, editedData: any) => {
        queryClient.setQueryData(["users-list"], ({ pages: [page1, ...rest] }) => {
          return {
            // @ts-ignore
            pages: [
              {
                ...page1,
                data: { items: page1.data.items.map((item: any) => (item.id === data1.data.id ? { ...editedData, ...data1.data } : item)) },
              },
              ...rest,
            ],
          };
        });
      },
    }
  );

  const { mutate: deleteUser } = useMutation(
    (itemToDelete: any) => {
      return axios.delete(`/admin/user/${itemToDelete?.id}`);
    },
    {
      onSuccess: (_, editedData: any) => {
        enqueueSnackbarRef?.("Deleted successfully", {
          variant: "success",
        });
        queryClient.setQueryData(["users-list"], ({ pages: [page1, ...rest] }) => {
          return {
            // @ts-ignore
            pages: [{ ...page1, data: { items: page1.data.items.filter(item => item.id !== editedData?.id) } }, ...rest],
          };
        });
      },
    }
  );

  const onSubmit = (values: any) => {
    return mutate(values, {});
  };

  const [sentryRef] = useInfiniteScroll({
    loading: isFetching,
    hasNextPage: hasNextPage || false,
    onLoadMore: () => fetchNextPage(),
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: isError,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 400px 0px",
  });

  const entities = _.flatten(data?.pages?.map((p: any) => _.get(p.data, "items")));

  return (
    <>
      <Grid container style={{ height: "100%" }}>
        <Grid style={{ height: "100%", padding: 10, paddingTop: 0 }} item md={12}>
          <Grid container style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 2 }}>
            <Grid item xs={12}></Grid>
          </Grid>

          <Grid container marginBottom={1}>
            <Grid container flex={1} spacing={2} alignItems={"center"}>
              <Grid item>
                <Typography fontSize={"20px"} color='textPrimary' fontWeight={600}>
                  Manage Users
                </Typography>
              </Grid>
            </Grid>

            <DialogActions>
              <Tooltip title='Refresh'>
                <Box marginInlineEnd={1} alignSelf='center'>
                  <IconButton
                    onClick={() => {
                      setIsRefreshing(true);
                      queryClient.refetchQueries(["users-list"]).then(() => {
                        setIsRefreshing(false);
                        // enqueueSnackbarRef?.("Refreshed Successfully", {
                        //   variant: "success",
                        // });
                      });
                    }}
                    disabled={isFetching}
                    style={{ backgroundColor: palette.primary.light }}
                  >
                    {isRefreshing ? <CircularProgress size='16px' /> : <IconRefresh color={palette.text.primary} size={16} />}
                  </IconButton>
                </Box>
              </Tooltip>
              <BXModal
                title={"Add User"}
                icon={<IconUserPlus />}
                label={"Add user"}
                buttonProps={{
                  startIcon: <IconUserPlus />,
                  color: "secondary",
                  variant: "contained",
                  style: { backgroundColor: palette.primary.main, borderRadius: 24 },
                  size: "small",
                }}
                withoutContainer
              >
                {(handleClose: Function) => {
                  return (
                    <CreateUserForm
                      onSave={(formData, event) => {
                        onSubmit(formData);
                        handleClose?.();
                      }}
                    />
                  );
                }}
              </BXModal>
            </DialogActions>
          </Grid>
          <TableContainer style={{ backgroundColor: palette.background.paper }}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  <TableCell style={{ backgroundColor: palette.background.paper }} />

                  {columns.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, backgroundColor: palette.background.paper }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!isFetching && entities?.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={columns.length} align={"center"}>
                      No users yet
                    </TableCell>
                  </TableRow>
                )}
                {entities?.map((row: any) => {
                  return (
                    <TableRow hover role='checkbox' tabIndex={-1} key={row.code}>
                      <TableCell>
                        <DialogActions sx={{ padding: 0, justifyContent: "flex-start" }}>
                          <BXModal
                            title={"Edit User"}
                            icon={<IconPencil color={palette.primary.main} height={26} width={26} style={{ padding: 4 }} />}
                            withoutLabel
                            buttonProps={{
                              startIcon: <IconUserPlus />,
                              color: "primary",
                              size: "small",
                              variant: "contained",
                            }}
                            isDirty={isDirty}
                            withoutContainer
                          >
                            {(handleClose: Function) => {
                              return (
                                <CreateUserForm
                                  editing
                                  user={row}
                                  setIsDirty={setIsDirty}
                                  onSave={(formData, event) => {
                                    if (!_.isEmpty(formData.id)) {
                                      editUser(formData);
                                    } else {
                                      onSubmit(formData);
                                    }
                                    handleClose?.(true);
                                  }}
                                  withoutPassword
                                />
                              );
                            }}
                          </BXModal>
                          <BXModal
                            title={"Update Password"}
                            icon={<IconLock color={palette.primary.main} height={26} width={26} style={{ padding: 4 }} />}
                            withoutLabel
                            buttonProps={{
                              startIcon: <IconLock />,
                              color: "primary",
                              size: "small",
                              variant: "contained",
                            }}
                            withoutContainer
                          >
                            {(handleClose: Function) => {
                              return (
                                <CreateUserForm
                                  editing
                                  user={row}
                                  onCancel={() => {
                                    handleClose();
                                  }}
                                  onSave={(formData, event) => {
                                    if (!_.isEmpty(formData.id)) {
                                      editUser(formData);
                                    } else {
                                      onSubmit(formData);
                                    }
                                    handleClose?.();
                                  }}
                                  onlyPassword
                                />
                              );
                            }}
                          </BXModal>
                          {row?.id != user?.id && (
                            <Box marginInlineStart={1}>
                              <BXConfirmationDialog
                                title={"Are you sure you want to delete this app?"}
                                iconButton
                                buttonProps={{
                                  color: "error",
                                  children: <IconTrashX height={20} width={20} style={{ padding: 0 }} />,
                                }}
                                onConfirm={() => {
                                  deleteUser(row);
                                }}
                              />
                            </Box>
                          )}
                        </DialogActions>
                      </TableCell>
                      {columns.map(column => {
                        const value = _.get(row, column.id);
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Grid container alignItems={"center"}>
                              {column.id == "name" && <Avatar src={row?.profilePhoto} style={{ marginInlineEnd: 10 }} />}
                              {column.format && typeof value === "number" ? column.format(value) : value}
                            </Grid>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
                {(isFetching || hasNextPage) && (
                  <TableRow ref={sentryRef}>
                    <TableCell colSpan={columns.length || 1}>Loading</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};
