import { Method } from "axios";
import _, { PropertyPath } from "lodash";
import { useEffect, useState } from "react";
import { isHostAvailable } from "src/App";
import { useBuildxContext } from "src/BXEngine/BuildxContext";
import { useReplaceDataPlaceholders } from "src/components/BXUI/DataTable/ActionButton";
import { useAppState } from "src/features/appState/hooks";
import { replaceBaseUrl } from "src/features/buildxProvider/buildxProviderUtils";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import { ElementDataSource } from "src/types/UIElement";
import externalAxiosServices from "src/utils/axiosExt";
import { getAuth } from "src/utils/buildxProviderOperations";
import { getAuthorizationHeader, handleCustomMessage, removeEmptyQueryParams } from "src/utils/generalUtils";

type useFetchDataProps = {
  queryKeys: any[];
  limit?: number | undefined;
  isUserInput?: boolean;
  withInfiniteQuery?: boolean;
  options?: any;
  cursor?: any;
  __data: any;
  dataEntry?: any;
  soundPlayer?: any;
  notificationMessage?: any;
  viewId?: any;
  pagination?: any;
  pageId?: any;
  selectedItem?: any;
  endpoint?: string;
  dataSource?: ElementDataSource;
  isApiCallInFirstLoad?: boolean;
  viewName?: string;
  componentKey?: string;
  isPagination?: boolean;
  path?: any;
  dataSourceMapKey?: any;
};

export const useFetchData = (props: useFetchDataProps) => {
  const {
    queryKeys,
    limit,
    cursor,
    dataEntry,
    options,
    withInfiniteQuery,
    pagination,
    isUserInput,
    notificationMessage,
    soundPlayer,
    viewId,
    pageId,
    selectedItem = {},
    __data,
    endpoint,
    dataSource,
    isApiCallInFirstLoad,
    viewName,
    componentKey,
    isPagination,
    path,
    dataSourceMapKey,
  } = props;
  const viewsState = useBuildxProviderValue("viewsState");
  const currentApp = useBuildxProviderValue("currentApp");
  const appProfiles = useBuildxProviderValue("appProfiles");
  const currentProfileId = useBuildxProviderValue("currentProfileId");
  const appTokens = useBuildxProviderValue("appTokens");
  const { unprotectedPages, getValue } = useBuildxContext();
  const { replaceDataPlaceholders, replaceDataPlaceholdersRecursively } = useReplaceDataPlaceholders({ viewName });
  const { setValue, watch } = useAppState();

  function resolveMessageOfDataSource(status) {
    const selectedMessage = dataSource?.statusMessages?.find((item: any) => item?.key == status);
    const statusMessage = selectedMessage?.value;
    const targetStatus = selectedMessage?.key;

    const resolvedStatusMessage = replaceDataPlaceholders({
      queryString: statusMessage,
      item: selectedItem,
      viewsState,
      pageId,
      __data,
      pagination,
      env: currentApp?.env,
      fallback: "",
    });

    if (_.isEmpty(selectedMessage?.mapValuesObjectsMessage)) {
      return resolvedStatusMessage;
    }

    let customMessage = handleCustomMessage(dataSource, targetStatus, resolvedStatusMessage);

    customMessage = replaceDataPlaceholders({
      queryString: customMessage,
      item: selectedItem,
      viewsState,
      pageId,
      __data,
      pagination,
      env: currentApp?.env,
      fallback: "",
    });

    return customMessage;
  }

  const checkUnprotected = path => {
    const pathSlugs = path?.split(".").slice(0, 3) || [];

    if (pathSlugs?.length < 3) {
      return true;
    }
    const pagePath = pathSlugs?.join(".");
    return unprotectedPages.current.has(pagePath as string);
  };

  let url: any = endpoint || getValue?.(viewId) || dataSource?.apiUrl;

  const isFixedData = !_.isString(url) || !url;
  const isGraphQL = dataSource?.payload?.isGraphQL && !isFixedData;
  const { token: accessToken } = getAuth(currentApp?.id!, currentProfileId, appProfiles, appTokens, currentApp) || {};
  let apolloMethod = dataSource?.payload?.method || "Query";
  let graphqlVariables = {};
  let graphqlQuery = "";
  let QUERY, mutateFunction, refetch, mutateData;

  let isError = false;
  let resolvedMessage = "";

  const [data, setData] = useState<any>(null); //Store the response of the source endpoint.
  const [firstLoadOfData, setFirstLoadOfData] = useState<any>();
  // TODO: idk what are we doing but it worked (amjad)
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);

  const elementKey = componentKey ? `${pageId}.${viewName}.${componentKey}.data` : `${pageId}.${viewName}.data`;
  const paginationInfoForElement = watch(`${elementKey}._pagination`, { pageId, viewName });

  const deviceToken = localStorage.getItem(`${currentApp?.id}-accessToken-device`);
  const isUnprotectedPage = checkUnprotected(path);

  const token = isUnprotectedPage ? deviceToken : accessToken || localStorage.getItem("accessToken") || deviceToken;

  const requestHeaders = {
    ...getAuthorizationHeader(currentApp?.appConfig?.auth, token),
    ...replaceDataPlaceholdersRecursively({
      obj: dataSource?.payload?.headers,
      viewsState,
      pageId,
      __data,
      pagination,
      env: currentApp?.env,
      cursor,
    }),
  };

  // TODO: For GraphQL
  // if (isGraphQL) {
  //   graphqlVariables = replaceDataPlaceholdersRecursively({
  //     obj: JSON.parse(dataSource?.payload?.graphqlVariables || "{}"),
  //     viewsState,
  //     pageId,
  //     __data,
  //     pagination,
  //     env: currentApp?.env,
  //     cursor,
  //     fallback: null,
  //   });

  //   graphqlQuery = dataSource?.payload?.graphqlQuery || "";
  //   QUERY = gql`${graphqlQuery}`;

  //   url = replaceBaseUrl(url, currentApp);
  //   url = replaceDataPlaceholders({
  //     queryString: url,
  //     item: selectedItem,
  //     viewsState,
  //     pageId,
  //     __data,
  //     pagination,
  //     env: currentApp?.env,
  //     cursor,
  //     fallback: "",
  //   });

  //   url = removeEmptyQueryParams(url);

  //   const apolloQueryContent = {
  //     variables: graphqlVariables,
  //     context: { uri: url, apolloHeaders: requestHeaders },
  //   };

  //   // MUTATION or QUERY logic for GraphQL
  //   if (apolloMethod === "MUTATION") {
  //     [mutateFunction, mutateData] = useMutation(QUERY, apolloQueryContent);
  //   } else {
  //     const { data: graphQLData, fetchMore, refetch: graphQLRefetch, loading: graphQLLoading } = useApolloQuery(QUERY, apolloQueryContent);
  //     data = graphQLData;
  //     loading = graphQLLoading;
  //     refetch = graphQLRefetch;
  //     hasNextPage = _.get(data, (dataSource?.hasMoreKey as PropertyPath) || "more");
  //   }
  // }

  // REST API logic using axios
  const fetchData = async (isRefetchOperation: boolean, currentCursorParam?: any, isPaginated?: boolean, isPaginationBar?: boolean) => {
    if (!url || !viewName) {
      return;
    }

    if (!options?.enabled) {
      return;
    }
    let targetKey = `${pageId}`;
    if (viewName) {
      targetKey = `${targetKey}.${viewName}`;
    }
    if (componentKey) {
      targetKey = `${targetKey}.${componentKey}`;
    }
    targetKey = `${targetKey}.data`;

    //Fixed data
    if (dataSource?.sourceType === "SIMPLE" || dataSource?.sourceType === "LIST" || isFixedData) {
      setTimeout(() => {
        if (dataSource?.sourceType === "SIMPLE") {
          const fixedData = JSON.parse(dataSource?.simple || "{}");
          setValue(`${targetKey}._body`, fixedData, { pageId, viewName });
          setData(fixedData);
        } else {
          const fixedData = endpoint;
          setValue(`${targetKey}._body`, fixedData, { pageId, viewName });
          setData(fixedData);
        }

        setIsLoading(false);
      }, 0);
      return;
    }

    try {
      // Set loading state
      setIsLoading(true);
      // Determine the cursor for the request
      let _currentCursor = "";
      if (!isRefetchOperation) {
        _currentCursor = withInfiniteQuery
          ? paginationInfoForElement?.nextCursor
          : isPaginated || currentCursorParam
          ? currentCursorParam
          : paginationInfoForElement?.currentCursor;
      }

      // Construct the URL and replace placeholders
      url = replaceBaseUrl(url, currentApp);
      url = replaceDataPlaceholders({
        queryString: url,
        item: selectedItem,
        viewsState,
        pageId,
        __data,
        pagination,
        env: currentApp?.env,
        cursor: _currentCursor,
        fallback: "",
      });
      url = removeEmptyQueryParams(url);
      // url = encodeURLParams(url);
      let params = {};
      if (!url.includes("limit")) {
        params = { ...params, limit };
      }
      if (!url.includes("cursor")) {
        params = { ...params, cursor: _currentCursor };
      }
      params = { ...params, skipAuthErrorClear: isHostAvailable };

      // Make the request
      const response = await externalAxiosServices.request({
        method: (dataSource?.payload?.method || "GET") as Method,
        url,
        params: params,
        headers: requestHeaders,
        data: replaceDataPlaceholdersRecursively({
          obj: JSON.parse(dataSource?.payload?.body || "{}"),
          viewsState,
          pageId,
          __data,
          pagination,
          env: currentApp?.env,
          cursor: _currentCursor,
        }),
      });

      const newData = response.data;
      let items;
      if (Array.isArray(newData)) {
        items = newData;
      } else {
        items = _.get(newData, dataSource?.dataEntry || "items", []);
      }
      setHasNextPage(_.get(newData, (dataSource?.hasMoreKey as PropertyPath) || "hasMore"));

      const paginationResult = {
        currentCursor: _currentCursor,
        nextCursor: _.get(newData, (dataSource?.cursorKey as PropertyPath) || "cursor"),
        hasMore: _.get(newData, (dataSource?.hasMoreKey as PropertyPath) || "hasMore"),
        length: items.length,
        isRefetchOperation: isRefetchOperation,
      };

      if (isPaginated && items.length === 0 && isPaginationBar && !withInfiniteQuery) {
        setValue(`${targetKey}._pagination`, paginationResult, { viewName, pageId });
        return;
      }

      resolvedMessage = resolveMessageOfDataSource(response.status);

      const dataSourceState = {
        _body: newData,
        _status: response.status,
        _headers: response.headers,
        _message: resolvedMessage,
        _pagination: paginationResult,
      };

      // Handle infinite scroll or pagination
      if (!withInfiniteQuery) {
        // Replace the data with the current page's data (pagination case)
        setValue(targetKey, dataSourceState, { pageId, viewName });
        setData(newData); // Only set the current page data
      } else {
        // Infinite scroll: append the new data to existing data or reset the pages list if refetch operation
        const existingData = _.isNil(data) ? [] : data;
        const key: any = dataSource?.dataEntry;
        let combinedData;

        if (Array.isArray(newData)) {
          combinedData = isRefetchOperation ? newData : [...existingData, ...newData];
        } else {
          const existingItems = _.get(existingData, dataSource?.dataEntry as PropertyPath, []);
          const combinedItems = isRefetchOperation ? items : [...existingItems, ...items];

          combinedData = {
            ...newData,
            [key]: combinedItems,
          };
        }
        dataSourceState._body = combinedData;
        setValue(targetKey, dataSourceState, { pageId, viewName });
        setData(combinedData); // Append new data
      }
      // change to watch the response value for condation on action config "clean the state"
      setValue(`${pageId}.${viewName}.response`, {}, { pageId, viewName });
      // Update pagination info

      return paginationResult;
    } catch (error) {
      resolvedMessage = resolveMessageOfDataSource((error as any).status);

      const errorState = {
        _body: error,
        _status: (error as any).status,
      };
      // change to watch the response value for condation on action config ""fill the state"

      if (viewName && error) {
        setValue(`${pageId}.${viewName}.response`, errorState, { pageId, viewName });
      }

      isError = true;
      console.error("Error fetching data", error);
    } finally {
      setIsLoading(false);
    }
  };

  //Interface method for fetching data as refresh or with user input query and resetting cursor
  const refetchDataSource = async () => {
    await fetchData(true);
  };

  //Interface method for fetching data within an infinite scroll context or pagination
  const fetchNextPage = async (currentCursor?: any, isPaginated?: boolean, isPaginationBar?: boolean) => {
    const paginationResult = await fetchData(false, currentCursor, isPaginated, isPaginationBar);
    return paginationResult;
  };

  //TODO: Fetch data on load if enabled
  useEffect(() => {
    if (!isGraphQL && dataSource?.sourceType !== "NONE") {
      //Initial load of data
      fetchData(false);
      const firstCallRecords = watch(elementKey);
      setFirstLoadOfData(firstCallRecords); //Track if the system data is empty or not
    }
  }, [dataSourceMapKey]);

  if (isGraphQL && apolloMethod === "MUTATION") {
    return { mutateData };
  } else {
    //Expose these methods for data source management
    return {
      data,
      fetchNextPage,
      isFetching: isLoading,
      hasNextPage,
      refetchDataSource,
      firstLoadOfData,
      isError,
    };
  }
};
