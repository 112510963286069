import _ from "lodash";
import { ComponentItemType } from "src/views/pages/BuildX/FormBuilder/types";
import { BxPatterns } from "./BxPatterns";
import { defaultValidationMessages } from "./defaultRules";

export const validationRules = (element: any, replaceDataPlaceholders: any) => {
  const componentProps = element?.props;

  const isStepValid = (value: number, enabled: boolean, step: number) => !enabled || (step > 0 && value % step === 0);

  const validateDate = (value: any, unit: string, enabled: boolean, step: number, customErrorMessage: string): any => {
    if (element?.type === ComponentItemType.DateTimePicker) {
      const date = new Date(value?.utcISO);
      if (value?.utcISO && date instanceof Date && !isNaN(date.getTime()) && !isStepValid(date[`get${unit}`](), enabled, step)) {
        return customErrorMessage || defaultValidationMessages.invalidDate;
      }
    }
    return true;
  };

  const validateStartOrEndDate = (
    value: any,
    configDate: string,
    nowCondition: boolean,
    comparisonFn: (date: Date, compareTo: Date) => boolean,
    customErrorMessage: string
  ): any => {
    if (element?.type === ComponentItemType.DateTimePicker) {
      const configDateValue = element?.config?.[configDate];
      const date = new Date(value?.utcISO);
      if (
        value?.utcISO &&
        ((nowCondition && comparisonFn(date, new Date())) ||
          (!nowCondition && configDateValue && comparisonFn(date, new Date(configDateValue))))
      ) {
        return customErrorMessage || defaultValidationMessages.invalidDate;
      }
    }
    return true;
  };

  return {
    required: (value: any) => {
      if (componentProps?.required && (value === null || value === undefined || value === "")) {
        return componentProps?.customRequiredMessageContent || defaultValidationMessages.required;
      }
      return true;
    },
    min: (value: any) => {
      if (componentProps?.minValue && value < componentProps?.minValue && componentProps?.type === "Number" && value !== "") {
        return (
          componentProps?.customNumberMessageContent || defaultValidationMessages.min(componentProps?.minValue, componentProps?.maxValue)
        );
      }
      return true;
    },
    max: (value: any) => {
      if (componentProps?.maxValue && value > componentProps?.maxValue && componentProps?.type === "Number" && value !== "") {
        return (
          componentProps?.customNumberMessageContent || defaultValidationMessages.max(componentProps?.maxValue, componentProps?.minValue)
        );
      }
      return true;
    },
    minLength: (value: any) => {
      if (
        componentProps?.stringValidation &&
        componentProps?.minLength &&
        value.length < componentProps?.minLength &&
        componentProps?.type === "Text"
      ) {
        return componentProps?.customStringMessageContent || defaultValidationMessages.minLength(componentProps?.minLength);
      }
      return true;
    },
    maxLength: (value: any) => {
      if (
        componentProps?.stringValidation &&
        componentProps?.maxLength &&
        value.length > componentProps?.maxLength &&
        componentProps?.type === "Text"
      ) {
        return componentProps?.customStringMessageContent || defaultValidationMessages.maxLength(componentProps?.maxLength);
      }
      return true;
    },
    regex: (value: any) => {
      if (componentProps?.isRegex && !new RegExp(componentProps?.textFieldRegex).test(value) && componentProps?.type === "Text") {
        return componentProps?.customRegexMessageContent || defaultValidationMessages.shouldBeRegex;
      }
      return true;
    },

    shouldBeNumber: (value: any) => {
      if (componentProps?.numberValidation && isNaN(parseFloat(value)) && componentProps?.type === "Number" && value !== "") {
        return componentProps?.customNumberMessageContent || defaultValidationMessages.shouldBeNumber;
      }
      return true;
    },
    shouldBeEmail: (value: any) => {
      if (componentProps?.emailValidation && !BxPatterns.emailPattern.test(value) && componentProps?.type === "Email") {
        return componentProps?.customEmailMessageContent || defaultValidationMessages.shouldBeEmail;
      }
      return true;
    },
    shouldBePassword: (value: any) => {
      if (componentProps?.passwordValidation && componentProps?.type === "Password") {
        const { includeLowercase, includeUppercase, includeNumbers, includeSymbols, minLengthPassword } = componentProps;

        if (minLengthPassword && value.length < minLengthPassword) {
          return componentProps?.customPasswordMessageContent || defaultValidationMessages.shouldBePassword.minLength(minLengthPassword);
        }

        if (includeLowercase && !/[a-z]/.test(value)) {
          return componentProps?.customPasswordMessageContent || defaultValidationMessages.shouldBePassword.includeLowercase;
        }
        if (includeUppercase && !/[A-Z]/.test(value)) {
          return componentProps?.customPasswordMessageContent || defaultValidationMessages.shouldBePassword.includeUppercase;
        }
        if (includeNumbers && !/[0-9]/.test(value)) {
          return componentProps?.customPasswordMessageContent || defaultValidationMessages.shouldBePassword.includeNumbers;
        }
        if (includeSymbols && !/[!@#$%^&*]/.test(value)) {
          return componentProps?.customPasswordMessageContent || defaultValidationMessages.shouldBePassword.includeSymbols;
        }
      }
      return true;
    },
    shouldBeUrl: (value: any) => {
      if (value !== "" && componentProps?.urlValidation && componentProps?.type === "URL") {
        const { enableHttpValidation, enableHttpsValidation } = componentProps;

        if (
          (enableHttpValidation && BxPatterns.httpPattern.test(value)) ||
          (enableHttpsValidation && BxPatterns.httpsPattern.test(value))
        ) {
          return true;
        }
        return componentProps?.customUrlMessageContent || defaultValidationMessages.shouldBeUrl;
      }
      return true;
    },
    shouldBeUUID: (value: any) => {
      if (componentProps?.uuidValidation && !BxPatterns.uuidPattern.test(value) && componentProps?.type === "UUID") {
        return componentProps?.customUUidMessageContent || defaultValidationMessages.shouldBeUUID;
      }
      return true;
    },
    shouldMatch: (value: any) => {
      if (componentProps?.matchValidation) {
        const matchValue = replaceDataPlaceholders(componentProps?.matchValue);

        if (!_.isEqual(value, matchValue)) {
          return componentProps?.customMatchMessageContent || defaultValidationMessages.shouldMatch(matchValue);
        }
      }
      return true;
    },
    shouldBeColor: (value: any) => {
      if (element?.type === ComponentItemType.ColorPicker) {
        if (!value?._actualHexColor && componentProps?.required) {
          return componentProps?.customRequiredMessageContent || defaultValidationMessages.required;
        }

        if (!BxPatterns.hexColorPattern.test(value?._actualHexColor) && (componentProps?.colorPickerInvalid ?? true)) {
          return componentProps?.customColorMessageContent || defaultValidationMessages.shouldBeColor;
        }
      }

      return true;
    },
    shouldBeDateTime: (value: any) => {
      if (element?.type === ComponentItemType.DateTimePicker) {
        if (!value?.userLocalISO && !value?.utcISO && !value?.epoch && componentProps?.required) {
          return componentProps?.customRequiredMessageContent || defaultValidationMessages.required;
        }
      }
    },
    shouldBeFileUploader: (value: any) => {
      if (element?.type === ComponentItemType.FileUploadInput) {
        if (!value?.key && componentProps?.required) {
          return componentProps?.customRequiredMessageContent || defaultValidationMessages.required;
        }
      }
      return true;
    },
    isEmptyString: (value: any) => {
      if (componentProps?.required && typeof value === "string" && value.trim().length === 0) {
        return componentProps?.customRequiredMessageContent || defaultValidationMessages.required;
      }
      return true;
    },
    isValidDateTimeSecondsStep: (value: any) =>
      validateDate(
        value,
        "Seconds",
        element?.config?.secondsEnabled,
        element?.config?.secondsStep,
        componentProps?.customErrorMessageSecondsStep
      ),

    isValidDateTimeMinutesStep: (value: any) =>
      validateDate(
        value,
        "Minutes",
        element?.config?.minutesEnabled,
        element?.config?.minutesStep,
        componentProps?.customErrorMessageMinutesStep
      ),

    isValidDateTimeHoursStep: (value: any) =>
      validateDate(value, "Hours", element?.config?.hoursEnabled, element?.config?.hoursStep, componentProps?.customErrorMessageHoursStep),

    isValidDateTimeEndDate: (value: any) =>
      validateStartOrEndDate(
        value,
        "endDate",
        element?.config?.endsNow,
        (date, compareTo) => date > compareTo,
        componentProps?.customErrorMessageEndDate
      ),

    isValidDateTimeStartDate: (value: any) =>
      validateStartOrEndDate(
        value,
        "startDate",
        element?.config?.startsNow,
        (date, compareTo) => date < compareTo,
        componentProps?.customErrorMessageStartDate
      ),
    isValidFileUploader: (value: any) => {
      if (element?.type !== ComponentItemType.FileUploadInput || componentProps?.fileValidation === false || !value?.url) {
        return true;
      }

      const uploadConfig = element?.config?.uploadConfig;
      const fileExtension = value?.ext?.toLowerCase();
      const errorMessage = componentProps?.customFileMessageContent || defaultValidationMessages.fileUploader;

      if (!fileExtension) {
        return errorMessage;
      }

      if (uploadConfig?.customEnabled) {
        const allowedCustomExtensions = uploadConfig.customFileExtensions || "";
        if (!allowedCustomExtensions.replace("svg", "svg+xml").includes(fileExtension)) {
          return errorMessage;
        }
      } else {
        const allowedExtensions = uploadConfig?.uploadAllowedTypes || [];
        const allowedExtensionsMap: Record<string, string[]> = {
          Image: ["jpg", "jpeg", "png", "gif", "bmp", "svg", "webp", "tiff", "heif", "raw", "svg+xml"],
          Audio: ["mp3", "wav", "ogg", "flac", "aac", "m4a", "wma", "alac", "aiff"],
          Video: ["mp4", "mov", "avi", "mkv", "webm", "flv", "wmv", "mpeg", "mpg", "3gp"],
        };
        const isAllowed = allowedExtensions.some(type => allowedExtensionsMap[type]?.includes(fileExtension));

        if (!isAllowed) {
          return errorMessage;
        }
      }

      return true;
    },
  };
};
