import { FC, useEffect } from "react";
import { useWindowSize } from "src/hooks/useScreenSize";

// interface FormBuilderListenerProps {
//   handleDeleteItem: (ids: string[]) => void;
//   handleCopyItems: (activeComponentId: string | undefined, selectedItemsId: string[]) => void;
//   handlePasteItems: () => void;
//   handleKeyCodeChange: (newKeyCode: string | undefined) => void;
//   componentId: string[];
//   boxItemRefs: any;
// }
const WindowSize: FC<any> = ({ boxRef, boxSizeRef }) => {
  const { width, scrollX, scrollY } = useWindowSize();

  useEffect(() => {
    const boxDimensions = boxRef.current?.getBoundingClientRect();
    const _boxSize = {
      width: boxDimensions?.width,
      height: boxDimensions?.height,
      y: boxDimensions?.y - scrollX,
      x: boxDimensions?.x - scrollX,
    };
    boxSizeRef.current = _boxSize;
  }, [scrollX, scrollY, boxRef]);

  return null;
};

export default WindowSize;
