/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable no-loop-func */
import { gql } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { AddressElement, CardCvcElement, CardExpiryElement, CardNumberElement, PaymentElement } from "@stripe/react-stripe-js";
import { IconX } from "@tabler/icons-react";
import _, { isNumber } from "lodash";
import { SnackbarContent } from "notistack";
import Papa from "papaparse";
import { FC, useState } from "react";
import { osVersion } from "react-device-detect";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { isHostAvailable } from "src/App";
import { BXEngine } from "src/BXEngine";
import { useBuildxContext } from "src/BXEngine/BuildxContext";
import { BXIcon } from "src/components/BXUI/Icon";
import config, { appVersion } from "src/config";
import { useAppState } from "src/features/appState/hooks";
import { getReferenceLevel } from "src/features/appState/utils";
import { setProviderState } from "src/features/buildxProvider/buildxProviderSlice";
import { apolloClient, encodeURLParams, replaceBaseUrl } from "src/features/buildxProvider/buildxProviderUtils";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import { setEndUser } from "src/features/endUser/endUserSlice";
import { selectComponentById } from "src/features/endUser/selectors";
import useAuth from "src/hooks/useAuth";
import store from "src/store/store";
import { UIElement } from "src/types/UIElement";
import { closeSnackbarRef, enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import axiosServices from "src/utils/axios";
import extAxiosServices from "src/utils/axiosExt";
import { getAuth, loginToApp, logoutOfApp, registerAppDevice } from "src/utils/buildxProviderOperations";
import {
  getAuthorizationHeader,
  getLastKeyFromObject,
  handleCustomMessage,
  handleRefetchQueries,
  reverseDataOfObjectValues,
} from "src/utils/generalUtils";
import { actionTypes } from "src/views/pages/BuildX/AppBuilder/forms/CreateViewForm";
import { getItemClosestProp, getSharedViews, updateStepperGroupIndex } from "src/views/pages/BuildX/FormBuilder/utils";
import { v4 as uuid } from "uuid";
import * as XLSX from "xlsx";
import { BXConfirmationDialog } from "../AlertDialog/ConfirmationDialog";
import { triggerActionableComponent } from "../FormBuilder/utils";
import BXModal from "../Modal";
import { ViewerModal } from "../viewerModal";
import CSVProgressDialog from "./CSVProgressDialog";
import CreatePayload from "./CreatePayload";

//Predefined Properties for responses.
const BXPredefinedSyntaxProperties = new Set(["_status", "_body", "_headers", "_messages", "_message", "_pagination", "_request"]);

//Data or Response JSON Path handling
function extractPropertyForSyntax(pathName: string) {
  // Regular expression to match patterns like .data/response[<number>].property or .data/response.property
  const regex = /(?:^|\W)(data(?:\[\d+\])?|responses(?:\[\d+\])?|response)\.(\w+)/;

  const match = pathName.match(regex);
  if (match) {
    const prefixPath = match[1];
    const property = match[2];

    return { prefixPath, property };
  }

  return null;
}

//Extract the corresponding property value based on current breakpoint
function extractResponsiveProperty(prop, breakpoint) {
  const breakpoints = ["xl", "lg", "md", "xs"];
  if (typeof prop === "object" && prop !== null) {
    // If the property contains breakpoint keys, resolve the value
    if (breakpoints.some(key => key in prop)) {
      if (prop[breakpoint] !== undefined) {
        return prop[breakpoint];
      }

      if (prop.lg !== undefined) {
        return prop.lg;
      }

      return getItemClosestProp(prop, breakpoint);
    }
  }

  return prop;
}

export const useReplaceDataPlaceholders = (props?: any) => {
  const { viewName } = props || {};
  const viewsState = useBuildxProviderValue("viewsState");
  const queriesStateGraphQL = useBuildxProviderValue("queriesStateGraphQL");
  const currentBreakpoint = useBuildxProviderValue("currentBreakpoint");

  const { getValues, setValue, watch, getValue, removeValue, mergeValue } = useAppState();

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  location.pathname = window.location.pathname;
  location.state = location.state;
  const searchParams = new URLSearchParams(location.search);
  const queryParams = {};
  searchParams.forEach((value, key) => {
    queryParams[key] = value;
  });

  const replaceDataPlaceholdersProps = {
    queriesStateGraphQL,
    viewsState,
    viewName,
    getValues,
    getValue,
    setValue,
    removeValue,
    mergeValue,
    watch,
    navigate,
    location: {
      params,
      queryParams,
      pathname: location.pathname,
      host: window.location.host,
      url: window.location.href,
      state: location.state,
    },
    currentBreakpoint,
  };

  return {
    replaceDataPlaceholders: props => replaceDataPlaceholders({ ...props, ...replaceDataPlaceholdersProps }),
    replaceDataPlaceholdersRecursively: props => replaceDataPlaceholdersRecursively({ ...props, ...replaceDataPlaceholdersProps }),
    updateDataPlaceholders: props => updateDataPlaceholders({ ...props, ...replaceDataPlaceholdersProps }),
  };
};

export const updateDataPlaceholders = (props: any) => {
  const {
    queryString,
    pageId,
    getValue,
    setValue,
    removeValue,
    index: itemIndex,
    viewName,
    $action,
    $payload,
    navigate,
    location,
    currentBreakpoint,
  } = props;

  let result = queryString;

  const deleteRepeatedStateRecursively = (children, pageId, preDefinedVariable, deletedIndex) => {
    children.forEach(child => {
      const childElement = selectComponentById(store.getState(), pageId, preDefinedVariable, child);
      const stateName = `${pageId}.${preDefinedVariable}.${child}.state[${deletedIndex}]`;
      const initialValueName = `${pageId}.${preDefinedVariable}.${child}.initialValue[${deletedIndex}]`;
      const errorName = `${pageId}.${preDefinedVariable}.${child}.errors.error[${deletedIndex}]`;
      const hasErrorName = `${pageId}.${preDefinedVariable}.${child}.errors.hasError[${deletedIndex}]`;
      const errorListName = `${pageId}.${preDefinedVariable}.${child}.errors.list[${deletedIndex}]`;

      removeValue(stateName, { pageId, viewName });
      removeValue(initialValueName, { pageId, viewName });
      removeValue(errorName, { pageId, viewName, repeatedComponentIndex: deletedIndex });
      removeValue(hasErrorName, { pageId, viewName });
      removeValue(errorListName, { pageId, viewName });

      if (childElement && Array.isArray(childElement.children) && childElement.children.length > 0) {
        deleteRepeatedStateRecursively(childElement.children, pageId, preDefinedVariable, deletedIndex);
      }
    });
  };

  if (typeof queryString === "boolean" || typeof queryString === "number") return queryString;
  const placeholders = queryString?.match(/\{(.*?)\}/g);

  placeholders?.forEach(function (placeholder: any) {
    let phText = "";

    const index = placeholder.split("").findIndex((key: string) => key == ".");
    phText = placeholder.substring(index + 1, placeholder.length - 1);

    let preDefinedVariable = placeholder.substring(1, index);
    if (preDefinedVariable === "this") {
      preDefinedVariable = viewName;
    }
    let deletedIndex: null | number = null;
    phText = phText.replace(/\[\*\s*([+-]?\d+)?\]/g, function (match, number, offset) {
      let indexAdjustment = number ? parseInt(number, 10) : 0;
      let adjustedIndex = itemIndex + indexAdjustment;
      deletedIndex = adjustedIndex;
      return `[${adjustedIndex}]`;
    });

    let key = `${pageId}.${preDefinedVariable}.${phText}`;

    if ($action === "DELETE") {
      if (preDefinedVariable == "$global") {
        if (phText.startsWith("localStorage.")) {
          phText = phText.replace("localStorage.", "");
          localStorage.removeItem(phText);
        }
      } else {
        if (phText.includes("repeatedState")) {
          const keyRepeated = phText.split(".")[0];
          const parentComp = selectComponentById(store.getState(), pageId, preDefinedVariable, keyRepeated);
          deleteRepeatedStateRecursively(parentComp.children, pageId, preDefinedVariable, deletedIndex);
        }
        removeValue(key, { pageId, viewName });
      }
    } else if ($action === "ADD") {
      const data = [...(getValue(key, { pageId, viewName }) || [])];
      if (key.split(".").pop() === "state") {
        const [pageId, viewName, componentKey, ..._rest] = key.split(".") || [];
        const viewState = _.cloneDeep(getValue(`${pageId}.${viewName}`, { pageId, viewName }));
        const baseComponent = _.get(viewState, [componentKey]);
        const baseElement = _.get(baseComponent, ["baseElement"]);
        if (baseElement) {
          if (Array.isArray(data)) {
            const baseKey = `${componentKey}.state[${data.length}]`;
            Object.keys(baseElement).forEach(childKey => {
              if (childKey !== componentKey) {
                const childParent = _.clone(_.get(baseElement, [childKey, "parent"]));
                const newParentPath = `${baseKey}.${childParent}`;
                _.set(baseElement, [childKey, "parent"], _.clone(newParentPath));
              }
              const childPath = [childKey, "props", "basePath"];
              _.set(baseElement, childPath, _.clone(baseKey));
            });
            data.push(baseElement);
          }
          setValue(`${key}`, data, { pageId, viewName });
        }
      } else {
        if (Array.isArray(data)) {
          data.push(uuid());
        }
        setValue(`${key}`, data, { pageId, viewName });
      }
    } else if ($action === "UPDATE") {
      if (preDefinedVariable === "$app") {
        setValue(`application.${phText}`, $payload, { pageId, viewName });
      } else if (preDefinedVariable == "$global") {
        if (phText.startsWith("location.state")) {
          const currentState = typeof location.state === "object" && location.state !== null ? location.state : {};
          phText = phText.replace("location.state", "");
          if (phText) {
            phText = phText.startsWith(".") ? phText.slice(1) : phText;
            navigate(location.pathname, {
              state: {
                ...currentState,
                [phText]: $payload,
              },
            });
          } else {
            navigate(location.pathname, {
              state: $payload,
            });
          }
        } else if (phText.startsWith("localStorage.")) {
          phText = phText.replace("localStorage.", "");
          if (typeof $payload == "object") {
            localStorage.setItem(phText, JSON.stringify($payload));
          } else {
            localStorage.setItem(phText, $payload);
          }
        }
      } else {
        //Resolve responsive style or rendering property path
        if (key.includes(".props.") || key.includes(".config.")) {
          key = `${key}.${currentBreakpoint}`;
        }
        setValue(`${key}`, $payload, { pageId, viewName, reEvaluateErrorsAndDirty: false });
      }
    }
  });

  return result;
};

function concat(...args) {
  return args.join("");
}

function urlEncode(input) {
  return encodeURIComponent(input);
}

function contrast(hexColor1: string, hexColor2: string): number {
  const Color = require("color");
  const backgroundColor = Color(hexColor1);
  const fontColor = Color(hexColor2);

  const contrastRatio = backgroundColor.contrast(fontColor);
  return contrastRatio;
}

function buildLinks(texts: string[], urls: string[], separator: string = ""): string {
  const anchors = texts.map((text, index) => {
    if (!text) return "";
    return urls?.[index] ? `<a href="${urls[index]}">${text}</a>` : text;
  });
  return anchors.filter(Boolean).join(separator);
}

function join(array, delimiter) {
  return array.join(delimiter);
}

function evaluateExpression(expression) {
  if (typeof expression !== "string") return expression;

  const operations = {
    "=concat": concat,
    "=urlEncode": urlEncode,
    "=contrast": contrast,
    "=buildLinks": buildLinks,
    "=join": join,
    "=eval": eval,
  };

  const evalExpr = expr => {
    for (const [prefix, func] of Object.entries(operations)) {
      if (expr.startsWith(prefix)) {
        return eval(expr.replace(prefix, func.name));
      }
    }
    return expr;
  };

  expression = expression.replace(/\{=(.*?)\}/g, (_, exprContent) => {
    try {
      return evalExpr(`=${exprContent}`);
    } catch {
      return "";
    }
  });

  if (expression.startsWith("=")) {
    try {
      return evalExpr(expression);
    } catch {
      return "";
    }
  }

  return expression;
}

export const replaceDataPlaceholders = (props: any) => {
  const {
    queryString,
    item = {},
    viewsState,
    watch,
    watchError,
    getDirty,
    getValue,
    setValue,
    pageId,
    formData,
    __data = {},
    fallback,
    actionResponse,
    dnd,
    env,
    pagination,
    multiLingual,
    validationRules,
    index: itemIndex,
    actionIndex,
    queriesStateGraphQL,
    viewName,
    location,
    selector,
    keyComp,
    fromParent,
    dataEntry,
    currentBreakpoint,
    actions,
    parentDataIndex,
    appId,
    tableAction,
    _key,
    canWatch,
    ...rest
  } = props;

  let result = queryString;

  let customLocalStorage: any = {};
  for (let i = 0; i < localStorage.length; i++) {
    let key: any = localStorage.key(i);
    let value = localStorage.getItem(key);
    if (key === `${appId}-accessToken-device`) {
      key = "deviceAccessToken";
    }

    customLocalStorage[key] = value;
  }
  const buildx = { appVersion: appVersion };
  const global = {
    location,
    env: {
      ...env?.config?.variables,
      ...env?.upConfig?.variables,
    },
    browser: {
      deviceType: config.deviceType,
      osVersion: osVersion,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    localStorage: customLocalStorage,
    breakpoint: currentBreakpoint,
  };

  const mapObj = {
    data: item,
    pagination,
    actionResponse,
    dnd,
    actions,
    ...rest,
  };

  if (typeof queryString === "boolean" || typeof queryString === "number" || queryString == undefined) return queryString;

  if (queryString.includes("{$.")) {
    result = result.replace(/\$/g, selector);
  }

  if (queryString.includes("{@.")) {
    result = result.replace(/\@/g, "this.actions[*].rows[*]");
  }

  if (queryString?.endsWith(".response}")) {
    result = result.replace(".response}", ".response._body}");
  }
  let flagTest = true;

  const resolvePlaceholders = (str: any): any => {
    const placeholderRegex = /\{\{(?!\=)([^{}]+?)\}\}|\{(?!\=)([^{}]+?)\}/g;
    const placeholderRegexTest = /\{\{(?!\=)([^{}]+?)\}\}|\{(?!\=)([^{}]+?)\}/g;
    let match;
    const processedPlaceholders = new Map();
    const memoizedResolved = new Map();
    let finalResult = str;
    if (!placeholderRegexTest.test(str) && flagTest && canWatch) {
      watch(str, { pageId, viewName });
    }
    while ((match = placeholderRegex.exec(str)) !== null) {
      let placeholder = match[0]; // {this}
      flagTest = false;

      if (memoizedResolved.has(placeholder)) {
        const replacedValue = memoizedResolved.get(placeholder);
        str = str.replace(placeholder, replacedValue);
        finalResult = finalResult.replace(placeholder, replacedValue);
        placeholderRegex.lastIndex = 0;
        if (processedPlaceholders.get(placeholder) === str) {
          return finalResult;
        }
        continue;
      }

      let phText = "";
      let data;

      const index = placeholder.split("").findIndex((key: string) => key == ".");
      phText = placeholder.substring(index + 1, placeholder.length - 1);

      data = mapObj;
      if (placeholder.startsWith("{i18n.")) {
        const translationKey = phText;
        const translationObject = multiLingual?.translations?.find((t: any) => t.key === translationKey);

        const defaultLanguage = multiLingual?.selectedLanguage;
        const selectedLanguage = defaultLanguage ? defaultLanguage : "";

        data = translationObject?.allLanguages;
        phText = selectedLanguage;
      } else if (placeholder.startsWith("{{")) {
        phText = placeholder.substring(2, placeholder.length - 2);
        data = global.env;
        if (!_.has(data, phText)) {
          str = str.replace(placeholder, `{${phText}}`);
          finalResult = finalResult.replace(placeholder, `{${phText}}`);
          placeholderRegex.lastIndex = 0;
          continue;
        }
      } else if (placeholder.startsWith("{buildxLogin")) {
        data = data.login;
      } else if (placeholder.startsWith("{$global")) {
        data = global;

        phText = phText.replace("{$global.", "");
        if (placeholder.includes(".")) {
          phText = placeholder.substring(index + 1, placeholder.length - 1);
        } else {
          phText = "";
        }
      } else if (placeholder.startsWith("{$buildx")) {
        data = buildx;

        phText = phText.replace("{$buildx.", "");
        if (placeholder.includes(".")) {
          phText = placeholder.substring(index + 1, placeholder.length - 1);
        } else {
          phText = "";
        }
      } else if (placeholder.startsWith("{this.actionResponse")) {
        data = actionResponse;
        let newText = placeholder.replace("{this.actionResponse.", "");
        phText = newText.replace("}", "");
      } else {
        let selectedViewName = viewName;

        if (placeholder.startsWith("{#.")) {
          const hashes = Object.keys(__data)?.reverse();
          phText = placeholder.substring(1, placeholder.length - 1);

          const currentHash = hashes?.find(hash => phText.startsWith(hash)) as any;

          if (!currentHash) return;

          selectedViewName = reverseDataOfObjectValues(__data)[currentHash];

          phText = phText.replace(currentHash, "");
          data = watch(`${pageId}.${selectedViewName}.selectedItem.${phText}`, { pageId, viewName: selectedViewName });
          str = str.replace(placeholder, data);
          finalResult = finalResult.replace(placeholder, data);
        } else if (!placeholder.startsWith("{this")) {
          selectedViewName = placeholder.substring(1, placeholder.includes(".") ? index : placeholder.length - 1);
        }
        if (queryString.includes(".response._status")) {
          result = getValue(`${pageId}.${viewName}.${_key}.response._status`, { pageId, viewName });
        }

        if (placeholder.includes(".")) {
          phText = placeholder.substring(index + 1, placeholder.length - 1);
        } else {
          phText = "";
        }

        phText = phText.replace(/\[\*\s*([+-]?\d+)?\]/g, function (match, number, offset) {
          const isActionIndex = phText.substring(0, offset).endsWith("actions");
          const isDataIndex = phText.substring(0, offset).endsWith("data._body"); //This is to handle some nested repeated items cases
          let baseIndex = itemIndex;
          if (isDataIndex) {
            baseIndex = isNumber(parentDataIndex) ? parentDataIndex : itemIndex;
          }

          if (isActionIndex) {
            baseIndex = actionIndex;
          }

          let indexAdjustment = number ? parseInt(number, 10) : 0;
          let adjustedIndex = baseIndex + indexAdjustment;

          return `[${adjustedIndex}]`;
        });

        //Handle access from parent for both data entry and data directly (e.g: {this.data})/
        if (fromParent && dataEntry && phText !== "data") {
          const keyPath = phText.replace("data.", "");

          const dynamicBase = placeholder.split(".")[1];
          phText = `${dynamicBase}.${dataEntry}[${itemIndex}].${keyPath}`;
        }
        let pathName = `${pageId}`;

        if (selectedViewName) {
          pathName = `${pathName}.${selectedViewName}`;
        }
        if (phText) {
          pathName = `${pathName}.${phText}`;
        }

        if (placeholder.startsWith("{$comp")) {
          pathName = `${pageId}.${viewName}.${keyComp}`;
          if (phText) {
            pathName = `${pathName}.${phText}`;
          }
        }

        if (placeholder.startsWith("{$page")) {
          pathName = `${pageId}`;
          if (phText) {
            pathName = `${pathName}.${phText}`;
          }
        }

        if (placeholder.startsWith("{$app")) {
          pathName = `application`;
          if (phText) {
            pathName = `${pathName}.${phText}`;
          }
        }

        const pathProperty = extractPropertyForSyntax(pathName);
        if (pathProperty?.prefixPath && !BXPredefinedSyntaxProperties.has(pathProperty.property)) {
          const pathParts = pathName.split(".");
          pathParts[pathParts.length - 1] = "_body";
          const newPath = pathParts.join(".");
          const dataWithBody = getValue?.(newPath, { pageId, viewName });
          const flagWithIndex = Array.isArray(dataWithBody) ? true : false;

          if (
            (!isNumber(itemIndex) && !isNumber(parentDataIndex)) ||
            fromParent ||
            !_.isEmpty(tableAction?.action) ||
            selector ||
            !flagWithIndex
          ) {
            pathName = pathName.replace(
              `${pathProperty.prefixPath}.${pathProperty.property}`,
              `${pathProperty.prefixPath}._body.${pathProperty.property}`
            );
          } else {
            const correctIndex = isNumber(parentDataIndex) ? parentDataIndex : itemIndex;
            pathName = pathName.replace(
              `${pathProperty.prefixPath}.${pathProperty.property}`,
              `${pathProperty.prefixPath}._body[${correctIndex}].${pathProperty.property}`
            );
          }
        }

        if (pathName.match(/^[^.]+\.[^.]+\.[^.]+\.state\[\d+\]\.[^.]+$/)) {
          pathName += ".state";
        }
        data = getValue?.(pathName, { pageId, viewName });

        if (data?.match?.(placeholderRegex)) {
          data = resolvePlaceholders(data);
        } else {
          data = watch(pathName, { pageId, viewName });
        }

        phText = "";
      }

      phText = phText.replace(/\[\*\s*([+-]?\d+)?\]/g, function (match, number, offset) {
        let indexAdjustment = number ? parseInt(number, 10) : 0;
        let adjustedIndex = itemIndex + indexAdjustment;

        return `[${adjustedIndex}]`;
      });

      let replacedValue: any = null;
      if (phText.includes("[]")) {
        const prefix = phText.substring(0, phText.indexOf("[]"));
        const suffix = phText.substring(phText.indexOf("[].") + 3, phText.length);

        if (_.isArray(_.get(data, prefix))) {
          replacedValue = _.get(data, prefix)?.map(item => _.get(item, suffix));
        }
      } else {
        if (!_.isNil(data) && !phText) {
          replacedValue = data;
        } else {
          let pathParts = phText.split(".");
          let lastPart = pathParts.pop();
          let parentPath = pathParts.join(".");

          let parentData = _.get(data, parentPath);

          if (_.isArray(parentData)) {
            replacedValue = _.map(parentData, lastPart);
          } else {
            replacedValue = !_.isNil(_.get(data, phText)) ? _.get(data, phText) : fallback;
            if (!replacedValue) {
              replacedValue = !_.isNil(_.get(data, `entities.${phText}`)) ? _.get(data, `entities.${phText}`) : fallback;
            }
          }
        }
      }

      //Resolve responsive values
      if (placeholder.includes(".props.") || placeholder.includes(".config.")) {
        replacedValue = extractResponsiveProperty(replacedValue, currentBreakpoint);
      }

      if (_.isNil(replacedValue)) {
        finalResult = str === placeholder ? replacedValue : finalResult.replace(placeholder, replacedValue);
        str = str === placeholder ? replacedValue : str.replace(placeholder, replacedValue);
      } else if (typeof replacedValue === "object") {
        if (str === placeholder) {
          str = replacedValue;
          finalResult = replacedValue;
        } else {
          str = str.replace(placeholder, "");
          finalResult = finalResult.replace(placeholder, JSON.stringify(replacedValue));
        }
      } else {
        str = str.replace(placeholder, replacedValue);
        finalResult = finalResult.replace(placeholder, replacedValue);
      }

      if (processedPlaceholders.get(placeholder) === str) {
        return finalResult;
      } else {
        processedPlaceholders.set(placeholder, str);
      }
      memoizedResolved.set(placeholder, replacedValue);

      placeholderRegex.lastIndex = 0;
    }
    return finalResult;
  };
  result = resolvePlaceholders(result);
  return evaluateExpression(result);
};

export const replaceDataPlaceholdersRecursively = ({
  item,
  viewsState,
  pageId,
  formData,
  __data,
  multiLingual,
  env,
  keyComp,
  obj = {},
  actions,
  appId,
  ...rest
}: any) => {
  Object.keys?.(obj ?? {})?.forEach?.(key => {
    let template = obj?.[key];
    if (typeof obj[key] === "object" && obj[key] !== null) {
      return replaceDataPlaceholdersRecursively({
        obj: obj[key],
        item,
        viewsState,
        pageId,
        env,
        formData,
        multiLingual,
        __data,
        actions,
        appId,
        ...rest,
      });
    }
    obj[key] = replaceDataPlaceholders({
      queryString: template,
      item,
      viewsState,
      pageId,
      formData,
      __data,
      multiLingual,
      env,
      keyComp,
      actions,
      appId,
      ...rest,
    });
  });

  return obj;
};

export const formatJSON = (val = {}) => {
  try {
    const res = JSON.parse(val as any);
    return JSON.stringify(res, null, 2);
  } catch {
    return undefined;
  }
};

export const ActionButton: FC<{
  actionsMap?: any;
  action?: any;
  pageId?: any;
  onStateChange?: (newState: string) => void;
  disabled?: boolean;
  item: any;
  isUserInput?: boolean;
  views?: UIElement[];
  onSelectRow?: any;
  tableId: any;
  handleSubmit?: any;
  iconButton?: boolean;
  formData?: any;
  fullWidth?: boolean;
  variant?: string;
  __data?: any;
  closeModal?: any;
  parentIds?: any;
  isDismissibleView?: string;
  queryKeys?: any[];
  withBorder?: boolean;
  isResetEnabled?: boolean;
  viewName?: string;
  entries?: any;
  path?: string;
  selectedRows?: any;
  _key?: any;
  components?: any;
  index?: any;
  isChainMapped?: any;
  actionsKey?: any;
  interactionConfig?: any;
  tableAction?: any;
  isLoadingForEntireChain?: boolean;
  element?: any;
  stripe?: any;
  elements?: any;
  conditionKeyFromTable?: any;
  currentRepeatedItem?: any;
  dataEntry?: any;
  fromParent?: boolean;
  viewNameOption?: any;
  actionIndex?: number;
  actions?: any;
}> = props => {
  const {
    actionsMap = {},
    onStateChange = () => {},
    disabled = false,
    item,
    isUserInput,
    views,
    onSelectRow,
    pageId,
    tableId,
    handleSubmit,
    formData,
    parentIds,
    iconButton = true,
    fullWidth = false,
    variant = "contained",
    __data = {},
    closeModal = () => {},
    withBorder = true,
    queryKeys = [],
    viewName,
    entries,
    selectedRows,
    path,
    children,
    _key,
    index,
    isChainMapped,
    actionsKey,
    interactionConfig,
    tableAction,
    isLoadingForEntireChain,
    action,
    conditionKeyFromTable,
    element,
    stripe,
    elements,
    currentRepeatedItem,
    dataEntry,
    fromParent,
    viewNameOption,
    actionIndex,
    actions,
  } = props;

  const actionId = tableAction?.action ? _key : element?.id;
  const [open, setOpen] = useState(false);
  const [jsonProgress, setJsonProgress] = useState<any[]>([]);
  const [proceedToNextAction, setProceedToNextAction] = useState<any>({});
  const [actionModal, setActionModal] = useState<any>();
  const [showModalForAction, setShowModalForAction] = useState<boolean>(false);

  const [isViewActionTriggered, setIsViewActionTriggered] = useState<boolean>(false);
  const [actionView, setActionView] = useState<any>();
  const [selectedView, setSelectedView] = useState<any>();
  const allowedApps = useBuildxProviderValue("allowedApps");
  const isAdministrationMode = useBuildxProviderValue("isAdministrationMode");

  let cancelWorkflow = false;
  let isConfirmationDenied = false;
  let dirtyConfirmationDenied = false;
  let validationError = false;
  let currentResponse = null;

  const { palette } = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { resetState, removeDirty, watch, setValue, getValue, clearValidation, runAllValidations, produceTrigger } = useAppState();
  const currentApp = useBuildxProviderValue("currentApp");
  const viewsState = useBuildxProviderValue("viewsState");
  const currentProfileId = useBuildxProviderValue("currentProfileId");
  const queriesStateGraphQL = useBuildxProviderValue("queriesStateGraphQL");
  const loadingViews = useBuildxProviderValue("loadingViews");
  const fqdnApp = useBuildxProviderValue("fqdnApp");
  const isConfirmAction = useBuildxProviderValue("isConfirmAction");
  const actionConfirmControl = useBuildxProviderValue("actionConfirmControl");
  const appTokens = useBuildxProviderValue("appTokens");
  const appProfiles = useBuildxProviderValue("appProfiles");
  const { appRoutesMap } = useBuildxContext();

  const { setUserAuth, loginSuccess } = useAuth();
  const dispatch = useDispatch();

  const { replaceDataPlaceholders, replaceDataPlaceholdersRecursively, updateDataPlaceholders } = useReplaceDataPlaceholders(
    viewNameOption ?? { viewName: viewName }
  );

  const [isLoading, setIsLoading] = useState(false);

  //Condition to evaluate for action button in table
  const condition = replaceDataPlaceholders({
    queryString: tableAction?.condition,
    item,
    viewsState,
    pageId,
    __data,
    env: currentApp?.env,
    selectedRows,
    selector: dataEntry ? `this.data.${dataEntry}[*]` : `this.data._body[*]`,
    index,
    actionIndex,
    actions,
  });

  try {
    if (!eval(condition) && !_.isEmpty(condition)) return <></>;
  } catch (error) {
    return <></>;
  }

  const checkUnprotected = () => {
    const currentLocation = window.location.pathname;

    if (appRoutesMap.current.hasOwnProperty(currentLocation)) {
      return appRoutesMap.current[currentLocation]?.isUnprotected;
    }

    return false;
  };

  const handleOnSuccess = async (action, response, variables) => {
    const { data, request, status, headers } = response;
    const currentLoadingViews = store.getState().buildxProvider.loadingViews;
    const updatedLoadingViews = { ...currentLoadingViews };
    updatedLoadingViews[`${pageId}-${viewName}`] = false;
    dispatch(
      setProviderState({
        loadingViews: updatedLoadingViews,
      })
    );
    if (_key) {
      currentResponse = { ...data, requestStatusCode: status };
    }
    if (tableAction?.action && action?.isResetEnabled) {
      resetState(`${pageId}.${viewName}`, pageId, viewName as string, "view");
    }

    if (variables?.isCsv) {
      variables?.then?.();
      setJsonProgress(prevProgress =>
        prevProgress.map(item => {
          if (item.id === variables?.csvRow?.id) {
            return { ...item, status: "Success" };
          }
          return item;
        })
      );
      onStateChange("normal");
      return;
    }
    if (_key) {
      const responseState = {
        _body: { ...data },
        _status: status,
        _headers: headers,
      };
      setValue(`${pageId}.${viewName}.${_key}.response`, responseState, { pageId, viewName });
    }

    const findKey = action?.statusMessages?.find((item: any) => item?.key == request?.status);
    const statusMessage = findKey?.value;
    const keyMessage = findKey?.key;
    const statusMessageReplace = replaceDataPlaceholders({
      queryString: statusMessage,
      item,
      viewsState,
      pageId,
      formData,
      __data,
      actionResponse: data,
      env: currentApp?.env,
      selectedRows,
      index,
      actionIndex,
      actions,
    });

    let customMessage = handleCustomMessage(action, keyMessage, statusMessageReplace);

    customMessage = replaceDataPlaceholders({
      queryString: customMessage,
      item,
      viewsState,
      pageId,
      formData,
      __data,
      actionResponse: data,
      env: currentApp?.env,
      selectedRows,
      index,
      actionIndex,
      actions,
    });

    if (action?.isDismissibleView != "No") {
      closeModal?.(data, false);
    }

    if (!_.isEmpty(tableAction?.action)) {
      const responseState = {
        _body: { ...data },
        _status: status,
        _headers: headers,
        _message: customMessage,
      };

      const isActionOnRowLevel = !tableAction?.action.isGlobal;
      const actionResponsePath = isActionOnRowLevel
        ? `${pageId}.${viewName}.actions[${actionIndex}].rows[${index}].response`
        : `${pageId}.${viewName}.actions[${actionIndex}].response`;

      setValue(actionResponsePath, responseState, { pageId, viewName });
    }

    if (action?.showSnackbar ?? true) {
      enqueueSnackbarRef?.(customMessage || statusMessageReplace || "Posted Successfully", {
        variant: "success",
      });
    }

    const isGraphQL = action?.isGraphQL || false;
    const key = `${pageId}-${viewName}`;
    const viewConfig = views?.find(view => view?.info?.viewName === viewName);
    if (viewConfig && viewConfig.dataSource?.sourceType != "NONE") {
      handleRefetchQueries({
        isGraphQL,
        queriesStateGraphQL,
        key,
        queryKeys,
        produceTrigger,
        pageId,
        viewName,
      });
    }

    if (parentIds) {
      parentIds.forEach((id: any) => {
        handleRefetchQueries({
          isGraphQL,
          queriesStateGraphQL,
          key,
          queryKeys: [id],
          produceTrigger,
          pageId,
          viewName,
        });
      });
    }

    if (tableAction?.action && action?.refreshActionView) {
      handleRefreshViews(tableAction?.action);
    }

    onStateChange("normal");
    // success: remove item from data
    if (action?.method.toLowerCase() === "delete") {
    } else if (action?.method.toLowerCase() === "post") {
      // update record because it is an item action
    } else if (action?.method.toLowerCase() === "put") {
      // update record because it is an item action
    } else if (action?.method.toLowerCase() === "patch") {
      // update record because it is an item action
    }
  };

  const handleOnError = (action, error, variables) => {
    const currentLoadingViews = store.getState().buildxProvider.loadingViews;
    const updatedLoadingViews = { ...currentLoadingViews };
    updatedLoadingViews[`${pageId}-${viewName}`] = false;
    dispatch(
      setProviderState({
        loadingViews: updatedLoadingViews,
      })
    );
    if (variables?.isCsv) {
      variables?.then?.();
      setJsonProgress(prevProgress =>
        prevProgress.map(item => {
          if (item.id === variables?.csvRow?.id) {
            return {
              ...item,
              status: "Error",
              __error: error,
            };
          }
          return item;
        })
      );
      onStateChange("normal");
      return;
    }
    onStateChange("normal");
    if (_key) {
      const errorState = {
        _body: error,
        _status: (error as any).status,
      };

      setValue(`${pageId}.${viewName}.${_key}.response`, errorState, { pageId, viewName });
    }
    const findKey = action?.statusMessages?.find((item: any) => item?.key == error?.requestStatusCode);
    const statusMessage = findKey?.value;
    const keyMessage = findKey?.key;

    const errorMessage = replaceDataPlaceholders({
      queryString: statusMessage,
      item,
      viewsState,
      pageId,
      formData,
      __data,
      actionResponse: error,
      env: currentApp?.env,
      selectedRows,
      index,
      actionIndex,
      actions,
    });

    let customMessage = handleCustomMessage(action, keyMessage, errorMessage);

    customMessage = replaceDataPlaceholders({
      queryString: customMessage,
      item,
      viewsState,
      pageId,
      formData,
      __data,
      actionResponse: error,
      env: currentApp?.env,
      selectedRows,
      index,
      actionIndex,
      actions,
    });

    if (_key) {
      const errorState = watch(`${pageId}.${viewName}.${_key}.response`, { pageId, viewName });
      setValue(`${pageId}.${viewName}.${_key}.response`, { ...errorState, _message: customMessage }, { pageId, viewName });
    }

    if (!_.isEmpty(tableAction?.action)) {
      const errorState = {
        _body: error,
        _status: (error as any).status,
        _message: customMessage,
      };

      const isActionOnRowLevel = !tableAction?.action.isGlobal;
      const actionResponsePath = isActionOnRowLevel
        ? `${pageId}.${viewName}.actions[${actionIndex}].rows[${index}].response`
        : `${pageId}.${viewName}.actions[${actionIndex}].response`;

      setValue(actionResponsePath, errorState, { pageId, viewName });
    }

    if (action?.showSnackbar ?? true) {
      enqueueSnackbarRef?.(
        customMessage ||
          errorMessage ||
          error?.response?.data?.error ||
          JSON.parse(error?.response?.config?.data || "{}")?.errorMessage ||
          "Wrong Services",
        {
          variant: "error",
        }
      );
    }

    if (error?.requestStatusCode === 401) {
      handleLogoutAction(action);
    }
  };

  const onAction = async (action, variables) => {
    const isGraphQL = action?.isGraphQL || false;
    const currentLoadingViews = store.getState().buildxProvider.loadingViews;
    const updatedLoadingViews = { ...currentLoadingViews };
    updatedLoadingViews[`${pageId}-${viewName}`] = true;
    dispatch(
      setProviderState({
        loadingViews: updatedLoadingViews,
      })
    );
    setIsLoading(true);
    const _appId = currentApp?.id ?? fqdnApp?.id;
    const isUnprotectedPage = checkUnprotected();
    if (fqdnApp && isUnprotectedPage) {
      await registerAppDevice?.(
        _appId as string,
        allowedApps,
        currentApp,
        currentProfileId,
        appProfiles,
        appTokens,
        replaceDataPlaceholdersRecursively,
        (currentApp ?? fqdnApp)?.appConfig?.auth?.deviceApi,
        currentApp ?? fqdnApp
      );
    }

    const { token: appAccessToken } = getAuth(_appId as string, currentProfileId, appProfiles, appTokens, currentApp) || {};

    const accessToken = appAccessToken || localStorage.getItem(_appId + `-${currentProfileId}-accessToken`);
    const deviceToken = localStorage.getItem(`${_appId}-accessToken-device`);

    const token = isUnprotectedPage ? deviceToken : accessToken || deviceToken;

    onStateChange("loading");

    let url = replaceDataPlaceholders({
      queryString: action?.source,
      item,
      viewsState,
      formData,
      pageId,
      __data,
      env: currentApp?.env,
      csvRow: variables?.csvRow,
      index,
      fromParent,
      dataEntry,
      selector: dataEntry ? `this.data.${dataEntry}[*]` : `this.data._body[*]`,
      actionIndex,
      actions,
      tableAction,
    });

    url = encodeURLParams(replaceBaseUrl(url, currentApp ?? fqdnApp));
    let data = undefined;

    const actionBody = isGraphQL ? action?.graphqlVariables : action?.body;

    const processedBody = replaceDataPlaceholdersRecursively({
      obj: JSON.parse(action?.showModal == "Yes" ? actionBody : actionBody || "{}"),
      viewsState,
      pageId,
      item,
      formData,
      __data,
      env: currentApp?.env,
      csvRow: variables?.csvRow,
      selectedRows,
      fallback: null,
      index,
      fromParent,
      dataEntry,
      actionIndex,
      actions,
    });

    const requestHeaders = {
      ...getAuthorizationHeader(currentApp?.appConfig?.auth, token),
      ...replaceDataPlaceholdersRecursively({
        obj: action?.showModal == "Yes" ? action?.headers : action?.headers,
        viewsState,
        pageId,
        item,
        formData,
        __data,
        env: currentApp?.env,
        csvRow: variables?.csvRow,
        selectedRows,
        index,
        fromParent,
        dataEntry,
        actionIndex,
        actions,
      }),
    };

    if (handleSubmit) {
      data = actionBody && processedBody;
    } else {
      data = actionBody && processedBody;
    }
    let response = {} as any;
    try {
      if (isGraphQL) {
        const gqlQuery = gql`
          ${action?.graphqlQuery}
        `;
        const isMutation = action?.graphQLMethod === "MUTATION" && action?.graphqlQuery?.includes("mutation");
        const operationFunction = (isMutation ? apolloClient.mutate : apolloClient.query) as any;
        const operationKey = isMutation ? "mutation" : "query";
        const graphQLContext = {
          uri: url,
          headers: requestHeaders,
        };
        response = await operationFunction({
          [operationKey]: gqlQuery,
          variables: data,
          context: graphQLContext,
        });
        if (variables?.onSuccess) {
          variables?.onSuccess(response?.data, variables);
        } else {
          handleOnSuccess(action, response, variables);
        }
      } else {
        response = await extAxiosServices.request({
          url: url,
          params: {
            skipAuthErrorClear: isHostAvailable,
          },
          method: action?.method?.toLowerCase?.(),
          headers: requestHeaders,
          data,
        });
        if (variables?.onSuccess) {
          variables?.onSuccess(response?.data, variables);
        } else {
          handleOnSuccess(action, response, variables);
        }
      }
    } catch (error) {
      cancelWorkflow = true;
      handleOnError(action, error, variables);
    } finally {
      setIsLoading(false);
      const currentLoadingViews = store.getState().buildxProvider.loadingViews;
      const updatedLoadingViews = { ...currentLoadingViews };
      updatedLoadingViews[`${pageId}-${viewName}`] = false;
      dispatch(
        setProviderState({
          loadingViews: updatedLoadingViews,
        })
      );
    }
  };

  const handleSelectClick = action => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = ".csv, .xlsx";
    fileInput.addEventListener("change", e => handleFileSelect(action, e));
    fileInput.click();
  };

  const handleFileSelect = (action, event: any) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      if (selectedFile.name.endsWith(".csv") || selectedFile.name.endsWith(".xlsx")) {
        convertXlsxAndCsvToJson(action, selectedFile);
      } else {
        console.error("Unsupported file format");
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setJsonProgress([]);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const convertXlsxAndCsvToJson = async (action, file: File) => {
    const duration = action?.duration;

    const delayFactor = ~~duration || 50;

    const maxCalls = ~~action?.maxCalls || 1;

    const handleOpenLogic = async (jsonArray: any[], delayFactor: number) => {
      handleOpen();

      const totalRequests = jsonArray.length - 1;
      let currentIndex = 0;

      let activeThreads = maxCalls;
      const makeRequest = () => {
        let isOpen = false;
        setOpen(prev => {
          isOpen = prev;
          return prev;
        });
        if (!isOpen) return;

        const handleNextRequest = async () => {
          await new Promise(resolve => setTimeout(resolve, delayFactor));
          currentIndex += 1;
          if (totalRequests < currentIndex) {
            activeThreads -= 1;
            if ((activeThreads === 0 && action?.showSnackbar) ?? true) {
              const successMessage = "CSV Processing Completed Successfully!";
              enqueueSnackbarRef?.(successMessage, {
                variant: "success",
              });
            }
            return;
          }
          makeRequest();
        };
        setJsonProgress((prevProgress: any[]) => [jsonArray[currentIndex], ...prevProgress]);
        onAction(action, { csvRow: jsonArray[currentIndex], isCsv: true, then: handleNextRequest });
      };

      for (let index = 0; index < maxCalls; index++) {
        if (totalRequests < currentIndex) return;
        makeRequest();
        currentIndex += 1;
      }
    };

    const reader = new FileReader();

    reader.onload = async event => {
      if (event.target && event.target.result instanceof ArrayBuffer) {
        const data = event.target.result;
        const workbook = XLSX.read(new Uint8Array(data), { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        let jsonArray: any[] = [];

        if (file.name.endsWith(".xlsx")) {
          jsonArray = XLSX.utils.sheet_to_json(worksheet).map((row: any) => ({
            __id: uuid(),
            ...row,
            status: "Pending",
          }));
          handleOpenLogic([...jsonArray], delayFactor);
        } else if (file.name.endsWith(".csv")) {
          Papa.parse(file, {
            complete: async (result: any) => {
              if (result.data && result.data.length > 1) {
                const keys: string[] = result.data[0];

                const statusIndex = keys.indexOf("status");
                if (statusIndex !== -1) {
                  keys.splice(statusIndex, 1);
                  keys.push("status");
                }

                for (let i = 1; i < result.data.length - 1; i++) {
                  const rowData: any[] = result.data[i];
                  const obj: any = {
                    __id: uuid(),
                  };
                  keys.forEach((key, j) => {
                    obj[key] = rowData[j];
                  });
                  obj.status = "Pending";
                  jsonArray.push(obj);
                }
                handleOpenLogic([...jsonArray], delayFactor);
              } else {
                console.error("Error reading file");
              }
            },
          });
        }
      } else {
        console.error("Error reading file");
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const downloadCSV = () => {
    if (entries?.length === 0) {
      return;
    }

    const keys = Object.keys(entries[0]);

    const csvContent = [keys.join(","), ...entries.map((entry: any) => keys.map(key => entry[key]).join(","))].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  };

  const handleResetForm = async action => {
    const inputReference = action?.dataReference ?? "";
    const elementKey = replaceNamePlaceHolder(inputReference);

    const resetLevel = getReferenceLevel(inputReference);

    if (elementKey) {
      resetState(elementKey, pageId, viewName as string, resetLevel);
    } else {
      // handle old reset state without key item logic
      resetState(`${pageId}.${viewName}`, pageId, viewName as string, "view");
    }
  };

  const handleActionClick = async action => {
    if (loadingViews[`${pageId}-${viewName}`]) return;
    if (action.enableCSV && action?.actionSourceType?.id !== -3) {
      handleSelectClick(action);
      return;
    }

    if (action?.actionSourceType?.id === -3) {
      downloadCSV();
      return;
    }

    if (handleSubmit) {
      const { isError, values } = handleSubmit();
      if (isError) {
        cancelWorkflow = true;
        validationError = true;
        return;
      }

      await onAction(action, {});
    } else {
      await onAction(action, {});
    }
  };

  const handleStripePayment = async action => {
    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardCvcElement = elements.getElement(CardCvcElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const addressElement = elements.getElement(AddressElement);
    const paymentElement = elements.getElement(PaymentElement);

    if (cardNumberElement && cardCvcElement && cardExpiryElement) {
      const payload = await stripe.createPaymentMethod({
        type: "card",
        card: cardNumberElement,
      });
    } else if (addressElement || paymentElement) {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: { return_url: "" },
        redirect: "if_required",
      });
    } else {
      console.error("Missing required elements");
      return;
    }
  };

  const moveGroupStepperIndexBy = (offset: number, _action: any) => {
    const newStepper = updateStepperGroupIndex(
      getValue(`${pageId}.${viewName}.${_action?.stepperGroupReference?.key}.state`, { pageId, viewName }) || {},
      "UPDATE",
      offset
    );

    setValue(`${pageId}.${viewName}.${_action?.stepperGroupReference?.key}.state`, newStepper, {
      pageId,
      viewName,
      reEvaluateErrorsAndDirty: true,
    });
  };

  const setStepperIndex = (index: number, _action: any) => {
    const newStepper = updateStepperGroupIndex(
      getValue(`${pageId}.${viewName}.${_action?.stepperGroupReference?.key}.state`, { pageId, viewName }) || {},
      "SET",
      index
    );
    setValue(`${pageId}.${viewName}.${_action?.stepperGroupReference?.key}.state`, newStepper, {
      pageId,
      viewName,
      reEvaluateErrorsAndDirty: true,
    });
  };

  const handleSetStepperIndex = _action => {
    setStepperIndex(_action?.stepperIndex, _action);
  };

  const handleStepperPrevious = _action => {
    moveGroupStepperIndexBy(-1, _action);
  };
  const handleStepperNext = _action => {
    moveGroupStepperIndexBy(1, _action);
  };

  const addItem = action => {
    updateDataPlaceholders({
      queryString: action.dataReference,
      item,
      viewsState,
      pageId,
      __data,
      index,
      $action: "ADD",
    });
  };

  const trigger = async (action, e, viewsState, wait?: any) => {
    const triggerSelf = action.triggerSelf;
    const key = triggerSelf ? _key : action.triggerKey;

    await new Promise<void>(resolve => {
      setTimeout(async () => {
        if (wait) {
          await triggerActionableComponent(e, pageId, viewName, key, viewsState, produceTrigger);
        } else {
          triggerActionableComponent(e, pageId, viewName, key, viewsState, produceTrigger);
        }
        resolve();
      }, 0);
    });
  };

  const deleteItem = action => {
    updateDataPlaceholders({
      queryString: action.dataReference,
      item,
      viewsState,
      pageId,
      __data,
      index,
      $action: "DELETE",
    });
  };

  const updateItem = action => {
    let updatedValue;
    if (action.selectedType === "object") {
      const object = JSON.parse(action.updatedValue);
      updatedValue = replaceDataPlaceholdersRecursively({
        obj: object,
        item,
        viewsState,
        pageId,
        __data,
        env: currentApp?.env,
        selectedRows,
        index,
      });
    } else {
      updatedValue = replaceDataPlaceholders({
        queryString: action.updatedValue,
        item,
        viewsState,
        formData,
        pageId,
        __data,
        index,
        actionIndex,
        actions,
      });
    }

    if (action.selectedType === "number") {
      updatedValue = Number(updatedValue);
    }

    updateDataPlaceholders({
      queryString: action.dataReference,
      item,
      viewsState,
      pageId,
      __data,
      index,
      $action: "UPDATE",
      $payload: updatedValue,
      actionIndex,
      actions,
    });
  };

  const cleanDirty = action => {
    const inputReference = action?.dataReference ?? "";
    const elementKey = replaceNamePlaceHolder(inputReference);
    const cleanDirtyLevel = getReferenceLevel(inputReference);

    if (elementKey) {
      removeDirty(elementKey, pageId, viewName, cleanDirtyLevel);
    }
  };

  const timerAction = async action => {
    const timer = Number(action?.timerValue);
    const sec = timer * 1000;
    await new Promise(resolve => {
      setTimeout(() => {
        resolve(true);
      }, sec);
    });
  };

  const handleCopyClick = action => {
    const copySourceValue =
      replaceDataPlaceholders({
        queryString: action.copySource,
        item,
        viewsState,
        pageId,
        __data,
        env: currentApp?.env,
        selectedRows,
        index,
        fromParent,
        dataEntry,
        actionIndex,
        actions,
      }) || "";

    if (copySourceValue) {
      navigator.clipboard
        .writeText(copySourceValue)
        .then(() => {
          if (action?.showSnackbar ?? true) {
            enqueueSnackbarRef("Copied!", { variant: "success" });
          }
        })
        .catch(err => {
          cancelWorkflow = true;
          if (action?.showSnackbar ?? true) {
            enqueueSnackbarRef("Failed to copy!", { variant: "error" });
          }
        });
    }
  };
  const showSnackbar = action => {
    const app = currentApp ?? fqdnApp;

    type VerticalPosition = "top" | "bottom";
    type HorizontalPosition = "left" | "center" | "right";
    const customMessage = replaceDataPlaceholders({
      queryString: action.snackbarMessage,
      item,
      viewsState,
      pageId,
      formData,
      __data,
      env: app?.env,
      selectedRows,
      index,
      fromParent,
      dataEntry,
      actionIndex,
      actions,
    });

    const defaultVariants = ["default", "success", "error", "info", "warning"];

    const duration = action.snackbarDuration || app?.appConfig?.snackbar?.duration;
    const durationInMs = Number(duration) * 1000;
    const customVariant = app?.appConfig?.snackbar?.allVariants?.includes(action?.snackbarVariant)
      ? action.snackbarVariant
      : app?.appConfig?.snackbar?.variant;

    const position = action?.snackbarPosition == "Inherent from app" ? app?.appConfig?.snackbar?.position : action?.snackbarPosition;
    const actionButton =
      action.snackbarActionButton == "Inherent from app" ? app?.appConfig?.snackbar?.closeButton : action.snackbarActionButton;
    const preventDuplicate = action.snackbarPreventDuplicate;
    const [vertical, horizontal] = position?.split("-") as [VerticalPosition, HorizontalPosition];
    const isCustom = action?.isCustom;
    const variantsSettings = app?.appConfig?.snackbar?.variantsSettings;
    const customPosition = isCustom ? action?.alignText : variantsSettings?.[customVariant]?.alignText;
    const customBgColor = isCustom ? action?.backgroundColorAdvance : variantsSettings?.[customVariant]?.backgroundColorAdvance;
    const customColor = isCustom ? action?.colorAdvance : variantsSettings?.[customVariant]?.colorAdvance;
    const customIcon = variantsSettings?.[customVariant]?.iconAdvance;
    const iconColor = isCustom ? action?.iconColor : variantsSettings?.[customVariant]?.iconColor;

    enqueueSnackbarRef?.(customMessage, {
      variant: customVariant,
      anchorOrigin: {
        vertical,
        horizontal,
      },
      ...(duration && { autoHideDuration: durationInMs }),
      ...(actionButton !== "Without Button" && {
        action: key =>
          actionButton == "Icon X" || actionButton == "Dismiss" ? (
            <Button
              onClick={() => {
                closeSnackbarRef(key);
              }}
            >
              {actionButton == "Icon X" && <IconX color='white' size={18} />}
              {actionButton == "Dismiss" && <Typography color={"white"}>Dismiss</Typography>}
            </Button>
          ) : (
            <Box
              style={{
                overflow: "hidden",
                height: "100%",
                cursor: "pointer",
                position: "absolute",
                top: "5px",
                right: "5px",
              }}
            >
              <IconX
                color='white'
                size={14}
                onClick={() => {
                  closeSnackbarRef(key);
                }}
              />
            </Box>
          ),
      }),
      ...(actionButton == "Without Button" && {
        action: key => <></>,
      }),

      preventDuplicate,
      ...((!defaultVariants.includes(customVariant) || isCustom) && {
        content: (key, message) => {
          return (
            <SnackbarContent
              style={{
                backgroundColor: customBgColor || "",
                color: customColor || "",
                width: "100%",
                boxSizing: "border-box",
                margin: "8px",
                padding: "12px 16px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderRadius: "5px",
                position: "relative",
              }}
            >
              <Box
                style={{
                  width: actionButton == "Without Button" || actionButton == "Corner X" ? "100%" : "auto",
                  boxSizing: "border-box",
                  display: "flex",
                  justifyContent: customPosition || "left",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <BXIcon
                  width={"25px"}
                  height={"25px"}
                  icon={isCustom ? action?.iconAdvance?.icon : variantsSettings?.[customVariant]?.iconAdvance?.icon}
                  url={isCustom ? action?.iconAdvance?.url : variantsSettings?.[customVariant]?.iconAdvance?.url}
                  visibility={isCustom ? action?.iconAdvance?.visibility : variantsSettings?.[customVariant]?.iconAdvance?.visibility}
                  color={iconColor || "white"}
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
                {customMessage}
              </Box>
              {actionButton !== "Without Button" && actionButton == "Corner X" && (
                <Box
                  style={{
                    overflow: "hidden",
                    height: "100%",
                    cursor: "pointer",
                    position: "absolute",
                    top: "5px",
                    right: "5px",
                  }}
                >
                  <IconX
                    color='#8a8a8a'
                    size={14}
                    onClick={() => {
                      closeSnackbarRef(key);
                    }}
                  />
                </Box>
              )}

              {actionButton !== "Without Button" && (actionButton == "Icon X" || actionButton == "Dismiss") && (
                <Button
                  onClick={() => {
                    closeSnackbarRef(key);
                  }}
                >
                  {actionButton == "Icon X" && <IconX color='black' size={18} />}
                  {actionButton == "Dismiss" && <Typography color={"black"}>Dismiss</Typography>}
                </Button>
              )}
            </SnackbarContent>
          );
        },
      }),
    });
  };

  const stopWorkflow = action => {
    cancelWorkflow = true;
  };

  const handleLinkClick = async action => {
    if (!action?.navigationAfterAction && action?.actionSourceType?.type !== "Link") return;

    const linkUrlValue =
      replaceDataPlaceholders({
        queryString: action?.linkUrl,
        item,
        viewsState,
        pageId,
        __data,
        env: currentApp?.env,
        fallback: "",
        index,
        selector: dataEntry ? `this.data.${dataEntry}[*]` : `this.data._body[*]`,
        fromParent,
        dataEntry,
        actionIndex,
        actions,
        tableAction,
        actionResponse: currentResponse,
      }) || "";

    if (linkUrlValue) {
      const path = encodeURLParams(replaceBaseUrl(linkUrlValue, undefined, fqdnApp ? "" : currentApp?.slug));
      if (linkUrlValue.startsWith("/")) {
        let updatedValue;
        if (action.selectedType === "object") {
          const object = JSON.parse(action.updatedValue);
          updatedValue = replaceDataPlaceholdersRecursively({
            obj: object,
            item,
            viewsState,
            pageId,
            __data,
            env: currentApp?.env,
            selectedRows,
            index,
          });
        } else {
          updatedValue = replaceDataPlaceholders({
            queryString: action.updatedValue,
            item,
            viewsState,
            formData,
            pageId,
            __data,
            index,
            actionIndex,
            actions,
          });
        }
        await new Promise((resolve, reject) => {
          setTimeout(() => {
            try {
              if (action?.openLinkAs == "_blank") {
                resolve(window.open(path, "_blank"));
              } else {
                resolve(
                  navigate(path, {
                    state: updatedValue,
                  })
                );
              }
            } catch (error) {
              reject(error);
            }
          }, 0);
        });
      } else {
        await new Promise((resolve, reject) => {
          setTimeout(() => {
            try {
              resolve(window.open(path, action?.openLinkAs || "_blank"));
            } catch (error) {
              reject(error);
            }
          }, 0);
        });
      }
    } else {
      cancelWorkflow = true;
      if (action?.showSnackbar ?? true) {
        enqueueSnackbarRef("Invalid URL!", { variant: "error" });
      }
    }
  };

  const handleRefreshViews = async action => {
    const isGraphQL = action?.isGraphQL || false;

    const _refreshList =
      typeof action?.refreshActionView?.[0] === "string"
        ? action?.refreshActionView.map(name => {
            const { id } = views?.find(view => view?.info?.name === name) as UIElement;
            return {
              id: id,
              name,
            };
          })
        : action?.refreshActionView;

    const refreshViewsIds = new Set(_refreshList.map(v => v?.id));
    const refreshViews = views?.filter(view => refreshViewsIds.has(view?.id));

    refreshViews?.forEach(async refreshView => {
      const refreshViewName = refreshView?.info?.viewName;
      const refreshKey = `${pageId}.${refreshViewName}`;

      await handleRefetchQueries({
        isGraphQL,
        queriesStateGraphQL,
        key: refreshKey,
        queryKeys: refreshKey,
        produceTrigger,
        pageId,
        viewName: refreshViewName,
      });
    });
  };

  const downloadFile = (url, showSnackbar) => {
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", blob.type);
        document.body.appendChild(link);
        link.click();
      })
      .catch(e => {
        cancelWorkflow = true;
        if (showSnackbar) {
          enqueueSnackbarRef("Failed to download!", { variant: "error" });
        }
      });
    return;
  };

  const handleDownloadClick = async action => {
    if (action?.downloadType === "api") {
      onAction(action, {
        onSuccess: data => {
          let url = data;
          if (action?.downloadKey) {
            url = data?.[action?.downloadKey];
          }
          downloadFile(url, action?.showSnackbar ?? true);
        },
      });
      return;
    }

    downloadFile(action?.directUrl, action?.showSnackbar ?? true);
  };

  const handleStackClick = (action, e) => {
    e.stopPropagation();
    onSelectRow?.(item, true);
    const currentViewStacks = store.getState().buildxProvider.viewStacks;
    const newViewStackElement = [
      ...currentViewStacks,
      <BXEngine
        path={[path, selectedView?.info?.name].join(".")}
        auth={{}}
        page={{ views, layout: selectedView, id: pageId } as any}
        layout={[{ ...(selectedView as any), type: action?.actionSourceType?.type }]}
        isVisible
        selectedViewId={item.id}
        __data={{
          ...__data,
          [(getLastKeyFromObject(__data) || "") + "#."]: viewName,
        }}
        closeModal={closeModal}
        parentIds={[...parentIds, ...queryKeys]}
      />,
    ] as UIElement[];
    dispatch(
      setProviderState({
        viewStacks: newViewStackElement,
      })
    );
  };

  const handleLoginAction = async action => {
    setIsLoading(true);
    const email = replaceDataPlaceholders({
      queryString: action?.BXLogin?.emailField,
      viewsState,
      pageId,
      __data,
      env: currentApp?.env,
      fallback: "",
      actionIndex,
      actions,
    });

    const password = replaceDataPlaceholders({
      queryString: action?.BXLogin?.passwordField,
      viewsState,
      pageId,
      __data,
      env: currentApp?.env,
      fallback: "",
      actionIndex,
      actions,
    });

    await registerAppDevice?.(
      currentApp?.id || fqdnApp?.id || "",
      allowedApps,
      currentApp,
      currentProfileId,
      appProfiles,
      appTokens,
      replaceDataPlaceholdersRecursively,
      fqdnApp?.appConfig?.auth?.deviceApi,
      fqdnApp
    );
    const response = await loginToApp?.(
      currentApp?.id || fqdnApp?.id || "",
      allowedApps,
      currentApp,
      isAdministrationMode,
      location,
      navigate,
      appRoutesMap,
      currentProfileId,
      replaceDataPlaceholdersRecursively,
      loginSuccess,
      email,
      password,
      undefined,
      undefined,
      () => {},
      undefined,
      fqdnApp?.appConfig?.auth?.authApi,
      fqdnApp,
      undefined,
      {
        skipAuthErrorClear: true,
      },
      action?.BXLogin?.disableAutoNavigate
    );

    if (_key) {
      const currentViewsState = store.getState().buildxProvider.viewsState;
      const updatedViewsState = { ...currentViewsState };
      _.set(updatedViewsState, `${pageId}-${viewName}.response.${_key}`, {
        ...response,
        requestStatusCode: response?.request?.code,
      });
      dispatch(
        setProviderState({
          viewsState: updatedViewsState,
        })
      );
    }

    if (_key) {
      const responseState = {
        _body: { ...(response?.data || response) },
        _status: response?.request?.code || response?.requestStatusCode || response?.status,
      };

      setValue(`${pageId}.${viewName}.${_key}.response`, responseState, { pageId, viewName });
    }

    setIsLoading(false);
  };

  const handleLogoutAction = async action => {
    const isFQDN = !!fqdnApp;
    if (isFQDN) {
      setUserAuth();
    }
    await logoutOfApp?.(currentApp?.id as string, currentProfileId, !!isFQDN);
    dispatch(
      setProviderState({
        currentProfileId: null,
      })
    );
    const currentAppProfiles = store.getState().buildxProvider.appProfiles;
    const updatedAppProfiles = { ...currentAppProfiles };
    delete updatedAppProfiles[currentApp?.id as string];
    dispatch(
      setProviderState({
        appProfiles: updatedAppProfiles,
      })
    );
    if (isHostAvailable || (currentApp?.appConfig?.withProfiles && currentApp?.appConfig?.isSingleSignOn)) {
      await axiosServices.delete(`application/${currentApp?.id}/profile/${currentProfileId}`);
    }

    localStorage.removeItem("targetPathAfterLogin");
  };

  const handleStartLoading = () => {
    //Change loading state for page and view

    setValue(`${pageId}.isLoading`, true, { pageId, viewName });
    setValue(`${pageId}.${viewName}.isLoading`, true, { pageId, viewName });
  };

  const handleEndLoading = () => {
    //Change loading state for page and view

    setValue(`${pageId}.isLoading`, false, { pageId, viewName });
    setValue(`${pageId}.${viewName}.isLoading`, false, { pageId, viewName });
  };

  const handleVisibilityToggle = action => {
    const keyReference = action.keyReference;
    if (keyReference) {
      const currentViewsState = store.getState().buildxProvider.viewsState;
      const viewRef = `${pageId}-${viewName}`;
      const updatedViewsState = {
        ...currentViewsState,
        state: currentViewsState.state,
        visibility: {
          ...currentViewsState.visibility,
          [viewRef]: {
            ...currentViewsState.visibility?.[viewRef],
            [keyReference]: {
              toggle: !currentViewsState.visibility?.[viewRef]?.[keyReference]?.toggle,
              display: action.displayRef ? "hidden" : "none",
            },
          },
        },
      };

      // Dispatch the updated views state to the Redux store
      dispatch(
        setProviderState({
          viewsState: updatedViewsState,
        })
      );
    }
  };

  const handleConfirmAction = async () => {
    actionConfirmControl.confirm();
    dispatch(
      setProviderState({
        isConfirmAction: null,
      })
    );
  };

  const handleCancelAction = async () => {
    actionConfirmControl.cancel();
    dispatch(
      setProviderState({
        isConfirmAction: null,
      })
    );
  };

  const replaceNamePlaceHolder = (dataSourceKey: string) => {
    let elementKey = "";

    if (dataSourceKey.startsWith("{this")) {
      if (dataSourceKey === "{this}") {
        //Current View
        elementKey = `${pageId}.${viewName}`;
      } else {
        //Component in the current view
        const placeholder = dataSourceKey.slice("{this.".length, -1);
        elementKey = `${pageId}.${viewName}.${placeholder}`;
      }
    } else if (dataSourceKey === "{$page}") {
      //Current Page
      elementKey = `${pageId}`;
    } else {
      //Another view in the same page
      dataSourceKey.replace(/{(\w+)(\.\w+)?}/g, (_match, view, placeholder) => {
        elementKey = placeholder ? `${pageId}.${view}${placeholder}` : `${pageId}.${view}`;
        return elementKey;
      });
    }
    return elementKey;
  };

  const handleTriggerDataSource = async action => {
    //Read data source key.
    const dataSourceKey = action?.dataSourceKey;
    const elementKey = replaceNamePlaceHolder(dataSourceKey);
    const viewOfDataSource = elementKey.split(".")[1]; //{pageId.<viewName>}

    await new Promise((resolve, reject) => {
      produceTrigger(
        elementKey,
        "refetch",
        { resolver: resolve },
        {
          pageId,
          viewName: viewOfDataSource,
        }
      );
    });
  };

  const handleRunValidation = action => {
    const validationKey = action?.dataReference;
    const elementKey = replaceNamePlaceHolder(validationKey);
    const validationLevel = getReferenceLevel(action?.dataReference);

    const result = runAllValidations(elementKey, validationLevel);
    validationError = !result;

    const scrollToErrorEnabled = action?.scrollToError ?? true;
    if (scrollToErrorEnabled) {
      setTimeout(() => {
        const firstInvalidField = document.querySelector('[aria-invalid="true"]');

        if (firstInvalidField) {
          firstInvalidField.scrollIntoView({ behavior: "smooth", block: "center" });
          (firstInvalidField as HTMLElement).focus();
        }
      }, 0);
    }
  };
  const handleCheckDirty = action => {
    //Read reference
    const validationKey = action?.dataReference;
    const elementKey = replaceNamePlaceHolder(validationKey);

    const isDirtyResult = getValue(`${elementKey}.isDirty`, { pageId, viewName });

    if (isDirtyResult) {
      // eslint-disable-next-line no-restricted-globals
      if (!confirm("There are some changes. Are you sure you want to navigate?")) {
        dirtyConfirmationDenied = true;
      } else {
        removeDirty(elementKey, pageId, viewName, getReferenceLevel(validationKey));
      }
    }
  };
  const handleNextPageAction = async action => {
    const dataSource = replaceNamePlaceHolder(action?.dataSourceKey);
    const paginationBar = replaceNamePlaceHolder(action?.paginationBarKey);

    const paginationBarState = getValue(paginationBar, { pageId, viewName });
    const currentPage = paginationBarState?.state?.currentPage;
    const hasMore = paginationBarState?.state?.hasMore;

    if (!hasMore) {
      return;
    }

    await new Promise(resolve => {
      produceTrigger(paginationBar, "updatePage", { resolver: resolve, page: currentPage + 1 }, { pageId, viewName });
    });
  };

  const handlePreviousPageAction = async action => {
    const dataSource = replaceNamePlaceHolder(action?.dataSourceKey);
    const paginationBar = replaceNamePlaceHolder(action?.paginationBarKey);

    const paginationBarState = getValue(paginationBar, { pageId, viewName });
    const currentPage = paginationBarState?.state?.currentPage;
    const hasMore = paginationBarState?.state?.hasMore;

    if (currentPage == 1) {
      return;
    }

    await new Promise(resolve => {
      produceTrigger(paginationBar, "updatePage", { resolver: resolve, page: currentPage - 1 }, { pageId, viewName });
    });
  };

  const handleClearValidation = action => {
    const elementKey = replaceNamePlaceHolder(action?.dataReference);

    if (elementKey) {
      clearValidation(elementKey);
    }
  };

  const handleActionMapper = async (action, e, viewsState) => {
    const isPureAction = actionTypes?.find(actionType => actionType?.id === action?.actionSourceType?.id);
    const isViewAction = !isPureAction;
    if (isViewAction) {
      const _views = views?.concat(getSharedViews(currentApp));
      setSelectedView(_views?.find(view => view?.id === action?.actionSourceType?.id));
      setActionView(action);
      setIsViewActionTriggered(true);
      await new Promise(resolve => {
        setProceedToNextAction({
          currentAction: action,
          confirm: () => {
            resolve(true);
          },
          cancel: () => {
            cancelWorkflow = true;
            resolve(false);
          },
        });
      });
      return;
    }

    if (action?.actionSourceType?.type === "API" && action?.dialog?.enabled) {
      const confirmationDialogData = replaceDataPlaceholdersRecursively({
        obj: _.cloneDeep(action?.dialog),
        item,
        viewsState,
        pageId,
        __data,
        env: currentApp?.env,
        selectedRows,
        index,
        selector: dataEntry ? `this.data.${dataEntry}[*]` : `this.data._body[*]`,
      });

      //Store confirmation state in app state for later referencing in the dialog view if any.
      if (!confirmationDialogData?.customViewEnabled) {
        setValue(`${pageId}.${viewName}.confirmation`, confirmationDialogData, { pageId, viewName });
      } else {
        const _views = views?.concat(getSharedViews(currentApp));
        const confirmationViewName = _views?.find(v => v?.id === action?.dialog?.customView)?.info?.viewName;
        dispatch(
          setEndUser({
            pageId: pageId,
            viewName: confirmationViewName,
          })
        );
        setValue(`${pageId}.${confirmationViewName}.confirmation`, confirmationDialogData, { pageId, viewName: confirmationViewName });
      }

      dispatch(
        setProviderState({
          isConfirmAction: actionId,
        })
      );
      await new Promise(resolve => {
        const updatedActionConfirmControl = {
          currentAction: action,
          title: confirmationDialogData?.title,
          confirmationDialogData: confirmationDialogData,
          confirm: async () => {
            await handleActionClick(action);
            resolve(true);
          },
          cancel: () => {
            cancelWorkflow = true;
            // When the user denies of the confirmation dialog, suspend the subsequent actions
            isConfirmationDenied = true;
            resolve(false);
          },
        };

        dispatch(
          setProviderState({
            actionConfirmControl: updatedActionConfirmControl,
          })
        );
      });
      return;
    }

    if (action?.actionSourceType?.type === "API" && action?.showModal === "Yes") {
      setActionModal(action);
      setShowModalForAction(true);
      await new Promise(resolve => {
        setProceedToNextAction({
          currentAction: action,
          confirm: () => {
            resolve(true);
          },
          cancel: () => {
            cancelWorkflow = true;
            resolve(false);
          },
        });
      });
      return;
    }

    if (isLoading || disabled) return;
    switch (action?.actionSourceType?.type) {
      case "API":
        await handleActionClick(action);
        break;
      case "Stripe Payment":
        await handleStripePayment(action);
        break;
      case "Views": {
        setSelectedView(views?.find(view => view?.id === action?.actionSourceType?.id));
        setActionView(action);
        setIsViewActionTriggered(true);
        await new Promise(resolve => {
          setProceedToNextAction({
            currentAction: action,
            confirm: () => {
              resolve(true);
            },
            cancel: () => {
              cancelWorkflow = true;
              resolve(false);
            },
          });
        });
        break;
      }
      case "Download":
        await handleDownloadClick(action);
        break;
      case "Copy":
        handleCopyClick(action);
        break;
      case "Show Snackbar":
        showSnackbar(action);
        break;
      case "Link":
        handleLinkClick(action);
        break;
      case "BuildX Login":
        await handleLoginAction(action);
        break;
      case "BuildX Logout":
        await handleLogoutAction(action);
        break;
      case "Stack":
        handleStackClick(action, e);
        break;
      case "Visibility Toggle":
        handleVisibilityToggle(action);
        break;
      case "Export CSV":
        handleActionClick(action);
        break;
      case "Add item":
        addItem(action);
        break;
      case "Delete item":
        deleteItem(action);
        break;
      case "Update item":
        updateItem(action);
        break;
      case "Trigger":
        if (!action.triggerSelf && action.triggerAwait && action.triggerKey !== _key) {
          const wait = true;
          await trigger(action, e, viewsState, wait);
        } else {
          trigger(action, e, viewsState);
        }
        break;
      case "Clean Dirty":
        cleanDirty(action);
        break;
      case "Timer":
        await timerAction(action);
        break;
      case "Dismiss": {
        closeModal?.(null);
        break;
      }
      case "Start Loading":
        handleStartLoading();
        break;
      case "End Loading":
        handleEndLoading();
        break;
      case "Stepper Previous": {
        handleStepperPrevious?.(action);
        break;
      }
      case "Stepper Next": {
        handleStepperNext?.(action);
        break;
      }
      case "Select Stepper": {
        handleSetStepperIndex?.(action);
        break;
      }
      case "Refresh Views": {
        await handleRefreshViews(action);
        break;
      }
      case "Reset Form": {
        handleResetForm(action);
        break;
      }
      case "Confirm": {
        await handleConfirmAction?.();
        break;
      }
      case "Cancel": {
        await handleCancelAction?.();
        break;
      }
      case "Trigger Data Source": {
        await handleTriggerDataSource(action);
        break;
      }
      case "Run Validation": {
        await handleRunValidation?.(action);
        break;
      }
      case "Check Dirty": {
        await handleCheckDirty?.(action);
        break;
      }
      case "Next Page": {
        handleNextPageAction(action);
        break;
      }
      case "Previous Page": {
        handlePreviousPageAction?.(action);
        break;
      }
      case "Stop Workflow": {
        stopWorkflow?.(action);
        break;
      }
      case "Clear Validation": {
        handleClearValidation?.(action);
        break;
      }
      default:
    }
  };

  async function executeChain(e: any, _viewsState?: any) {
    let actions: any[] = actionsMap?.default || [];
    // reset the cancel work flow error
    cancelWorkflow = false;
    dirtyConfirmationDenied = false;
    isConfirmationDenied = false;
    validationError = false;

    let mappedActionKey: string;

    const isTableAction: boolean = !_.isEmpty(tableAction?.action);
    if (!isChainMapped) {
      mappedActionKey = "default";
      actions = actionsMap?.default || [];
      if (props?.action && _.isEmpty(actionsMap)) {
        actions = [{ actionConfig: props?.action }, ...actions];
      }
      if (_.isEmpty(actionsMap) && tableAction?.action?.actionSourceType) {
        actions = [{ actionConfig: tableAction?.action }, ...actions];
      }
    } else {
      const resolvedActionsKey = replaceDataPlaceholders({
        queryString: actionsKey,
        item,
        viewsState,
        pageId,
        formData,
        __data,
        env: currentApp?.env,
        selectedRows,
        index,
        actionIndex,
        actions,
      });
      mappedActionKey = resolvedActionsKey;

      const condition = element?.config?.conditionActionMapKey || conditionKeyFromTable || "equal";
      if (condition === "equal" && actionsMap?.[resolvedActionsKey]) {
        actions = actionsMap?.[resolvedActionsKey];
      } else if (condition === "startWith") {
        const matchingKey = Object.keys(actionsMap).find(key => resolvedActionsKey?.startsWith(key));
        if (matchingKey) {
          actions = actionsMap?.[matchingKey];
        }
      }
    }

    if (!_.isEmpty(actions) && e) {
      // reset .response for the whole workflow action
      if (_key) {
        setValue(`${pageId}.${viewName}.${_key}.response`, null, { pageId, viewName });
      }
      e.stopPropagation();
    }

    actions = actions.filter(action => Object.keys(action?.actionConfig)?.length !== 0);
    if (isLoadingForEntireChain?.[mappedActionKey]) {
      actions = actions.filter(action => !action?.actionConfig?.actionSourceType?.type?.includes("Loading"));
      handleStartLoading?.();
    }

    for (let i = 0; i < actions?.length; i++) {
      let action = actions?.[i]?.actionConfig;
      const actionCondition = replaceDataPlaceholders({
        queryString: action.actionCondition ?? action.condition,
        actionResponse: _.get(viewsState, `${pageId}-${viewName}.response.${_key}`),
        item,
        viewsState,
        pageId,
        formData,
        __data,
        env: currentApp?.env,
        selectedRows,
        selector: dataEntry ? `this.data.${dataEntry}[*]` : `this.data._body[*]`,
        index,
        actionIndex,
        actions,
        _key,
      });

      try {
        if (!eval(actionCondition) && !_.isEmpty(actionCondition)) {
          continue;
        }
      } catch (error) {
        console.error("Expression throws an exception");
      }

      await handleActionMapper(action, e, _viewsState || viewsState);

      if (isConfirmationDenied) {
        break;
      }

      if (validationError && (action.abort ?? true)) {
        break;
      }

      if (dirtyConfirmationDenied) {
        break;
      }

      //Store response state for the current action, if any
      const currentActionResponse = getValue(`${pageId}.${viewName}.${_key}.response`, { pageId, viewName });
      setValue(`${pageId}.${viewName}.${_key}.responses[${i}]`, currentActionResponse, { pageId, viewName });

      if (isTableAction) {
        const isActionOnRowLevel = !tableAction?.action.isGlobal;

        const actionResponsePath = isActionOnRowLevel
          ? `${pageId}.${viewName}.actions[${actionIndex}].rows[${index}].response`
          : `${pageId}.${viewName}.actions[${actionIndex}].response`;

        const actionResponsesPath = isActionOnRowLevel
          ? `${pageId}.${viewName}.actions[${actionIndex}].rows[${index}].responses[${i}]`
          : `${pageId}.${viewName}.actions[${actionIndex}].responses[${i}]`;

        const currentActionResponse = getValue(actionResponsePath, { pageId, viewName });
        setValue(actionResponsesPath, currentActionResponse, { pageId, viewName });
      }

      if (cancelWorkflow && (action.abort ?? true)) {
        // enqueueSnackbarRef?.("Workflow aborted", { variant: "error" });
        handleEndLoading?.();
        break;
      }

      // enqueueSnackbarRef?.("Workflow executed successfully", { variant: "success" });
    }
    if (isLoadingForEntireChain?.[mappedActionKey]) {
      handleEndLoading?.();
    }
  }

  const renderActionableContent = () => {
    return typeof children === "function" ? children({ actionHandlerMapper: executeChain, isLoading }) : children;
  };

  const onClickHandler = e => {
    //For old action version
    if (!interactionConfig) {
      executeChain(e);
      return;
    }

    if (currentRepeatedItem && onSelectRow) {
      onSelectRow?.(currentRepeatedItem);
    }

    const selfTriggers = interactionConfig?.filter(interaction => interaction.type === "OnClick" && interaction?.triggerSelf);
    selfTriggers?.forEach(_ => executeChain(e));
  };

  const renderActionableComponent = () => {
    return children ? (
      <span style={{ display: "contents" }} onClick={onClickHandler}>
        {renderActionableContent()}
      </span>
    ) : (
      <Tooltip title={tableAction?.label}>
        {iconButton ? (
          <IconButton
            onClick={e => {
              onSelectRow?.(item);
              e.stopPropagation();
              executeChain(e);
            }}
            disabled={disabled}
          >
            <BXIcon icon={tableAction?.icon} width={16} height={16} color={"currentColor"} />
          </IconButton>
        ) : (
          <LoadingButton
            loading={isLoading}
            loadingPosition='start'
            aria-label={tableAction?.label}
            variant={variant as any}
            disabled={disabled}
            onClick={e => {
              onSelectRow?.(item);
              e.stopPropagation();
              executeChain(e);
            }}
            startIcon={tableAction?.icon && <BXIcon icon={tableAction?.icon} width={20} height={20} color={palette.text.primary} />}
            fullWidth={fullWidth}
            style={!withBorder ? { borderWidth: 0, justifyContent: "flex-start" } : {}}
          >
            <Typography color={palette.text.primary}>{tableAction?.label}</Typography>
          </LoadingButton>
        )}
      </Tooltip>
    );
  };

  // if (!action || _.isEmpty(action) || action?.actionSourceType?.id === -7) return (renderActionableContent() as any) || null;
  // const isAPI = _.isNaN(action?.actionSourceType?.type) || !action?.actionSourceType?.type || action?.actionSourceType?.type == "API";

  const isAPI = false;

  return (
    <>
      {isAPI && <CSVProgressDialog open={open} handleClose={handleClose} jsonData={jsonProgress} />}

      {isConfirmAction === actionId && (
        <BXConfirmationDialog
          views={views}
          open={isConfirmAction === actionId}
          title={actionModal?.title || "Are you sure?"}
          iconButton
          onConfirm={async () => {
            await handleConfirmAction();
          }}
          onCancel={() => {
            handleCancelAction();
          }}
          isCustom={actionConfirmControl?.confirmationDialogData?.customViewEnabled}
          isCustomWidth={actionConfirmControl?.currentAction?.isCustomWidthConfirm}
          customModalWidth={actionConfirmControl?.currentAction?.customModalWidthConfirm}
          customDialogView={actionConfirmControl?.confirmationDialogData?.customView}
          customDialogData={actionConfirmControl?.confirmationDialogData}
          pageId={pageId}
        />
      )}

      {actionModal && (
        <BXModal
          open={showModalForAction}
          buttonComponent={children}
          label={actionModal?.label}
          icon={<BXIcon icon={actionModal?.icon} width={14} height={14} color={"currentColor"} />}
          buttonProps={{
            variant: "contained",
            startIcon: <BXIcon icon={actionModal?.icon} width={14} height={14} color={"currentColor"} />,
          }}
          title={actionModal?.label}
          withoutLabel={iconButton}
          onClose={() => {
            setShowModalForAction(false);
            setActionModal(null);
            proceedToNextAction?.cancel();
          }}
        >
          {(handleClose: Function) => {
            const isGraphQL = actionModal?.isGraphQL || false;

            const actionBody = isGraphQL ? actionModal?.graphqlVariables : actionModal?.body;

            const resolvedBody = replaceDataPlaceholdersRecursively({
              obj: JSON.parse(actionModal?.showModal === "Yes" ? actionBody : actionBody || "{}"),
              viewsState,
              pageId,
              item,
              formData,
              __data,
              env: currentApp?.env,
              selectedRows,
              index,
            });

            const resolvedHeaders = replaceDataPlaceholdersRecursively({
              obj: actionModal?.headers,
              viewsState,
              pageId,
              item,
              formData,
              __data,
              env: currentApp?.env,
              selectedRows,
              index,
            });

            const uri = replaceDataPlaceholders({
              queryString: actionModal?.source,
              item,
              viewsState,
              formData,
              pageId,
              __data,
              index,
              actionIndex,
              actions,
            });
            return (
              <CreatePayload
                payload={{
                  ...(isGraphQL
                    ? { graphqlVariables: JSON.stringify(resolvedBody), graphqlQuery: actionModal?.graphqlQuery }
                    : { body: JSON.stringify(resolvedBody) }),
                  headers: resolvedHeaders,
                  uri,
                  method: actionModal?.method,
                  graphQLMethod: actionModal?.graphQLMethod,
                  isGraphQL: actionModal?.isGraphQL,
                }}
                onSave={async (payload: any) => {
                  proceedToNextAction.action = {
                    ...proceedToNextAction.action,
                    ...(isGraphQL ? { graphqlVariables: payload?.graphqlVariables } : { body: payload?.body }),
                    headers: payload?.headers,
                    source: payload?.uri,
                    method: actionModal?.method,
                    graphQLMethod: actionModal?.graphQLMethod,
                  };

                  await onAction(proceedToNextAction?.action, {});
                  setActionModal(null);
                  proceedToNextAction?.confirm();
                }}
                label={actionModal?.label || "Save"}
                onClose={() => handleClose()}
                isLoading={isLoading}
                disabled={disabled}
              />
            );
          }}
        </BXModal>
      )}
      {renderActionableComponent()}
      {isViewActionTriggered && (
        <ViewerModal
          action={actionView}
          path={path}
          keyComp={_key}
          viewName={viewName}
          actionSourceType={actionView?.actionSourceType}
          item={item}
          pageId={pageId}
          selectedView={selectedView}
          openView={isViewActionTriggered}
          onSelectRow={onSelectRow}
          views={views}
          withoutLabel={iconButton}
          iconColor='currentColor'
          buttonComponent={children}
          isUserInput={isUserInput}
          showHeader={!!actionView?.showHeader}
          buttonProps={{
            style: iconButton
              ? {
                  // backgroundColor: palette.primary.main, padding: 6
                }
              : { ...(!withBorder && { borderWidth: 0, justifyContent: "flex-start" }) },
            fullWidth,
            variant,
          }}
          modalSize={actionView?.modalSize}
          __data={__data}
          id={tableId}
          parentIds={[...parentIds, ...queryKeys]}
          clearActionView={() => {
            proceedToNextAction.confirm();
            setIsViewActionTriggered(false);
            setActionView(null);
            setSelectedView(null);
          }}
        />
      )}
    </>
  );
};
