import { Autocomplete, Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import { IconCirclePlus, IconCopy, IconTrashX } from "@tabler/icons-react";
import { FC, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import BXModal from "src/components/BXUI/Modal";
import { updateComponent } from "src/features/builder/builderSlice";

interface BXMapValuesProps {
  type?: any;
  item?: any;
  onChangeProp?: any;
  initialKey: any;
  queryString: any;
  targetMap: any;
  handleKeyChange: any;
  setSelectedValue: any;
  selectedValue?: any;
  condition?: any;
  setCondition?: any;
  handleConditionChange?: any;
  addNewValue: any;
  copyFromDefault: any;
  copyFromValue: any;
  deleteValue: any;
  isTable?: boolean;
}

export const BXMapValues: FC<BXMapValuesProps> = ({
  initialKey,
  handleKeyChange,
  queryString,
  targetMap,
  setSelectedValue,
  addNewValue,
  copyFromDefault,
  copyFromValue,
  deleteValue,
  type,
  item,
  onChangeProp,
  selectedValue,
  condition,
  setCondition,
  handleConditionChange,
  isTable = false,
}) => {
  const [isCopyValueModalOpen, setIsCopyValueModalOpen] = useState<boolean>(false);
  const [newValue, setNewValue] = useState<string>("");
  const [selectedSource, setSelectedSource] = useState<string>("default");
  const [key, setKey] = useState(initialKey);
  const preventDefaultRef = useRef<any>(null);
  const dispatch = useDispatch();

  const valueOptions = Object.entries(targetMap || {}).map(([key, value]) => ({
    value: key,
    actions: value,
    label: key === "default" ? "<Default>" : key,
    isAddNew: false,
  }));
  const [localSelectedValue, setLocalSelectedValue] = useState<string>(selectedValue || "");

  let defaultOption;
  if (type) {
    defaultOption = valueOptions.find(option => option.value === item?.selectedType) || { label: "<Default>", value: "default" };
  } else {
    defaultOption = valueOptions.find(option => option.value === "default");
  }
  const filteredValueOptions = valueOptions.filter(option => option.label !== "initItemValue");

  return (
    <Grid container gap={type ? 1 : 2} display={"flex"}>
      <Grid item xs={type ? 12 : 3}>
        <FormControl fullWidth size={type ? "small" : "medium"}>
          <InputLabel>Condition</InputLabel>
          <Select value={condition} onChange={e => handleConditionChange(e.target.value as string)}>
            <MenuItem value='equal'>Equal</MenuItem>
            <MenuItem value='startWith'>Start With</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      <Grid item xs={type ? 12 : 4}>
        <TextField
          size={type ? "small" : "medium"}
          fullWidth
          label={"Key"}
          value={key || ""}
          onChange={e => {
            setKey(e.target.value);
            if (isTable) {
              handleKeyChange("actionsKey", e?.target?.value);
            } else {
              handleKeyChange(queryString)(e);
            }
          }}
        />
      </Grid>

      <Grid item xs={type ? 12 : 4}>
        <Autocomplete
          size={type ? "small" : "medium"}
          options={valueOptions}
          defaultValue={defaultOption}
          getOptionLabel={option => option?.label}
          blurOnSelect
          filterOptions={(options, { inputValue }) => {
            const filtered = options.filter(option => option?.value.toLowerCase().includes(inputValue.toLowerCase()));

            const isExisting = options.some(option => option?.value === inputValue);
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                value: inputValue,
                actions: [],
                label: inputValue,
                isAddNew: true,
              });
            }

            return filtered;
          }}
          onChange={(_, newValue) => {
            if (newValue && newValue.isAddNew) {
              if (preventDefaultRef.current) {
                preventDefaultRef.current = true;
                return;
              }
              if (type) {
                copyFromDefault(newValue?.value, selectedValue);
              } else {
                copyFromDefault(newValue?.value);
              }
            } else {
              if (type) {
                const prevSelectedType = selectedValue;
                setSelectedValue(newValue?.value || "");
                const newItem = {
                  ...item,
                  selectedType: newValue?.value,
                  prevSelectedType: prevSelectedType,
                };
                // onChangeProp(newItem);
                dispatch(updateComponent({ id: item?.id, changes: newItem }));
              } else {
                setSelectedValue(newValue?.value || "");
              }
            }
          }}
          renderOption={(props, option) => (
            <li {...props}>
              <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                {option.isAddNew ? <div>Add {option?.value}</div> : <div>{option?.label}</div>}
                {option.value !== "default" && !option.isAddNew && (
                  <IconTrashX
                    style={{
                      fontSize: "12px",
                    }}
                    onClick={e => {
                      e.stopPropagation();
                      deleteValue(option?.value);
                    }}
                  />
                )}
                {option.isAddNew && (
                  <Box display='flex' alignItems='center' justifyContent='center' gap='10px'>
                    <Tooltip title='Add a new chain'>
                      <IconCirclePlus
                        style={{
                          fontSize: "12px",
                        }}
                        onClick={e => {
                          preventDefaultRef.current = true;
                          if (type) {
                            addNewValue(option?.value, selectedValue);
                          } else {
                            addNewValue(option?.value);
                          }
                        }}
                      />
                    </Tooltip>
                    <Tooltip title='Copy from an existing chain'>
                      <IconCopy
                        style={{
                          fontSize: "12px",
                        }}
                        onClick={e => {
                          preventDefaultRef.current = true;
                          setNewValue(option?.value);
                          setIsCopyValueModalOpen(true);
                        }}
                      />
                    </Tooltip>
                  </Box>
                )}
              </div>
            </li>
          )}
          renderInput={params => <TextField {...params} label='Value' />}
        />
      </Grid>
      <Grid item xs={2}></Grid>
      <BXModal
        label='Copy from an existing value'
        maxWidth='sm'
        title='Copy from an existing value'
        icon={<IconCopy />}
        open={isCopyValueModalOpen}
        onClose={() => setIsCopyValueModalOpen(false)}
      >
        {(handleClose: Function) => {
          return (
            <>
              <TextField
                size='medium'
                select
                fullWidth
                value={selectedSource}
                onChange={event => setSelectedSource(event.target.value)}
                sx={{
                  marginBottom: 2,
                  fontSize: "14px",
                }}
              >
                {valueOptions.map(option => (
                  <MenuItem key={option?.value} value={option?.value}>
                    {option?.label}
                  </MenuItem>
                ))}
              </TextField>

              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  const source = valueOptions.find(option => option.value === selectedSource);
                  if (type) {
                    copyFromValue(newValue, selectedSource, selectedValue);
                  } else {
                    copyFromValue(newValue, source);
                  }
                  setIsCopyValueModalOpen(false);
                  setSelectedValue(newValue);
                  handleClose();
                }}
                sx={{
                  alignSelf: "flex-end",
                  paddingX: 3,
                  paddingY: 1,
                  fontSize: "14px",
                }}
              >
                Add
              </Button>
            </>
          );
        }}
      </BXModal>
    </Grid>
  );
};
