import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { formBuilderPath } from "src/config";
import { CustomAccordionProps } from "./types";

const useStyles = makeStyles(() => ({
  accordionSummary: {
    backgroundColor: "black",
  },
}));

export const CustomAccordion = (props: CustomAccordionProps) => {
  const { children, label, defaultExpanded = false, __props, $builderProps, $children } = props;

  const classes = useStyles();
  const location = useLocation();
  const [expanded, setExpanded] = useState(location.pathname.startsWith(formBuilderPath) ? true : defaultExpanded);

  const handleChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  return (
    <Accordion expanded={expanded} onChange={handleChange} sx={{ display: "contents" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel-content'
        id='panel-header'
        className={classes.accordionSummary}
      >
        <Typography>{label || "Accordion Label"}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ display: "contents" }}>
        {location.pathname.startsWith(formBuilderPath) ? (
          <>
            {$children?.map((item: any, index: number) => {
              return (
                // <BoxComponent
                //   {...$builderProps}
                //   key={`${index}`}
                //   itemIndex={index}
                //   item={item}
                //   active={$builderProps?.selectedItemsId?.includes(item?.id)}
                // />
                <></>
              );
            })}
          </>
        ) : (
          <>
            {/* TODO: @Laith use render children instead */}
            {/* <RenderItems {...__props} data={children} /> */}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
