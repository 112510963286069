import { BoxProps } from "@mui/material";
import CustomContainerImage from "../../../../assets/images/icons/custom.svg";
import GridContainerImage from "../../../../assets/images/icons/GridContainer.svg";
import FlexContainerImage from "../../../../assets/images/icons/rectangle.svg";
import { CustomContainerProps } from "./components/CustomContainer";
import { ComponentItemType } from "./types";

export const flexContainerConfig = {
  type: ComponentItemType.FlexContainer,
  props: {
    sx: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: "10px",
      padding: "10px",
      flexDirection: "column",
    },
  } as BoxProps,
  config: {
    defaultWidth: 200,
    defaultHeight: 100,
    minWidth: 0,
    minHeight: 0,
    fixedWidth: true,
    isPercentageHeight: false,
    isDynamicWidth: false,
    position: {
      type: {
        lg: "fixed",
      },
    },
    sideMenuSx: {
      border: "1px dashed #57585c",
      minHeight: "100px",
    },
    placeholderConfig: {
      title: "Flex",
      image: FlexContainerImage,
      group: "container",
    },
  },
};

export const gridContainerConfig = {
  type: ComponentItemType.GridContainer,
  props: {
    sx: {
      width: "100%",
      height: "100%",
      display: "grid",
      gap: "10px",
    },
  } as BoxProps,
  config: {
    defaultWidth: 200,
    defaultHeight: 100,
    minWidth: 0,
    minHeight: 0,
    fixedWidth: false,
    isPercentageHeight: false,
    sideMenuSx: {
      border: "1px dashed #57585c",
      minHeight: "100px",
    },
    placeholderConfig: {
      title: "Grid",
      image: GridContainerImage,
      group: "container",
    },
  },
};

export const customContainerConfig = {
  type: ComponentItemType.CustomContainer,
  props: {
    sx: {
      width: "100%",
      height: "100%",
      minHeight: "100px",
    },
  } as CustomContainerProps,
  config: {
    defaultWidth: 200,
    defaultHeight: 100,
    minWidth: 0,
    minHeight: 0,
    fixedWidth: true,
    isPercentageHeight: false,
    sideMenuSx: {
      border: "1px dashed #57585c",
    },
    placeholderConfig: {
      title: "Container",
      image: CustomContainerImage,
      group: "container",
    },
  },
};
