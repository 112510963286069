import colorNameList from "color-name-list";
import _ from "lodash";
import moment from "moment";
import { PixelCrop } from "react-image-crop";
import { appVersion } from "src/config";
import { upsertComponents } from "src/features/builder/builderSlice";
import { selectComponentById } from "src/features/builder/selectors";
import store from "src/store/store";
import { BXApp } from "src/types/BXAppType";
import { v4 as uuid } from "uuid";
import { normalizePathWithPlaceholders } from "./Mappers/normalizePathWithPlaceholders";
import { apolloClient, queryClient } from "src/features/buildxProvider/buildxProviderUtils";

export const getLastKeyFromObject = (obj = {} as any) => {
  const keys = Object.keys(obj);
  return keys[keys?.length - 1];
};

export const reverseDataOfObjectValues = (obj = {} as any) => {
  const values = Object.values(obj).reverse();
  const result = {} as any;
  Object.keys(obj).forEach((key, index) => {
    result[key] = values[index];
  });

  return result;
};

export const getHashesFromString = (string = "") => {
  return string
    .split("")
    .filter((key: any) => key == "#")
    .join("");
};

export function dereferenceOAS(oas: any) {
  // Create a map to store the dereferenced definitions
  const definitions = new Map();

  // Recursively dereference the definitions
  function dereference(obj: any): any {
    // If the object is a reference, look up the definition and return it
    if (obj.$ref) {
      const definition = definitions.get(obj.$ref);
      return definition ? dereference(definition) : obj;
    }

    // If the object is an array, dereference each element in the array
    if (Array.isArray(obj)) {
      return obj.map(dereference);
    }

    // If the object is an object, dereference each property in the object
    if (typeof obj === "object") {
      const dereferencedObj: any = {};
      for (const key of Object.keys(obj)) {
        dereferencedObj[key] = dereference(obj[key]);
      }
      return dereferencedObj;
    }

    // If the object is not a reference or an object, return it as is
    return obj;
  }

  // Determine which property to use for the definitions, depending on the OAS version
  let definitionsProperty;
  if (oas.swagger === "2.0") {
    definitionsProperty = "definitions";
  } else if (oas.openapi) {
    definitionsProperty = "components.schemas";
  } else {
    throw new Error("Unrecognized OAS version");
  }

  // Populate the definitions map with the dereferenced definitions from the OAS object
  if (_.get(oas, definitionsProperty)) {
    for (const definitionName of Object.keys(_.get(oas, definitionsProperty))) {
      definitions.set(`#/${definitionsProperty.replace(".", "/")}/${definitionName}`, _.get(oas, definitionsProperty)[definitionName]);
    }
  }

  // Dereference the OAS object and return it
  return dereference(oas);
}

export const convertToProperCase = (text: string) => {
  // Split the text into an array of words
  const words = text.split(/(?=[A-Z])/);

  // Map the array of words to an array of capitalized words
  const capitalizedWords = words.map(word => {
    // Get the first letter of the word and convert it to uppercase
    const firstLetter = word[0].toUpperCase();

    // Get the rest of the word and convert it to lowercase
    const rest = word.slice(1).toLowerCase();

    // Concatenate the first letter and the rest of the word
    return firstLetter + rest;
  });

  // Join the array of capitalized words with spaces and return the result
  return capitalizedWords.join(" ");
};

export async function getCroppedImageFile(image: HTMLImageElement, canvas: HTMLCanvasElement, crop: PixelCrop, callback?: any) {
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;

  const pixelRatio = window.devicePixelRatio;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);

  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight);
  canvas.toBlob(function (blob) {
    var file = new File([blob!], "coppedImage", { type: blob!.type });

    callback?.(file);
  });
  ctx.restore();
}

export const getAuthorizationHeader = (auth: any, token?: any) => {
  const authorization: any = {
    Authorization: undefined,
  };
  const authType = auth?.type;
  if (authType === "Buildx") {
    authorization.Authorization = !auth?.authApi?.uri ? "" : "Bearer " + token;
  } else if (authType === "API Key") {
    authorization[auth?.keyValue!] = auth?.apiValue;
  } else if (authType === "Basic Auth") {
    authorization.Authorization = "Basic " + btoa(auth?.basicAuthUsername + ":" + auth?.basicAuthPassword);
  } else if (authType === "No Auth") {
  } else {
    authorization.Authorization = !auth?.authApi?.uri && authType ? "" : "Bearer " + token || localStorage.getItem("accessToken");
  }
  return authorization;
};

export function compareVersions(version1: string, version2: string) {
  const v1Parts = version1.split(".");
  const v2Parts = version2.split(".");

  for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
    const v1Part = parseInt(v1Parts[i], 10) || 0;
    const v2Part = parseInt(v2Parts[i], 10) || 0;

    if (v1Part > v2Part) {
      return 1;
    } else if (v1Part < v2Part) {
      return -1;
    }
  }

  return 0;
}

export function checkVersion(version?: string) {
  if (!version) return false;
  if (compareVersions(version, appVersion) >= 0) {
    return true;
  } else {
    return false;
  }
}

export function splitTemplateConfig(app: BXApp) {
  const upCollections = app?.templateConfig?.collections?.filter(col => col.pages.some(p => p.unprotectedPage));
  const _upTemplateConfigCollections = upCollections?.map(col => ({
    ...col,
    pages: col.pages.filter(p => p.unprotectedPage),
  }));

  const _upTemplateConfig = {
    //More necessary properties for this template config
    ...app?.templateConfig,
    collections: _upTemplateConfigCollections,
  };
  return _upTemplateConfig;
}

export const isUnixTimestamp = (value: any) => {
  if (typeof value !== "number") {
    return false; // It's not a number, so it can't be a timestamp.
  }

  // Define reasonable bounds for the timestamp range (adjust as needed).
  const lowerBound = 0;
  const upperBound = 2147483648000; // January 19, 2038 in milliseconds.

  return value >= lowerBound && value <= upperBound;
};

export const isValidDate = (dateString: any) => {
  // Attempt to parse the input string as a Date
  const parsedDate = new Date(dateString);
  const parsedDate2 = new Date(Number(dateString));

  // Check if the parsed date is a valid date and the input string is not empty
  return !isNaN(parsedDate.getTime()) || !isNaN(parsedDate2.getTime());
};

export const returnArrayValue = data => (_.isArray(data) ? data : [data]);

export function removeEmptyQueryParams(url: string): string {
  try {
    const urlObject = new URL(url);
    const queryParams = new URLSearchParams(urlObject.search);

    // Iterate through each query parameter
    queryParams.forEach((value, key) => {
      // Check if the parameter has an empty value
      if (value === "") {
        // Delete the parameter if the value is empty
        queryParams.delete(key);
      }
    });

    // Update the search portion of the URL with the modified query parameters
    urlObject.search = queryParams.toString();

    return urlObject.toString();
  } catch (error) {
    // If an error occurs, you can choose to return the original URL or handle it in a specific way.
    return url;
  }
}

const updatedChildrenProps = (item: any, newId: any) => {
  let updatedProps;
  let updatedConfig = {
    ...item?.config,
  };
  if (item?.type === "CustomCheckbox" || item?.type === "CustomRadio") {
    updatedProps = {
      ...item?.props,
      defaultValue: "",
      groupName: newId,
    };
  } else {
    updatedProps = {
      ...item?.props,
      defaultValue: "",
    };
  }
  return { updatedConfig, updatedProps };
};

export const updateElementsIds = (data: any, newId: any) => {
  const result = data.map((item: any) => {
    const { updatedConfig, updatedProps } = updatedChildrenProps(item, newId);
    const camelCaseType = item?.type ? toCamelCase(item.type) : "";
    const newItem = {
      ...item,
      id: uuid(),
      props: {
        ...updatedProps,
        id: `${camelCaseType}-${uuid()}`,
        key: `${camelCaseType}-${uuid()}`,
      },
      config: { ...updatedConfig },
    };
    if (item.children && item.children.length > 0) {
      newItem.children = updateElementsIds(item.children, item);
    }
    return newItem;
  });

  return result;
};

export const updateElementsIdsNew = (childrenIds: any, newId: any, randomId: any) => {
  const result = childrenIds.map((childId: any) => {
    const item = selectComponentById(store.getState(), childId);
    if (!item) return null;
    const { updatedConfig, updatedProps } = updatedChildrenProps(item, newId);
    const camelCaseType = item?.type ? toCamelCase(item.type) : "";
    const newIdItem = uuid();
    const newKey = `${camelCaseType}-${uuid()}`;
    const newItem = {
      ...item,
      id: newIdItem,
      props: {
        ...updatedProps,
        id: newKey,
        key: newKey,
      },
      config: { ...updatedConfig },
      parentId: randomId,
    };
    if (item.children && item.children.length > 0) {
      newItem.children = updateElementsIdsNew(item.children, newKey, newIdItem);
    }

    store.dispatch(upsertComponents([{ ...newItem, skipHistory: true }]));
    return newItem.id;
  });

  return result;
};

export interface RGBVariants {
  r: number | null;
  g: number | null;
  b: number | null;
  rHex: string | null;
  gHex: string | null;
  bHex: string | null;
  rgb: string | null;
  hex: string | null;
  _actualHexColor?: string;
}

function isValidHex(hex: string): boolean {
  return /^#?([0-9A-Fa-f]{3}){1,2}$/.test(hex);
}

export function colorToRgbVariants(color: string): RGBVariants {
  const _actualColor = color;
  let r: number | null = null;
  let g: number | null = null;
  let b: number | null = null;

  const rgbRegex = /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/i;
  const rgbMatch = color?.match(rgbRegex);

  if (rgbMatch) {
    r = parseInt(rgbMatch[1]);
    g = parseInt(rgbMatch[2]);
    b = parseInt(rgbMatch[3]);
  } else {
    const colorHex = colorNameList?.find(c => c?.name?.toLowerCase() === color?.toLowerCase());
    if (colorHex) color = colorHex.hex;

    if (!isValidHex(color)) {
      return {
        r: null,
        g: null,
        b: null,
        rHex: null,
        gHex: null,
        bHex: null,
        rgb: null,
        hex: null,
        _actualHexColor: color,
      };
    }

    color = color.replace(/^#/, "");
    const hexValue = `#${color}`;

    if (color.length === 3) {
      color = color
        .split("")
        .map(char => char + char)
        .join("");
    }

    const bigint = parseInt(color, 16);
    r = (bigint >> 16) & 255;
    g = (bigint >> 8) & 255;
    b = bigint & 255;

    color = hexValue;
  }

  const rHex = r?.toString(16).padStart(2, "0") || null;
  const gHex = g?.toString(16).padStart(2, "0") || null;
  const bHex = b?.toString(16).padStart(2, "0") || null;
  const rgb = r !== null && g !== null && b !== null ? `rgb(${r},${g},${b})` : null;

  return {
    r,
    g,
    b,
    rHex,
    gHex,
    bHex,
    rgb,
    hex: rHex && gHex && bHex ? `#${rHex}${gHex}${bHex}` : null,
    _actualHexColor: _actualColor,
  };
}

interface HandleRefetchQueriesParams {
  isGraphQL?: boolean;
  queriesStateGraphQL: Record<string, any>;
  key: string;
  queryKeys?: any;
  setIsRefreshing?: (value: boolean) => void;
  produceTrigger?: any;
  pageId?: string;
  viewName?: string;
}

export const handleRefetchQueries = async ({
  isGraphQL,
  queriesStateGraphQL,
  key,
  queryKeys,
  setIsRefreshing,
  produceTrigger,
  pageId,
  viewName,
}: HandleRefetchQueriesParams) => {
  try {
    if (isGraphQL) {
      await apolloClient.refetchQueries({ include: [queriesStateGraphQL[key]] });
    } else {
      await new Promise((resolve, reject) => {
        produceTrigger(key, "refetch", { resolver: resolve }, { pageId, viewName });
      });
    }
  } finally {
    if (setIsRefreshing) {
      setIsRefreshing(false);
    }
  }
};

export const handleCleanupQueries = async ({ isGraphQL, gqlQuery, queryKeys }) => {
  if (isGraphQL) {
    apolloClient.cache.evict({ id: gqlQuery });
  } else {
    queryClient.setQueryData(queryKeys, () => ({
      pages: [],
    }));
  }
};

export const handleSetQueryData = ({ isGraphQL, gqlQuery, queryKeys, payload, variables }) => {
  if (isGraphQL) {
    apolloClient.writeQuery({
      query: gqlQuery,
      data: payload,
      variables,
    });
  } else {
    queryClient.setQueryData(queryKeys, payload);
  }
};

export const handleGetQueryData = ({ isGraphQL, queriesStateGraphQL, queryKey }) => {
  if (isGraphQL) {
    const queryResult = apolloClient.readQuery({
      query: queriesStateGraphQL[queryKey],
    }) as any;
    return queryResult;
  } else {
    const queryResult = queryClient.getQueryData([queryKey], { active: true, exact: false }) as any;
    return queryResult;
  }
};

export const formatDate = (
  value: number | string | null | undefined,
  formatString?: string,
  fallback = "",
  useLocalTime = true
): string => {
  if (_.isNil(value)) {
    return fallback;
  }
  const actualValue = Array.isArray(value) ? value[0] : value;
  const numericValue = typeof actualValue === "string" && /^\d+$/.test(actualValue) ? Number(actualValue) : actualValue;
  let date;
  if (typeof numericValue === "number" && !isNaN(numericValue)) {
    if (numericValue.toString().length === 10) {
      date = moment.unix(numericValue);
    } else {
      date = moment(numericValue);
    }
  } else {
    date = moment(actualValue, moment.ISO_8601, true);
  }
  if (!useLocalTime) {
    return date.utc().isValid() ? date.utc().format(formatString) : fallback;
  }
  return date.isValid() ? date.format(formatString) : fallback;
};
export const decodeMarkdown = (encodedMarkdown = "") => {
  // Decode HTML entities
  const decodeHtmlEntities = (str: string) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = str;
    return txt.value;
  };

  let decodedMarkdown = decodeHtmlEntities(encodedMarkdown);

  // Replace escaped newline characters
  decodedMarkdown = decodedMarkdown.replace(/\\n/g, "\n");

  // Remove extra escaped characters
  decodedMarkdown = decodedMarkdown.replace(/\\&quot;/g, '"').replace(/&quot;/g, "");

  return decodedMarkdown;
};

export function generateKeyDescriptor(event) {
  let keys: string[] = [];
  if (event.ctrlKey) keys.push("Ctrl");
  if (event.shiftKey) keys.push("Shift");
  if (event.altKey) keys.push("Alt");
  if (event.key.length === 1) event.key = event.key.toUpperCase();
  if (!event.shiftKey && !event.altKey) {
    keys.push(event.key);
  }
  return keys.join("+");
}

export const toCamelCase = str => {
  return str.replace(/^[A-Z]/, match => match.toLowerCase()).replace(/([-_]\w)/g, match => match.charAt(1).toUpperCase());
};

export const handleCustomMessage = (action, keyMessage, statusMessage) => {
  let statusMessagesIndex;
  let mapValuesObjectsMessages;
  let condition;
  let customMessage;

  if (keyMessage) {
    statusMessagesIndex = action?.statusMessages?.findIndex((item: any) => item?.key == keyMessage);

    if (statusMessagesIndex !== -1 && action.statusMessages[statusMessagesIndex]) {
      mapValuesObjectsMessages = action.statusMessages[statusMessagesIndex].mapValuesObjectsMessage.values || [];
      condition = action.statusMessages[statusMessagesIndex].mapValuesObjectsMessage.condition || "equal";
      customMessage = mapValuesObjectsMessages.default || statusMessage;

      if (mapValuesObjectsMessages) {
        if (condition === "equal" && mapValuesObjectsMessages[statusMessage]) {
          customMessage = mapValuesObjectsMessages[statusMessage];
        } else if (condition === "startWith") {
          const matchingKey = Object.keys(mapValuesObjectsMessages).find(key => statusMessage.startsWith(key));
          if (matchingKey) {
            customMessage = mapValuesObjectsMessages[matchingKey];
          }
        }
      }
    }
  }

  return customMessage;
};

export const isValidTargetPathAfterLogin = (path: string | null, routesMap: Record<string, any>, currentAppId: string): boolean => {
  if (!path) return false;

  const normalizedRoute: string = normalizePathWithPlaceholders(path, routesMap, currentAppId) as string;

  return routesMap.hasOwnProperty(normalizedRoute) && !routesMap[normalizedRoute].isUnprotected;
};

export function validateCollectionAndPage(
  collectionName: string,
  pageName: string | undefined,
  app: any,
  pageId?: string
): {
  isValidCollection: boolean;
  isValidPage: boolean;
} {
  if (!app?.templateConfig?.collections) {
    return { isValidCollection: false, isValidPage: false };
  }
  const collections = app.templateConfig.collections;
  // Handle root collection ("/")
  const rootCollection = collections?.find((col: any) => col.slug === "/");
  const collection = collections?.find((col: any) => col.slug === `/${collectionName}`);
  const isValidCollection =
    !!collection ||
    (!!rootCollection && collectionName === "") ||
    (!pageName && collectionName && rootCollection?.pages?.some((page: any) => page.slug === `/${collectionName}`));

  const isValidPage =
    pageId !== undefined ||
    (isValidCollection && collection && pageName && collection?.pages?.some((page: any) => page.slug === `/${pageName}`)) ||
    (rootCollection &&
      (rootCollection?.pages?.some((page: any) => page.slug === `/${collectionName}/${pageName}`) ||
        (pageName && rootCollection?.pages?.some((page: any) => page.slug === `/${pageName}`)))) ||
    (!pageName && collectionName && rootCollection?.pages?.some((page: any) => page.slug === `/${collectionName}`));

  return { isValidCollection, isValidPage };
}
