import _ from "lodash";
import { BXApp } from "src/types/BXAppType";

export function migrateTo1_0_0(app: BXApp) {
  const iterateValues = (obj: any, path?: string) => {
    _.forEach(obj, (value, key) => {
      const currentPath = path ? path + `[${key}]` : key;
      if (_.isObject(value)) {
        iterateValues(value, currentPath);
      } else {
        if (typeof value === "string") {
          obj[key] = value.replace(/\{(?!\s*\})([^{},$]+)\}/g, (match, p1) => {
            if (p1.includes("#")) {
              const hashes = p1?.split(".")?.[0]?.split("")?.join(".");
              return `{${hashes}.data${p1?.replace(p1?.split(".")?.[0], "")}}`;
            } else {
              return `{this.data.${p1.split(".").join(".")}}`;
            }
          });
        }
      }
    });
  };
  iterateValues(app);

  app?.templateConfig?.collections?.map(collection => {
    collection?.pages?.map(page => {
      page?.views?.map(view => {
        if (view?.info) {
          view.info.viewName = view.info?.name?.replace(/[^0-9a-zA-Z\-]/g, "-");
        }
      });
    });
  });
  _.set(app, "templateConfig.appVersion", "1.0.0");
}
