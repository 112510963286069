/**
 * MUI Components whose styles are override as per theme
 * @param {object} theme Plain Json Object
 */

// project imports
import { ThemeOptions } from "@mui/material/styles";
import { CustomTypography } from "src/themes/types";

export default function componentStyleOverrides(
  theme: CustomTypography
): ThemeOptions["components"] & { MuiPickersCalendarHeader: any; MuiDateCalendar: any } {
  const bgColor = theme.colors?.paper;
  return {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(45,49,62, 0.50)",
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: theme.colors?.textPrimary,
          padding: 6,
          "&.Mui-selected": {
            color: "white",
            border: "1px solid white !important",
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        track: {
          backgroundColor: theme.colors?.primaryMain + "!important",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&.MuiIconButton-root": {
            backgroundColor: theme.colors?.lightGrey,
            "&:hover": {
              backgroundColor: theme.colors?.background,
              color: theme.colors?.primaryMain,
            },
          },
          "&.Mui-disabled": {
            color: `${theme.colors?.textPrimary} !important`,
            opacity: 0.5,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          textTransform: "capitalize",
          borderRadius: "4px !important",
        },
        textSuccess: {
          backgroundColor: `rgba(0, 230, 118, 0.05)`,
        },
        textError: {
          backgroundColor: `${theme.colors?.errorLight}09`,
        },
        contained: {
          backgroundColor: theme.colors?.primaryMain,
          borderRadius: 24,
          "&:hover": {
            backgroundColor: theme.colors?.primaryLight,
          },
        },
        outlined: {
          borderRadius: 24,
        },
        outlinedPrimary: {
          borderColor: theme.colors?.primaryMain,
          color: theme.textPrimary,
          "&:hover": {
            borderColor: theme.colors?.primaryMain,
          },
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundImage: "none",
        },
        rounded: {
          borderRadius: `8px`,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderWidth: 0,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.colors?.textDark,
          padding: "24px",
        },
        title: {
          fontSize: "1.125rem",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: "24px",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: "center",
        },
        outlined: {
          border: "1px dashed",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: theme.textPrimary,
          paddingTop: "10px",
          paddingBottom: "10px",
          "&.Mui-selected": {
            color: theme.colors?.primaryMain,
            backgroundColor: theme.menuSelectedBack,
            "&:hover": {
              backgroundColor: theme.colors?.primaryLight,
            },
            "& .MuiListItemIcon-root": {
              color: theme.colors?.primaryMain,
            },
          },
          "&:hover": {
            backgroundColor: theme.colors?.primaryLight,
            color: theme.colors?.primaryMain,
            "& .MuiListItemIcon-root": {
              color: theme.colors?.primaryMain,
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.textPrimary,
          minWidth: "36px",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: theme.textDark,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: theme.textDark,
          "&::placeholder": {
            color: theme.textPrimary,
            fontSize: "0.875rem",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#57585c",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: bgColor,
          borderRadius: 10,
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#53545940",
          },
          "&:hover $notchedOutline": {
            borderColor: theme.colors?.primaryLight,
          },
          "&.MuiInputBase-multiline": {
            padding: 1,
          },
        },
        input: {
          fontWeight: 500,
          background: bgColor,
          padding: "15.5px 14px",
          borderRadius: 10,
          "&.MuiInputBase-inputSizeSmall": {
            padding: "8.2px 14px",
            "&.MuiInputBase-inputAdornedStart": {
              paddingInlineStart: 0,
            },
          },
        },
        inputAdornedStart: {
          paddingInlineStart: 4,
        },
        notchedOutline: {
          borderRadius: 10,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: theme.colors?.textPrimary! + 50,
          },
        },
        mark: {
          backgroundColor: theme.colors?.paper,
          width: "4px",
        },
        valueLabel: {
          color: theme?.colors?.primaryMain,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiAutocomplete-tag": {
            background: theme.colors?.textPrimary! + 20,
            borderRadius: 4,
            color: theme.textDark,
            ".MuiChip-deleteIcon": {
              color: theme.colors?.textPrimary! + 80,
            },
          },
        },
        popper: {
          borderRadius: `1px`,
          boxShadow: "0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%)",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.divider,
          opacity: 0.2,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: theme.colors?.textPrimary,
          "&.Mui-disabled": {
            color: theme.colors?.textPrimary!,
          },
          /** checked not prop
           *"&.Mui-checked": {
           *    fontSize: "28px"
           *}
           */
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: theme.colors?.darkLevel1,
          background: theme.textPrimary,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-deletable .MuiChip-deleteIcon": {
            color: "inherit",
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          borderBottom: "1px solid",
          borderColor: theme.colors?.textPrimary! + 20,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: "12px 0 12px 0",
          border: " 1px solid #303540",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingInlineStart: 24,
          borderColor: theme.colors?.textPrimary! + 15,
          "&.MuiTableCell-head": {
            fontSize: "13px",
            color: theme.heading,
            fontWeight: 600,
            backgroundColor: `${theme.paper} !important`,
          },
          "&.MuiTableCell-body": {
            // paddingTop: 14,
            // paddingBottom: 8,
            fontSize: "14px",
            borderBottom: 0,
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: `${theme.colors?.primaryLight} !important`,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.colors?.textPrimary,
          background: theme.colors?.background,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: "1.25rem",
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        labelContainer: {
          maxHeight: "35px !important",
        },
      },
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          "@media (max-width: 600px)": {
            width: "240px",
          },
        },
      },
    },
  };
}
