import { Box, CircularProgress } from "@mui/material";
import { FC, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import RenderChildren from "src/components/BXUI/FormBuilder/RenderChildren";
import { useAppState } from "src/features/appState/hooks";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import { setEndUser } from "src/features/endUser/endUserSlice";
import normalizeEndUser from "src/features/endUser/normalizeEndUser";
import store from "src/store/store";
import { CustomMediaCard } from "./components";
import { getSharedViews } from "./utils";

interface CustomLoadingViewProps {
  loadingConfig: any;
  pageId: string;
  viewName?: string;
  componentKey?: string;
  [key: string]: any;
}

const defaultFallbackData = <CircularProgress />;

const overlayStyles = (opacity, color) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: "100%",
  height: "100%",
  backgroundColor: color,
  opacity: opacity,
  zIndex: 99999900,
});

const LoadingOverlayComponent = ({ opacity, color }) => (
  <Box>
    {/* Background overlay */}
    <Box sx={{ ...overlayStyles(opacity, color) }}></Box>
  </Box>
);

export const CustomLoadingView: FC<CustomLoadingViewProps> = ({ loadingConfig, pageId, componentKey = "", viewName, ...rest }) => {
  const loadingViewId = loadingConfig?.loadingView;
  const {
    loadingImage,
    isLoadingImageEnabled,
    isLoadingViewEnabled,
    loadingImageType,
    loadingViewType,
    loadingOverlayColor = "#000000",
    loadingOverlayOpacity = "0.5",
  } = loadingConfig;

  const dispatch = useDispatch();
  const currentApp = useBuildxProviderValue("currentApp");
  const loadingView = getSharedViews(currentApp)?.find(view => view?.id === loadingViewId);
  const { watch } = useAppState();

  //*The component key identifies uniquely the page or view that contains the loading view and state
  //Use it to retrieve the current state of loading related to the component, and render based on it.

  const isLoading = watch(`${componentKey}.isLoading`, { pageId, viewName });

  //TODO: Add scale factor

  //Normalize form builder data structure of loading view
  useEffect(() => {
    const topLevel = loadingView?.dataSource?.formBuilder?.filter(item => !item.parentId);
    const normalized = normalizeEndUser(topLevel) as any;
    const endUserArray = normalized?.entities ? Object.values(normalized.entities) : [];
    if (endUserArray.length > 0) {
      const existingEntry: any = store.getState().endUser.application?.pages?.[pageId || 0]?.views?.[loadingView?.info?.viewName || 0];
      if (!existingEntry) {
        dispatch(
          setEndUser({
            pageId: pageId,
            viewName: loadingView?.info?.viewName,
          })
        );
        dispatch(
          setEndUser({
            pageId: pageId,
            viewName: loadingView?.info?.viewName,
            endUserElements: endUserArray,
          })
        );
      }
    }
  }, []);

  // useLayoutEffect(() => {
  //   if (renderItemsRef.current) {
  //     const { width, height } = renderItemsRef.current.getBoundingClientRect();
  //     setDimensions({ width, height });
  //   }
  // }, [loadingView?.dataSource]);

  const LoadingImageComponent = useMemo(() => {
    if (!isLoadingImageEnabled) {
      return null;
    }

    if (loadingImageType === "centered") {
      return (
        <Box
          sx={{
            position: "absolute",
            zIndex: 99999906,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CustomMediaCard src={loadingImage} component={"img"} width={"50%"} height={"50%"} isLoadingImage />
        </Box>
      );
    }

    if (loadingImageType === "cover") {
      //TODO
      return (
        <Box
          sx={{
            position: "absolute",
            zIndex: 99999906,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <CustomMediaCard src={loadingImage} component={"img"} width={"50%"} height={"50%"} isLoadingImage />
        </Box>
      );
    }

    return null;
  }, [loadingImage, isLoadingImageEnabled, loadingImageType]);

  // const [scrollOffset, setScrollOffset] = useState({
  //   x: 0,
  //   y: 0,
  // });
  // const [elementRect, setElementRect] = useState<DOMRect | undefined>(undefined);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     setScrollOffset({
  //       x: window.scrollX,
  //       y: window.scrollY,
  //     });

  //     const element = document.querySelector(`[parent-id="${componentKey}"]`);
  //     if (element) {
  //       setElementRect(element.getBoundingClientRect());
  //     }
  //   };

  //   handleScroll();

  //   window.addEventListener("scroll", handleScroll);
  //   window.addEventListener("resize", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //     window.removeEventListener("resize", handleScroll);
  //   };
  // }, [componentKey]);

  const element = document.getElementById("loadingView");
  const loadingElement = element?.querySelector("[data-bx-key]");
  const loadingViewRect = loadingElement?.getBoundingClientRect();

  const renderItemsRef = useRef<HTMLDivElement | null>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  // Measure RenderItems dimensions
  useLayoutEffect(() => {
    if (renderItemsRef.current) {
      const height = loadingView?.dataSource?.formBuilder?.[0]?.children[0]?.config?.heightPx?.lg;
      const width = loadingView?.dataSource?.formBuilder?.[0]?.children[0]?.config?.widthPx?.lg;
      setDimensions({ width, height });
    }
  }, [loadingView?.dataSource?.formBuilder]);

  // const dx = (elementRect?.left ?? 0) + scrollOffset?.x;
  // const dy = (elementRect?.top ?? 0) + scrollOffset?.y;
  // const leftDistance = Math.abs((window.innerWidth - dx) / 2) - (loadingViewRect?.width as number) / 2;
  // const topDistance = Math.abs((window.innerHeight - dy) / 2) - ((loadingViewRect?.height as number) / 2 - 100);

  if (!isLoading) {
    return <></>;
  }

  const allComponent = store.getState().endUser.application?.pages?.[pageId].views?.[loadingView?.info?.viewName].entities;

  const topLevelIds = Object.values(allComponent)
    .filter((component: any) => component.parentKey === null)
    .map((component: any) => component.props.key);

  return (
    <>
      <LoadingOverlayComponent opacity={loadingOverlayOpacity} color={loadingOverlayColor} />
      {isLoadingImageEnabled && LoadingImageComponent}
      {/* TODO: Add cover option for loading view */}
      {isLoadingViewEnabled && (
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            height: "100vh",
            zIndex: 99999906,
          }}
        >
          <Box
            ref={renderItemsRef}
            sx={{
              position: "absolute",
              top: `calc(50% - ${dimensions.height / 2}px)`,
              left: `calc(50% - ${dimensions.width / 2}px)`,
              zIndex: 99999907,
            }}
          >
            {!!topLevelIds?.length &&
              topLevelIds.map((elementId, idx) => {
                return (
                  <RenderChildren
                    key={elementId}
                    data={elementId}
                    topLevelIds={elementId}
                    elementId={elementId}
                    pageId={pageId}
                    currentApp={currentApp}
                    viewName={loadingView?.info?.viewName}
                    info={{ viewName: loadingView?.info?.viewName }}
                    isLoadingImage
                  />
                );
              })}
          </Box>
        </Box>
      )}
    </>
  );
};
