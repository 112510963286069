import { Box, Card, CardHeader } from "@mui/material";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import { FC, useState } from "react";
import { useReplaceDataPlaceholders } from "src/components/BXUI/DataTable/ActionButton";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import { useFetchData } from "src/hooks/useFetchData";
import Chart, { useChart } from "./chart";
import { CustomSmallSelect } from "./custom-input";

const StyledChart = styled("div")(({ theme }) => ({
  ".apexcharts-tooltip.apexcharts-theme-light": {
    alignContent: "center",
    border: `solid 1px ${theme.palette.divider}`,
    background: `${theme.palette.background.paper}`,
  },
}));

export const SplineChart: FC<any> = ({ options, __data, ...other }) => {
  const { config } = other?.componentData || {};
  const { title, sourceType, subheader, apiMode, categories, series, enableGroup, showYAxis } = config || other?.$configData || {};
  const { groupTitle, responseGroupKey, lineTitle, responseLineKey, fixedLines, valuesKey, chartLineData } = apiMode || {};
  const { id, pageId, info } = other;
  const currentApp = useBuildxProviderValue("currentApp");
  const isAdministrationMode = useBuildxProviderValue("isAdministrationMode");
  const viewsState = useBuildxProviderValue("viewsState");
  const isRTL = useBuildxProviderValue("isRTL");
  const { replaceDataPlaceholders } = useReplaceDataPlaceholders({ viewName: info?.viewName });
  let queryKeys = [id];

  const { data: apiData } = useFetchData({
    queryKeys,
    __data,
    viewId: id,
    pageId,
    dataSource: sourceType === "fixedData" ? undefined : (apiMode as any),
    options: { enabled: !isAdministrationMode },
  });

  const [seriesIndex, setSeriesIndex] = useState<number | string>(0);

  const chartOptions = useChart({
    legend: {
      position: "top",
      horizontalAlign: "right",
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      show: showYAxis,
      opposite: isRTL,
    },
    fill: {
      type: "solid", 
      opacity: 0, 
    },
    series: series?.map(s => ({
      ...s,
      fill: {
        type: "solid",
        opacity: 0, 
      },
    })),
    ...options,
  });

  const responseGroupsArr = _.get(apiData, responseLineKey);

  const responseCurrentLineData = _.get(responseGroupsArr?.[seriesIndex], responseGroupKey);

  return (
    <Card sx={{ height: "100%" }}>
      {sourceType === "fixedData" ? (
        <>
          <CardHeader
            title={title}
            subheader={subheader}
            action={
              enableGroup &&
              series.length > 0 && (
                <CustomSmallSelect
                  onChange={event => {
                    setSeriesIndex(event.target.value);
                  }}
                >
                  {series.map((option, index) => (
                    <option key={option.type} value={index}>
                      {option.type}
                    </option>
                  ))}
                </CustomSmallSelect>
              )
            }
          />
          <Box sx={{ mx: 3 }}>
            <StyledChart>
              <Chart type='area' series={series?.[seriesIndex]?.data} options={chartOptions} />
            </StyledChart>
          </Box>
        </>
      ) : (
        <>
          <CardHeader
            title={title}
            subheader={subheader}
            action={
              responseCurrentLineData?.length > 0 && (
                <CustomSmallSelect
                  value={seriesIndex}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSeriesIndex(event.target.value);
                  }}
                >
                  {responseGroupsArr?.map((option, index) => (
                    <option key={_.get(option, groupTitle)} value={index}>
                      {_.get(option, groupTitle)}
                    </option>
                  ))}
                </CustomSmallSelect>
              )
            }
          />
          <Box sx={{ mx: 3 }}>
            <StyledChart>
              <Chart
                type='area'
                series={
                  fixedLines
                    ? chartLineData?.map((line, index) => {
                        return {
                          name: replaceDataPlaceholders({
                            queryString: line.name,
                            chart: {
                              line: responseCurrentLineData?.[index],
                            },
                            viewsState,
                            pageId,
                            env: currentApp?.env,
                          }),
                          data: replaceDataPlaceholders({
                            queryString: line.data,
                            chart: {
                              line: responseCurrentLineData?.[index],
                            },
                            viewsState,
                            pageId,
                            env: currentApp?.env,
                          }),
                        };
                      })
                    : responseCurrentLineData?.map(line => {
                        return {
                          name: _.get(line, lineTitle),
                          data: _.get(line, valuesKey),
                        };
                      })
                }
                options={chartOptions}
              />
            </StyledChart>
          </Box>
        </>
      )}
    </Card>
  );
};
