import { Autocomplete, Box, Button, Checkbox, Grid, IconButton, MenuItem, Switch, TextField, Typography } from "@mui/material";
import _ from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BXIcon } from "src/components/BXUI/Icon";
import { updateComponent } from "src/features/builder/builderSlice";
import { selectAllComponentsArray, selectComponentById, selectComponentPropertyByPath } from "src/features/builder/selectors";
import store from "src/store/store";
import DraggableRow from "../AppBuilder/forms/DraggableRow";

interface PageData {
  pageReference: string;
  componentId?: string;
  customPageSelected?: boolean;
  isChildren?: boolean;
}

interface StepperGroupData {
  pages: PageData[];
  worksWith: "currentPage" | "allPages" | "customPages";
  enableLooping: boolean;
  selectedPage: number | string;
}

const EMPTY_PAGE: PageData = { pageReference: "", customPageSelected: false };

const PageReferenceField = ({ disabled, value, onChange, options, label }) => (
  <Grid item xs={6} container alignItems='center' justifyContent='center'>
    {disabled ? (
      <TextField
        value={value.pageReference || ""}
        label={label}
        fullWidth
        disabled
        size='small'
        InputLabelProps={{ style: { fontSize: "12px" } }}
        InputProps={{ style: { fontSize: "12px" } }}
      />
    ) : (
      <Autocomplete
        value={options.find(component => component?.id === value.componentId) || null}
        options={options}
        getOptionLabel={component => component?.props?.key || ""}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        onChange={(_event, value) => onChange(value)}
        fullWidth
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            size='small'
            fullWidth
            InputLabelProps={{ style: { fontSize: "12px" } }}
            InputProps={{
              ...params.InputProps,
              style: { fontSize: "12px" },
            }}
          />
        )}
      />
    )}
  </Grid>
);

const Page = ({ value, onChange, onDelete, disabled, showCheckbox, stepperId, stepperKey, groupData }) => {
  const dispatch = useDispatch();
  const componentsArray = useSelector(state => selectAllComponentsArray(state));
  const isSelected = useSelector(state => selectComponentById(state, value.componentId)?.config?.isVisiblePreviewByStepper);
  const selectedComponent = useSelector(state => selectComponentById(state, value.componentId));

  useEffect(() => {
    if (value.componentId && selectedComponent?.props?.key !== value.pageReference) {
      onChange({
        ...value,
        pageReference: selectedComponent?.props?.key || "",
      });
    }
  }, [selectedComponent?.props?.key, value.componentId]);

  const handleComponentChange = newComponent => {
    const oldComponentId = value.componentId;
    const newComponentId = newComponent?.id;

    if (oldComponentId) {
      const oldItem = selectComponentById(store.getState(), oldComponentId);
      if (oldItem) {
        dispatch(
          updateComponent({
            id: oldComponentId,
            changes: {
              ...oldItem,
              config: {
                ...oldItem.config,
                stepperParent: null,
                isVisiblePreviewByStepper: false,
              },
            },
          })
        );
      }
    }

    if (newComponentId) {
      const newItem = selectComponentById(store.getState(), newComponentId);
      if (newItem) {
        dispatch(
          updateComponent({
            id: newComponentId,
            changes: {
              ...newItem,
              config: {
                ...newItem.config,
                stepperParent: stepperKey,
                isVisiblePreviewByStepper: false,
              },
            },
          })
        );

        onChange({
          ...value,
          componentId: newComponentId,
          pageReference: newItem.props?.key || "",
        });
      }
    }
  };

  const handleVisibilityChange = (isVisible: boolean) => {
    if (!value.componentId) return;

    if (!isVisible) return;

    groupData.pages.forEach(page => {
      if (page.componentId) {
        const item = selectComponentById(store.getState(), page.componentId);
        if (item) {
          dispatch(
            updateComponent({
              id: page.componentId,
              changes: {
                ...item,
                config: {
                  ...item.config,
                  isVisiblePreviewByStepper: false,
                },
              },
            })
          );
        }
      }
    });

    const currentItem = selectComponentById(store.getState(), value.componentId);
    if (currentItem) {
      dispatch(
        updateComponent({
          id: value.componentId,
          changes: {
            ...currentItem,
            config: {
              ...currentItem.config,
              isVisiblePreviewByStepper: true,
            },
          },
        })
      );
    }
  };

  const handleDelete = () => {
    if (value.componentId) {
      const item = selectComponentById(store.getState(), value.componentId);
      if (item) {
        dispatch(
          updateComponent({
            id: value.componentId,
            changes: {
              ...item,
              config: {
                ...item.config,
                stepperParent: null,
                isVisiblePreviewByStepper: null,
              },
            },
          })
        );
      }
    }
    onDelete();
  };

  return (
    <Grid container item spacing={1} alignItems='center' justifyContent='center' minWidth='326px'>
      {showCheckbox && (
        <Grid item xs={1} container alignItems='center' justifyContent='center'>
          <Checkbox
            checked={value.customPageSelected}
            onChange={e => onChange({ ...value, customPageSelected: e.target.checked })}
            size='small'
          />
        </Grid>
      )}
      <Grid item xs={showCheckbox ? 1 : 2} container alignItems='center' justifyContent='center'>
        <BXIcon icon='Menu' height={20} width={20} style={{ cursor: "grab" }} />
      </Grid>

      <PageReferenceField
        disabled={disabled}
        value={value}
        onChange={handleComponentChange}
        options={componentsArray?.filter((component: any) => stepperId !== component?.id)}
        label='Page reference'
      />

      <Grid item xs={2} container alignItems='center' justifyContent='center'>
        <Switch checked={isSelected} onChange={e => handleVisibilityChange(e.target.checked)} size='small' />
      </Grid>
      <Grid item xs={2} container alignItems='center' justifyContent='center'>
        <IconButton onClick={handleDelete}>
          <BXIcon icon='Trash' width={16} height={16} color='red' />
        </IconButton>
      </Grid>
    </Grid>
  );
};

const Group = ({ onChange, stepperId }: { onChange: (data: StepperGroupData) => void; stepperId: string }) => {
  const groupData = useSelector(state => selectComponentPropertyByPath(state, stepperId, "props.defaultValue")) || {
    selectedPage: 0,
    pages: [],
    worksWith: "currentPage",
    enableLooping: false,
  };
  const stepperKey = useSelector(state => selectComponentPropertyByPath(state, stepperId, "props.key"));

  const updateData = (updates: Partial<StepperGroupData>) => {
    const newData = { ...groupData, ...updates };
    onChange(newData);
  };

  return (
    <Grid container item spacing={1} gap={2} minWidth='326px'>
      <Grid container item xs={12} alignItems='center' justifyContent='space-between'>
        <Typography fontSize='12px'>Enable Looping</Typography>
        <Switch checked={groupData?.enableLooping} onChange={e => updateData({ enableLooping: e.target.checked })} size='small' />
      </Grid>

      <Grid item container xs={12} gap={1}>
        <TextField
          value={groupData.selectedPage}
          onChange={e => updateData({ selectedPage: e.target.value })}
          label='index or reference'
          fullWidth
          size='small'
          InputLabelProps={{ style: { fontSize: "12px" } }}
          InputProps={{ style: { fontSize: "12px" } }}
        />
        <TextField
          select
          value={groupData.worksWith}
          onChange={e => updateData({ worksWith: e.target.value as StepperGroupData["worksWith"] })}
          label='works with'
          fullWidth
          size='small'
          InputLabelProps={{ style: { fontSize: "12px" } }}
          InputProps={{ style: { fontSize: "12px" } }}
        >
          <MenuItem value='currentPage'>Current Page</MenuItem>
          <MenuItem value='allPages'>All Pages</MenuItem>
          <MenuItem value='customPages'>Custom Pages</MenuItem>
        </TextField>
      </Grid>

      {groupData.pages.map((page, index) => (
        <DraggableRow
          key={index}
          component={Box}
          id={`page-${index}`}
          index={index}
          name='stepper-pages'
          moveElement={(dragIndex, hoverIndex) => {
            const newPages = [...groupData.pages];
            const [removed] = newPages.splice(dragIndex, 1);
            newPages.splice(hoverIndex, 0, removed);
            updateData({ pages: newPages });
          }}
        >
          <Page
            value={page}
            onChange={newPage => {
              const newPages = [...groupData.pages];
              newPages[index] = newPage;
              updateData({ pages: newPages });
            }}
            onDelete={() => {
              const newPages = groupData.pages.filter((_, i) => i !== index);
              updateData({ pages: newPages });
            }}
            disabled={!!page.isChildren}
            showCheckbox={groupData.worksWith === "customPages"}
            stepperId={stepperId}
            stepperKey={stepperKey}
            groupData={groupData}
          />
        </DraggableRow>
      ))}

      <Grid item alignItems='center'>
        <Button onClick={() => updateData({ pages: [...groupData.pages, EMPTY_PAGE] })} variant='contained' style={{ height: "30px" }}>
          Add Page
        </Button>
      </Grid>
    </Grid>
  );
};

const StepperConfiguration = ({ componentId, deleteComponent }) => {
  const dispatch = useDispatch();

  return (
    <Grid container spacing={2} p={2} gap={2} overflow='auto'>
      <Group
        onChange={newStepperGroup => {
          const item = selectComponentById(store.getState(), componentId);
          if (item) {
            dispatch(
              updateComponent({
                id: componentId,
                changes: {
                  ...item,
                  props: {
                    ...item.props,
                    defaultValue: _.cloneDeep(newStepperGroup),
                  },
                },
              })
            );
          }
        }}
        stepperId={componentId}
      />
    </Grid>
  );
};

export default StepperConfiguration;
