import { MenuItem, TextField } from "@mui/material";
import { FC } from "react";
import { useFetchData } from "src/hooks/useFetchData";

const BXSelect: FC<any> = props => {
  const { id, pageId, __data, $configData, ...rest } = props;
  const { key, dataEntry, selectValues, sourceType, source } = $configData || {};

  const endpoint = source?.payload?.uri;
  let queryKey = [id];
  let { data: computedOptions } = useFetchData({
    queryKeys: queryKey,
    __data,
    viewId: id,
    pageId,
    endpoint,
    dataSource: sourceType === "LIST" ? undefined : (source as any),
    dataEntry,
    options: {
      enabled: true,
    },
  });

  if (sourceType === "LIST") {
    computedOptions = selectValues;
  }

  const options = key ? computedOptions?.map(option => option[key]) : computedOptions || [];

  return (
    <TextField {...rest}>
      {options?.map(option => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default BXSelect;
