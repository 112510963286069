import { Box, Button, Typography } from "@mui/material";
import { IconX } from "@tabler/icons-react";
import { SnackbarProvider } from "notistack";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import { closeSnackbarRef } from "./SnackbarUtilsConfigurator";

export default function CustomSnackbarProvider({ children }) {
  const currentApp = useBuildxProviderValue("currentApp");
  const isRTL = useBuildxProviderValue("isRTL");
  const { maxSnack, duration, position, variant, closeButton, backgroundColorAdvance, colorAdvance, iconAdvance, iconColor, alignText } =
    currentApp?.appConfig?.snackbar || {};
  const autoHideDuration = duration ? Number(duration) * 1000 : 5000;
  const anchorOrigin = position
    ? {
        vertical: position.split("-")[0] === "top" || position.split("-")[0] === "bottom" ? position.split("-")[0] : "bottom",
        horizontal:
          position.split("-")[1] === "center" || position.split("-")[1] === "left" || position.split("-")[1] === "right"
            ? position.split("-")[1]
            : isRTL
            ? "left"
            : "right",
      }
    : { vertical: "bottom", horizontal: isRTL ? "left" : "right" };

  const actionButton = key =>
    closeButton == "Icon X" || closeButton == "Dismiss" ? (
      <Button
        onClick={() => {
          closeSnackbarRef(key);
        }}
      >
        {closeButton == "Icon X" && <IconX color='white' size={18} />}
        {closeButton == "Dismiss" && <Typography color={"white"}>Dismiss</Typography>}
      </Button>
    ) : (
      <Box
        style={{
          overflow: "hidden",
          height: "100%",
          cursor: "pointer",
          position: "absolute",
          top: "5px",
          right: "5px",
        }}
      >
        <IconX
          color='white'
          size={14}
          onClick={() => {
            closeSnackbarRef(key);
          }}
        />
      </Box>
    );
  const action = closeButton !== "Without Button" ? actionButton : null;

  return (
    <SnackbarProvider maxSnack={maxSnack} autoHideDuration={autoHideDuration} anchorOrigin={anchorOrigin} action={action} variant={variant}>
      {children}
    </SnackbarProvider>
  );
}
