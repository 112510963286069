import { Divider, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { setViewBuilder } from "src/features/builder/builderSlice";

const DuplicatesKeysTable = ({ duplicates = [] }: any) => {
  const dispatch = useDispatch();

  const allIds = duplicates.map((duplicate): any => duplicate.id);
  dispatch(setViewBuilder({ selectedItemsId: allIds, activeComponent: undefined, addToHistory: false }));

  // Group duplicates by key
  const groupedByKey = duplicates.reduce((acc, item: any) => {
    if (!acc[item.key]) {
      acc[item.key] = [];
    }
    acc[item.key].push(item.type);
    return acc;
  }, {});

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Type</TableCell>
          <TableCell>Key</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.entries(groupedByKey).map(([keyValue, typeArray]: any) => (
          <React.Fragment key={keyValue}>
            {typeArray.map((typeItem, index) => (
              <TableRow key={`${keyValue}-${index}`}>
                <TableCell>{typeItem}</TableCell>
                <TableCell>{keyValue}</TableCell>
              </TableRow>
            ))}

            <TableRow
              sx={{
                "&:hover": {
                  backgroundColor: "transparent",
                },
                cursor: "default",
              }}
            >
              <TableCell
                colSpan={2}
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                  cursor: "default",
                }}
              >
                <Divider sx={{ my: 1 }} />
              </TableCell>
            </TableRow>
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
  );
};

export default DuplicatesKeysTable;
