//Mapper method to map between a dynamic route and the stored template
//e.g.: /collection/xyz/page/123 -> collection/:name/page/:id

import { match } from "path-to-regexp";

export function normalizePathWithPlaceholders(path, routesMap, appId = "") {
  let routes: string[] = [];

  if (appId === "") {
    routes = Object.keys(routesMap);
  } else {
    Object.entries(routesMap).forEach(entry => {
      const [route, routeDetails] = entry;

      if ((routeDetails as any).appId === appId) {
        routes.push(route);
      }
    });
  }

  //Match the route
  for (let route of routes) {
    const matchRouteFunction = match(route);
    const matchResult = matchRouteFunction(path);

    if (Boolean(matchResult)) {
      return route;
    }
  }

  return null;
}
