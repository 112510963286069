// PaginationBar.tsx
import { FC, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Pagination from "src/components/pagination/Pagination";
import { useAppState } from "src/features/appState/hooks";
import store from "src/store/store";
import { PaginationBarProps } from "./types";

export const PaginationBar: FC<PaginationBarProps> = props => {
  const {
    siblingCount = 1,
    isFetching = false,
    className,
    unselectedPageBackgroundColor,
    unselectedPageColor,
    unselectedPageColorHover,
    selectedPageBackgroundColor,
    selectedPageColor,
    selectedPageColorHover,
    fwdCursor,
    prevCursor,
    lastCursor,
    onChange,
    config,
    metaData,
    maxPrev,
    pageType,
    pagesOrientation,
    ...restProps
  } = props;

  const pageId = metaData?.pageId || "";
  const viewName = metaData?.viewName || "";
  const componentKey = restProps["data-bx-key"];
  const dispatch = useDispatch();
  const { watch, getValue, produceTrigger } = useAppState();

  let elementKey = "";
  if (config?.dataPagination.startsWith("{this")) {
    if (config?.dataPagination === "{this}") {
      //Current View
      elementKey = `${pageId}.${viewName}`;
    } else {
      //Component in the current view
      const placeholder = config?.dataPagination.slice("{this.".length, -1);
      elementKey = `${pageId}.${viewName}.${placeholder}`;
    }
  } else {
    //Another view in the same page
    config?.dataPagination.replace(/{(\w+)(\.\w+)?}/g, (match, view, placeholder) => {
      elementKey = placeholder ? `${pageId}.${view}${placeholder}` : `${pageId}.${view}`;
    });
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [cursors, setCursors] = useState([undefined]);
  const ref = useRef(false);
  const paginationInfoForElement = watch(`${elementKey}.data._pagination`, { pageId, viewName });

  let hasMore = useRef(false);

  useEffect(() => {
    if (paginationInfoForElement && !ref.current) {
      setCursors(prev => [...prev, paginationInfoForElement?.nextCursor]);
      hasMore.current = paginationInfoForElement?.hasMore;
      ref.current = true;
    }
  }, [paginationInfoForElement]);

  const trigger = watch(`${componentKey}.trigger`, { pageId, viewName });

  useEffect(() => {
    const handleTrigger = async () => {
      if (trigger?.type === "updatePage") {
        await onPageChange(trigger?.payload?.page);
        trigger?.payload?.resolver?.();
      }
    };

    handleTrigger();
  }, [trigger]);

  const onPageChange = async (page: number) => {
    const viewFromName: any = elementKey?.split(".")[1];
    const entities = store?.getState()?.endUser?.application?.pages?.[pageId]?.entities || {};
    const isExist = Object.values(entities).some((entity: any) => entity.viewName === viewFromName && viewFromName);
    const viewIsExistFullObjet = Object.keys(store?.getState()?.endUser?.application?.pages?.[pageId]?.views?.[viewFromName]) || isExist;
    const viewIsExist = !!viewIsExistFullObjet;
    await new Promise((resolve, reject) => {
      produceTrigger(
        elementKey,
        "fetchNextPage",
        {
          resolver: resolve,
          currentCursorParam: cursors[page - 1],
          prevCursorParam: currentPage === 2 ? "undefined" : cursors[currentPage - 2],
          isPaginationBar: true,
        },
        { pageId, viewName, viewIsExist }
      );
    });

    hasMore.current = getValue(`${elementKey}.data._pagination.hasMore`, { pageId, viewName: viewFromName });
    const lengthViewData = getValue(`${elementKey}.data._pagination.length`, { pageId, viewName: viewFromName });

    if (!hasMore.current && lengthViewData == 0) {
      setCurrentPage(page - 1);
      setTotalPageCount(page - 1);
    } else {
      setCurrentPage(page);
    }
  };
  useEffect(() => {
    const updatedValues = {
      currentPage: currentPage,
      currentCursor: cursors[currentPage - 1],
      fwdCursor: cursors[currentPage],
      prevCursor: currentPage === 2 ? "undefined" : cursors[currentPage - 1],
      firstCursor: "undefined",
      hasMore: hasMore.current,
    };
    onChange?.(updatedValues);
  }, [cursors, currentPage]);

  const nextCursor = paginationInfoForElement?.nextCursor;
  const isRefetchOperation = paginationInfoForElement?.isRefetchOperation;
  useEffect(() => {
    setCursors(prevCursors => {
      let updatedCursors;
      if (!prevCursors.includes(nextCursor)) {
        updatedCursors = [...prevCursors, nextCursor];
      } else {
        const index = prevCursors.indexOf(nextCursor);
        updatedCursors = prevCursors.slice(0, index + 1);
      }

      hasMore.current = paginationInfoForElement?.hasMore;
      const lengthViewData = paginationInfoForElement?.length;

      if (!isRefetchOperation) {
        if (!hasMore.current && lengthViewData == 0) {
          setTotalPageCount(updatedCursors.length - 2);
          setCurrentPage(updatedCursors.length - 2);
        } else {
          setTotalPageCount(updatedCursors.length - 1);
          setCurrentPage(updatedCursors.length - 1);
        }
      } else {
        if (nextCursor !== "") {
          updatedCursors = [undefined, nextCursor];
          setTotalPageCount(updatedCursors.length - 1);
          setCurrentPage(updatedCursors.length - 1);
        } else {
          updatedCursors = [undefined];
          setTotalPageCount(updatedCursors.length);
          setCurrentPage(updatedCursors.length);
        }
      }

      return updatedCursors.filter(cursor => cursor !== "");
    });
  }, [nextCursor]);

  return (
    <Pagination
      currentPage={currentPage > 0 ? currentPage : 1 || 1}
      totalPageCount={totalPageCount > 0 ? totalPageCount : 1 || 1}
      onPageChange={onPageChange}
      siblingCount={siblingCount}
      hasNextPage={hasMore.current}
      showPrevButton={false}
      showNextButton={false}
      unselectedPageBackgroundColor={unselectedPageBackgroundColor}
      unselectedPageColor={unselectedPageColor}
      unselectedPageColorHover={unselectedPageColorHover}
      selectedPageBackgroundColor={selectedPageBackgroundColor}
      selectedPageColor={selectedPageColor}
      selectedPageColorHover={selectedPageColorHover}
      maxPrev={maxPrev}
      pageType={pageType}
      pagesOrientation={pagesOrientation}
      isPaginationBar={true}
    />
  );
};
