import { MenuItem, Select } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { useDispatch } from "react-redux";
import { setProviderState } from "src/features/buildxProvider/buildxProviderSlice";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import axiosServices from "src/utils/axios";
import { updateRTL } from "src/utils/buildxProviderOperations";

const LanguagesSection = () => {
  const theme = useTheme();
  const currentApp = useBuildxProviderValue("currentApp");
  const multiLingual = useBuildxProviderValue("multiLingual");
  const isAdministrationMode = useBuildxProviderValue("isAdministrationMode");
  const dispatch = useDispatch();

  const handleLanguageChange = event => {
    const selectedLanguage = event.target.value;
    const dataObj = {
      ...multiLingual,
      selectedLanguage: selectedLanguage,
    };

    try {
      if (currentApp?.id) {
        axiosServices
          .put(`/admin/multilingual/app/${currentApp?.id}`, dataObj, {
            headers: {
              "Cache-Control": "no-cache, no-store, must-revalidate",
              Pragma: "no-cache",
              Expires: "0",
            },
          })
          .then(({ data }) => {
            if (multiLingual) {
              dispatch(setProviderState({ multiLingual: dataObj }));
              updateRTL(multiLingual, isAdministrationMode, dispatch, dataObj);
            }
          });
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const renderLanguageOptions = () => {
    return multiLingual?.languages?.map(language => (
      <MenuItem key={language.id} value={language.name}>
        {language.name.charAt(0).toUpperCase() + language.name.slice(1)}
      </MenuItem>
    ));
  };

  return (
    <>
      {multiLingual && multiLingual?.languages?.length > 1 && currentApp?.multilingualId ? (
        <Select
          size='small'
          value={multiLingual?.selectedLanguage || ""}
          color='primary'
          onChange={handleLanguageChange}
          displayEmpty
          fullWidth
          sx={{
            width: 120,
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.primary.main,
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.primary.main,
            },
          }}
        >
          {renderLanguageOptions()}
        </Select>
      ) : (
        <></>
      )}
    </>
  );
};

export default LanguagesSection;
