import { Box, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { IconSettings } from "@tabler/icons-react";
import { FC } from "react";
import { DragSourceMonitor, useDrag } from "react-dnd";
import { AdministrationSwitcher } from "../Header/AdministrationSwitcher";

interface BuildxGearProps {
  position: { x: number; y: number };
  updatePosition: (newPosition: { x: number; y: number }) => void;
}

const useStyles = makeStyles((theme: any) => ({
  gearBox: {
    position: "fixed",
    zIndex: 99999,
    boxShadow: theme.shadows[6],
  },
  gearButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

const BuildxGear: FC<BuildxGearProps> = ({ position, updatePosition }) => {
  const classes = useStyles();
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "gear",
      item: { position },
      collect: (monitor: DragSourceMonitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult<{ position: any }>();
        if (dropResult) {
          updatePosition(dropResult.position);
        }
      },
    }),
    [position, updatePosition]
  );

  return (
    <Box
      ref={drag}
      className={classes.gearBox}
      display='flex'
      justifyContent='center'
      alignItems='center'
      style={{
        top: position.y,
        left: position.x,
        opacity: isDragging ? 0.5 : 1,
        cursor: "move",
      }}
    >
      <IconButton className={classes.gearButton}>
        <IconSettings size={26} />
      </IconButton>
      <div>
        <AdministrationSwitcher />
      </div>
    </Box>
  );
};

export default BuildxGear;
