import { Portal } from "@mui/material";
import React, { createElement, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { isPageScrollable } from "src/views/pages/BuildX/FormBuilder/utils";

const ResizeElement = ({
  width: initialWidth,
  height: initialHeight,
  stepper = 10,
  children,
  onResize,
  isDragging,
  disableHeight,
  boxPosition,
  scrollToButton,
  isCanvas,
}: {
  width?: number;
  height?: number;
  stepper?: number;
  onResize?: any;
  children: React.ReactNode;
  isParent?: any;
  isDragging?: any;
  disableHeight?: boolean;
  scrollToButton?: boolean;
  boxPosition?: any;
  isCanvas?: any;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<any>({});
  const scaleFactor = useSelector((state: any) => state.builder.scaleFactor);
  const isRTL = useSelector((state: any) => state.builder.isRTL);

  useEffect(() => {
    if (ref.current) {
      const { top, left, width, height } = ref.current.getBoundingClientRect();

      // Get the scroll offsets
      const scrollX = window.scrollX || document.documentElement.scrollLeft || 0;
      const scrollY = window.scrollY || document.documentElement.scrollTop || 0;

      const handleSize = 5;

      // Calculate bottom position
      const bottom = window.innerHeight - scrollY - (top + height) - handleSize;

      // Calculate right position
      const zoomWidth = isRTL ? 0 : width;
      const right = window.innerWidth - scrollX - (left + zoomWidth) - handleSize;

      // Adjust right position if the page is scrollable vertically
      const verticalScrollbarWidth = document.documentElement.scrollHeight > window.innerHeight ? handleSize : 0;

      const adjustedRight = right - verticalScrollbarWidth;

      // Update the position state if values have changed
      if (position.bottom !== bottom || position.right !== adjustedRight) {
        setPosition(prev => ({ ...prev, bottom, right: adjustedRight }));
      }
    }
  }, [isDragging, children, isRTL]);

  const handleMouseDown = (axis: "x" | "y" | "both") => (e: React.MouseEvent<HTMLDivElement>) => {
    const startX = e.clientX;
    const startY = e.clientY;
    const startWidth = ref.current?.clientWidth || 0;
    const startHeight = ref.current?.clientHeight || 0;
    let newWidth = startWidth;
    let newHeight = startHeight;
    let isScrolling = false;
    let autoScrollOffset = 0;
    const handleMouseMove = (e: MouseEvent) => {
      const dx = (e.clientX - startX) / scaleFactor;
      const dy = (e.clientY + autoScrollOffset - startY) / scaleFactor;

      if (axis === "x" || axis === "both") {
        newWidth = startWidth + Math.round((isRTL ? -1 : 1) * dx);

        if (boxPosition) {
          newWidth = newWidth <= boxPosition?.width ? newWidth : boxPosition?.width;
        }
      }

      if (axis === "y" || axis === "both") {
        const _newHeight = startHeight + Math.round(dy);

        newHeight = document.body.clientHeight - 1 == e.clientY ? newHeight + stepper : _newHeight;

        if (boxPosition) {
          newHeight = newHeight <= boxPosition?.height ? newHeight : boxPosition?.height;
        }
      }
      if (ref.current) {
        const { top, left, width, height } = ref?.current?.getBoundingClientRect();
        if (!disableHeight) {
          ref.current.style.width = newWidth + "px";
        }
        ref.current.style.height = newHeight + "px";

        const handleSize = 5;

        const scrollY =
          window.pageYOffset !== undefined
            ? window.pageYOffset
            : (document.documentElement || document.body.parentNode || document.body).scrollTop;

        const bottom = window.innerHeight - top - height - handleSize - scrollY;

        const zoomWidth = isRTL ? 0 : width;

        const right = window.innerWidth - left - zoomWidth - handleSize - (!!scrollY || isPageScrollable() ? handleSize : 0);
        setPosition(prev => ({
          ...prev,
          bottom: newHeight < 1 ? prev?.bottom : bottom,
          ...(!disableHeight && { right: newWidth < 1 ? prev?.right : right }),
          width: newWidth,
          height: newHeight,
        }));
        if (scrollToButton) {
          window.scrollTo(0, document.body.scrollHeight);
        }
      }

      if (isCanvas) {
        if (e.clientY >= window.innerHeight - 10) {
          if (!isScrolling) {
            isScrolling = true;
            handleAutoScroll();
          }
        } else {
          isScrolling = false;
        }
      }
    };

    const handleAutoScroll = () => {
      if (isScrolling) {
        autoScrollOffset += 10;
        window.scrollBy(0, 310);
        newHeight += 10;

        if (ref.current) {
          ref.current.style.height = newHeight + "px";
        }

        window.requestAnimationFrame(handleAutoScroll);
      }
    };

    const handleMouseUp = () => {
      onResize?.({ width: newWidth, height: newHeight });
      if (ref.current && !isCanvas) {
        ref.current.style.removeProperty("width");
        ref.current.style.removeProperty("height");
      }

      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
      isScrolling = false;
    };

    window.addEventListener("mousemove", handleMouseMove as any);
    window.addEventListener("mouseup", handleMouseUp);
  };

  const _children = React.Children?.map(children, child => child);
  const firstChild = _children?.[0] as React.ReactElement;
  return (
    <>
      {createElement(
        firstChild?.type || "div",
        { ...firstChild?.props, ref },
        <>
          {firstChild?.props?.children}
          {!isDragging && (
            <Portal>
              <div
                style={{
                  position: "absolute",
                  bottom: position?.bottom,
                  right: position?.right,
                  width: `${Math.max(10, 10 * scaleFactor)}px`,
                  height: `${Math.max(10, 10 * scaleFactor)}px`,
                  border: `${Math.max(1, 1 * scaleFactor)}px solid black`,
                  borderRadius: "50%",
                  zIndex: 999,
                  backgroundColor: "white",
                  cursor: isRTL ? "nesw-resize" : "nwse-resize",
                }}
                onMouseDown={e => {
                  e.preventDefault(); // Prevent the default drag behavior
                  handleMouseDown("both")(e);
                }}
                onDragStart={e => e.preventDefault()} // Prevent the drag start event
              />
            </Portal>
          )}
        </>
      )}
    </>
  );
};

export { ResizeElement };
