import _ from "lodash";
import { FC, memo } from "react";
import { StripeContainer } from "src/views/pages/BuildX/FormBuilder/components/StripeComponents/StripeContainer";
import RenderChildren from "./RenderChildren";

interface ChildrenRendererProps {
  element: any;
  stripe?: any;
  index?: number;
  parentKey?: string;
  isComponentDisabled?: boolean;
  dataEntry?: any;
  viewName?: string;
  dataSource?: any;
  isRepeated?: boolean;
  __data?: any;
  pageId?: string;
  getValues?: any;
  parentIds?: any;
  closeModal?: any;
  info?: any;
  pageOutlet?: any;
  handleSelectRow?: any;
  path?: string;
  parentDataIndex?: any;
}

function getHierarchyName(elementKey, index, parentKey = "") {
  if (!_.isNil(index)) {
    return `${parentKey}.${elementKey}[${index}]`;
  }
  return `${parentKey}.${elementKey}`;
}

const ChildrenRenderer: FC<ChildrenRendererProps> = ({
  element,
  stripe,
  index,
  parentKey,
  isComponentDisabled,
  dataEntry,
  viewName,
  dataSource,
  isRepeated,
  __data,
  pageId,
  getValues,
  parentIds,
  closeModal,
  info,
  pageOutlet,
  handleSelectRow,
  path,
  parentDataIndex,
}) => {
  return element.children.map((elementChild: any, _index: number) => {
    if (element?.config?.isStripeWrapper) {
      return (
        <StripeContainer element={element} key={element?.id}>
          <RenderChildren
            stripe={stripe}
            key={elementChild}
            elementId={elementChild}
            parentDataIndex={parentDataIndex}
            data={element?.children}
            path={[path, element?.props?.key].join(".")}
            index={index}
            parentKey={getHierarchyName(element?.props?.key, index, parentKey)}
            disabled={isComponentDisabled}
            dataEntry={dataEntry}
            viewName={viewName}
            dataSource={dataSource}
            isRepeated={isRepeated}
            repeatedComponent={false}
            topLevelIds={null}
            __data={__data}
            pageId={pageId}
            getValues={getValues}
            parentIds={parentIds}
            closeModal={closeModal}
            info={info}
            pageOutlet={pageOutlet}
            handleSelectRow={handleSelectRow}
            enableDirtyCheck={info?.disableDirtyOption}
          />
        </StripeContainer>
      );
    }

    return (
      <RenderChildren
        key={elementChild}
        elementId={elementChild}
        parentDataIndex={parentDataIndex}
        data={element?.children}
        path={[path, element?.props?.key].join(".")}
        index={index}
        parentKey={getHierarchyName(element?.props?.key, index, parentKey)}
        disabled={isComponentDisabled}
        dataEntry={dataEntry}
        viewName={viewName}
        dataSource={dataSource}
        isRepeated={isRepeated}
        repeatedComponent={false}
        topLevelIds={null}
        __data={__data}
        pageId={pageId}
        getValues={getValues}
        parentIds={parentIds}
        closeModal={closeModal}
        info={info}
        pageOutlet={pageOutlet}
        handleSelectRow={handleSelectRow}
        enableDirtyCheck={info?.disableDirtyOption}
      />
    );
  });
};

export default memo(ChildrenRenderer, _.isEqual);
