import { Box, Checkbox, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import { FC } from "react";
import { BXEngine } from "src/BXEngine";
import { useAppState } from "src/features/appState/hooks";
import DraggableRow from "src/views/pages/BuildX/AppBuilder/forms/DraggableRow";

type BXRepeatedViewItemProps = {
  builderView: any;
  __data: any;
  repeatedViewLayout: any;
  item: any;
  index: any;
  pageId: any;
  views: any;
  isSelected?: boolean;
  order: any;
  handleDragHover: any;
  handleUpdateElement: any;
  selectedRows?: any[];
  handleSelectRow: any;
  info?: {
    name?: string;
    showApiMode?: string;
    paginationMode?: string;
    enableMultipleSelection?: string;
    viewName?: string;
  };
  path?: string;
};

const BXRepeatedViewItem: FC<BXRepeatedViewItemProps> = ({
  builderView,
  order,
  __data,
  repeatedViewLayout,
  item,
  index,
  pageId,
  views,
  handleUpdateElement,
  handleDragHover,
  info,
  handleSelectRow,
  selectedRows,
  isSelected,
  path,
}) => {
  const theme = useTheme();
  const { setValue, getValue } = useAppState();

  const ActiveComponent = order?.enabled ? DraggableRow : Grid;
  const breakpointProps = {
    xs: 12 / (repeatedViewLayout?.xs || 1) || 12,
    sm: 12 / (repeatedViewLayout?.sm || 1) || 12,
    md: 12 / (repeatedViewLayout?.md || 1) || 12,
    lg: 12 / (repeatedViewLayout?.lg || 1) || 12,
    xl: 12 / (repeatedViewLayout?.xl || 1) || 12,
  };
  const ActiveComponentProps = order?.enabled
    ? {
        componentProps: {
          item: true,
          onClick: (e: any) => {
            e.preventDefault();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            handleSelectRow?.(item, index);
          },
          ...breakpointProps,
        },
      }
    : {
        item: true,
        onClick: (e: any) => {
          e.preventDefault();
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
          handleSelectRow?.(item, index);
        },
        ...breakpointProps,
      };

  const handleCheckboxChange = (item: any) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentSelectedRows = getValue(`${pageId}.${info?.viewName}.selectedItems`) || [];
    const isChecked = e.target.checked;
    if (isChecked) {
      if (!currentSelectedRows?.some(row => row?.__id === item?.__id)) {
        setValue(`${pageId}.${info?.viewName}.selectedItems`, [...currentSelectedRows, item]);
      }
    } else {
      setValue(
        `${pageId}.${info?.viewName}.selectedItems`,
        currentSelectedRows.filter((rowItem: any) => rowItem?.__id !== item?.__id)
      );
    }
  };

  return (
    <>
      <ActiveComponent
        component={Grid}
        id={item.id}
        index={index}
        name='repeated-views'
        moveElement={handleDragHover}
        updateElement={handleUpdateElement}
        {...ActiveComponentProps}
      >
        {info?.enableMultipleSelection && (
          <Box style={{ position: "relative" }}>
            <Box
              style={{
                position: "absolute",
                top: "0px",
                left: "0px",
                zIndex: 10,
              }}
            >
              <Checkbox
                color='primary'
                checked={selectedRows?.some(row => row?.__id === item?.__id)}
                onChange={handleCheckboxChange(item)}
                onClick={e => {
                  e.stopPropagation();
                }}
              />
            </Box>
          </Box>
        )}
        <Box style={{ border: `2px solid ${isSelected ? theme.palette.primary.main : "transparent"}` }}>
          <BXEngine
            auth={{}}
            pageId={pageId}
            page={{ views, layout: builderView, id: pageId } as any}
            layout={[{ ...(builderView as any), type: builderView?.type }]}
            data={item}
            __data={__data}
            path={path}
            isVisible
            isSelected={isSelected}
            handleSelectRow={handleSelectRow}
            info={info}
            index={index}
          />
        </Box>
      </ActiveComponent>
    </>
  );
};
export default BXRepeatedViewItem;
