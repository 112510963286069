import _ from "lodash";
import { BXApp } from "src/types/BXAppType";

export function migrateTo1_0_1(app: BXApp) {
  _.set(app, "appConfig.auth.authApi", {
    method: "POST",
    headers: {
      authorization: "Bearer {this.device.accessToken}",
    },
    body: '{\n    "email": "{this.login.email}",\n    "password": "{this.login.password}",\n    "recaptcha": "{this.login.recaptcha}"\n}',
    uri: app?.appConfig?.auth?.authApi as string,
  });

  _.set(app, "appConfig.auth.deviceApi", {
    method: "POST",
    body: '{\n    "appVersion": "{this.config.appVersion}",\n    "deviceType": "{this.config.deviceType}",\n    "deviceUDID": "{this.config.deviceUDID}",\n    "osVersion": "{this.config.osVersion}",\n    "timeZone": "{this.config.timeZone}"\n}',
    uri: app?.appConfig?.auth?.deviceApi as string,
  });

  _.set(app, "templateConfig.appVersion", "1.0.1");
}
