import { FC } from "react";
// material-ui

// project imports
import { useBuildxContext } from "src/BXEngine/BuildxContext";
import { NavItemType } from "src/types";
import NavGroup from "./NavGroup";

// ==============================|| SIDEBAR MENU LIST ||============================== //

type MenuListProps = {
  menuItems: NavItemType[];
};

const MenuList: FC<MenuListProps> = ({ menuItems }) => {
  const { checkPermissions } = useBuildxContext();
  const navItems = menuItems.map(item => {
    let canViewCollections = checkPermissions([{ path: item.appName + ".*", action: "VIEW" }]);
    canViewCollections = true;
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={{ ...item, canViewCollections }} />;
      default:
        return <></>;
    }
  });

  return <>{navItems}</>;
};

export default MenuList;
