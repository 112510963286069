import { QRCodeSVG } from "qrcode.react";
import React from "react";

export const CustomQR: React.FC<any> = props => {
  const { QRCodeValue = "", QRSize = null } = props;
  const { heightPx, widthPx } = props?.$config || props?.config;

  const derivedSize = (heightPx?.lg + widthPx?.lg) / 2;
  const _size = QRSize ? QRSize : derivedSize;

  return (
    <div>
      <QRCodeSVG value={QRCodeValue} size={_size} />
    </div>
  );
};
