import { normalize } from "normalizr";
import { component } from "./schemas";

const normalizeEndUser = endUser => {
  if (!endUser) return;
  //
  // Assign unique IDs if not present
  const assignIds = (items, parentKey = null) => {
    return items?.map(item => {
      const id = item.id;
      const keyComp = item?.props?.key;
      // const children = item.children ? assignIds(item.children, keyComp) : [];
      const assignedChildren = item.children ? assignIds(item.children, keyComp) : [];
      let assignedOptionMap = {};
      if (item.optionMap && typeof item.optionMap === "object") {
        assignedOptionMap = Object.fromEntries(
          Object.entries(item.optionMap).map(([optionKey, optionValue]) => {
            const processed = assignIds([optionValue], keyComp);
            return [optionKey, processed[0]];
          })
        );
      }

      let additionalComponentData: any = {};

      const componentOnLoadTrigger = item?.interactionConfig?.some(i => i?.type === "OnLoad");
      if (componentOnLoadTrigger) {
        additionalComponentData.trigger = {
          type: "onLoad",
          eventPayload: {},
        };
      }

      return {
        ...item,
        id,
        parentKey,
        children: assignedChildren,
        optionMap: assignedOptionMap,
        ...additionalComponentData,
      };
    });
  };

  const dataWithIds = assignIds(endUser);

  // Normalize the data
  const normalizedData = normalize(dataWithIds, [component]);

  // Extract entities and top-level IDs
  const { entities, result } = normalizedData;

  return {
    entities: entities.components,
    result, // Array of top-level component IDs
  };
};

export default normalizeEndUser;
