import { normalize } from "normalizr";
import { component } from "./schemas";

const normalizeFormBuilder = formBuilder => {
  // Assign unique IDs if not present
  if (!formBuilder) return;
  const assignIds = (items, parentId = null) => {
    if (!Array.isArray(items)) return [];

    return items.map(item => {
      return recursivelyAssignObject(item, parentId);
    });
  };

  function recursivelyAssignObject(obj, parentId) {
    if (!obj || typeof obj !== "object") {
      return obj;
    }

    const { id } = obj;

    let assignedChildren: any = [];
    if (Array.isArray(obj.children)) {
      assignedChildren = assignIds(obj.children, id);
    }

    let assignedOptionMap;
    if (obj.optionMap && typeof obj.optionMap === "object") {
      assignedOptionMap = Object.fromEntries(
        Object.entries(obj.optionMap).map(([key, optionValue]) => {
          let assignedVal = recursivelyAssignObject(optionValue, parentId);

          assignedVal = {
            ...assignedVal,
            id, // same ID as the parent
            parentId, // same parentId as the parent
          };

          // If that optionValue has children, fix their parentId = assignedVal.id
          if (Array.isArray(assignedVal.children)) {
            assignedVal.children = assignIds(assignedVal.children, assignedVal.id);
          }

          return [key, assignedVal];
        })
      );
    }

    return {
      ...obj,
      id,
      parentId,
      children: assignedChildren,
      optionMap: assignedOptionMap && Object.keys(assignedOptionMap).length ? assignedOptionMap : undefined,
    };
  }

  const dataWithIds = assignIds(formBuilder);

  // Normalize the data
  const normalizedData = normalize(dataWithIds, [component]);

  // Extract entities and top-level IDs
  const { entities, result } = normalizedData;

  return {
    entities: entities.components,
    result, // Array of top-level component IDs
  };
};

export default normalizeFormBuilder;
