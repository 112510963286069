import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { adminPaths, formBuilderPath } from "src/config";
import { setProviderState } from "src/features/buildxProvider/buildxProviderSlice";
import { useBlocker } from "./useBlocker";

export function useCallbackPrompt(
  when: boolean,
  preventdefaultModalBrowser?: boolean,
  customModalType?: boolean,
  customMessage?: string
): [boolean, (location?: any) => void, () => void, any] {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState<any>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);

    const isAdministrationPath = adminPaths.includes(location.pathname);
    const isFormBuilderPath = location.pathname === formBuilderPath;

    if (!isAdministrationPath && !isFormBuilderPath) {
      dispatch(setProviderState({ isAdministrationMode: false }));
    }
  }, [location.pathname]);

  const confirmNavigation = useCallback((nextLocation?: any) => {
    setConfirmedNavigation(true);
    setShowPrompt(false);
    if (nextLocation.location.pathname === "/buildx/app") {
      dispatch(setProviderState({ isAdministrationMode: true }));
    }
  }, []);

  const handleBlockedNavigation = useCallback(
    nextLocation => {
      if (!confirmedNavigation && nextLocation.location.pathname !== location.pathname) {
        if (!customModalType) {
          // eslint-disable-next-line no-restricted-globals
          if (confirm(customMessage || "There are some changes. Are you sure you want to navigate?")) {
            setLastLocation(nextLocation);
            confirmNavigation(nextLocation);
          } else {
            cancelNavigation();
          }
        } else if (preventdefaultModalBrowser && customModalType) {
          setLastLocation(nextLocation);
          setShowPrompt(true);
          dispatch(setProviderState({ isAdministrationMode: false }));
        }
        return false;
      }
      return true;
    },
    [confirmedNavigation]
  );

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location.pathname);
    }
  }, [confirmedNavigation, lastLocation]);

  useBlocker(handleBlockedNavigation, when);

  return [showPrompt, confirmNavigation, cancelNavigation, lastLocation];
}
