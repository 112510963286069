import { TextFieldProps } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { FC } from "react";
import { Control, Controller } from "react-hook-form";
import { AutoCompleteSyntax } from "src/views/pages/BuildX/FormBuilder/components/ComponentEditor/EditorComponents/AutoCompleteSyntax";

type BXInputAutocompleteProps = TextFieldProps & {
  label: string;
  control: Control<any>;
  name: string;
  defaultValue?: any;
  id?: string;
  error?: any;
  required?: boolean;
  views?: any;
  view?: any;
  onChange?: any;
};

export const BXInputAutocomplete: FC<BXInputAutocompleteProps> = ({
  name,
  control,
  label,
  id,
  defaultValue,
  error,
  onChange: onSideChange,
  variant = "outlined",
  required = false,
  view,
  views,
  ...rest
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required,
      }}
      render={({ field: { onChange, value } }) => (
        <FormControl fullWidth>
          <AutoCompleteSyntax
            view={view}
            views={views}
            error={Boolean(error)}
            aria-errormessage={"error message"}
            onChange={e => {
              onChange(e);
              onSideChange?.(e);
            }}
            size={"medium"}
            id={id}
            label={label}
            variant={variant}
            fullWidth
            value={value || defaultValue || ""}
            {...rest}
          />
          {error && <FormHelperText error>{error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
};
