import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { ButtonProps } from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { IconX } from "@tabler/icons-react";
import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import { BXConfirmationDialog } from "../AlertDialog/ConfirmationDialog";

type BXModalProps = {
  title: string;
  icon?: any;
  showIconHeader?: boolean;
  buttonProps?: ButtonProps;
  label?: React.ReactNode;
  children?: React.ReactNode | Function;
  minWidth?: any;
  maxWidth?: any;
  open?: boolean;
  showHeader?: boolean;
  withoutLabel?: boolean;
  isDirty?: boolean;
  onClose?: () => void;
  headerRightComponent?: any;
  paperProps?: any;
  buttonComponent?: any;
  withoutContainer?: boolean;
  closeOnlyViaDismiss?: boolean;
  isCustomWidth?: boolean;
  customModalWidth?: any;
};
const BXModal: FC<BXModalProps> = ({
  children,
  buttonProps,
  label,
  title,
  icon,
  open: _open,
  onClose,
  maxWidth = "md",
  customModalWidth,
  isCustomWidth,
  showHeader = true,
  withoutLabel,
  isDirty = false,
  showIconHeader = true,
  headerRightComponent = <></>,
  paperProps = {},
  buttonComponent,
  withoutContainer = false,
  closeOnlyViaDismiss = false,
}) => {
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [callback, setCallback] = useState<(() => void) | null>(null);

  const renderActionableContent = () => {
    return typeof buttonComponent === "function" ? buttonComponent(handleOpen) : buttonComponent;
  };

  const handleOpen = (e: any) => {
    e.stopPropagation();
    buttonProps?.onClick?.(e);
    setOpen(true);
  };
  const handleClose = (confirmed?: boolean, cb?: () => void, _isDirty?: boolean) => {
    if (confirmed == true || !isDirty || _isDirty == false) {
      onClose?.();
      setOpen(false);
      if (typeof cb == "function") cb?.();
      return;
    }
    if (isDirty) {
      // eslint-disable-next-line no-restricted-globals
      setConfirmOpen(true);
      setCallback(() => cb);
    }
  };

  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const handleConfirm = () => {
    onClose?.();
    setOpen(false);
    if (typeof callback === "function") callback?.();
    setConfirmOpen(false);
  };

  useEffect(() => {
    if (!_.isNil(_open)) {
      setOpen(_open);
    }
  }, [_open]);

  return (
    <>
      {buttonComponent ? (
        <Box display={"contents"} onClick={handleOpen}>
          {renderActionableContent()}
        </Box>
      ) : !_.isNil(_open) ? null : _.isNil(title) || withoutLabel ? (
        <Tooltip title={label || ""}>
          <IconButton {...(_.isNil(buttonProps) ? {} : buttonProps)} onClick={handleOpen}>
            {icon}
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={label || ""}>
          <LoadingButton {...(_.isNil(buttonProps) ? {} : buttonProps)} onClick={handleOpen}>
            {label}
          </LoadingButton>
        </Tooltip>
      )}

      <Dialog
        open={open}
        onClose={(event, reason) => {
          const shouldPreventClose = closeOnlyViaDismiss && (reason === "backdropClick" || reason === "escapeKeyDown");

          if (shouldPreventClose) return;

          handleClose();
        }}
        closeAfterTransition
        keepMounted={false}
        BackdropProps={{
          timeout: 500,
        }}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        fullWidth
        maxWidth={maxWidth}
        PaperProps={{
          style: { maxWidth: isCustomWidth ? Number(customModalWidth) : maxWidth },
          onClick: e => {
            e.stopPropagation();
          },
          ...paperProps,
        }}
        // classes={{ paper: classes.paper }}
      >
        {showHeader && (
          <>
            <DialogTitle display={"flex"} alignItems='center'>
              <Box flex={1} display={"flex"} alignItems='center'>
                {icon && icon}
                <Typography marginInlineStart={1} id='modal-modal-title' fontSize={16} fontWeight={800}>
                  {title}
                </Typography>
              </Box>
              <Box sx={{ cursor: "pointer", display: "flex" }} onClick={() => handleClose()}>
                {headerRightComponent}
                <IconX />
              </Box>
            </DialogTitle>
            <Divider />
          </>
        )}
        {withoutContainer ? (
          _.isFunction(children) ? (
            children?.(handleClose)
          ) : (
            children
          )
        ) : (
          <DialogContent style={{ padding: 10 }}>{_.isFunction(children) ? children?.(handleClose) : children}</DialogContent>
        )}
      </Dialog>
      <BXConfirmationDialog
        open={confirmOpen}
        onCancel={handleConfirmClose}
        onConfirm={handleConfirm}
        title={"WARNING"}
        content='There are some changes, Are you sure you want to navigate ?'
      />
    </>
  );
};

export default BXModal;
