import { Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addComponent, updateComponent } from "src/features/builder/builderSlice";
import { selectComponentById, selectFullComponentItemById } from "src/features/builder/selectors";
import store from "src/store/store";
import { v4 as uuid } from "uuid";
import { BXMapValues } from "../../../BXMapValues";
import { components } from "../../../utils";

const MapValueSelector = ({ onChangeProp, componentId, handleChangeProp, handleConfigChange }) => {
  const item = useSelector(selectFullComponentItemById(componentId));
  const { mapKey } = item?.config || {};
  const { conditionMapKey } = item?.config || {};
  const { isMapValues } = item?.props || {};
  const { prevSelectedType, selectedType, optionMap, ...restOfItem } = item || {};
  const [selectedValue, setSelectedValue] = useState<string>(item?.selectedType || "default");
  const [initialItem, setInitialItem] = useState();
  const [condition, setCondition] = useState(item?.config?.conditionMapKey || "equal");
  const dispatch = useDispatch();

  const handleConditionChange = (newCondition: string) => {
    setCondition(newCondition);
    const newItem = {
      ...item,
      config: {
        ...item?.config,
        conditionMapKey: newCondition,
      },
    };
    dispatch(updateComponent({ id: item?.id, changes: newItem }));
  };

  useEffect(() => {
    if (!item?.optionMap) {
      const newItem = {
        ...item,
        selectedType: "default",
        optionMap: {
          default: {
            ...item,
            id: uuid(),
            children: JSON.parse(JSON.stringify(item?.children || [])),
          },
        },
      };
      setSelectedValue("default");
      // onChangeProp?.(newItem);
      dispatch(updateComponent({ id: item?.id, changes: newItem, isCopy: true }));
    }
  }, []);
  const prevSelectedTypeRef = useRef(selectedType);
  const prevItemIdRef = useRef();

  useEffect(() => {
    const prevSelectedOption = item?.prevSelectedType;
    if (prevItemIdRef.current !== item?.id) {
      prevItemIdRef.current = item?.id;
      return;
    }
    let prevItem: any = {};
    if (prevSelectedOption) {
      prevItem = {
        ...item,
        optionMap: {
          ...item.optionMap,
          [prevSelectedOption]: {
            ...restOfItem,
            children: JSON.parse(JSON.stringify(restOfItem?.children || [])),
          },
        },
      };
      dispatch(updateComponent({ id: item?.id, changes: prevItem, isCopy: true }));
    }

    const selectedOption = item?.optionMap?.[selectedType];
    if (selectedOption) {
      const newItem = {
        ...selectedOption,
        config: {
          ...selectedOption?.config,
          mapKey: item?.config?.mapKey,
          conditionMapKey: item?.config?.conditionMapKey,
        },
        optionMap: prevItem?.optionMap || item?.optionMap,
        selectedType: item?.selectedType,
        prevSelectedType: prevItem?.prevSelectedType,
      };
      dispatch(updateComponent({ id: item?.id, changes: newItem, isCopy: true }));
    }
  }, [selectedType]);

  function updateChildrenIds(item, parentId?: any) {
    if (!item?.children || !Array.isArray(item.children)) {
      return [];
    }
    let children: any = [];
    item.children.map((child, index) => {
      let itemChild = selectComponentById(store.getState(), child);
      const newId: any = `newId_${index}${uuid()}`;
      children.push(newId);
      let newItem = {
        ...itemChild,
        id: newId,
        props: {
          ...itemChild.props,
          id: newId,
          key: newId,
        },
        ...(parentId && { parentId: parentId }),
        children: updateChildrenIds(itemChild, newId),
      };
      dispatch(addComponent(newItem));
    });
    return children;
  }
  function copyFromDefault(value, selectedValue) {
    let prevItem: any = {};
    prevItem = {
      ...item,
      optionMap: {
        ...item.optionMap,
        [selectedValue]: { ...restOfItem },
      },
    };
    dispatch(updateComponent({ id: item?.id, changes: prevItem, isCopy: true }));

    const defaultValue = prevItem?.optionMap?.default || {};
    const newItem = {
      ...item,
      selectedType: value,
      prevSelectedType: selectedValue,
      optionMap: {
        ...item?.optionMap,
        [value]: { ...defaultValue, children: updateChildrenIds(defaultValue) },
      },
    };
    dispatch(updateComponent({ id: item?.id, changes: newItem, isCopy: true }));

    setSelectedValue(value);
  }

  function copyFromValue(value, source, selectedValue) {
    let prevItem: any = {};
    prevItem = {
      ...item,
      optionMap: {
        ...item.optionMap,
        [selectedValue]: { ...restOfItem },
      },
    };
    dispatch(updateComponent({ id: item?.id, changes: prevItem, isCopy: true }));

    const newItem = {
      ...item,
      prevSelectedType: selectedValue,
      selectedType: value,
      optionMap: {
        ...item?.optionMap,
        [value]: { ...prevItem?.optionMap?.[source], children: updateChildrenIds(prevItem?.optionMap?.[source]) },
      },
    };
    dispatch(updateComponent({ id: item?.id, changes: newItem, isCopy: true }));
  }

  function addNewValue(value, selectedValue) {
    let prevItem: any = {};
    prevItem = {
      ...item,
      optionMap: {
        ...item.optionMap,
        [selectedValue]: { ...restOfItem },
      },
    };
    dispatch(updateComponent({ id: item?.id, changes: prevItem, isCopy: true }));

    const newValue: any = components.find((comp: any) => comp?.type === item.type) || {};

    setSelectedValue(value);

    const newItem = {
      ...item,
      prevSelectedType: selectedValue,
      selectedType: value,
      optionMap: {
        ...item?.optionMap,
        [value]: {
          ...newValue,
          id: item.id,
          props: {
            ...newValue.props,
            key: prevItem?.props?.key,
            id: item?.id,
            isMapValues: true,
          },
          config: item.config,
          children: [],
          leftPercentage: prevItem?.optionMap?.[selectedValue]?.leftPercentage,
          left: prevItem?.optionMap?.[selectedValue]?.left,
          top: prevItem?.optionMap?.[selectedValue]?.top,
        },
      },
    };

    dispatch(updateComponent({ id: item?.id, changes: newItem, isCopy: true }));
  }
  function deleteChain(value) {
    const updatedOptionMap = { ...item?.optionMap };
    delete updatedOptionMap[value];

    const newItem = {
      ...item,
      selectedType: "default",
      optionMap: updatedOptionMap,
    };
    dispatch(updateComponent({ id: item?.id, changes: newItem, isCopy: true }));
    setSelectedValue("default");
  }

  return (
    <Grid display='flex' gap={2} marginInlineStart={"5px"} marginInlineEnd={"5px"} item xs={12} justifyContent='center' alignItems='center'>
      <BXMapValues
        key={item.id}
        initialKey={mapKey}
        queryString='mapKey'
        targetMap={optionMap}
        handleKeyChange={handleConfigChange}
        setSelectedValue={setSelectedValue}
        addNewValue={addNewValue}
        copyFromDefault={copyFromDefault}
        copyFromValue={copyFromValue}
        deleteValue={deleteChain}
        selectedValue={selectedValue}
        item={item}
        onChangeProp={onChangeProp}
        condition={condition}
        setCondition={setCondition}
        handleConditionChange={handleConditionChange}
        type='item'
      />
    </Grid>
  );
};

export default MapValueSelector;
