import { IconButton, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { IconDots } from "@tabler/icons-react";
import _ from "lodash";
import { FC, useState } from "react";
import { ActionButton, useReplaceDataPlaceholders } from "src/components/BXUI/DataTable/ActionButton";
import { PermissibleRender } from "src/components/PermissionValidation/PermissibleRender";
import { BxPatterns } from "src/utils/BXValidate/BxPatterns";
import Popover from "./Popover";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
// import Popover from "@mui/material/Popover";

export const ItemActions: FC<{
  actions?: any[];
  onStateChange: (newState: string) => void;
  disabled?: boolean;
  item: any;
  views: any;
  isUserInput?: boolean;
  tableId: any;
  onSelectRow?: (object: any) => void;
  __data?: any;
  closeModal?: any;
  parentIds?: any;
  pageId?: any;
  queryKeys?: any[];
  viewName?: string;
  path?: string;
  dataEntry?: any;
  index?: number;
  showEllipsisForActions?: boolean;
}> = ({
  actions: _actions,
  onStateChange,
  disabled,
  item,
  isUserInput,
  views,
  tableId,
  onSelectRow,
  closeModal,
  parentIds,
  queryKeys,
  pageId,
  viewName,
  __data = {},
  dataEntry,
  index,
  showEllipsisForActions = true,
  path,
}) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const { palette } = useTheme();
  const currentApp = useBuildxProviderValue("currentApp");
  const viewsState = useBuildxProviderValue("viewsState");
  const { replaceDataPlaceholders } = useReplaceDataPlaceholders({ viewName });
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [x, setX] = useState(2361);
  const [y, setY] = useState(453);

  const handleClose = () => {
    setAnchorEl(null);
    setIsPopoverOpen(false); // Close the popover
  };

  const handleClick = (event: any) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const x = rect.left + window.scrollX;
    const y = rect.top + window.scrollY;
    setX(x);
    setY(y);
    setIsPopoverOpen(true); // Open the popover
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const actions = _actions?.filter(action => {
    const condition = replaceDataPlaceholders({
      queryString: action?.visibilityCondition ?? action?.condition,
      item,
      viewsState,
      pageId,
      __data,
      selector: dataEntry ? `this.data.${dataEntry}[*]` : `this.data._body[*]`,
      index,
      env: currentApp?.env,
    });
    return !(!eval(condition) && !_.isEmpty(condition));
  });

  if (_.isArray(actions) && !_.isEmpty(actions))
    return (
      <>
        {(showEllipsisForActions ? actions?.slice(0, 3) : actions)?.map(action => (
          <PermissibleRender isAllowed key={action?.id} action={["VIEW"]} path={[path, action.label].join(".")}>
            {({ permitted }) =>
              permitted && (
                <ActionButton
                  path={[path, action.label].join(".")}
                  item={item}
                  viewName={viewName}
                  queryKeys={queryKeys}
                  pageId={pageId}
                  disabled={disabled || !permitted}
                  key={action.id}
                  _key={`${action.id}-${item.__id}`}
                  tableAction={{
                    action: action,
                    label: action?.label,
                    icon:
                      (action?.iconConfig?.visibility === "PUBLIC" && action?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
                        ? action?.iconConfig?.url
                        : action?.iconConfig?.icon) || action?.icon,
                    condition: action?.visibilityCondition ?? action?.condition, //Needs Migration
                  }}
                  onStateChange={onStateChange}
                  isUserInput={isUserInput}
                  views={views}
                  onSelectRow={onSelectRow}
                  tableId={tableId}
                  __data={__data}
                  closeModal={closeModal}
                  parentIds={parentIds}
                  iconButton={!action?.showButtonLabel}
                  isLoadingForEntireChain={action?.actionMappingConfig?.isLoadingForEntireChain}
                  actionsMap={action?.actionsMap}
                  actionsKey={action?.actionsKey}
                  isChainMapped={action?.isChainMapped}
                  conditionKeyFromTable={action?.conditionActionMapKey}
                  index={index}
                  dataEntry={dataEntry}
                  actionIndex={action?.actionIndex}
                  actions={actions}
                />
              )
            }
          </PermissibleRender>
        ))}
        {showEllipsisForActions && actions?.length > 3 && (
          <>
            <Box style={{ cursor: "pointer" }} onClick={handleClick}>
              <IconButton
                style={{
                  // backgroundColor: palette.primary.main, padding: 6,
                  marginInlineStart: 8,
                }}
              >
                <IconDots height={16} width={16} color='currentColor' />
              </IconButton>
            </Box>
            <Popover open={open} onClose={handleClose} x={x} y={y}>
              <Box padding={2} justifyContent='center' display='flex' flexDirection={"column"}>
                {actions?.slice(3, actions.length)?.map(action => (
                  <PermissibleRender isAllowed key={action?.id} action={["VIEW"]} path={[path, action.label].join(".")}>
                    {({ permitted }) =>
                      permitted && (
                        <Box key={action?.id} mb={0.5}>
                          <ActionButton
                            path={[path, action.label].join(".")}
                            viewName={viewName}
                            queryKeys={queryKeys}
                            pageId={pageId}
                            item={item}
                            disabled={disabled || !permitted}
                            key={action.id}
                            _key={`${action.id}-${item.__id}`}
                            tableAction={{
                              action: action,
                              label: action?.label,
                              icon:
                                (action?.iconConfig?.visibility === "PUBLIC" && action?.iconConfig?.icon?.match(BxPatterns.mediaUrlPattern)
                                  ? action?.iconConfig?.url
                                  : action?.iconConfig?.icon) || action?.icon,
                              condition: action?.visibilityCondition ?? action?.condition, //Needs Migration
                            }}
                            onStateChange={data => {
                              handleClose?.();
                              onStateChange(data);
                            }}
                            isUserInput={isUserInput}
                            views={views}
                            onSelectRow={onSelectRow}
                            tableId={tableId}
                            iconButton={false}
                            fullWidth
                            withBorder={false}
                            variant='outlined'
                            __data={__data}
                            closeModal={closeModal}
                            parentIds={parentIds}
                            isLoadingForEntireChain={action?.actionMappingConfig?.isLoadingForEntireChain}
                            actionsMap={action?.actionsMap}
                            actionsKey={action?.actionsKey}
                            isChainMapped={action?.isChainMapped}
                            conditionKeyFromTable={action?.conditionActionMapKey}
                            dataEntry={dataEntry}
                            actionIndex={action?.actionIndex}
                            actions={actions}
                          />
                        </Box>
                      )
                    }
                  </PermissibleRender>
                ))}
              </Box>
            </Popover>
          </>
        )}
      </>
    );
  return <></>;
};
