import { useSelector } from "react-redux";
import { selectFullComponentItemById } from "src/features/builder/selectors";
import { isParentFlex, isParentGrid } from "../../../utils";
import { shouldComponentRender } from "../componentEditorUtils";

export const ConditionalRenderer: React.FC<{
  renderConditionPaths: any[];
  componentId: any;
  isParentFlexEnable?: boolean;
  isParentGridEnabled?: boolean;
  children: React.ReactNode;
}> = ({ renderConditionPaths, componentId, children, isParentFlexEnable, isParentGridEnabled }) => {
  let shouldRender = shouldComponentRender(renderConditionPaths, componentId);

  const selectorFunc = useSelector;
  if (isParentFlexEnable) {
    const item = selectorFunc(selectFullComponentItemById(componentId));
    shouldRender = isParentFlex(item);
  } else if (isParentGridEnabled) {
    const item = selectorFunc(selectFullComponentItemById(componentId));
    shouldRender = isParentGrid(item);
  }
  if (!shouldRender) {
    return null;
  }

  return <>{children}</>;
};
