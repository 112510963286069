import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Container, DialogActions, FormControl, Grid } from "@mui/material";
import _ from "lodash";
import { FC, useEffect, useRef } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { BXInput, BXSwitch } from "src/components/BXUI/FormControls";
import { RHFIconPicker } from "src/components/BXUI/FormControls/RHFIconPicker";
import { useCallbackPrompt } from "src/hooks/useCallbackPrompt";
import { BXAppCollection } from "src/types/BXAppType";
import * as yup from "yup";
import { handleSlugChange } from "../../FormBuilder/utils";

type CreateCollectionFormProps = {
  onSave: SubmitHandler<FieldValues>;
  onCancel: Function;
  collection?: BXAppCollection;
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
  collections?: BXAppCollection[];
};

export const CreateCollectionForm: FC<CreateCollectionFormProps> = ({
  onCancel = _.noop,
  onSave = _.noop,
  collection,
  collections,
  setIsDirty,
}) => {
  const schema = yup
    .object({
      slug: yup
        .string()
        .matches(
          /^(?!.*\/\/)[a-zA-Z0-9/-]+$/,
          "Slug can only contain letters, numbers, slashes, and hyphens, and cannot have consecutive slashes"
        )
        .min(1, "Slug must be at least 1 character")
        .required("Slug is required")
        .test("unique-root-collection", "The app cannot contain more than one root slug(/).", slug => {
          if (slug !== "/") {
            return true;
          }
          return !collections?.some(c => c.slug === "/" && c.id !== collection?.id);
        }),
      name: yup
        .string()
        .matches(/^[a-zA-Z\s-]+$/, "Name can only contain letters, spaces, and hyphens")
        .required("Name is required"),
      hideFromSideBarMenu: yup.boolean(),
      icon: yup.string(),
    })
    .required();

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    setValue,
    watch,
    getValues,
  } = useForm<FieldValues>({
    defaultValues: { ...(collection || {}), hideFromSidebarMenu: collection?.hideFromSideBarMenu ?? false },
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  useCallbackPrompt(isDirty);

  useEffect(() => {
    setIsDirty?.(isDirty);
  }, [isDirty]);
  const prevCollectionNameRef = useRef(watch("name") || "");

  return (
    <Box component='form' noValidate autoComplete='off'>
      <Container maxWidth={"sm"}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <BXInput
                name={"name"}
                control={control}
                label='Name'
                variant='outlined'
                id={"bx-collection-create-name-input"}
                error={errors?.name}
                onChange={e => handleSlugChange(e.target.value, prevCollectionNameRef, getValues, setValue)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <BXInput
              name={"slug"}
              control={control}
              label='Slug'
              variant='outlined'
              id={"bx-collection-create-slug-input"}
              error={errors?.slug}
            />
          </Grid>

          <Grid item xs={12}>
            <RHFIconPicker
              variant='outlined'
              control={control}
              name={"iconConfig.icon"}
              objName={`iconConfig`}
              label={"Icon"}
              error={errors?.iconConfig}
              id={"bx-collection-create-icon"}
              setValue={setValue}
            />
          </Grid>

          <Grid item xs={12}>
            <BXSwitch name={"hideFromSideBarMenu"} control={control} label='Hide from sidebar menu' error={errors?.hideFromSideBarMenu} />
          </Grid>
        </Grid>
      </Container>
      <Grid item xs={12}>
        <DialogActions style={{ padding: 0, marginTop: 16, justifyContent: "center" }}>
          <Button onClick={handleSubmit(onSave)} variant='contained' aria-label={"save"}>
            Save
          </Button>
        </DialogActions>
      </Grid>
    </Box>
  );
};
