import { createSelector } from "@reduxjs/toolkit";
import { endUserAdapter } from "./endUserSlice";

// Get the adapter selectors for convenience
// We can use these adapter selectors for each viewName
const endUserSelectors = endUserAdapter.getSelectors();

const selectAppSlice = (state, pageId, viewName) => state.endUser.application || endUserAdapter.getInitialState();

const selectPageSlice = (state, pageId, viewName) => state.endUser?.application?.pages?.[pageId] || endUserAdapter.getInitialState();

const selectViewSlice = (state, pageId, viewName) =>
  state.endUser.application?.pages?.[pageId]?.views[viewName] || endUserAdapter.getInitialState();

// Select all components for a given viewName and pageId
export const selectAllComponents = (state, pageId, viewName) =>
  endUserSelectors.selectAll(
    pageId
      ? viewName
        ? selectViewSlice(state, pageId, viewName)
        : selectPageSlice(state, pageId, viewName)
      : selectAppSlice(state, pageId, viewName)
  );

// Select all component IDs for a given viewName and pageId
export const selectAllIds = (state, pageId, viewName) => selectViewSlice(state, pageId, viewName).ids;

// Select all components as an array of values for a given viewName and pageId
export const selectAllComponentsArray = (state, pageId, viewName) => Object.values(selectViewSlice(state, pageId, viewName).entities || {});

// Select top-level component IDs (parentId === null) for a given viewName and pageId
export const selectTopLevelComponents = (pageId, viewName) =>
  createSelector(
    state => selectAllComponents(state, pageId, viewName),
    entities => entities.filter(component => component.parentKey === null).map(component => component.props.key)
  );
// Select component by ID for a given viewName and pageId
export const selectComponentById = (state, pageId, viewName, id) =>
  pageId
    ? viewName
      ? selectViewSlice(state, pageId, viewName).entities?.[id] || null
      : selectPageSlice(state, pageId, viewName).entities?.[id] || null
    : selectAppSlice(state, pageId, viewName).entities?.[id] || null;
