import { Elements } from "@stripe/react-stripe-js";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";

export const StripeContainer = (props: any) => {
  const { children, element } = props;
  const clientSecrets = useBuildxProviderValue("clientSecrets") || [];
  const stripePromises = useBuildxProviderValue("stripePromises") || [];
  const stripeAppearance = useBuildxProviderValue("stripeAppearance") || {};

  const matchingClientSecret = Array.isArray(clientSecrets) ? clientSecrets.find(secret => secret.id === element?.id) : undefined;

  const matchingStripePromise = Array.isArray(stripePromises) ? stripePromises.find(promise => promise.id === element?.id) : undefined;

  const options = {
    clientSecret: matchingClientSecret ? matchingClientSecret.clientSecret : "",
    appearance: stripeAppearance,
  };

  return (
    <>
      {matchingStripePromise && matchingClientSecret ? (
        <Elements stripe={matchingStripePromise.stripePromise} options={options}>
          {children}
        </Elements>
      ) : (
        <>{children}</>
      )}
    </>
  );
};
