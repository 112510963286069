import { yupResolver } from "@hookform/resolvers/yup";
import MonacoEditor from "@monaco-editor/react";
import {
  Autocomplete,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  DialogActions,
  Divider,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { IconCopy, IconTrashX } from "@tabler/icons-react";
import axios from "axios";
import _ from "lodash";
import { FC, useEffect, useRef, useState } from "react";
import { Controller, FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useBuildxContext } from "src/BXEngine/BuildxContext";
import { ApiInput } from "src/components/ApiInput";
import { formatJSON } from "src/components/BXUI/DataTable/ActionButton";
import { BXInput, BXSwitch } from "src/components/BXUI/FormControls";
import { BXIconPicker } from "src/components/BXUI/FormControls/IconPicker";
import { RHFIconPicker } from "src/components/BXUI/FormControls/RHFIconPicker";
import BXModal from "src/components/BXUI/Modal";
import { useBuildxProviderValue } from "src/features/buildxProvider/selectors";
import { useCallbackPrompt } from "src/hooks/useCallbackPrompt";
import { BXApp } from "src/types/BXAppType";
import { BxPatterns } from "src/utils/BXValidate/BxPatterns";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import axiosServices from "src/utils/axios";
import * as yup from "yup";
import { ColorPicker } from "../../FormBuilder/components/ColorPicker";
import ItemsPropertiesAccordion from "../../FormBuilder/components/ComponentEditor/EditorComponents/ItemsPropertiesAccordion";
import { handleSlugChange } from "../../FormBuilder/utils";
import OASSelector from "../../OASSelector";

type CreateAppFormProps = {
  onSave: SubmitHandler<FieldValues>;
  onCancel: Function;
  app?: any;
  withAppInfo?: boolean;
  withAuthConfig?: boolean;
  appId?: string;
  setIsDirty?: React.Dispatch<React.SetStateAction<boolean>>;
  isImport?: boolean;
  localApps: any;
};

export const CreateAppForm: FC<CreateAppFormProps> = ({
  onCancel = _.noop,
  onSave = _.noop,
  app = undefined,
  withAppInfo = true,
  withAuthConfig = true,
  isImport = false,
  setIsDirty,
  appId,
  localApps,
}) => {
  const envs = useBuildxProviderValue("envs");
  const { appRoutesMap } = useBuildxContext();

  const validateLandingPageRoute = (route, { isProtected = false, slug = "" } = {}) => {
    if (isImport) {
      return true;
    }

    if (!route || route === "/") {
      return true;
    }

    const isUnprotectedLanding = !isProtected;
    const targetRoute = `${slug}${route}`;
    const routeDetails = appRoutesMap.current?.[targetRoute];

    // Check if the route exists in the map
    if (!routeDetails) {
      return false;
    }

    // Validate the unprotected/protected status of the route
    return routeDetails.isUnprotected === isUnprotectedLanding;
  };

  const getValidationName: any = () => {
    const appNameExists = (name, appId) => {
      return !localApps.some(app => app.name.toLowerCase() === name.toLowerCase() && app.id !== appId);
    };

    return yup
      .string()
      .required("App name is required")
      .test("unique-name", "App name already exists", (value, context) => {
        if (!value) {
          return new yup.ValidationError("App name is required", null, context.path);
        }
        return appNameExists(value, appId);
      });
  };
  const defaultVariants = ["default", "success", "error", "info", "warning"];

  const schema = yup
    .object({
      slug: yup.string().required(),
      name: getValidationName(),
      icon: yup.string(),
      authDisabled: yup.boolean(),
      baseUrl: yup.string().when("appConfig.isSingleSignOn", {
        is: true,
        then: yup.string().required("Field is required"),
      }),
      fqdn: yup.string().when("appConfig.isSingleSignOn", {
        is: true,
        then: yup.string().required("Field is required"),
      }),
      userInfo: yup.object().when("appConfig", {
        is: (appConfig: any) => !!appConfig?.isSingleSignOn,
        then: schema =>
          schema.shape({
            uri: yup.string().required("Field is required"),
            handleKey: yup.string().test("handleKey", "Field is required", (value, context: any) => {
              const [parent1, parent2] = context?.from as any;
              const authType = parent2?.value?.appConfig?.auth?.type;
              return authType == "Other" ? !!value : true;
            }),
            nameKey: yup.string().test("nameKey", "Field is required", (value, context: any) => {
              const [parent1, parent2] = context?.from as any;
              const authType = parent2?.value?.appConfig?.auth?.type;
              return authType == "Other" ? !!value : true;
            }),
            emailKey: yup.string().test("emailKey", "Field is required", (value, context: any) => {
              const [parent1, parent2] = context?.from as any;
              const authType = parent2?.value?.appConfig?.auth?.type;
              return authType == "Other" ? !!value : true;
            }),
          }),
      }),

      appConfig: yup.object().shape({
        snackbar: yup.object().shape({
          duration: yup.string().default("5"),
          position: yup.string().required().default("bottom-right"),
          variant: yup.string().required().default("default"),
          allVariants: yup.array().of(yup.string()).default(defaultVariants),
          showCloseButton: yup.boolean().default(true),
          closeButton: yup.string().required().default("Without Button"),
          maxSnack: yup.string().default("3"),
          backgroundColorAdvance: yup.string(),
          colorAdvance: yup.string(),
          iconAdvance: yup.string(),
          iconColor: yup.string(),
          alignText: yup.string().default("left"),
          variantsSettings: yup.object().default({}),
        }),
        script: yup.object().shape({
          head: yup.string(),
          body: yup.string(),
        }),
        pageUri: yup.string().test("Valid App Route", "Provide a valid app route", (value, context) => {
          return validateLandingPageRoute(value, {
            isProtected: true,
            slug: (context as any)?.from?.[1].value.slug,
          });
        }),
        unprotectedPageUri: yup.string().test("Valid Unprotected App Route", "Provide a valid unprotected app route", (value, context) => {
          return validateLandingPageRoute(value, {
            isProtected: false,
            slug: (context as any)?.from?.[1].value.slug,
          });
        }),
        enableRecaptcha: yup.boolean(),
        recaptchaSiteKey: yup.string().when("enableRecaptcha", {
          is: true,
          then: yup.string().required("Your client site key is required"),
          otherwise: yup.string(),
        }),
        auth: yup.object().shape({
          type: yup.string(),
          authApi: yup.object().when("type", {
            is: (type: any) => type !== "No Auth",
            then: yup.object().shape({
              uri: yup.string().required("Auth API URL is required"),
            }),
            otherwise: yup.object().shape({
              uri: yup.string().notRequired(),
            }),
          }),
        }),
        isOnlyPhoto: yup.boolean().default(false),
        theme: yup.object().shape({
          background: yup
            .string()
            .test("background", "Invalid color", (value: any) => !value || !!CSS.supports("color", value))
            .matches(BxPatterns.hexColorPattern, { message: "Please enter a hexadecimal color", excludeEmptyString: true })
            .nullable(),
          paper: yup
            .string()
            .test("paper", "Invalid color", (value: any) => !value || !!CSS.supports("color", value))
            .matches(BxPatterns.hexColorPattern, { message: "Please enter a hexadecimal color", excludeEmptyString: true })
            .nullable(),
          textPrimary: yup
            .string()
            .test("textPrimary", "Invalid color", (value: any) => !value || !!CSS.supports("color", value))
            .matches(BxPatterns.hexColorPattern, { message: "Please enter a hexadecimal color", excludeEmptyString: true })
            .nullable(),
          primaryMain: yup
            .string()
            .test("primaryMain", "Invalid color", (value: any) => !value || !!CSS.supports("color", value))
            .matches(BxPatterns.hexColorPattern, { message: "Please enter a hexadecimal color", excludeEmptyString: true })
            .nullable(),
          primaryLight: yup
            .string()
            .test("primaryLight", "Invalid color", (value: any) => !value || !!CSS.supports("color", value))
            .matches(BxPatterns.hexColorPattern, { message: "Please enter a hexadecimal color", excludeEmptyString: true })
            .nullable(),
        }),
        loginCustomizations: yup.object().shape({
          backgroundColor: yup.string().test("backgroundColor", "Invalid color", (value: any) => !value || !!CSS.supports("color", value)),
          cardColor: yup.string().test("cardColor", "Invalid color", (value: any) => !value || !!CSS.supports("color", value)),
        }),
        isBuildxLoginEnabled: yup.boolean(),
        enableBuildxSignIn: yup.boolean().test("enableBuildxSignIn", "At least one must be selected", (value: any, context: any) => {
          if (!value && !context?.parent?.enableGoogleSignIn && context?.parent?.isSingleSignOn) return false;
          return true;
        }),
        enableGoogleSignIn: yup.boolean().test("enableGoogleSignIn", "At least one must be selected", (value: any, context: any) => {
          if (!value && !context?.parent?.enableBuildxSignIn && context?.parent?.isSingleSignOn) return false;
          return true;
        }),
        googleInfo: yup.object().when("enableGoogleSignIn", {
          is: true,
          then: yup.object().shape({
            clientId: yup.string().required("Field is required"),
            authEndpoint: yup.string().required("Field is required"),
          }),
        }),
      }),
    })
    .required();

  const canSingleSignOn =
    (app?.cfMetadata?.distributionStatus === "DEPLOYED" &&
      app?.cfMetadata?.distributionEnabled &&
      app?.cfMetadata?.certificateStatus === "CERTIFICATE_ISSUED") ||
    window.location.host.includes("localhost");

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    setError,
    reset,
    getValues,
    formState: { errors, isDirty },
  } = useForm<FieldValues>({
    defaultValues: {
      ...app,
      appConfig: {
        ...app?.appConfig,
        isSingleSignOn: canSingleSignOn && app?.appConfig?.isSingleSignOn,
        snackbar: {
          ...app?.appConfig?.snackbar,
          duration: app?.appConfig?.snackbar?.duration || 5,
          maxSnack: app?.appConfig?.snackbar?.maxSnack || 3,
          allVariants: defaultVariants,
          variantsSettings: {},
        },
        auth: {
          ...app?.appConfig?.auth,
          type: app?.appConfig?.auth?.type || "Buildx",
          deviceApi: {
            ...app?.appConfig?.auth?.deviceApi,
            uri: app ? app?.appConfig?.auth?.deviceApi?.uri : "/device",
            method: app?.appConfig?.auth?.deviceApi?.method || "POST",
            accessTokenPath: app ? app?.appConfig?.auth?.deviceApi?.accessTokenPath : "accessToken",
            body:
              app?.appConfig?.auth?.deviceApi?.body ||
              '{\n    "appVersion": "{$buildx.appVersion}",\n    "deviceType": "{$global.browser.deviceType}",\n    "deviceUDID": "{$global.localStorage.device_uuid}",\n    "osVersion": "{$global.browser.osVersion}",\n    "timeZone": "{$global.browser.timeZone}"\n}',
          },
          authApi: {
            ...app?.appConfig?.auth?.authApi,
            uri: app?.appConfig?.auth?.authApi?.uri || "/auth/authenticate",
            method: app?.appConfig?.auth?.authApi?.method || "POST",
            accessTokenPath: app ? app?.appConfig?.auth?.authApi?.accessTokenPath : "accessToken",
            namePath: app ? app?.appConfig?.auth?.authApi?.namePath : "principal.name",
            usernamePath: app ? app?.appConfig?.auth?.authApi?.usernamePath : "principal.handle",
            userPhotoPath: app ? app?.appConfig?.auth?.authApi?.userPhotoPath : "principal.profilePhoto",
            body:
              app?.appConfig?.auth?.authApi?.body ||
              '{\n    "email": "{buildxLogin.email}",\n    "password": "{buildxLogin.password}",\n    "recaptcha": "{buildxLogin.recaptcha}"\n}',
            headers: _.isObject(app?.appConfig?.auth?.authApi?.headers)
              ? app?.appConfig?.auth?.authApi?.headers
              : {
                  Authorization: "Bearer {$global.localStorage.accessToken}",
                },
          },
        },
        theme: {
          ...app?.appConfig?.theme,
        },
        isBuildXLoginEnabled: app?.appConfig?.isBuildXLoginEnabled ?? true,
      },

      userInfo: {
        ...app?.userInfo,
        uri: app?.userInfo?.uri || "/user/me",
        type: app?.appConfig?.auth?.type === "Buildx" ? "BUILDX" : "OTHER",
        method: app?.userInfo?.method || "GET",
        headers: _.isObject(app?.userInfo?.headers)
          ? app?.userInfo?.headers
          : {
              Authorization: "Bearer {this.login.token}",
            },
      },
    } as BXApp,
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const [isBackgroundImageLoading, setIsBackgroundImageLoading] = useState(false);
  const [isAppIconLoading, setIsAppIconLoading] = useState(false);
  const [tabValue, setTabValue] = useState(1);
  const [customThemeTabValue, setCustomThemeTabValue] = useState(1);
  const [inputValue, setInputValue] = useState("");
  const [showCustomVariantInputs, setShowCustomVariantInputs] = useState(false);
  const [isCopyValueModalOpen, setIsCopyValueModalOpen] = useState<boolean>(false);
  const [newType, setNewType] = useState<string>("");
  const [selectedType, setSelectedType] = useState<string>("");

  const preventDefaultRef = useRef(false);
  const variantsFromState = watch("appConfig.snackbar.allVariants");
  const [options, setOptions] = useState(variantsFromState || defaultVariants);
  useEffect(() => {
    setOptions(variantsFromState || defaultVariants);
  }, [variantsFromState]);

  const fileInputRef = useRef<any>(null);

  // const alignmentValues = [
  //   { key: "left", value: "Left" },
  //   { key: "center", value: "Center" },
  //   { key: "right", value: "Right" },
  // ];

  const modalTabs = [
    {
      name: "slug,name,baseUrl",
      label: "Info",
      condition: true,
      value: 1,
    },
    {
      name: "auth,userInfo",
      label: "Auth",
      condition: true,
      value: 2,
    },
    {
      name: "appConfig.theme",
      label: "Theme",
      condition: true,
      value: 4,
    },
    {
      name: "appConfig.loginCustomizations",
      label: "Login Customizations",
      condition: !!watch("appConfig.isSingleSignOn") && watch("appConfig.auth.type") !== "No Auth",
      value: 5,
    },
    {
      name: "appConfig.config",
      label: "Config",
      condition: true,
      value: 6,
    },
  ];
  const customThemeTabs = [
    {
      name: "JSON",
      value: 1,
    },
    {
      name: "Upload",
      value: 2,
    },
  ];

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>, onChange?: any, setLoading?: any) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setLoading(true);

    let key = null;

    try {
      const { data } = await axiosServices.get("/uploads/s3-url");

      const { key: responseKey, url } = data;
      key = responseKey;
      const extn = file.type.split("/")[1];

      await axios.put(url, file, {
        headers: {
          "Content-Type": file.type,
        },
      });

      const { data: _data } = await axiosServices.post(`/admin/application/${appId}/resources`, undefined, {
        params: {
          key,
          extn,
        },
      });

      onChange(_data?.msg);

      enqueueSnackbarRef?.("Uploaded Successfully", {
        variant: "success",
      });
    } catch (e) {
      enqueueSnackbarRef?.("Something went wrong", {
        variant: "error",
      });
      event.target.value = "";
    } finally {
      setLoading(false);
    }
  };

  useCallbackPrompt(isDirty);

  useEffect(() => {
    setIsDirty?.(isDirty);
  }, [isDirty]);

  useEffect(() => {
    reset({
      ...app,
      appConfig: {
        ...app?.appConfig,
        isSingleSignOn: canSingleSignOn && app?.appConfig?.isSingleSignOn,
        auth: {
          ...app?.appConfig?.auth,
          type: app?.appConfig?.auth?.type || "Buildx",
          deviceApi: {
            ...app?.appConfig?.auth?.deviceApi,
            uri: app ? app?.appConfig?.auth?.deviceApi?.uri : "/device",
            method: app?.appConfig?.auth?.deviceApi?.method || "POST",
            accessTokenPath: app ? app?.appConfig?.auth?.deviceApi?.accessTokenPath : "accessToken",
            body:
              app?.appConfig?.auth?.deviceApi?.body ||
              '{\n    "appVersion": "{$buildx.appVersion}",\n    "deviceType": "{$global.browser.deviceType}",\n    "deviceUDID": "{$global.localStorage.device_uuid}",\n    "osVersion": "{$global.browser.osVersion}",\n    "timeZone": "{$global.browser.timeZone}"\n}',
          },
          authApi: {
            ...app?.appConfig?.auth?.authApi,
            uri: app?.appConfig?.auth?.authApi?.uri || "/auth/authenticate",
            method: app?.appConfig?.auth?.authApi?.method || "POST",
            accessTokenPath: app ? app?.appConfig?.auth?.authApi?.accessTokenPath : "accessToken",
            namePath: app ? app?.appConfig?.auth?.authApi?.namePath : "principal.name",
            usernamePath: app ? app?.appConfig?.auth?.authApi?.usernamePath : "principal.handle",
            userPhotoPath: app ? app?.appConfig?.auth?.authApi?.userPhotoPath : "principal.profilePhoto",
            body:
              app?.appConfig?.auth?.authApi?.body ||
              '{\n    "email": "{buildxLogin.email}",\n    "password": "{buildxLogin.password}",\n    "recaptcha": "{buildxLogin.recaptcha}"\n}',
            headers: _.isObject(app?.appConfig?.auth?.authApi?.headers)
              ? app?.appConfig?.auth?.authApi?.headers
              : {
                  Authorization: "Bearer {$global.localStorage.deviceAccessToken}",
                },
          },
        },
        theme: {
          ...app?.appConfig?.theme,
        },
        config: {
          ...app?.appConfig?.config,
        },
        isBuildXLoginEnabled: app?.appConfig?.isBuildXLoginEnabled ?? true,
      },
      userInfo: {
        ...app?.userInfo,
        uri: app?.userInfo?.uri || "/user/me",
        type: app?.appConfig?.auth?.type === "Buildx" ? "BUILDX" : "OTHER",
        method: app?.userInfo?.method || "GET",
        headers: _.isObject(app?.userInfo?.headers)
          ? app?.userInfo?.headers
          : {
              Authorization: "Bearer {this.login.token}",
            },
      },
    });
  }, [app]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const handleCustomThemeTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCustomThemeTabValue(newValue);
  };

  const handleCustomThemeFileSend = async () => {
    if (_.isNil(watch("appConfig.theme.custom.url"))) return;

    try {
      const response = await axios.get(watch("appConfig.theme.custom.url"));
      setValue("appConfig.theme.custom.json", response?.data);
      setValue("appConfig.theme.custom.url", "");
    } catch (error) {}
  };
  const handleCustomThemeFileUpload = async (event: any) => {
    const file = event?.target?.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        try {
          const json = formatJSON(e?.target?.result);
          setValue("appConfig.theme.custom.json", json);
        } catch (error) {
          setError("appConfig.theme.custom.file", { message: "Invalid JSON file" });
        }
        if (fileInputRef?.current?.value) fileInputRef.current.value = "";
      };
      reader.readAsText(file);
    }
  };
  const filterOptions = (options, state) => {
    const filtered = options.filter(option => option.toLowerCase().includes(state.inputValue.toLowerCase()));

    if (state.inputValue !== "" && !options.includes(state.inputValue)) {
      filtered.push(`Add "${state.inputValue}"`);
    }

    return filtered;
  };
  const handleSetChangeIconProp = (path: string) => (newValue: any) => {
    if (newValue.icon) {
      setValue(`${path}.icon`, newValue.icon);
    }
    if (newValue.url) {
      setValue(`${path}.url`, newValue.url);
    }
    if (newValue.visibility) {
      setValue(`${path}.visibility`, newValue.visibility);
    }
  };

  const prevAppNameRef = useRef(watch("name") || "");
  const currentVariant = watch("appConfig.snackbar.variant");
  const allVariants = watch("appConfig.snackbar.allVariants");
  const customVariants = allVariants?.filter(variant => !defaultVariants.includes(variant));

  const [accordionState, setAccordionState] = useState({
    snackbar: false,
    script: false,
  });
  type AccordionState = {
    snackbar: boolean;
    script: boolean;
  };
  const handleAccordionChange = (accordionKey: keyof AccordionState) => {
    setAccordionState(prevState => ({
      ...prevState,
      [accordionKey]: !prevState[accordionKey],
    }));
  };

  return (
    <Box component='form' noValidate autoComplete='off'>
      {!withAuthConfig && (
        <Box sx={{ bgcolor: "background.paper" }} mb={2}>
          <Tabs value={tabValue} onChange={handleTabChange} textColor='primary' indicatorColor='primary' style={{ textTransform: "none" }}>
            {modalTabs.map(
              ({ value, label, condition, name }) =>
                condition && (
                  <Tab
                    key={name}
                    label={
                      <>
                        {!!name?.split(",")?.find(e => !!errors?.[e] || !!(errors as any)?.appConfig?.[e]) && (
                          <Badge color='error' variant='dot' style={{ position: "absolute", top: 10, right: 10 }} />
                        )}
                        <Typography fontWeight={"bold"}>{label}</Typography>
                      </>
                    }
                    sx={{ textTransform: "none" }}
                    value={value}
                  />
                )
            )}
          </Tabs>
        </Box>
      )}

      <Container maxWidth={"md"}>
        <Grid container spacing={3}>
          {withAppInfo && (
            <>
              {tabValue == 1 && (
                <>
                  <Grid item xs={12}>
                    <BXInput
                      variant='outlined'
                      required
                      label={"App name"}
                      id={"bx-app-create-name-input"}
                      control={control}
                      name={"name"}
                      error={errors?.name}
                      onChange={e => handleSlugChange(e.target.value, prevAppNameRef, getValues, setValue)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <BXInput
                      variant='outlined'
                      required
                      label={"App slug"}
                      id={"bx-app-create-slug-input"}
                      control={control}
                      name={"slug"}
                      error={errors?.slug}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <BXInput variant='outlined' required label={"Base URL"} control={control} name={"baseUrl"} error={errors?.baseUrl} />
                  </Grid>
                  <Grid item xs={12}>
                    <BXInput
                      variant='outlined'
                      label={"Landing Page URI"}
                      control={control}
                      name={"appConfig.unprotectedPageUri"}
                      error={(errors as any)?.appConfig?.unprotectedPageUri}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <BXInput
                      variant='outlined'
                      label={"Logged in Landing Page URI"}
                      control={control}
                      name={"appConfig.pageUri"}
                      error={(errors as any)?.appConfig?.pageUri}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <BXInput
                      required
                      variant='outlined'
                      name={"env.id"}
                      error={(errors as any)?.env?.id}
                      control={control}
                      select
                      label={"Environment"}
                    >
                      <MenuItem value=''>
                        <em>None</em>
                      </MenuItem>
                      <Divider />
                      {envs?.map(item => (
                        <MenuItem key={item.id} value={item.id}>
                          {item?.name}
                        </MenuItem>
                      ))}
                    </BXInput>
                  </Grid>
                  <Grid item xs={12}>
                    <RHFIconPicker
                      label={"App Icon/Logo"}
                      control={control}
                      name={"appConfig.iconConfig.icon"}
                      objName={`appConfig.iconConfig`}
                      setValue={setValue}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <BXSwitch
                      name={"appConfig.isOnlyPhoto"}
                      control={control}
                      label={"Show only photo"}
                      error={(errors as any)?.appConfig?.isOnlyPhoto}
                    />
                  </Grid>
                </>
              )}
              {tabValue == 2 && (
                <>
                  <Grid item xs={6}>
                    <BXInput
                      variant='outlined'
                      required
                      label={"Type"}
                      control={control}
                      select
                      name={"appConfig.auth.type"}
                      error={(errors as any)?.auth?.type}
                    >
                      {["Buildx", "No Auth", "API Key", "Basic Auth", "Other"]?.map(item => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </BXInput>
                  </Grid>
                  {watch("appConfig.auth.type") !== "No Auth" && (
                    <>
                      {watch("appConfig.auth.type") == "API Key" && (
                        <Grid item container spacing={2}>
                          <Grid item xs={6}>
                            <BXInput
                              variant='outlined'
                              required
                              label={"Key"}
                              control={control}
                              name={"appConfig.auth.keyValue"}
                              error={(errors as any)?.auth?.keyValue}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <BXInput
                              variant='outlined'
                              required
                              label={"Value"}
                              control={control}
                              name={"appConfig.auth.apiValue"}
                              error={(errors as any)?.auth?.apiValue}
                            />
                          </Grid>
                        </Grid>
                      )}
                      {watch("appConfig.auth.type") == "Basic Auth" && (
                        <Grid item container spacing={2}>
                          <Grid item xs={6}>
                            <BXInput
                              variant='outlined'
                              required
                              label={"Username"}
                              control={control}
                              name={"appConfig.auth.basicAuthUsername"}
                              error={(errors as any)?.auth?.basicAuthUsername}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <BXInput
                              variant='outlined'
                              required
                              label={"Password"}
                              control={control}
                              name={"appConfig.auth.basicAuthPassword"}
                              error={(errors as any)?.auth?.basicAuthPassword}
                            />
                          </Grid>
                        </Grid>
                      )}

                      <Grid item xs={12}>
                        <ApiInput
                          apiLabel='Device Registration API'
                          control={control}
                          path='appConfig.auth.deviceApi'
                          pathURL='appConfig.auth.deviceApi.uri'
                          error={(errors as any)?.auth?.deviceApi}
                          errorURL={(errors as any)?.auth?.deviceApi?.uri}
                          getValues={getValues}
                          setValue={setValue}
                          watch={watch}
                          OASElement={
                            <OASSelector
                              swaggerProps={{
                                type: "auth",
                                onSuccess: (values: any, data: any) => {
                                  setValue(`appConfig.auth.deviceApi.uri`, data?.path);
                                  setValue(`appConfig.auth.deviceApi.source`, data?.path);
                                  setValue(`appConfig.auth.deviceApi.method`, (data?.method as string).toUpperCase());
                                  setValue(`appConfig.auth.deviceApi.body`, formatJSON(JSON.stringify(data?.body || {})));
                                  setValue(`appConfig.auth.deviceApi.headers`, values.headers);
                                },
                              }}
                            />
                          }
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <ApiInput
                          apiLabel='Auth API'
                          control={control}
                          path='appConfig.auth.authApi'
                          pathURL='appConfig.auth.authApi.uri'
                          getValues={getValues}
                          setValue={setValue}
                          error={(errors as any)?.appConfig?.auth?.authApi}
                          errorURL={(errors as any)?.appConfig?.auth?.authApi?.uri}
                          OASElement={
                            <OASSelector
                              swaggerProps={{
                                type: "auth",
                                onSuccess: (values: any, data: any) => {
                                  setValue(`appConfig.auth.authApi.uri`, data?.path);
                                  setValue(`appConfig.auth.authApi.source`, data?.path);
                                  setValue(`appConfig.auth.authApi.method`, (data?.method as string).toUpperCase());
                                  setValue(`appConfig.auth.authApi.body`, formatJSON(JSON.stringify(data?.body || {})));
                                  setValue(`appConfig.auth.authApi.headers`, values.headers);
                                },
                              }}
                            />
                          }
                          watch={watch}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <BXInput
                          variant='outlined'
                          required
                          label={"Device Access Token Key"}
                          control={control}
                          name={"appConfig.auth.deviceApi.accessTokenPath"}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <BXInput
                          variant='outlined'
                          required
                          label={"Auth Access Token Key"}
                          control={control}
                          name={"appConfig.auth.authApi.accessTokenPath"}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <BXInput
                          variant='outlined'
                          required
                          label={"Name Key"}
                          control={control}
                          name={"appConfig.auth.authApi.namePath"}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <BXInput
                          variant='outlined'
                          required
                          label={"Username Key"}
                          control={control}
                          name={"appConfig.auth.authApi.usernamePath"}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <BXInput
                          variant='outlined'
                          required
                          label={"User Profile Photo Key"}
                          control={control}
                          name={"appConfig.auth.authApi.userPhotoPath"}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <BXSwitch
                          name={"appConfig.enableRecaptcha"}
                          control={control}
                          label={"Enable reCAPTCHA"}
                          error={(errors as any)?.appConfig?.enableRecaptcha}
                        />
                      </Grid>
                      {watch("appConfig.enableRecaptcha") && (
                        <Grid item xs={12}>
                          <BXInput
                            variant='outlined'
                            required
                            label={"Your client site key"}
                            error={Boolean((errors as any)?.appConfig?.recaptchaSiteKey)}
                            helperText={(errors as any)?.appConfig?.recaptchaSiteKey?.message}
                            control={control}
                            name={"appConfig.recaptchaSiteKey"}
                          />
                        </Grid>
                      )}

                      <Grid item xs={12}>
                        <BXSwitch
                          name={"appConfig.isSingleSignOn"}
                          control={control}
                          label={"Single Sign On"}
                          disabled={!canSingleSignOn}
                          error={(errors as any)?.appConfig?.isSingleSignOn}
                        />
                        {!canSingleSignOn && <Typography color='gray'>*You must configure DNS to enable this option</Typography>}
                      </Grid>

                      {watch("appConfig.isSingleSignOn") && (
                        <>
                          <Grid item xs={12} marginInlineStart={2}>
                            <BXSwitch label='Admins only' control={control} name='adminLoginOnly' defaultChecked defaultValue={"on"} />
                          </Grid>
                          <Grid item xs={12} marginInlineStart={2}>
                            <BXSwitch
                              label='Profile Management'
                              control={control}
                              name='appConfig.withProfiles'
                              disabled={!watch("appConfig.isSingleSignOn")}
                            />
                          </Grid>
                          <>
                            <Grid item xs={12} marginInlineStart={2}>
                              <ApiInput
                                apiLabel='User Info Url'
                                control={control}
                                pathURL='userInfo.uri'
                                path='userInfo'
                                getValues={getValues}
                                setValue={setValue}
                                watch={watch}
                                error={(errors as any)?.userInfo}
                                errorURL={(errors as any)?.userInfo?.uri}
                                OASElement={
                                  <OASSelector
                                    swaggerProps={{
                                      type: "auth",
                                      onSuccess: (values: any, data: any) => {
                                        setValue(`userInfo.uri`, data?.path);
                                        setValue(`userInfo.source`, data?.path);
                                        setValue(`userInfo.method`, (data?.method as string).toUpperCase());
                                        setValue(`userInfo.body`, formatJSON(JSON.stringify(data?.body || {})));
                                        setValue(`userInfo.headers`, values.headers);
                                      },
                                    }}
                                  />
                                }
                              />
                            </Grid>
                            {watch("appConfig.auth.type") == "Other" && (
                              <Grid item container spacing={3} marginInlineStart={0}>
                                <Grid item xs={3}>
                                  <BXInput
                                    variant='outlined'
                                    required
                                    label={"Name Key"}
                                    control={control}
                                    name={"userInfo.nameKey"}
                                    error={(errors as any)?.userInfo?.nameKey}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <BXInput
                                    variant='outlined'
                                    required
                                    label={"Username Key"}
                                    control={control}
                                    name={"userInfo.handleKey"}
                                    error={(errors as any)?.userInfo?.handleKey}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <BXInput variant='outlined' required label={"Nonce Key"} control={control} name={"userInfo.nonceKey"} />
                                </Grid>
                                <Grid item xs={3}>
                                  <BXInput variant='outlined' required label={"Roles Key"} control={control} name={"userInfo.rolesKey"} />
                                </Grid>
                                <Grid item xs={3}>
                                  <BXInput
                                    variant='outlined'
                                    required
                                    label={"Role Name Key"}
                                    control={control}
                                    name={"userInfo.roleNameKey"}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <BXInput
                                    variant='outlined'
                                    required
                                    label={"Email Key"}
                                    control={control}
                                    name={"userInfo.emailKey"}
                                    error={(errors as any)?.userInfo?.emailKey}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <BXInput
                                    variant='outlined'
                                    required
                                    label={"Phone Number Key"}
                                    control={control}
                                    name={"userInfo.phoneNumKey"}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <BXInput
                                    variant='outlined'
                                    required
                                    label={"User Profile Photo Key"}
                                    control={control}
                                    name={"userInfo.userPhotoKey"}
                                  />
                                </Grid>
                              </Grid>
                            )}
                            <Grid item xs={12}>
                              <BXSwitch
                                label='Enable BuildX Login'
                                defaultChecked
                                defaultValue={"on"}
                                control={control}
                                name='appConfig.isBuildXLoginEnabled'
                              />
                            </Grid>
                            {watch(`appConfig.isBuildXLoginEnabled`) && (
                              <Grid item container spacing={3}>
                                <Grid item xs={12} marginInlineStart={4}>
                                  <BXSwitch
                                    name={"appConfig.enableBuildxSignIn"}
                                    control={control}
                                    label={"Email login"}
                                    error={(errors as any)?.appConfig?.enableBuildxSignIn}
                                  />
                                </Grid>

                                <Grid item xs={12} marginInlineStart={4}>
                                  <BXSwitch
                                    name={"appConfig.enableGoogleSignIn"}
                                    control={control}
                                    label={"Google Login"}
                                    error={(errors as any)?.appConfig?.enableGoogleSignIn}
                                  />
                                </Grid>

                                <Grid item xs={12} marginInlineStart={4}>
                                  <BXInput
                                    variant='outlined'
                                    required
                                    label={"Client ID"}
                                    control={control}
                                    name={"appConfig.googleInfo.clientId"}
                                    error={(errors as any)?.appConfig?.googleInfo?.clientId}
                                    disabled={!watch("appConfig.enableGoogleSignIn")}
                                  />
                                </Grid>
                                <Grid item xs={12} marginInlineStart={4}>
                                  <BXInput
                                    variant='outlined'
                                    required
                                    label={"Auth Endpoint"}
                                    control={control}
                                    name={"appConfig.googleInfo.authEndpoint"}
                                    error={(errors as any)?.appConfig?.googleInfo?.authEndpoint}
                                    disabled={!watch("appConfig.enableGoogleSignIn")}
                                  />
                                </Grid>
                              </Grid>
                            )}
                          </>
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              <>
                {tabValue == 4 && (
                  <>
                    <Grid item container width='100%' spacing={2}>
                      <Grid item>
                        <BXSwitch sx={{ marginY: 1 }} label='Custom theme' control={control} name='appConfig.theme.isCustomThemeEnabled' />
                      </Grid>

                      {!watch("appConfig.theme.isCustomThemeEnabled") && (
                        <Grid container width='100%' spacing={2}>
                          <Grid item xs={4}>
                            <ColorPicker
                              control={control}
                              setValue={setValue}
                              watch={watch}
                              errors={(errors as any)?.appConfig?.theme?.background}
                              name='appConfig.theme.background'
                              label='Background Color'
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <ColorPicker
                              control={control}
                              setValue={setValue}
                              watch={watch}
                              errors={(errors as any)?.appConfig?.theme?.paper}
                              name='appConfig.theme.paper'
                              label='Paper Color'
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <ColorPicker
                              control={control}
                              setValue={setValue}
                              watch={watch}
                              errors={(errors as any)?.appConfig?.theme?.textPrimary}
                              name='appConfig.theme.textPrimary'
                              label='Primary Text Color'
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <ColorPicker
                              control={control}
                              setValue={setValue}
                              watch={watch}
                              errors={(errors as any)?.appConfig?.theme?.primaryMain}
                              name='appConfig.theme.primaryMain'
                              label='Main Primary Color'
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <ColorPicker
                              control={control}
                              setValue={setValue}
                              watch={watch}
                              errors={(errors as any)?.appConfig?.theme?.primaryLight}
                              name='appConfig.theme.primaryLight'
                              label='Light Primary Color'
                            />
                          </Grid>
                        </Grid>
                      )}

                      {watch("appConfig.theme.isCustomThemeEnabled") && (
                        <>
                          <Tabs
                            value={customThemeTabValue}
                            onChange={handleCustomThemeTabChange}
                            textColor='primary'
                            indicatorColor='primary'
                            sx={{ width: "100%", mb: 2 }}
                          >
                            {customThemeTabs.map(({ name, value }) => (
                              <Tab
                                key={name}
                                label={<Typography fontWeight='bold'>{name}</Typography>}
                                sx={{ textTransform: "none" }}
                                value={value}
                              />
                            ))}
                          </Tabs>

                          <Grid container spacing={2} padding={2} width='100%'>
                            {customThemeTabValue === 1 && (
                              <Grid item xs={12}>
                                <MonacoEditor
                                  width='100%'
                                  height='250px'
                                  language='json'
                                  theme='vs-dark'
                                  value={
                                    !_.isString(watch("appConfig.theme.custom.json"))
                                      ? JSON.stringify(watch("appConfig.theme.custom.json"))
                                      : watch("appConfig.theme.custom.json")
                                  }
                                  options={{ colorDecorators: true }}
                                  onChange={newValue => setValue("appConfig.theme.custom.json", newValue)}
                                />
                              </Grid>
                            )}

                            {customThemeTabValue === 2 && (
                              <Grid item container xs={12} spacing={2}>
                                <Grid item xs={6}>
                                  <TextField
                                    sx={{ width: "100%" }}
                                    variant='outlined'
                                    type='file'
                                    inputProps={{ accept: ".json" }}
                                    onChange={handleCustomThemeFileUpload}
                                    error={(errors as any)?.appConfig?.theme?.custom?.file}
                                    ref={fileInputRef}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography>Or</Typography>
                                </Grid>
                                <Grid item container xs={12} spacing={2}>
                                  <Grid item xs={6}>
                                    <BXInput
                                      name='appConfig.theme.custom.url'
                                      control={control}
                                      label='Url'
                                      variant='outlined'
                                      error={(errors?.appConfig as any)?.theme?.custom?.url}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </>
                )}
                {tabValue == 5 && (
                  <>
                    <Grid item xs={6}>
                      <BXInput
                        variant='outlined'
                        required
                        label={"Title"}
                        control={control}
                        name={"appConfig.loginCustomizations.title"}
                        error={(errors as any)?.appConfig?.loginCustomizations?.title}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <BXInput
                        variant='outlined'
                        required
                        label={"Description"}
                        control={control}
                        name={"appConfig.loginCustomizations.description"}
                        error={(errors as any)?.appConfig?.loginCustomizations?.description}
                      />
                    </Grid>
                    <ColorPicker
                      control={control}
                      setValue={setValue}
                      watch={watch}
                      gridProps={{ xs: 4 }}
                      errors={(errors as any)?.appConfig?.loginCustomizations?.backgroundColor}
                      name='appConfig.loginCustomizations.backgroundColor'
                      label='Background Color'
                    />
                    <ColorPicker
                      control={control}
                      setValue={setValue}
                      watch={watch}
                      gridProps={{ xs: 4 }}
                      errors={(errors as any)?.appConfig?.loginCustomizations?.cardColor}
                      name='appConfig.loginCustomizations.cardColor'
                      label='Card Background Color'
                    />

                    <Grid item xs={6}>
                      <BXInput
                        variant='outlined'
                        required
                        label={"Background Image Size"}
                        control={control}
                        name={"appConfig.loginCustomizations.backgroundImageSize"}
                        error={(errors as any)?.appConfig?.loginCustomizations?.backgroundImageSize}
                      />
                    </Grid>
                    {/* <Grid item xs={6}>
                        <BXInput
                          required
                          variant='outlined'
                          name={`appConfig.loginCustomizations.titleAlignment`}
                          error={(errors as any)?.appConfig?.loginCustomizations?.titleAlignment}
                          control={control}
                          select
                          label={"Title Alignment"}
                          value={watch("appConfig.loginCustomizations.titleAlignment") || alignmentValues[1].key}
                        >
                          {alignmentValues?.map(item => (
                            <MenuItem key={item.key} value={item.key}>
                              {item.value}
                            </MenuItem>
                          ))}
                        </BXInput>
                      </Grid> */}
                    <Grid xs={12} item container spacing={3}>
                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          name={"appConfig.loginCustomizations.backgroundImage"}
                          render={({ field: { onChange, value } }) => (
                            <FormControl fullWidth>
                              <TextField
                                fullWidth
                                inputProps={{
                                  accept: "image/*",
                                }}
                                type='file'
                                label={"Background Image"}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                disabled={!!isBackgroundImageLoading}
                                onChange={(e: any) => {
                                  handleChange(e, onChange, setIsBackgroundImageLoading);
                                }}
                                size={"medium"}
                              />
                            </FormControl>
                          )}
                        />
                        {watch("appConfig.loginCustomizations.backgroundImage") && (
                          <img
                            src={watch("appConfig.loginCustomizations.backgroundImage")}
                            style={{
                              maxHeight: 150,
                              width: "100%",
                              objectFit: "contain",
                              marginTop: 12,
                            }}
                          />
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        <Controller
                          control={control}
                          name={"appConfig.loginCustomizations.loginIcon"}
                          render={({ field: { onChange, value } }) => (
                            <FormControl fullWidth>
                              <TextField
                                fullWidth
                                inputProps={{
                                  accept: "image/png,image/jpeg,image/jpg",
                                }}
                                type='file'
                                label={"Login Icon"}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                disabled={!!isAppIconLoading}
                                onChange={(e: any) => {
                                  handleChange(e, onChange, setIsAppIconLoading);
                                }}
                                size={"medium"}
                              />
                            </FormControl>
                          )}
                        />
                        {watch("appConfig.loginCustomizations.loginIcon") && (
                          <img
                            src={watch("appConfig.loginCustomizations.loginIcon")}
                            style={{
                              maxHeight: 150,
                              width: "100%",
                              objectFit: "contain",
                              marginTop: 12,
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </>
                )}
                {tabValue == 6 && (
                  <Box sx={{ marginTop: 2, marginLeft: 2 }}>
                    <ItemsPropertiesAccordion
                      expanded={accordionState.snackbar}
                      onChange={() => handleAccordionChange("snackbar")}
                      label='Snackbar'
                      isAdmin={true}
                    >
                      <Grid container px={2} py={1} spacing={2}>
                        <Grid item xs={6}>
                          <BXInput
                            variant='outlined'
                            required
                            label={"Duration"}
                            type='number'
                            id={"bx-app-snackbar-duration-input"}
                            defaultValue={"5"}
                            control={control}
                            name={"appConfig.snackbar.duration"}
                            error={(errors as any)?.appConfig?.snackbar?.duration}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <BXInput
                            variant='outlined'
                            required
                            label={"Max Snack"}
                            type='number'
                            defaultValue={"3"}
                            control={control}
                            name={"appConfig.snackbar.maxSnack"}
                            error={(errors as any)?.appConfig?.snackbar?.maxSnack}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <Controller
                            name='appConfig.snackbar.variant'
                            control={control}
                            defaultValue='default'
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                freeSolo
                                options={options}
                                inputValue={inputValue}
                                onInputChange={(event, newInputValue) => {
                                  setInputValue(newInputValue);
                                }}
                                filterOptions={filterOptions}
                                onChange={(event, newValue) => {
                                  if (typeof newValue === "string" && newValue.startsWith('Add "')) {
                                    const newVariant = inputValue;
                                    const updatedOptions = [...options, newVariant];
                                    setOptions(updatedOptions);
                                    setValue("appConfig.snackbar.allVariants", updatedOptions);
                                    setValue(`appConfig.snackbar.variantsSettings.${newVariant}`, {
                                      backgroundColorAdvance: "",
                                      colorAdvance: "",
                                      iconAdvance: "",
                                      iconColor: "",
                                      alignText: "left",
                                    });
                                    field.onChange(newVariant);
                                    setShowCustomVariantInputs(true);
                                  } else {
                                    field.onChange(newValue);

                                    if (defaultVariants.includes(newValue)) {
                                      setShowCustomVariantInputs(false);
                                    } else {
                                      setShowCustomVariantInputs(true);
                                    }
                                  }
                                }}
                                renderOption={(props, option) => (
                                  <li {...props}>
                                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                          marginRight: "20px",
                                        }}
                                      >
                                        {option}
                                      </div>
                                      {!defaultVariants.includes(option) && options.includes(option) && (
                                        <IconButton
                                          size='small'
                                          onClick={event => {
                                            event.stopPropagation();
                                            const updatedOptions = options.filter(opt => opt !== option);
                                            setOptions(updatedOptions);
                                            setValue("appConfig.snackbar.allVariants", updatedOptions);

                                            const currentVariantsSettings = watch("appConfig.snackbar.variantsSettings");
                                            delete currentVariantsSettings[option];
                                            setValue("appConfig.snackbar.variantsSettings", currentVariantsSettings);

                                            if (field.value === option) {
                                              field.onChange("default");
                                              setInputValue("default");
                                            }
                                          }}
                                        >
                                          <IconTrashX color='red' />
                                        </IconButton>
                                      )}
                                      {!defaultVariants.includes(option) && !options.includes(option) && customVariants?.length > 0 && (
                                        <Tooltip title='Copy from an existing Custom Type'>
                                          <IconButton
                                            size='small'
                                            onClick={e => {
                                              e.stopPropagation();
                                              preventDefaultRef.current = true;
                                              setNewType(option);
                                              setIsCopyValueModalOpen(true);
                                            }}
                                          >
                                            <IconCopy style={{ fontSize: "12px" }} />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </div>
                                  </li>
                                )}
                                renderInput={params => <TextField {...params} label='Variant' />}
                              />
                            )}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <BXInput
                            label={"Position"}
                            name={"appConfig.snackbar.position"}
                            control={control}
                            defaultValue={"bottom-right"}
                            select
                            error={(errors as any)?.appConfig?.snackbar?.position}
                          >
                            {["top-left", "top-center", "top-right", "bottom-left", "bottom-center", "bottom-right"]?.map(item => (
                              <MenuItem key={item} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                          </BXInput>
                        </Grid>
                        <Grid item xs={6}>
                          <BXInput
                            label={"Action Button"}
                            name={"appConfig.snackbar.closeButton"}
                            control={control}
                            defaultValue={"Without Button"}
                            select
                            error={(errors as any)?.appConfig?.snackbar?.closeButton}
                          >
                            {["Without Button", "Dismiss", "Icon X", "Corner X"]?.map(item => (
                              <MenuItem key={item} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                          </BXInput>
                        </Grid>
                        <Grid item xs={6}></Grid>
                        <BXModal
                          label='Copy from an existing Custom Type'
                          maxWidth='sm'
                          title='Copy from an existing Custom Type'
                          icon={<IconCopy />}
                          open={isCopyValueModalOpen}
                          onClose={() => setIsCopyValueModalOpen(false)}
                        >
                          {(handleClose: Function) => {
                            return (
                              <>
                                <TextField
                                  size='medium'
                                  select
                                  fullWidth
                                  value={selectedType || customVariants?.[0]}
                                  onChange={event => setSelectedType(event.target.value)}
                                  sx={{
                                    marginBottom: 2,
                                    fontSize: "14px",
                                  }}
                                >
                                  {customVariants?.map(option => (
                                    <MenuItem key={option} value={option}>
                                      {option}
                                    </MenuItem>
                                  ))}
                                </TextField>

                                <Button
                                  variant='contained'
                                  color='primary'
                                  onClick={() => {
                                    const updatedOptions = [...options, inputValue];
                                    const valueCopyFrom = getValues(
                                      `appConfig.snackbar.variantsSettings.${selectedType || customVariants[0]}`
                                    );
                                    setOptions(updatedOptions);
                                    setValue("appConfig.snackbar.allVariants", updatedOptions);
                                    setValue(`appConfig.snackbar.variantsSettings.${inputValue}`, valueCopyFrom);
                                    setValue("appConfig.snackbar.variant", inputValue);

                                    handleClose();
                                  }}
                                  sx={{
                                    alignSelf: "flex-end",
                                    paddingX: 3,
                                    paddingY: 1,
                                    fontSize: "14px",
                                  }}
                                >
                                  Add
                                </Button>
                              </>
                            );
                          }}
                        </BXModal>

                        {!defaultVariants.includes(currentVariant) && (
                          <>
                            <Grid item xs={6}>
                              <ColorPicker
                                key={currentVariant}
                                control={control}
                                setValue={setValue}
                                watch={watch}
                                name={`appConfig.snackbar.variantsSettings.${currentVariant}.backgroundColorAdvance`}
                                label='Background Color'
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <ColorPicker
                                key={currentVariant}
                                control={control}
                                setValue={setValue}
                                watch={watch}
                                name={`appConfig.snackbar.variantsSettings.${currentVariant}.colorAdvance`}
                                label='Font Color'
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <BXIconPicker
                                key={currentVariant}
                                control={control}
                                name={`appConfig.snackbar.variantsSettings.${currentVariant}.iconAdvance.icon`}
                                label='Icon'
                                defaultValue={watch(`appConfig.snackbar.variantsSettings.${currentVariant}.iconAdvance.icon`) || ""}
                                previewIcon={true}
                                onChange={value =>
                                  setValue(`appConfig.snackbar.variantsSettings.${currentVariant}.iconAdvance.icon`, value)
                                }
                                objName={`appConfig.snackbar.variantsSettings.${currentVariant}.iconAdvance`}
                                handleSetChangeIconProp={handleSetChangeIconProp}
                                showCaption={false}
                                inputLabelProps={{ style: { fontSize: "12px" } }}
                                inputProps={{ style: { fontSize: "12px" } }}
                                size='medium'
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <ColorPicker
                                key={currentVariant}
                                control={control}
                                setValue={setValue}
                                watch={watch}
                                name={`appConfig.snackbar.variantsSettings.${currentVariant}.iconColor`}
                                label='Icon Color'
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <BXInput
                                key={currentVariant}
                                label={"Align Text"}
                                name={`appConfig.snackbar.variantsSettings.${currentVariant}.alignText`}
                                control={control}
                                defaultValue={"left"}
                                select
                              >
                                {["left", "center", "right"]?.map(item => (
                                  <MenuItem key={item} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </BXInput>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </ItemsPropertiesAccordion>

                    <ItemsPropertiesAccordion
                      expanded={accordionState.script}
                      onChange={() => handleAccordionChange("script")}
                      label='Script'
                      isAdmin={true}
                    >
                      <Grid container px={2} py={1} spacing={2}>
                        <Grid item xs={12}>
                          <BXInput
                            variant='outlined'
                            label={"Head Script"}
                            control={control}
                            multiline
                            minRows={3}
                            name={"appConfig.script.head"}
                            error={(errors as any)?.appConfig?.script?.head}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <BXInput
                            variant='outlined'
                            label={"Body Script"}
                            control={control}
                            multiline
                            minRows={3}
                            name={"appConfig.script.body"}
                            error={(errors as any)?.appConfig?.script?.body}
                          />
                        </Grid>
                      </Grid>
                    </ItemsPropertiesAccordion>
                  </Box>
                )}
              </>
            </>
          )}
          {withAuthConfig && (
            <Grid item xs={12}>
              <Card variant={"outlined"}>
                <CardHeader title={"Auth Config"} style={{ paddingBottom: 0 }} />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <BXInput
                        variant='outlined'
                        required
                        label={"Device Registration API"}
                        id={"bx-app-create-device-register-api-input"}
                        control={control}
                        name={"appConfig.auth.deviceApi"}
                        error={errors?.name}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <BXInput
                        variant='outlined'
                        required
                        label={"Auth API"}
                        id={"bx-app-create-auth-api-input"}
                        control={control}
                        name={"appConfig.auth.authApi"}
                        error={errors?.name}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </Container>
      <Grid item xs={12}>
        <DialogActions style={{ padding: 0, marginTop: 16, justifyContent: "center" }}>
          <Button
            onClick={async () => {
              await handleCustomThemeFileSend();
              handleSubmit(onSave)();
            }}
            variant='contained'
            aria-label={"save"}
          >
            Save
          </Button>
        </DialogActions>
      </Grid>
    </Box>
  );
};
