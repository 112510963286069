// schemas.js
import { schema } from "normalizr";

// Define a component schema
const component = new schema.Entity(
  "components",
  {},
  {
    idAttribute: value => value?.props?.key,
  }
);

const optionMapValue = new schema.Object({
  children: [component],
});

// Define a recursive relationship for children
component.define({
  children: [component],
  optionMap: new schema.Values(optionMapValue),
});

export { component };
