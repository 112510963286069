import {
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  useTheme,
} from "@mui/material";
import { IconArrowBackUp } from "@tabler/icons-react";
import _ from "lodash";
import moment from "moment";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useInfiniteQuery } from "react-query";
import { queryClient } from "src/features/buildxProvider/buildxProviderUtils";
import { enqueueSnackbarRef } from "src/utils/SnackbarUtilsConfigurator";
import axios from "src/utils/axios";

const TemplateHistory = (props: any) => {
  const { template, handleClose, backupTemplate } = props;
  const { palette } = useTheme();
  let queryKey = ["templateHistory", template?.id];

  const { data, hasNextPage, fetchNextPage, isFetching, isError } = useInfiniteQuery(
    queryKey,
    ({ pageParam, queryKey }) => {
      if (queryKey[1] && queryKey[1].length < 3) {
        return {
          data: {
            items: [],
          },
        } as any;
      }
      return axios.get(process.env.REACT_APP_HOST_API_KEY + `/api/admin/template-history/temp-versions/${template?.id}`, {
        params: {
          cursor: pageParam,
          limit: 10,
        },
      });
    },
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage: any) => (lastPage?.data?.hasMore ? lastPage?.data?.cursor : undefined),
      refetchOnWindowFocus: false,
    }
  );

  const [sentryRef] = useInfiniteScroll({
    loading: isFetching,
    hasNextPage: hasNextPage || false,
    onLoadMore: () => fetchNextPage(),
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: isError,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: "0px 0px 400px 0px",
  });

  const handleRestoreApp = (templateHistory: any) => {
    axios
      .post(process.env.REACT_APP_HOST_API_KEY + `/api/admin/template-history/${template?.id}/restore-version/${templateHistory?.id}`)
      .then(() => {
        queryClient.refetchQueries(["template-list"]);
        enqueueSnackbarRef?.("The template has been successfully restored", {
          variant: "success",
        });
        handleClose?.();
      });
  };

  // const handleBackupRestoreApp = (templateHistory: any) => {
  //   backupTemplate(template);
  //   handleRestoreApp(templateHistory);
  // };

  const columns: any[] = [
    { id: "id", label: "ID", minWidth: 270 },
    { id: "createDate", label: "Create Date", minWidth: 270 },
  ];

  const entries = _.flatten(data?.pages?.map((p: any) => p?.data?.items))?.filter?.(Boolean);

  return (
    <TableContainer style={{ backgroundColor: palette.background.paper }}>
      <Table stickyHeader aria-label='sticky table'>
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth, backgroundColor: palette.background.paper }}
              >
                {column.label}
              </TableCell>
            ))}
            <TableCell style={{ minWidth: 250, backgroundColor: palette.background.paper }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {!isFetching && entries?.length === 0 && (
            <TableRow>
              <TableCell colSpan={columns.length + 1} align={"center"}>
                No backups yet
              </TableCell>
            </TableRow>
          )}
          {entries?.map((row: any) => {
            return (
              <TableRow hover role='checkbox' tabIndex={-1} key={row?.id}>
                {columns.map(column => {
                  const value = _.get(row, column.id);

                  return (
                    <TableCell key={column.id}>
                      <Grid container alignItems={"center"}>
                        {column.id === "createDate" ? moment(value).format("YYYY-MM-DD HH:mm:ss") : value || "-"}
                      </Grid>
                    </TableCell>
                  );
                })}
                <TableCell sx={{ display: "flex", gap: 1 }}>
                  <Tooltip title={"Restore"}>
                    <IconButton onClick={() => handleRestoreApp(row)}>
                      <IconArrowBackUp size={18} />
                    </IconButton>
                  </Tooltip>
                  {/* <Tooltip title={"Backup and Restore"}>
                    <IconButton onClick={() => handleBackupRestoreApp(row)}>
                      <IconAnchor size={18} />
                    </IconButton>
                  </Tooltip> */}
                  <Tooltip title={"view Builder"}>
                    <Button
                      variant='contained'
                      onClick={() => {
                        window.open(`/buildx/form-builder/template/history/${row?.id}`, "_blank");
                      }}
                      children={"View Builder"}
                    />
                  </Tooltip>
                </TableCell>
              </TableRow>
            );
          })}
          {isFetching && (
            <TableRow ref={sentryRef}>
              <TableCell colSpan={columns.length || 1}>Loading</TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TemplateHistory;
