import { Button, CircularProgress, Grid, MenuItem, Stack } from "@mui/material";
import { IconEye, IconRefresh } from "@tabler/icons-react";
import { BXInput } from "src/components/BXUI/FormControls";

const SelectViewComponent = ({
  isRefreshing,
  setIsRefreshing,
  handleAppSelect,
  appId,
  openView,
  openNewWindow,
  watch,
  control,
  error,
  formBuilderViews,
  setValue,
  fieldName,
  fieldObject = {},
}) => {
  const handleChange = event => {
    const selectedId = event?.target.value;
    const selectedView = formBuilderViews.find(view => view?.id === selectedId);

    if (Object.keys(fieldObject)?.length > 0) {
      setValue(fieldObject, selectedView);
    } else {
      setValue(fieldName, selectedView?.id);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <BXInput name={fieldName} control={control} error={error} select label={"Select view"} onChange={handleChange}>
          {
            //TODO: Modify this
          }{" "}
          {/* <Box sx={{ maxHeight: "300px", overflowY: "auto" }}> */}
          {formBuilderViews?.map(item => (
            <MenuItem key={item?.id} value={item?.id}>
              {item?.info?.name || item?.type}
            </MenuItem>
          ))}
          {/* </Box> */}
        </BXInput>
      </Grid>

      <Grid item xs={6}>
        <Stack direction='row' spacing={2} mt={1}>
          {watch(fieldName) && (
            <Button variant='outlined' onClick={() => openView(watch(fieldName))}>
              <IconEye size={16} />
            </Button>
          )}

          <Button
            variant='outlined'
            onClick={() => {
              setIsRefreshing(true);
              handleAppSelect(appId).then(() => {
                setIsRefreshing(false);
              });
            }}
          >
            {isRefreshing ? <CircularProgress size={16} /> : <IconRefresh size={16} />}
          </Button>

          <Button onClick={openNewWindow} variant='contained'>
            Create View
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default SelectViewComponent;
