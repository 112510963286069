import React from "react";
import useBuildxProvider from "./hooks/useBuildxProvider";

const RenderBuildxProvider: React.FC<any> = () => {
  useBuildxProvider();

  return <></>;
};

export default RenderBuildxProvider;
