// src/components/AppController.tsx
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppState } from "src/features/appState/hooks";
import { clearErrors, registerValidation, setAppError } from "src/features/endUser/endUserSlice";
import { useReplaceDataPlaceholders } from "../DataTable/ActionButton";
import { getControllerDefaultValue } from "../FormBuilder/utils";

interface AppControllerProps {
  /*
   * The 'name' should represent the relative path of the field, e.g., 'page -> view -> field'.
   */
  name: string;
  defaultValue?: any;
  validate?: { [key: string]: (value: any) => true | string };
  enableDirtyCheck?: boolean;
  disabled?: boolean;
  matchValidationField?: string;
  replaceDataPlaceholdersProps?: any;
  viewNameOption?: any;
  element?: any;
  isRepeated?: any;
  index?: any;
  viewName?: any;
  render: (props: { value: any; onChange: (e: React.ChangeEvent<HTMLInputElement>) => void; error: string }) => JSX.Element;
}

const AppController: React.FC<AppControllerProps> = ({
  name,
  element,
  viewNameOption,
  viewName,
  defaultValue,
  validate,
  enableDirtyCheck,
  disabled,
  matchValidationField,
  replaceDataPlaceholdersProps,
  isRepeated,
  index,
  render,
}) => {
  let stateName = `${name}.state`;
  let initialValueName = `${name}.initialValue`;
  let errorName = `${name}.errors.error`;
  if (isRepeated && !_.isNil(index)) {
    stateName = `${stateName}[${index}]`;
    initialValueName = `${initialValueName}[${index}]`;
    errorName = `${errorName}[${index}]`;
  }

  const { replaceDataPlaceholders, replaceDataPlaceholdersRecursively } = useReplaceDataPlaceholders(viewNameOption);
  const { setValue, watch } = useAppState();
  const dispatch = useDispatch();
  const { item, viewsState, pageId, __data, currentApp } = replaceDataPlaceholdersProps;

  const value = watch(stateName, { pageId, viewName });
  const error = watch(errorName, { pageId, viewName });

  const replaceValue = !_.isObject(defaultValue)
    ? replaceDataPlaceholders({
        queryString: defaultValue,
        ...replaceDataPlaceholdersProps,
      })
    : replaceDataPlaceholdersRecursively({ obj: defaultValue, ...replaceDataPlaceholdersProps });

  const [currentValue, setCurrentValue] = useState();

  useEffect(() => {
    if (validate && !disabled) {
      registerValidation(name, validate);
    }

    //Register the component as repeated
    if (isRepeated && !_.isNil(index)) {
      setValue(`${name}.isRepeated`, true, { pageId, viewName });
    }
  }, []);

  const resolvedDefaultValue = getControllerDefaultValue(
    element,
    item,
    viewsState,
    pageId,
    __data,
    currentApp,
    null,
    null,
    replaceValue,
    true
  );
  const types = ["StepperContainer", "CustomAutoCompleteBX", "PaginationBar"];

  useEffect(() => {
    if (value != resolvedDefaultValue) {
      setValue(
        stateName,
        resolvedDefaultValue,
        {
          pageId,
          viewName,
          repeatedComponentIndex: index,
          setWithInitialValue: true,
        },
        !types.includes(element?.type)
      );
      // setValue(
      //   initialValueName,
      //   resolvedDefaultValue,
      //   {
      //     pageId,
      //     viewName,
      //   },
      //   !types.includes(element?.type)
      // );
    }
  }, [JSON.stringify(replaceValue)]);

  const setValueDebounce = useRef(
    _.debounce((stateName, newValue, validationError) => {
      // Update the value in the state
      setValue(`${stateName}`, newValue, {
        pageId,
        viewName: viewNameOption?.viewName,
        enableDirtyCheck: true,
        error: validationError,
        repeatedComponentIndex: index,
      });
    }, 300)
  ).current;

  const handleChange = (e: any, options?: any) => {
    const { disableDebounce = false } = options || {};
    if (disabled) return;
    let newValue;

    // Check if `e` is an event or a direct value
    if (e && e.target && typeof e.target.value !== "undefined") {
      newValue = e.target.value; // It's an event, get the value from the event
      e.stopPropagation();
      e.preventDefault();
    } else {
      newValue = e; // It's a direct value, use it as it is
    }

    // Validate the input field if a validation function is provided
    let validationError = "";
    if (validate) {
      for (const ruleKey in validate) {
        if (validate.hasOwnProperty(ruleKey)) {
          const rule = validate[ruleKey];
          const result = rule(newValue);
          if (result !== true && result !== undefined) {
            validationError = result as string; // Get the error message from the validation
            break; // Stop on the first validation error
          }
        }
      }
    }

    setCurrentValue(newValue);

    if (disableDebounce || element?.props?.type?.toLowerCase() === "email" || element?.props?.type?.toLowerCase() === "password") {
      setValue(`${stateName}`, newValue, {
        pageId,
        viewName: viewNameOption?.viewName,
        enableDirtyCheck: true,
        error: validationError,
        repeatedComponentIndex: index,
      });
    } else {
      setValueDebounce(stateName, newValue, validationError);
    }
  };

  //If the field has a match validation against another field, rerun validation function on change of target field
  useEffect(() => {
    if (matchValidationField !== undefined) {
      const shouldMatchRule = validate?.shouldMatch;
      const matchResult = shouldMatchRule?.(value ?? resolvedDefaultValue);

      if (matchResult !== true && matchResult !== undefined) {
        dispatch(setAppError({ name, error: matchResult as string, pageId, viewName }));
      } else if (matchResult === true) {
        //If the fields match, remove the error field
        dispatch(clearErrors({ name, pageId, viewName }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchValidationField]);

  return render({
    value: currentValue ?? resolvedDefaultValue,
    onChange: handleChange,
    error: error,
  });
};

export { AppController };
