import { Box, Button } from "@mui/material";
import { IconEdit, IconSettings } from "@tabler/icons-react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BXModal from "src/components/BXUI/Modal";
import { setViewBuilder } from "src/features/builder/builderSlice";
import { RootState } from "src/store/store";
import { UIElement } from "src/types/UIElement";
import { CreateViewForm } from "../AppBuilder/forms/CreateViewForm";

interface CurrentViewConfigProps {
  appId: string;
  collectionId: string;
  pageId: string;
  viewId: string;
  page: any;
  views: Array<UIElement>;
}

export const CurrentViewConfig: React.FC<CurrentViewConfigProps> = props => {
  const { appId, collectionId, pageId, viewId, page, views } = props;
  const currentView: any = useSelector((state: RootState) => state.builder.viewConfiguration);

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const dispatch = useDispatch();

  return (
    <Box p={1}>
      <Button variant='outlined' onClick={() => setIsEdit(true)}>
        Edit View
      </Button>
      <BXModal
        open={isEdit}
        title={currentView?.info?.name ? `${currentView?.info?.name}` : ""}
        icon={<IconSettings />}
        buttonProps={{ startIcon: <IconEdit />, color: "secondary", size: "small" }}
        minWidth={600}
        maxWidth='lg'
        onClose={() => {
          setIsEdit(false);
        }}
        isDirty={isDirty}
      >
        {(handleClose: Function) => {
          return (
            <CreateViewForm
              id={viewId}
              appId={appId}
              collectionId={collectionId}
              pageId={pageId}
              viewId={viewId}
              view={currentView}
              layout={page?.layout}
              tables={null}
              views={views}
              onCancel={() => {
                handleClose(false, () => {
                  setIsEdit(false);
                });
              }}
              onSave={(data: any) => {
                dispatch(
                  setViewBuilder({
                    viewConfiguration: data,
                  })
                );
                setIsEdit(false);
                handleClose?.(true);
              }}
              setIsDirty={setIsDirty}
              insideFormBuilderEditor
            />
          );
        }}
      </BXModal>
    </Box>
  );
};
